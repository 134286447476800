import React from 'react'
import {Input,Button,Alert,Modal} from 'reactstrap'
import axios from 'axios'
import qs from 'qs'
import history from '../history'
import * as GLOBAL from './global.js'
import {reactLocalStorage} from 'reactjs-localstorage'
import LoadingScreen from '../components/LoadingScreen'

export default class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invalidLoginAlert:false,
            invalidLoginMessage:"",
            username:"",
            password:"",

            openAgreementModal:false,
            isChecked:false,
        }
        this.redirect=null
    }

    componentDidMount=()=>{
        //scroll to top
        window.scrollTo(0, 0)
        axios.defaults.headers.common.Authorization = ""
        this.launchFunc()
    }

    launchFunc=()=>{
        //parse search path inputs and process accordingly
        //parse
        let pars=window.location.search.substring(1)
        let inputSplit=pars.split("&")
        let parsedInputSplit=[]
        for(let input of inputSplit){
            parsedInputSplit.push(input.split("="))
        }
        //process
        for(const input of parsedInputSplit){
            let param=input[0]
            let value=input[1]
            if(param=="fbclid"){  //when user shared via facebook
                //do nothing
            }else if(param=="redirect"){
                if(input[2]){
                    let redirectLink=value+"="+input[2] //extra value parameter
                    this.redirect=redirectLink
                }else{
                    let redirectLink=value //extra value parameter
                    this.redirect=redirectLink
                }
            }
        }

        if(reactLocalStorage.get("token")!=null){
            if(reactLocalStorage.get("signedAgreement")=="true"||reactLocalStorage.get("signedAgreement")==true){
                this.visitFirstPage()
            }else{
                this.setState({openAgreementModal:true})
            }
        }
    }

    handleCheckboxChange=(event)=> {
        this.setState({ isChecked: event.target.checked });
      }

    setAgreement=()=>{
        const payload = {
            id:reactLocalStorage.get('id'),
        }
        axios
        .post('sales/setagreement',qs.stringify(payload))
        .then(async response => {
            alert("Successfully signed agreement")
            this.setState({
                loading:false,
                openAgreementModal:false,
            })
            this.visitFirstPage()
        })
        .catch(error=>{
            alert("Sign agreement error\n"+error)
            this.setState({
                loading:false,
                openAgreementModal:false,
            })
        })
    }

    loginFormSend=()=>{
        //collect login form data
        let username=this.state.username.trim()
        let password=this.state.password
        //validate login form data
        if(username.trim().length==0){
            this.setState({
                invalidLoginAlert:true,
                invalidLoginMessage:"Username cannot be empty",
            })
            return
        }
        if(password.length==0){
            this.setState({
                invalidLoginAlert:true,
                invalidLoginMessage:"Password cannot be empty",
            })
            return
        }
        //pass validation
        this.setState({invalidLoginAlert:false})

        //send login data to server for login
        this.setState({loading:true})
        const payload = {
            username: this.state.username,
            password: this.state.password
        }
        axios
            .post(`sales/saleslogin`, qs.stringify(payload))
            .then(async response => {
                //save returned token
                reactLocalStorage.set("token",response.data.token)
                axios.defaults.headers.common.Authorization = `Token ${response.data.token}`
                //flush the states
                this.setState({
                    loginDropdown:false,
                    username:"",
                    password:"",
                })
                if(this.redirect!=null&&this.redirect.includes("joinagent")){
                    history.push('/'+this.redirect)
                }else{
                    //get employee detail
                    axios
                        .get(`sales/getemployeedetail`)
                        .then(async response => {
                            //login success
                            //job
                            reactLocalStorage.set('job',response.data.job.jobName)
                            let permissionArray=[]
                            for(let perm of response.data.job.permissions){
                                permissionArray.push(perm.code)
                            }
                            //extra job
                            for(let job of response.data.extraJob){
                                for(let perm of job.permissions){
                                    permissionArray.push(perm.code)
                                }
                            }
                            //tier
                            reactLocalStorage.set('tier',JSON.stringify(response.data.tier))
                            //permissions
                            reactLocalStorage.set('permissions',permissionArray.join(","))
                            reactLocalStorage.set('advisorCompany',response.data.advisorCompany)
                            if(response.data.company!=null){
                                reactLocalStorage.set('companyName',response.data.company)
                            }else{
                                reactLocalStorage.set('companyName',null)
                            }
                            reactLocalStorage.set('companyOfflineEvent',response.data.companyOfflineEvent)
                            //save the returned data
                            reactLocalStorage.set('id', response.data.member.id)
                            reactLocalStorage.set('username', response.data.member.username)
                            reactLocalStorage.set('email', response.data.member.email)
                            reactLocalStorage.set('name', response.data.member.name)
                            reactLocalStorage.set('membership_type', response.data.member.membership_type)
                            //parse membership type to chinese
                            reactLocalStorage.set('membership_type_chinese',this.parseMembershipTypeToChinese(response.data.member.membership_type))
                            reactLocalStorage.set('profileImage', response.data.member.profileImage)
                            reactLocalStorage.set('topupWallet', response.data.member.topupWallet)
                            reactLocalStorage.set('point', response.data.member.point)
                            reactLocalStorage.set('referCode',response.data.member.referCode)
                            reactLocalStorage.set('signedAgreement',response.data.signedAgreement)
                            
                            if(response.data.signedAgreement == true){
                                this.visitFirstPage()
                            }
                            else{
                                this.setState({openAgreementModal:true})
                            }
                            this.setState({loading:false})
                        })
                        .catch(error => {
                            reactLocalStorage.clear()
                            if(error.response){
                                if(error.response.status==444){  //not an employee
                                    alert("Only Enlliance Employee and Agent are allowed to login this page")
                                    this.setState({loading:false})
                                    return
                                }
                            }
                            alert("Get user detail error\n"+error)
                            this.setState({loading:false,})
                        });
                }
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==400){
                        this.setState({
                            invalidLoginAlert:true,
                            invalidLoginMessage:"登入电话号码或密码错误",
                        })
                        reactLocalStorage.clear()
                        return
                    }
                }
                reactLocalStorage.clear()
                alert("Login error\n"+error)
                this.setState({loading:false})
            })
    }

    parseMembershipTypeToChinese=(mType)=>{
        if(mType=="CM"){   //Classic Member
            return "标准会员"
        }else if(mType=="GM"){  //Gold Member
            return "黄金会员"
        }else if(mType=="SM"){  //Strategy Member
            return "战略会员"
        }else if(mType=="CP"){   //Classic Partner
            return "标准伙伴"
        }else if(mType=="GP"){   //Gold Partner
            return "黄金伙伴"
        }else if(mType=="SP"){   //Strategy Partner
            return "战略伙伴"
        }else if(mType=="Staff"){
            return "顾客的仆人"
        }else{
            return "--"
        }
    }

    visitFirstPage=()=>{
        if(this.redirect==null){
            if(GLOBAL.checkPermission("-!95U_]G")){
                history.push("/saleslog")
            }else if(reactLocalStorage.get("companyOfflineEvent")!=null){
                let companyOfflineEvent=JSON.parse(reactLocalStorage.get("companyOfflineEvent"))
                history.push('/offlineevent?type='+companyOfflineEvent[0].sku+"&name="+companyOfflineEvent[0].name,"_self")
            }else if(GLOBAL.checkPermission("*[4T$P.*")){
                history.push("/agency")
            }else{
                history.push("/restricted")
            }
        }else{
            history.push('/'+this.redirect)
        }
    }

    render(){
        const { isChecked } = this.state;
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }
                
                <Modal isOpen={this.state.openAgreementModal} style={{maxWidth:500}}>
                <div style={{padding:15,display:"flex",flexDirection:"column",alignItems:"flex-start",maxWidth:800,width:"100%"}}>
                <div style={{marginTop:20,width:"100%"}}>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                <div style={{fontWeight:"bold"}}>
                                    Enlliance Agent Agreement (EAA)
                                </div>
                            </div>
                            <div style={{overflowY:"scroll",border:"1px solid lightgrey",width:"100%",padding:5,fontFamily:'Trebuchet MS',fontSize:13,maxHeight:600}}>
                                This Enlliance Agent Agreement (“EAA”) is entered into by and between Enlliance Management Sdn. Bhd., a Malaysia
                                company, located at No.32-2, Jalan PJU 5/21, The Strand, Kota Damansara, 47810 Petaling Jaya, Selangor D.E.,
                                Malaysia (“Enlliance”) and the company or individual with details registered in the system, (“the Agent”) (each a
                                “Party” and collectively the “Parties”).<br/><br/>
                                The Agent Addendum entered into by the Parties constitute the integral part of this EAA. Capitalized terms used below,
                                but not defined herein, shall have the meaning ascribed to such terms in the Order Form. The EAA may not be modified
                                or amended except in a writing signed by a duly authorized representative of each party; and no other act, document,
                                usage or custom shall be deemed to amend or modify the EAA.<br/><br/>
                                <b>1. DEFINITIONS AND INTERPRETATIONS</b><br/>
                                1.1 Unless the context otherwise requires, the following expressions shall have the following meanings in this EAA:<br/><br/>
                                1.1.1 “Agent”, in relation to a Party, means any entity which controls, is controlled by, or is under common control with
                                such Party (where “control”, including its correlative meanings such as “controlled by”, “controls” and “under common
                                control with”, means, the direct or indirect power to direct or cause the direction of the management and policies of a
                                corporation, whether through the ownership of voting securities, by contract, or otherwise).<br/><br/>
                                1.1.2 “Anti-Bribery Laws” means the Malaysian Anti-Corruption Commission Act 2009, Prevention of Corruption Act
                                (Chapter 241 of Singapore), the UK Bribery Act 2010, the United States Foreign Corrupt Practices Act, the French Sapin
                                II law (Law No. 2016-1691) and any other anti-corruption or anti-bribery laws or regulations applicable to either Party.<br/><br/>
                                1.1.3 “Applicable Laws” means any law, regulation and order at state and local levels which is mandatory in Territory
                                and Malaysia.<br/><br/>
                                1.1.4 “Application” means the platform known as “Enlliance/企盟家/GatherDeal”.<br/><br/>
                                1.1.5 “Business Day” means any day other than Saturday, Sunday or a holiday on which Malaysia banks are open for
                                general business. Unless the context otherwise requires, all periods terminating on a given day, period of days, or date
                                shall terminate at 24:00 hour. (Malaysia Time) on such date or dates, and references to "days" shall refer to calendar
                                days, except if such references are to Business Days. Any event specified to occur on a non-Business Day shall be
                                extended automatically to the end of the first Business Day thereafter.<br/><br/>
                                1.1.6 “Confidential Information” is defined in Section 7.1.<br/><br/>
                                1.1.7 “Data Protection Legislation” means all laws and regulations relating to data protection or privacy, including the
                                The Personal Data Protection Act 2010 (PDPA), as such laws and regulations may be introduced, amended or revised
                                from time to time.<br/><br/>
                                1.1.8 “End Customer” means a customer who orders any Enlliance courses (online or offline) from Agent and
                                authorizes its users/ referrals to attend<br/><br/>
                                1.1.9 “Force Majeure Event” means any fire, flood, elements of nature, acts of God, health epidemics declared by the
                                World Health Organization, acts of war, terrorism or civil unrest, industrial action in any form (except industrial action
                                by employees of either Party) in the country in which such obligations are being performed or any other similar events
                                beyond the reasonable control of the Party which prevents or delays such Party’s performance or observance of its
                                obligation under this Agreement.<br/><br/>
                                1.1.10 “Intellectual Property Rights” means all copyright, patents, trademarks, service marks, layout design rights,
                                registered designs, design rights, database rights, trade or business names, rights protecting trade secrets and confidential
                                information, rights protecting goodwill and reputation, and all other similar or corresponding proprietary rights and all
                                applications for the same, whether presently existing or created in the future, anywhere in the world, whether registered
                                or not, and all rights to sue, recover damages and obtain relief or other remedies for any past, current or future
                                infringement, misappropriation or violation of any of the foregoing rights.<br/><br/>
                                1.1.11 “Enlliance Brand Guidelines” means Enlliance’s standards, specifications, procedures, requirements and
                                instructions on the Enlliance Marks which will be provided by Enlliance separately.<br/><br/>
                                1.1.12 “Enlliance Courses” means the online products and services made available by Enlliance via the Users login link
                                located at www.enlliance.com and/or such other web pages designated by Enlliance including associated offline
                                components such as offline courses or events<br/><br/>
                                1.1.13 “Enlliance Marks” means Enlliance’s trademarks, service marks, logos, logotypes, signatures and design marks
                                (whether registered or not) including but not limited to “Enlliance”, “企盟家”, and “GatherDeal”.<br/><br/>
                                1.1.14 “Losses” means all claims, settlement sums, costs (including legal costs on a solicitor-client basis), losses,
                                expenses or other liabilities, whether foreseeable or not.<br/><br/>
                                1.1.15 “Agent Order Form” means a written ordering document for Agent’s purchases of Enlliance’s products to
                                distribute/resell to End Customer, if applicable.<br/><br/>
                                1.1.16 “Agent Rights” means the rights granted to the Agent as set out in the Addendum.<br/><br/>
                                1.1.17 “Personal Data” means all information (including for the avoidance of doubt opinions) which identifies, or
                                which relates to an individual, whether true or not, in any form, including:<br/>
                                    (i) all data which is defined to be "personal data" under the Personal Data Protect Act (PDPA) and all data
                                    which is defined to be an equivalent under any applicable Data Protection Legislation; and<br/>
                                    (ii) all information, the collection, disclosure, use or processing of which is subject to the Data
                                    Protection Legislation;<br/><br/>
                                1.1.18 “Privacy Policy” means the form of policy relating to personal data and privacy applicable to the license of the
                                Services by Enlliance to its users, as may be amended from time to time at the sole discretion of Enlliance, via the Users
                                login link located at www.enlliance.com.<br/><br/>
                                1.1.19 “Public Official” means an officer or employee of:<br/>
                                    &nbsp;&nbsp;(i) a government (including its agencies and departments); a government-owned or government- controlled entity;<br/>
                                    &nbsp;&nbsp;(ii) a regulatory entity;<br/>
                                    &nbsp;&nbsp;(iii) a public international organization;<br/>
                                    &nbsp;&nbsp;(iv) a political party; or<br/>
                                    &nbsp;&nbsp;(v) any person acting in an official capacity on behalf of any of the above.<br/><br/>
                                1.1.20 “Term” is defined in Section 9.1.<br/><br/>
                                1.1.21 “Enlliance Course Agreement” means the form of agreement applicable to the agreement of the
                                courses/Services by Enlliance to an End Customer, as may be amended from time to time at the sole discretion of
                                Enlliance.<br/><br/>
                                1.1.22 “Territory” is defined in the Addendum.<br/><br/>
                                1.1.23 “Third Party” means a legal entity or person that is not a Party to this EAA.<br/><br/>
                                1.2 Interpretation. In this EAA:<br/>
                                    &nbsp;&nbsp;(i) the Addendum to this EAA shall be incorporated into and deemed part of this EAA and all references to this EAA
                                    shall include the Addendum to this EAA unless the context otherwise requires;<br/>
                                    &nbsp;&nbsp;(ii) whenever the words “include”, “includes” or “including” are used in this EAA, they will be deemed to be followed
                                    by the words “without limitation”;<br/>
                                    &nbsp;&nbsp;(iii) words importing the singular only shall also include the plural and vice versa where the context requires; and<br/>
                                    &nbsp;&nbsp;(iv) Section headings are inserted for convenience only and shall not affect the interpretation of this EAA.<br/><br/>
                                1.3 Except as otherwise set forth in the body of this EAA or in any Addendum, in the event of a conflict or
                                inconsistency between the provision in the body of this EAA and the provisions in an Addendum, the provisions will be
                                applied in the following order of precedence with the provisions higher in the order of precedence prevailing over the
                                provisions in the lower order of precedence: (i) the provisions in the Order Form; (ii) the provisions in the Addendum;
                                (iii) the provisions in the body of this EAA.<br/><br/><br/>

                                <b>2. ENLLIANCE AGENT NETWORK PROGRAM</b><br/>
                                2.1 Joining the Program. To join the Program, Agent agrees to be bound by this EAA. For the avoidance of doubt,
                                participation in this Program does not authorize Agent to resell or sublicense our services, unless agreed upon in the
                                Addendum between the Parties.<br/><br/>
                                2.2 Agent Rights. In connection with the appointment in Section 2.1 above, Enlliance agrees to grant to the Agent, the
                                Agent Rights described in the Addendum.<br/><br/>
                                2.3 Reservation of rights: For the avoidance of doubt, all rights, whether now existing or which may hereafter come
                                into existence, with respect to or in relation to the Enlliance courses and services in all countries of the world which are
                                not specifically granted in the Addendum are reserved exclusively to Enlliance.<br/><br/>
                                2.4 Enlliance has the absolute right to:<br/>
                                2.4.1 amend the course and services and the Privacy Policy upon which the Enlliance courses and services are offered
                                by Enlliance to the End Customers and its users; and<br/>
                                2.4.2 administer, control and/or modify the access privileges and permissions to any aspect of the Enlliance courses and
                                services in relation to any End Customer.<br/><br/>

                                <b>3. INTELLECTUAL PROPERTY AND PERSONAL DATA PROTECTION</b><br/>
                                3.1 Enlliance Marks<br/>
                                3.1.1 Enlliance hereby grants to the Agent, and the Agent accepts, during the Term, a royalty-free, non-exclusive, nontransferable
                                and non-sublicensable right and license to use the Enlliance Marks or such other marks as Enlliance may
                                direct from time to time for the purpose of use set forth in the Addendum.<br/><br/>
                                3.1.2 The Agent hereby acknowledges and agrees that Enlliance is the sole, exclusive and lawful owner of Enlliance
                                Marks (including word marks and logos) designs and all domain names which include the “Enlliance” and “企盟家
                                “ name lawfully registered and/or filed by Enlliance or by any authorized entity on behalf of it.<br/><br/>
                                3.1.3 Nothing contained in this EAA is intended to, or shall be construed to vest in, transfer or otherwise convey to the
                                Agent any right, title or interest in, or to, the Enlliance Marks or any other trademark used by Enlliance and Enlliance’s
                                group, designs, patents and domain names or the goodwill associated therewith or pertaining thereto, other than the right
                                to use the Enlliance Marks in connection hereof, for the duration of this EAA and under the conditions set forth herein.<br/><br/>
                                3.1.4 The Parties agree that the provisions of the Trade Marks Act 1976 and the Trade Marks Regulations 1997 or
                                similar or equivalent under any applicable laws, if any, are hereby expressly excluded by the Parties for the purposes of
                                this Agreement.<br/><br/>
                                3.1.5 The Agent may not, during or after the duration of this EAA, engage in any conduct directly or indirectly that
                                would infringe upon, harm or contest Enlliance’s rights in any of the Enlliance Marks or the goodwill associated with the
                                Enlliance Marks and others intellectual property rights, including any use of the Enlliance Marks in a derogatory,
                                negative, or other inappropriate manner in any media, including but not limited to print or electronic media.<br/><br/>
                                3.1.6 The Agent shall not register or use, or attempt to register or use any copyright, designs, patent and internet domain
                                name or trade name (including corporate names) containing, or alluding to, the Enlliance Marks or “Enlliance” “企盟
                                家” “GatherDeal”word or logo, or any trademark similar or confusingly similar to any Enlliance Marks.<br/><br/>
                                3.1.7 The Agent agrees to use Enlliance Marks solely in connection with, and exclusively for, the display, marketing,
                                promotion and sale of the Enlliance courses and services as provided for in this EAA and the Addendum. If any
                                Applicable Laws (as defined in Section 1) so requires, the Agent may use an alternate version of the Enlliance Marks,
                                subject to the prior written approval of Enlliance. The Parties agree that the use of such alternate version of the Enlliance
                                Marks shall be subject to the terms and conditions set forth herein and, as the case may be, the instructions to that effect
                                given by Enlliance.<br/><br/>
                                3.1.8 All use of Enlliance Marks must comply at all times with the standards, specifications, procedures, requirements
                                and instructions available in the Enlliance Brand Guidelines.<br/><br/>
                                3.1.9 The Agent may use the Enlliance Marks on various materials, according to the Enlliance Brand Guidelines and
                                provided the Agent: (a) accurately depicts the Enlliance Marks on the said materials as prescribed in the Enlliance
                                Brand Guidelines or otherwise from Enlliance; (b) includes a statement on the materials indicating that the business is
                                independently owned and operated by the Agent; (c) does not use the Enlliance Marks in connection with any other
                                trademarks, logo or trade names; and (d) makes preliminarily available to Enlliance for approval a draft copy of any
                                materials depicting the Enlliance Marks, before adopting the same. For the avoidance of doubt, if Enlliance does not
                                approve any draft copy of any materials, the Agent shall not use the Enlliance Marks on such materials.<br/><br/>
                                3.1.10 The Agent may not make any changes or substitutions to the Enlliance Marks unless Enlliance directs in writing
                                to do so. Enlliance reserves the right to change the Enlliance Marks at any time. Upon receipt of Enlliance’s notice to
                                change the Enlliance Marks, the Agent must cease using the former Enlliance Marks and commence using the changed
                                Enlliance Marks, at its sole expense.<br/><br/>
                                3.1.11 The Agent shall fully cooperate with Enlliance in order to protect and enhance Enlliance’s goodwill and
                                Enlliance Marks. The Agent must promptly notify Enlliance of any misuse, infringement or counterfeiting of Enlliance
                                Marks of which the Agent is (or should reasonably be) aware or any challenge or claim arising out of the Agent’s use of
                                any Enlliance Marks. The Agent shall not take any action (legal action or otherwise) without Enlliance's prior request
                                and written consent. Enlliance will have the right to determine whether a suit will be instituted, prosecuted or settled, the
                                terms of settlement and whether any other action will be taken in respect thereof. The Agent must take reasonable steps
                                and collaborate, without compensation, to assist Enlliance with any action Enlliance undertakes. Enlliance will be
                                responsible for fees and expenses with any such action, unless the challenge or claim results from the Agent’s misuse of
                                the Enlliance Marks in violation of this EAA and the Addendum, in which case the Agent must reimburse which case the
                                Agent must reimburse Enlliance for any such fees and expenses or any such fees and expenses.<br/><br/>
                                3.2 Intellectual Property Rights to the Enlliance Courses and Services<br/>
                                3.2.1 The Agent hereby agrees and acknowledges that any and all Intellectual Property Rights in, over or relating to the
                                Enlliance Courses and Services shall vest at all times in Enlliance and are the exclusive and proprietary property of
                                Enlliance.<br/><br/>
                                3.2.2 The Agent shall not be entitled to, or allow, permit or license any Third Party to, directly or indirectly: (i) reverse
                                engineer, decompile, disassemble, localize, port or translate any part of the Enlliance Courses and Services; or
                                (ii) prepare or develop derivative works based on the Enlliance Courses and Services, without prior consent of
                                Enlliance.<br/><br/>
                                3.2.3 The Agent shall not challenge the validity of the Enlliance Courses and Services, nor shall the Agent challenge
                                Enlliance’s ownership of the Enlliance Courses and Services or the enforceability of Enlliance’s rights therein. All use of
                                the Enlliance Courses and Services by the Agent shall inure to the benefit of Enlliance.<br/><br/>
                                3.2.4 Nothing in this EAA shall give the Agent any rights in respect of any of the Enlliance Courses and Services or any
                                goodwill associated with any of the Enlliance Courses and Services. The Agent acknowledges that Enlliance is the
                                owner of the Enlliance Courses and Services, and that, except as expressly provided in this EAA, the Agent shall not
                                acquire any rights in respect thereof and that all such rights and goodwill are, and shall remain, vested in Enlliance.<br/><br/>
                                3.2.5 To the extent that any Intellectual Property Rights in or to any part of the Enlliance Courses and Services may
                                remain or become vested in the Agent or where any judicial or other determination purports to grant rights in the
                                Enlliance Courses and Services to the Agent or to the Agent’s benefit, the Agent hereby irrevocably assigns such rights
                                or benefits to Enlliance, together with any goodwill related thereto.<br/><br/>
                                3.3 Personal Data. The Parties shall, in its collection, use, processing or disclosure of any Personal Data, for any
                                purpose arising out of or in connection with this Agreement, adhere to the requirements of all Data Protection
                                Legislation.<br/><br/>

                                <b>4. COMPLIANCE WITH LAW – LICENSES AND PERMITS</b><br/>
                                4.1 The Agent must at all times – and for the entire duration of this EAA - conduct its operations in compliance with
                                Applicable Laws. The Agent must secure and maintain in force all required licenses, permits and certificates relating to
                                its business.<br/><br/>
                                4.2 The Agent shall operate its business as an independent business and shall be responsible for its control and
                                management, including, but not limited to, the hiring and laying-off of employees and setting and paying employees’
                                wages and benefits. The Agent acknowledges that Enlliance has no power, responsibility or liability in respect to the
                                hiring, laying- off, setting and paying of wages or related matters.<br/><br/>
                                4.3 The Agent shall immediately notify Enlliance in writing in the event of a conflict between any Applicable Laws and
                                the Agent’s obligations under this EAA and comply with the ensuing instructions which will be given by Enlliance. The
                                Agent must likewise immediately notify Enlliance in writing of any claim, litigation or proceeding that arises from or
                                affects the operation or financial condition of the Agent.<br/><br/>

                                <b>5. PERSONNEL AND TRAINING</b><br/>
                                5.1 The Agent will employ an adequate number of highly competent and trained employees or agents under its care to
                                insure prompt, courteous and efficient service and assistance to customers. Such personnel shall have experience in
                                “Sales and Marketing”or “Digital Marketing” business.<br/><br/>
                                5.2 The Agent must, at its own expense, comply with all of the training requirements (also on an on-going basis)
                                prescribed by Enlliance. In the event the Agent is given notice of default as set forth in Section 10 and the default relates,
                                in whole or in part, to the Agent’s failure to meet any operational standards, Enlliance shall have the right to require as a
                                condition of curing the default that the Agent, at its sole expense, comply with the additional training requirements
                                Enlliance prescribes.<br/><br/>
                                5.3 The Agent (and/or its personnel) must attend, at its own expense, all conventions Enlliance may hold or sponsor and
                                all meetings relating to new products, new operational procedures or programs, training, sales or sales promotion, or
                                similar topics.<br/><br/>

                                <b>6. REPRESENTATIONS AND WARRANTIES</b><br/>
                                6.1 Each Party represents and warrants to the other party that:<br/><br/>
                                6.1.1 it is duly organized, validly existing and in good standing as a corporation or other entity as represented herein
                                under the laws and regulations of its jurisdiction of incorporation, organization or chartering;<br/><br/>
                                6.1.2 it has the full right, power and authority to enter into this Agreement, to grant the rights and licenses granted
                                hereunder and to perform its obligations hereunder; and<br/><br/>
                                6.1.3 this Agreement, when executed, will constitute valid and binding obligations of such Party, in accordance with the
                                terms herein.<br/><br/>
                                6.2 The Agent represents and warrants to Enlliance that:<br/>
                                6.2.1 neither Enlliance nor any representative of Enlliance has made any representation or warranty, oral or otherwise,
                                that is inconsistent with the terms and conditions set forth in this EAA so as to induce the Agent to execute this EAA and
                                the Addendum;<br/><br/>
                                6.2.2 it has carefully reviewed the terms and conditions of this EAA with the assistance of a competent financial and
                                legal counsel or any other counsel selected by the Agent (at its own expense) and undertakes all business and financial
                                risks associated thereto;<br/><br/>
                                6.2.3 the Agent does not enter into Enlliance Agent Network Program and this EAA for speculative purposes;<br/><br/>
                                6.2.4 it has adequate financial resources and relevant expertise to enter into this EAA and perform all of its obligations
                                hereunder;<br/><br/>
                                6.2.5 the Enlliance Agent Network Program shall be solely carried out by the corporate entity indicated as the Agent;<br/><br/>
                                6.2.6 it is not subject to any Applicable Laws that either invalidate or alter any of the terms or conditions of this EAA or
                                impose upon Enlliance any obligations which are not specifically provided for herein; and<br/><br/>
                                6.2.7 it has provided Enlliance on, or before, the date of execution of this EAA, with a copy of the: (i) certification of its
                                qualification to do business at authorized Territory obtained from the competent local governmental agency, and (ii) a
                                copy of its most recent (if possible audited) financial statement.<br/><br/>

                                <b>6.3 Product Warranties & Indemnity.</b><br/>
                                The warranties with respect to the functionality and/or performance of the Enlliance Courses and Services shall be as set
                                out in the Terms of Service and shall be limited by the terms and conditions therein. No warranty of any nature
                                whatsoever with respect to the Enlliance Courses and Services, whether express or implied, shall be deemed to have
                                been created by this EAA. In the event that the Agent in any way modifies or alters the Application other than as
                                expressly provided by the terms and conditions of this EAA or other than with the prior written consent of Enlliance, any
                                warranty provision, whether express or implied, with respect to such modified or altered Application shall lapse.<br/><br/>

                                <b>6.4 Other Representations and Warranties</b><br/>
                                6.4.1 The Agent shall not in any event make any promise, commitment or representation whatsoever, whether verbally
                                or in writing or otherwise, on behalf of Enlliance or concerning any products or services offered by or available from
                                Enlliance unless expressly authorized by such Party in writing to do so. The Agent shall not make any such promise
                                commitment or representation regarding the Enlliance Courses and Services other than in accordance with such
                                information and details as may from time to time be provided by Enlliance for the purpose.<br/><br/>
                                6.4.2 Notwithstanding any other provision of this EAA, the Agent agrees to offer, market, distribute, promote and
                                provide the Enlliance Courses and Services strictly as products and services that are provided by Enlliance. In
                                connection with the foregoing, the Agent agrees and confirms that the Enlliance Courses and Services shall at all times
                                be offered, marketed, distributed, promoted and provided as Enlliance’s products and services and the Agent shall take
                                all necessary steps and/or precautions to ensure that the Enlliance Courses and Services are not mistaken or
                                misrepresented (whether as being the Agent’s products or services or otherwise).<br/><br/>

                                <b>7. CONFIDENTIALITY</b><br/>
                                7.1 “Confidential Information” means all confidential information in oral, written, graphic, electronic, or other form
                                including, but not limited to, past, present, and future business, financial and commercial information, business concepts,
                                prices and pricing methods, marketing and customer information, financial forecasts and projections, technical data and
                                information, formulae, analyses, trade secrets, ideas, inventions, discoveries, methods, processes, know-how, computer
                                programs, source code, products, equipment, product road maps, prototypes, samples, designs, data sheets, schematics,
                                configurations, specifications, techniques, drawings, and any other data or information disclosed, whether orally,
                                visually, or in writing. Among other things, Enlliance regards the source and object code, software programs, processes,
                                algorithms, methods, and related know-how and residual knowledge developed, created or used by Enlliance or its
                                agents in connection with the performance of the Enlliance Courses and Services, including, without limitation, any
                                software products, processing platforms or other tools named in the Order Form, and any documentation relating thereto
                                including any modifications, enhancements, new versions or derivative works thereof, and all trade secrets, copyrights,
                                patents and other intellectual and proprietary rights therein as Enlliance’s Confidential Information. Confidential
                                Information shall not include data or information which (i) was in the public domain at the time it was disclosed or falls
                                within the public domain, except through the fault of the receiving party; (ii) was known to the receiving party at the
                                time of disclosure without an obligation of confidentiality, as evidenced by the receiving party’s written records; (iii) is
                                disclosed after written approval of the disclosing party; (iv) becomes known to the receiving party from a source other
                                than the disclosing party without an obligation of confidentiality; or (v) is developed by the receiving party
                                independently of the disclosing party’s confidential information as demonstrated by written records.<br/><br/>
                                7.2 During the term of this EAA, neither Party shall (i) disclose to any unaffiliated third party any Confidential
                                Information (provided Enlliance may disclose any Data which constitutes Confidential Information in accordance with
                                its Privacy Policy); or (ii) use the Confidential Information for any purpose other than that indicated in this EAA without
                                the disclosing party’s prior written approval. The receiving party agrees to notify the disclosing party promptly of any
                                unauthorized disclosure of Confidential Information and to assist the receiving party in remedying any such unauthorized
                                disclosure. The receiving party agrees that all persons having access to the Confidential Information under this EAA will
                                abide by the obligations set forth in this EAA. Nothing in this EAA shall be construed to restrict the parties from
                                disclosing Confidential Information as required by law or court order or other governmental order or request, provided in
                                each case the Party requested to make such disclosure shall timely inform the other Party and use all reasonable efforts to
                                limit the disclosure and maintain the confidentiality of such Confidential Information to the extent possible. In addition,
                                the Party required to make such disclosure shall permit the other Party to attempt to limit such disclosure by appropriate
                                legal means.<br/><br/>
                                7.3 The Agent shall disclose the Confidential Information only on a “need-to-know” and confidential basis to its
                                employers, auditors and lawyers, provided that each such party having access to Confidential Information is made aware
                                of and subject to the obligations relating to confidentiality set out in this Section 7.<br/><br/>
                                7.4 The obligations in this Section shall survive the expiry or termination of this EAA.<br/><br/>

                                <b>8. ASSIGNMENT</b><br/>
                                8.1 Enlliance has entered into this Agreement with specific reliance upon the Agent’s financial qualifications,
                                experience, skills and managerial qualifications as being essential to the satisfactory operation of the Enlliance Agent
                                Network Program. The Agent shall not assign, novate, mortgage or charge any of its rights and obligations under this
                                EAA to any Third Party without the prior written consent of Enlliance. This EAA and all the rights and obligations of
                                Enlliance under it may be assigned, transferred, novated or otherwise dealt with by Enlliance to its Affiliates without the
                                consent or approval of the Agent and will inure to the benefit of such successors and assigns of the Agent. Enlliance
                                undertakes to do all things and execute all documents necessary to facilitate such assignment, transfer, novation or
                                dealing.<br/><br/>
                                8.2 Any sale (including instalment sale), lease, pledge, management agreement, contract for deed, option agreement,
                                assignment, bequest, gift or otherwise, or any arrangement pursuant to which the Agent turns over, de facto or de jure, all
                                or part of the daily operation of its business and/or control over the Agent will be considered a transfer for purposes of
                                this EAA.<br/><br/>
                                8.3 Upon death or permanent disability of the Agent’s legal representatives or controlling person, Enlliance shall have
                                the power to terminate this EAA.<br/><br/>

                                <b>9. TERM AND TERMINATION</b><br/>
                                9.1 Term. This EAA shall come into effect from the effective date as specified in Section 14, and shall remain valid for
                                one (1) year unless terminated sooner in accordance with this EAA (the “Term”).<br/><br/>
                                9.2 No Automatic Renewal. The term of this EAA shall not be automatically renewed or extended and the Agent hereby
                                expressly waives to the maximum extent allowed under the Applicable Laws any right that the Agent has, or may have,
                                under any existing or future Applicable Laws to extend the term of this EAA by operation of any such Applicable Laws.<br/><br/>
                                9.3 Expiration. Unless otherwise provided for herein, upon the expiration or earlier termination of this Agreement, the
                                terms and conditions thereof, and the Parties’ respective obligations hereunder will terminate and be of no further force
                                and effect. Challenges to the expiration and/or termination of this Agreement potentially lodged by the Agent will not
                                affect the exercise of Enlliance’s rights pursuant to this Agreement and shall by no means free the Agent from its own
                                obligations or allow for delayed performance thereof.<br/><br/>
                                9.4 Force Majeure: Suspension and Termination. If either Party becomes unable to perform any of its obligations under
                                this EAA because of any Force Majeure Event, the affected Party shall immediately send the other Party a written notice
                                thereof and shall take all such steps as required to resume performance of the EAA as soon as possible. Upon receipt of
                                such notice, either Party’s respective obligations under this EAA shall immediately be suspended. If the non-performing
                                Party has been unable to resume performance of the EAA within 30 days of the commencement of the Force Majeure
                                Event, either Party may terminate this EAA by giving written notice to the other Party, without any obligation or liability
                                vis-à-vis the latter Party for compensation or any other obligation or liability whatsoever.<br/><br/>
                                9.5 Limited Termination. No Party shall have any unilateral right to terminate this Agreement and this Party may only
                                be terminated by mutual agreement of all the Parties.<br/><br/>
                                9.6 Consequences of expiry or termination. Upon the expiry or termination of this EAA (howsoever occasioned), the
                                Agent agrees to:<br/>
                                9.6.1 unless otherwise expressly provided in this EAA, all rights and obligations of the Parties shall automatically
                                terminate except for such rights of action as shall have accrued prior thereto and any obligations which expressly or by
                                implication are intended to come into or continue in force on or after such expiration or termination;<br/>
                                9.6.2 immediately discontinue any and all use of the Enlliance Marks and Enlliance Intellectual Property Rights;<br/>
                                9.6.3 assign to Enlliance or abandon, at the Agent’s sole expense, any rights in or to the Enlliance Marks, which may
                                have accrued to the benefit of the Agent during the duration of this EAA;<br/>
                                9.6.4 take all actions necessary to ensure that the Agent shall no longer be presented or recognized as a Enlliance Agent;<br/>
                                9.6.5 not represent or state that the Agent is identified, associated or connected with Enlliance in any manner
                                whatsoever;<br/>
                                9.6.6 not to initiate any action for seeking, nor demand Enlliance to pay, any compensation for termination of the EAA
                                or for loss of future profits;<br/>
                                9.6.7 not to prevent or impede Enlliance from entering into any agreement with any Third Parties;<br/>
                                9.6.8 provide all assistance to Enlliance in relation to any issue arising as a consequence of the expiration or earlier
                                termination of this EAA, including in the case when the Agent has registered, filed or recorded any document or
                                statement relating to the right and license granted to the Agent under this EAA; in such a case, the Agent shall sign, file
                                and record any document which may be necessary to enforce the termination of the rights granted to the Agent hereunder
                                and/or to cancel the EAA from any register in which it may have been registered before; and<br/>
                                9.6.9 promptly return to Enlliance or destroy, subject to Enlliance’s option, all documents and materials bearing the
                                Enlliance Trademarks or related to Enlliance which are in the Agent’s possession or control (even if in copy). In case of
                                early termination of the EAA due to an event of default, all documents and materials shall be returned to Enlliance at
                                Agent’s cost and expense.<br/><br/>

                                <b>10. DEFAULT</b><br/>
                                10.1 Defaults for Breach of the Agent. Enlliance shall have the right to terminate this EAA in accordance with the
                                following provisions:<br/>
                                10.1.1 Termination after Opportunity to Cure: except as otherwise expressly provided in this Section 10, or
                                elsewhere in the EAA:<br/>
                                    &nbsp;&nbsp;(a) the Agent will have 15 days from the date of issuance of a written notice of default to cure any default under this
                                EAA;<br/>
                                    &nbsp;&nbsp;(b) the Agent’s failure to cure a default within the above 15-day period will provide Enlliance with good cause to
                                terminate this EAA;<br/>
                                    &nbsp;&nbsp;(c) termination will be accomplished by mailing or delivering to the Agent of a written notice of termination; and<br/>
                                    &nbsp;&nbsp;(d) termination will be effective immediately upon Enlliance’s issuance of the written notice of termination.<br/><br/>
                                10.1.2 Immediate Termination with No Opportunity to Cure: in the event any of the following defaults occurs, the
                                Agent will have no right or opportunity to cure the default and this EAA will terminate effective immediately on
                                Enlliance’s issuance of written notice of termination:<br/>
                                    &nbsp;&nbsp;(a) any material misrepresentation or omission in the Agent’s application or proposal in order to enter into the Enlliance
                                Agent Network Program;<br/>
                                    &nbsp;&nbsp;(b) breach of any of the representations set out in Section 6;<br/>
                                    &nbsp;&nbsp;(c) the Agent’s voluntary abandonment of this EAA or the authorized Territory as described in the Addendum;<br/>
                                    &nbsp;&nbsp;(d) any unauthorized use of Enlliance’s Confidential Information pursuant to Section 7;<br/>
                                    &nbsp;&nbsp;(e) bankruptcy or other insolvency procedures of the Agent;<br/>
                                    &nbsp;&nbsp;(f) conviction of the Agent and/or its shareholders, officers, managers, or key employees of (or pleading no contest to)
                                any felony regardless of the nature of the charges, or any misdemeanor that brings or tends to bring any of the Enlliance
                                Marks into disrepute or impairs or tends to impair the Agent’s reputation or the goodwill of the Enlliance Marks;<br/>
                                    &nbsp;&nbsp;(g) unauthorized transfer in violation of Section8; and<br/>
                                    &nbsp;&nbsp;(h) other situations described in the Addendum.<br/><br/>
                                10.2 Remedies.<br/>
                                10.2.1 Without prejudice to Section 10.1, upon the occurrence of any of the events of default above, Enlliance shall have
                                the right to exercise any of the following remedies, including any other legal or equitable remedies Enlliance may have
                                under the law:<br/>
                                    &nbsp;&nbsp;(a) terminate this EAA by written notice to the Agent; and<br/>
                                    &nbsp;&nbsp;(b) in any case, to claim for further damages.<br/>
                                The Agent acknowledges and agrees that in the event that Enlliance exercises any of the foregoing remedies, Enlliance
                                shall have no obligation or liability to pay to the Agent any form of compensation whatsoever.<br/><br/>
                                10.2.2 A waiver by Enlliance to claim any specific event of default or any delay or omission by Enlliance to exercise any
                                rights arising from any event of default under this EAA shall not affect or impair any rights of Enlliance with respect to
                                the subsequent occurrence of an event of default of the same or of a different nature. The remedies provided for in this
                                EAA arising from any event of default shall be cumulative and shall be in addition to, and not in lieu of, any and all
                                other remedies available to Enlliance under the law<br/><br/>
                                10.3 Default for Breach of Enlliance. The Agent may terminate this EAA as a result of a breach by Enlliance of a
                                material provision of this EAA provided that:<br/>
                                10.3.1 the Agent provides Enlliance with written notice of the breach that identifies the grounds for the breach; and<br/>
                                10.3.2 Enlliance fails to cure the breach within 45 days after receipt of the written notice. If Enlliance fails to cure the
                                breach, the termination will be effective 90 days after Enlliance’s receipt of the Agent’s written notice of breach.
                                Termination of this EAA under this Section 10.3 will not release or modify the Agent’s obligations under this EAA.<br/><br/>

                                <b>11. INDEMNITY</b><br/>
                                11.1 Without prejudice to Enlliance's rights under any other provision of this Agreement, the Agent shall indemnify,
                                defend and hold harmless Enlliance and their employees, servants, officers and agents (collectively “Enlliance
                                Indemnitees”), from and against any and all Losses which may be sustained, instituted, made or alleged against, or
                                suffered or incurred by Enlliance Indemnitees, and which arise (whether directly or indirectly) out of, in the course of or
                                in connection with one or more of the following:<br/>
                                11.1.1 any breach or non-performance by the Agent of any of the Agent’s undertakings, warranties or obligations under
                                this Agreement;<br/>
                                11.1.2 any action, claim or demand brought or threatened against Enlliance Indemnitees that alleges or is based on a
                                promise, warranty or representation made by the Agent relating to any part of the Enlliance Courses or Services ; and/or<br/>
                                11.1.3 from any negligent act or omission or willful default, misconduct or fraud of the Agent, its employees, agents,
                                servants or subcontractors.]<br/><br/>

                                <b>12. FORCE MAJEURE</b><br/>
                                12.1 If either Party here to (the “Affected Party”) is prevented from performing any of its material obligations under this
                                Agreement by reason of a Force Majeure event it shall immediately notify the other (the “Unaffected Party”) in writing
                                of the circumstances constituting the Force Majeure event and shall keep the Unaffected Party regularly informed of the
                                progress in resolving the Force Majeure event.<br/>
                                12.2 Neither the Affected Party nor the Unaffected Party (but only to the extent it is prevented from performing any of
                                its obligations by reason of the Affected Party’s failure to perform as a result of the Force Majeure event) will be treated
                                as being in breach of this Agreement, or otherwise liable to the other, by reason of any delay in performance, or nonperformance
                                of any of its obligations due to a Force Majeure event.<br/><br/>

                                <b>13. GENERAL TERMS</b><br/>
                                13.1 Governing Law. This EAA and all matters arising out of or relating to this EAA shall be governed by the laws of
                                Malaysia. Any dispute arising out of or in connection with this EAA, including any question regarding existence,
                                validity or termination of this EAA, shall be referred to and finally resolved by arbitration administered by the Malaysia
                                governing authority which rules are deemed to be incorporated by reference in this Section. The seat of the arbitration
                                shall be Malaysia. The Tribunal shall consist of three (3) arbitrators. The language of the arbitration shall be English.
                                This EAA shall not be governed by the United Nations Convention on Contracts for the International Sales of Goods, the
                                application of which is expressly excluded.<br/><br/>
                                13.2 Legal Compliance; Export Administration. By accepting this EAA Agent represents and warrants that Agent and
                                Agent Authorized Users (i) are not located in a jurisdiction that is subject to a Malaysia government embargo, or that has
                                been designated by the Malaysia government as a “terrorist supporting” country, and will not use the Documentation or
                                the Enlliance Courses and Services in such jurisdictions; (ii) are not listed on any Malaysia government list of prohibited
                                or restricted parties; and (iii) will comply fully with all relevant export laws and regulations of the Malaysia, including,
                                without limitation, the Malaysia Export Administration Regulations (collectively “Export Controls”). If Agent are an
                                agency or instrumentality of Malaysia, the Enlliance Courses and Services and the software accessed there through
                                constitutes “commercial computer software” and the Documentation constitutes “commercial computer software
                                documentation”, as applicable, use, reproduction, and disclosure of the Enlliance Courses and Services, the software
                                accessed there through and Documentation are governed by the terms of this EAA. The manufacturer of the software
                                accessed through the Enlliance Courses and Services is Enlliance Management Sdn. Bhd, with an address at No. 32-2,
                                Jalan PJU 5/21, The Strand, Kota Damansaram 47810 Petaling Jaya, Selangor D.E., Malaysia.<br/><br/>
                                13.3 Independent Contractors. Nothing herein shall be deemed to constitute either Party as the Agent of the other nor,
                                except as otherwise herein expressly provided, to constitute either Party the agent or legal representative of the other, nor
                                to create any fiduciary relationship between the Parties.<br/><br/>
                                13.4 Severability. If any provision of this EAA is agreed by the Parties to be illegal, void or unenforceable under any
                                law that is applicable hereto or if any court of competent jurisdiction in a final decision so determines, this EAA shall
                                continue in force save that such provision shall be deemed to be excised herefrom with effect from the date of such EAA
                                or decision or such earlier date as the Parties may agree.<br/><br/>
                                13.5 Notices. Notices under this EAA may be delivered by hand, by registered mail or facsimile to the addresses and
                                numbers specified in the head of this EAA, or via system generated notification which requires acknowledgment from
                                the other party. Notice will be deemed effectively given:<br/>
                                13.5.1 when received, if delivered by hand, with signed confirmation of receipt;<br/>
                                13.5.2 when received, if sent by a nationally recognized overnight courier, signature required;<br/>
                                13.5.3 when sent, if by facsimile or e-mail, (in each case, with confirmation of transmission), if sent during the
                                addressee's normal business hours, and on the next business day, if sent after the addressee's normal business hours; and<br/>
                                13.5.4 on the fifth day after the date mailed by certified or registered mail, return receipt requested, postage prepaid.
                                The addresses and numbers for notice may be changed by either Party by giving notice to the other Party as provided
                                herein.<br/>
                                13.5.5 by system generated notification and the receiving Party responding with acknowledgement.<br/><br/>
                                13.6 Rights of Third Parties. Save for Enlliance’s Affiliates, a person or entity who is not a party to this EAA shall
                                have no right under the Malaysia Contracts Act 1950 to enforce any term of this EAA, regardless of whether such person
                                or entity has been identified by name, as a member of a class or as answering a particular description. For the avoidance
                                of doubt, nothing in this Section 13.5 shall affect the rights of any permitted assignee or transferee of this EAA.<br/><br/>
                                13.7 Waiver. A failure by Enlliance to exercise or enforce any rights conferred upon it by this EAA shall not be deemed
                                to be a waiver of any such rights or operate so as to bar the exercise or enforcement thereof at any subsequent time or
                                times. Waiver of any right arising from a breach or non-performance of this EAA or arising upon default under this EAA
                                shall be in writing and signed by the Party granting the waiver. A Party is not entitled to rely on a delay in the exercise or
                                non-exercise of a right arising from a breach or non-performance of this EAA or on a default under this EAA as
                                constituting a waiver of that right.<br/><br/>
                                13.8 Variation. No variation, amendment or rescission of this EAA shall bind either Party, unless made in writing in the
                                English language and signed by both Parties. Without prejudice to the generality of Section 12.6, the Parties rights to
                                vary, amend or rescind this EAA in the manner aforesaid may be exercised without the consent of any person or entity
                                who is not a party to this EAA.<br/><br/>
                                13.9 Cumulative Rights and Remedies. Unless otherwise expressly agreed by the Parties or provided under this EAA,
                                the provisions of this EAA, and the rights and remedies of Enlliance under this EAA are cumulative and are without
                                prejudice and in addition to any rights or remedies Enlliance may have in law or in equity. The Parties shall comply with
                                all the obligations set out in the Schedules even where such obligations are not specifically set out or referred to in this
                                EAA.<br/><br/>
                                13.10 Entire Agreement. This EAA constitutes the entire agreement between the Parties regarding its subject matter
                                and supersedes all prior agreements between the Parties, whether written or oral, with respect to such subject matter.<br/><br/>
                                13.11 Counterparts. This EAA may be executed simultaneously in two or more counterparts, each of which shall be
                                deemed to be an original and all of which together shall constitute the same agreement.<br/><br/>
                                13.12 Anti-bribery and Corruption<br/>
                                13.12.1 The Parties warrant and undertake that they and their Affiliates will not, nor will any of their respective officers,
                                employees, shareholders, representatives, agents or contractors (“Associated Parties”) commit, authorize or permit any
                                action in connection with the negotiation, conclusion or the performance of this EAA or any related EAA which would
                                cause the other Party and/or its Associated Parties to be in violation of the Anti-Bribery Laws. This obligation applies,
                                inter alia, to illegitimate payments including facilitation payments to Public Officials or their associates, families or
                                close friends.<br/>
                                13.12.2 The Parties warrant and undertake that they and their Associated Parties understand fully their own anti- bribery
                                and anti-corruption legal obligations and shall implement adequate controls, systems and procedures to ensure their own
                                compliance with all Anti-Bribery Laws.<br/>
                                13.12.3 The Parties warrant and undertake that they have not undergone and they are not undergoing any audit, review,
                                inspection, investigation, survey or examination by any governmental authority relating to the Anti- Bribery Laws. The
                                Parties further warrant and undertake that there are no threatened claims, nor presently existing facts or circumstances
                                that would constitute a reasonable basis for any future claims, under any Anti-Bribery Laws against themselves.<br/>
                                13.12.4 Each Party agrees to assist the other Party with its bribery and corruption risk assessment and due diligence, and
                                its compliance with the Anti-Bribery Laws, as far as possible by providing relevant information upon request.<br/>
                                13.12.5 A Party shall promptly notify the other Party if it becomes aware of or has any difficulties in its anti-bribery and
                                anti-corruption obligations that would amount to a breach of this EAA or any of the Anti-Bribery Laws.<br/>
                                13.12.6 The Parties warrant and undertake and they are not, nor are any of their Associated Parties, the subject of any
                                allegation, voluntary disclosure, prosecution or other enforcement action related to any Anti-Bribery Law.<br/>
                                13.12.7 The Parties agree that it and the Associated Parties will not either offer, or give, or agree to give, to any
                                employee, representative or Third Party acting on behalf of the other Party, and/or any Public Official or accept, or agree
                                to accept from any employee, representative or Third Party acting on behalf of the other Party, and/or a Public Official,
                                any undue gift or benefit, be it monetary or other, with regard to the negotiation, conclusion or the performance of this
                                EAA or any related EAA.<br/>
                                13.12.8 A Party shall promptly notify the other Party if it becomes aware of or has specific suspicion of any corruption
                                or any request or demand for undue financial advantage or other advantage of any kind, with regard to the negotiation,
                                conclusion or the performance of this EAA or any related EAA.<br/>
                                13.12.9 In the case that either Party provides any undue gifts or benefits with regard to the negotiation, conclusion or the
                                performance of this EAA or any related EAA, in violation of this Section 13.12, the other Party may terminate this EAA
                                with immediate effect by giving notice to the violating Party.<br/><br/>

                                <b>14. SERVICES</b><br/>
                                14.1 During the Term, the Agent shall provide the following services to Enlliance: (together, the “Services”).<br/><br/>
                                14.2 The Agent agrees that:<br/>
                                14.2.1 Market the Enlliance Courses and Services in the Territory; and<br/>
                                14.2.2 Refer leads for potential Customers or End Customers (“Leads”) to Enlliance by system referral link generated
                                and provided by Enlliance;<br/>
                                14.2.3 Enlliance may elect to use any Lead at such time or in such manner as solely determined by Enlliance. There is
                                no absolute obligation on Enlliance to follow up on any Lead. Enlliance reserves the right to refer any Lead to its other
                                Agents; and<br/>
                                14.2.4 It shall pass the Lead exclusively to Enlliance or a Enlliance Agent as may be directed by Enlliance. For the
                                avoidance of doubt, the Agent shall not refer any Leads to any businesses directly and materially competitive with
                                Enlliance’s current lines of business.<br/><br/>
                                14.3 Subject to Enlliance’s prior written consent (which shall not be unreasonably withheld), the Agent may perform
                                some or all of its obligations through any of its Affiliates or sub-agent, provided, however, that the Agent shall remain
                                responsible for the payment and performance by its Affiliates and shall cause its Affiliates to comply with the provisions
                                of the EAA and this Addendum. Any breach of any provision of the EAA or this Addendum by any Affiliate of the
                                Agent shall be deemed a breach hereof by the Agent, with the Agent being liable hereunder with respect to such breach
                                as if such Agent itself had breached the EAA or the Addendum.<br/><br/>

                                <b>15. COMMISSION</b><br/>
                                15.1 Subject to clause 14 being satisfied, Enlliance shall pay to the Agent the commission as set out below in
                                consideration for the Agent’s performance of the Services:<br/>
                                15.2 For the avoidance of doubt:<br/>
                                15.2.1 Percentage of the amount received from Customer or End Customer on Enlliance Courses and Services referred
                                by the Agent to Enlliance as determined or placed by or on behalf of the Customer or the End Customer, as the case may
                                be (the “Commission”);<br/>
                                15.2.2 the Commission shall be paid on a monthly basis subject to validity of the EAA signed.<br/>
                                15.2.3 the Commission shall be the Agent’s sole compensation for performance of the Services.<br/>
                                15.2.4 the Commission shall only be payable if the Lead referred by the Agent is used by Enlliance (for and on behalf
                                of itself or through another Enlliance partner or agent) and results in a Customer or End Customer: (i) ordering the
                                Enlliance Courses and Services; and (ii) accepting the Enlliance customer terms and conditions.<br/>
                                15.2.5 the Commission shall be determined solely based on the purchase order of the Enlliance Courses and Services
                                placed by the Customer or End Customer.<br/>
                                15.2.6 In the event that the First Order relates to a subscription for the Enlliance Courses and Services exceeds one
                                month, the Commission shall be payable upon full payment received from End Customer, subject to an EAA and
                                Addendum between Enlliance and the Agent being valid, existing and in force at the time of such payment. For the
                                avoidance of doubt, to the extent (i) the EAA and/or Addendum has expired, been terminated or suspended, or (ii) either
                                Party has provided written notice to the other Party to terminate the EAA and/or the Addendum, Enlliance shall not be
                                obliged to make further Commission payments to the Agent.<br/>
                                15.2.7 the Agent accepts that Enlliance’s calculation of the Commission shall be conclusive and binding on the Agent
                                for all purposes.<br/>
                                15.2.8 For the avoidance of doubt, the calculation of Commission shall include only fees or payment received by
                                Enlliance from the Customer or End Customer and shall exclude without limitation any fees pending payment by the
                                Customer or End Customer.<br/>
                                15.2.9 For courses provided by Enlliance and Offline Courses commission shall be system generated in accordance to
                                the agent assigned tier and fulfilling terms and conditions stipulated for each tier.<br/>
                                15.2.10 The commission percentage is valid subject to terms and conditions stated in the system.<br/>
                                15.2.11 Any sales submission of lesser amount within the stipulated time may result in deduction in commission
                                percentage pay-out in the next month following demotion of agent’s assigned tier.<br/>
                                15.2.12 Any violation of terms and conditions or improper conduct of the agent may result in demotion or termination.<br/>
                                15.2.13 Agent tier assignment is under the full discretion of respective agency or agent leader or by Enlliance.<br/>
                                15.2.14 Enlliance reserves the full and final right in agent tier assignment.<br/>
                                15.2.15 Enlliance reserves rights to revise the referral commission and remuneration with notice to Agent via email or
                                system notification<br/>
                                15.2.16 Enlliance reserves all rights to retrieve all data of Customer or End Customer from Agent<br/>
                                15.2.17 For any Lead non-conversion to sales within three months from date of registration into Enlliance referral system
                                may be considered a non-performing lead and Enlliance reserves right to take over the sales commission from Agent<br/>
                                15.2.18 The remuneration or commission payout from Agent to its own sales personnel or affiliate shall follow the
                                commission tier schedule stipulated by Enlliance.<br/>
                                15.2.19 It is under Enlliance’s sole discretion to determine and revise the remuneration or commission payout from
                                Agent to individual sales personnel or affiliate where deemed necessary to cater to best practice.<br/>
                                15.2.20 Enlliance reserves right to terminate any Agent who fails to adhere to term stated.<br/><br/>
                                15.3 At the end of each month, Enlliance will calculate the total commission amount and issue a "Commission
                                Statement" to the Agent for confirmation. Enlliance must receive written notice of any disputed commission from the
                                Agent within 5 (five) days after the “Commission Statement” date; otherwise, the Agent shall be deemed to agree to the
                                “Commission Statement” provided by Enlliance. The commission shall be paid to the Agent within 30 (thirty) business
                                days from the date of the Commission Statement.<br/><br/>

                                <b>16. EFFECTIVE DATE</b><br/>
                                The Effective Date of this Enlliance Agent Network Agreement is in accordance to the
                                signing or acknowledgement of receipt date of this EAA and shall be valid and in force for a period of one year.
                            </div>
                            <div style={{marginTop:10,display:"flex",flexDirection:"row",alignItems:"center",width:"100%"}}>
                                <input type="checkbox" checked={isChecked} style={{}} onChange={this.handleCheckboxChange}
                                 />
                                <div style={{marginLeft:10}}>I had read, understood and agree to the agreement above.</div>
                            </div>
                        </div>
                        <Button color="success" style={{width:"100%",marginTop:20}} disabled={!isChecked} onClick={()=>{
                            this.setAgreement()
                        }}>
                            Join
                        </Button>
                        </div>
                </Modal>
            <div style={{height:"100vh",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                    <h1 style={{marginBottom:20}}>EnllianceSales</h1>
                    <Alert color="danger" isOpen={this.state.invalidLoginAlert} style={{borderRadius:0}}>
                        {this.state.invalidLoginMessage}
                    </Alert>
                    <Input type="text" name="username" placeholder="手机号码"
                        style={{}}
                        value={this.state.username}
                        onChange={event => this.setState({username: event.target.value})}
                        onKeyDown={(e)=>{
                            if(e.keyCode==13){
                                e.preventDefault()
                                this.loginFormSend()
                            }
                        }}/>
                    <Input type="password" name="password" placeholder="密码"
                        style={{marginTop:10}}
                        value={this.state.password}
                        onChange={event => this.setState({password:event.target.value})}
                        onKeyDown={(e)=>{
                            if(e.keyCode==13){
                                e.preventDefault()
                                this.loginFormSend()
                            }
                        }}/>
                    <Button color="primary" style={{width:"100%",marginTop:20}} onClick={()=>{this.loginFormSend()}}>Log in</Button>
                    <Button color="light" style={{width:"100%",marginTop:20,border:"1px solid lightgrey"}} onClick={()=>{
                        if(this.redirect==null){
                            history.push("/signup")
                        }else{
                            history.push("/signup?redirect="+this.redirect)
                        }
                    }}>Sign up</Button>
                </div>
            </div>
            </div>
        )
    }
}

let styles = {

}
