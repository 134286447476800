import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal,Label} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import { EditorState,ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull'

import {MdLocalMovies,MdPause,MdPlayArrow,MdFullscreenExit,MdFullscreen} from 'react-icons/md'
import {FaVolumeMute,FaVolumeUp,FaVolumeDown} from 'react-icons/fa'

export default class Knowledgebase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKnowledgebaseField:"",
            mode:"search",
            knowledgeList:[],
            knowledgeListSearch:[],
            currentKnowledge:{},

            createTitle:"",
            editorState:EditorState.createEmpty(),
            htmlPreviewMode:false,

            editModeTitle:"",
            editModeEditorState:EditorState.createEmpty(),
            knowledgeNewList:[],

            videoNow:"",
            videoPlaying:false,
            videoVolume:1,
            playbackRate:1,
            bufferedVideo:0,
            playedVideo:0,
            playedVideoMinute:"00",
            playedVideoSecond:"00",
            seekBarMinute:"00",
            seekBarSecond:"00",
            totalVideoDurationSecond:0,
            videoDurationMinute:"00",
            videoDurationSecond:"00",
            showTimelineInfo:false,
            videoList:[],
        }
        this.extensionList=[]

        this._videoComponent=React.createRef()
        this._reactplayer=React.createRef()
        this._volumeInput=React.createRef()
        this._timelineContainer=React.createRef()
        this._timelineInfo=React.createRef()
    }

    componentWillMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        this.searchButtonClicked()
    }

    createButtonClicked=()=>{
        this.setState({mode:"create"})
    }

    searchButtonClicked=()=>{
        //get knowledge list from database
        this.props.setLoading(true)
        axios
            .get(`sales/getallknowledge`)
            .then(async response => {
                //parse createDate
                for(var f=0;f<response.data.length;f++){
                    let rawDateSplit=response.data[f].createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data[f].createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }
                //parse lastEditDate
                for(var f=0;f<response.data.length;f++){
                    let rawDateSplit=response.data[f].lastEditDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data[f].lastEditDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }

                this.props.setLoading(false)
                this.setState({
                    mode:"search",
                    knowledgeList:response.data,
                })
            })
            .catch(error =>{
                alert("Get all knowledge error\n"+error)
                this.props.setLoading(false)
            })
    }
    searchKnowledgebase=(sText)=>{
            var searchText=this.state.searchKnowledgebaseField
                if(sText){
                    searchText=sText
                }
        //search function, filter off the knowledge list, remain only the relevants
            var searchArray=[]
            for(var i=0;i<this.state.knowledgeList.length;i++){
                if(this.state.knowledgeList[i].title.toUpperCase().includes(searchText.toUpperCase())){
                    searchArray.push(this.state.knowledgeList[i]);
                }
            }
            this.setState({knowledgeListSearch:searchArray});
    }

    imageUploadCallback=(file)=>{
        const imageObject = {
          file: file,
          localSrc: URL.createObjectURL(file),
        }
        var re = /(?:\.([^.]+))?$/
        let extension=re.exec(file.name)[1]
        this.extensionList.push([imageObject.localSrc,extension])
        return new Promise(
          (resolve, reject) => {
            resolve({ data: { link: imageObject.localSrc } });
          }
        );
    }

    onEditorStateChange=(editorState) => {
        this.setState({
            editorState,
        });
    };
    onEditModeEditorStateChange=(editModeEditorState) => {
        this.setState({
            editModeEditorState,
        });
    };

    htmlPreview=()=>{
        this.setState({
            htmlPreviewMode:!this.state.htmlPreviewMode,
        });
    }

    publish=async()=>{
        //validate
        //has title
        if(this.state.createTitle.length==0){
            alert("Every knowledge needs a title")
            return
        }

        //generate JSON from the editor's content
        let theJSON=convertToRaw(this.state.editorState.getCurrentContent())
        //go through all image links, upload the files to server and replace them with server's link
        var theImages=theJSON.entityMap
        let theKeys=Object.keys(theImages)
        var imagesToUpload=[]
        for(const key of theKeys){
            let srcLink=theImages[key].data.src
            if(srcLink.includes("blob:")){
                //add to the upload queue
                imagesToUpload.push(srcLink)
            }
        }
        //upload the images to server
        this.props.setLoading(true)
        const formData = new FormData();
        for(var v=0;v<imagesToUpload.length;v++){
            let blob = await fetch(imagesToUpload[v]).then(r => r.blob());  //get dat blob
            var theType="image/"
            var ext=""
            //find out the extension
            for(const extList of this.extensionList){
                if(extList[0]==imagesToUpload[v]){
                    ext=extList[1]
                    break
                }
            }
            const imageFile = new File([blob],`${Date.now()}.${ext}`,{type:theType,});
            formData.append('image'+v,imageFile)
            formData.append('image'+v+'_originalName',imagesToUpload[v])
        }
        axios
            .post(`sales/uploadknowledgebaseimage`, formData, {headers: {"Content-type": "multipart/form-data"}})
            .then(async response => {
                //replace the blobs with returned server image links {serverLink:,imageName:}
                let imageLinks=response.data
                for(const image of imageLinks){
                    for(const key of theKeys){
                        let srcLink=theImages[key].data.src
                        if(srcLink==image.originalName){
                            theJSON.entityMap[key].data.src=image.serverLink
                        }
                    }
                }

                //save the html as raw JSON to server
                const payload = {
                    title:this.state.createTitle,
                    htmlString:JSON.stringify(theJSON),
                }
                axios
                    .post(`sales/publishknowledge`, qs.stringify(payload))
                    .then(async response => {
                        this.props.setLoading(false)
                        this.setState({
                            mode:"search",
                            createTitle:"",
                            editorState:EditorState.createEmpty(),
                        })
                        this.searchButtonClicked()
                    })
                    .catch(error =>{
                        alert("Publish knowledge error\n"+error)
                        this.props.setLoading(false)
                    })
            })
            .catch(error =>{
                alert("Upload image error\n"+error)
                this.props.setLoading(false)
            })
    }

    viewKnowledge=(theKnowledge)=>{
        //get the htmlstring from sever
        this.props.setLoading(true)
        const payload = {
            knowledgeID:theKnowledge.id,
        }
        axios
            .post(`sales/gethtmlstringofknowledge`, qs.stringify(payload))
            .then(async response => {
                //parse createDate
                var rawDateSplit=response.data.createDate.split("T")
                var dateSplit=rawDateSplit[0].split("-")
                var timeSplit=rawDateSplit[1].split(":")
                response.data.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                //parse lastEditDate
                rawDateSplit=response.data.lastEditDate.split("T")
                dateSplit=rawDateSplit[0].split("-")
                timeSplit=rawDateSplit[1].split(":")
                response.data.lastEditDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]

                //formulate the htmlstring
                const content = convertFromRaw(JSON.parse(response.data.htmlString))

                this.props.setLoading(false)
                this.setState({
                    mode:"viewKnowledge",
                    currentKnowledge:response.data,
                    editModeEditorState:EditorState.createWithContent(content),
                })
            })
            .catch(error =>{
                alert("Get knowledge htmlString error\n"+error)
                this.props.setLoading(false)
            })
    }

    editCurrentKnowledge=()=>{
        this.setState({
            mode:"edit",
            editModeTitle:this.state.currentKnowledge.title,
        })
    }

    deleteCurrentKnowledge=()=>{
        if(!window.confirm("Delete the knowledge\nAre you sure?")){
            return
        }

        const payload = {
            knowledgeID:this.state.currentKnowledge.id,
        }
        axios
            .post(`sales/deleteknowledge`, qs.stringify(payload))
            .then(async response => {
                this.searchButtonClicked()
                this.props.setLoading(false)
                this.setState({
                    mode:"search",
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("You do not have the priviledge to delete this knowledge")
                        this.props.setLoading(false)
                        return
                    }
                }
                alert("Delete knowledge error\n"+error)
                this.props.setLoading(false)
            })
    }

    saveEditKnowledge=async()=>{
        //validate
        //has title
        if(this.state.editModeTitle.length==0){
            alert("Every knowledge needs a title")
            return
        }

        //generate JSON from the editor's content
        let theJSON=convertToRaw(this.state.editModeEditorState.getCurrentContent())
        //go through all image links, upload the files to server and replace them with server's link
        var theImages=theJSON.entityMap
        let theKeys=Object.keys(theImages)
        var imagesToUpload=[]
        for(const key of theKeys){
            let srcLink=theImages[key].data.src
            if(srcLink!=null){
                if(srcLink.includes("blob:")){
                    //add to the upload queue
                    imagesToUpload.push(srcLink)
                }
            }
        }
        //upload the images to server
        this.props.setLoading(true)
        const formData = new FormData();
        for(var v=0;v<imagesToUpload.length;v++){
            let blob = await fetch(imagesToUpload[v]).then(r => r.blob());  //get dat blob
            var theType="image/"
            var ext=""
            //find out the extension
            for(const extList of this.extensionList){
                if(extList[0]==imagesToUpload[v]){
                    ext=extList[1]
                    break
                }
            }
            const imageFile = new File([blob],`${Date.now()}.${ext}`,{type:theType,});
            formData.append('image'+v,imageFile)
            formData.append('image'+v+'_originalName',imagesToUpload[v])
        }
        axios
            .post(`sales/uploadknowledgebaseimage`, formData, {headers: {"Content-type": "multipart/form-data"}})
            .then(async response => {
                //replace the blobs with returned server image links {serverLink:,imageName:}
                let imageLinks=response.data
                for(const image of imageLinks){
                    for(const key of theKeys){
                        let srcLink=theImages[key].data.src
                        if(srcLink==image.originalName){
                            theJSON.entityMap[key].data.src=image.serverLink
                        }
                    }
                }

                //save the html as raw JSON to server
                const payload = {
                    knowledgeID:this.state.currentKnowledge.id,
                    title:this.state.editModeTitle,
                    rawJSON:JSON.stringify(theJSON),
                }
                axios
                    .post(`sales/saveeditknowledge`, qs.stringify(payload))
                    .then(async response => {
                        this.searchButtonClicked()
                        this.props.setLoading(false)
                        this.setState({
                            mode:"search",
                        })
                    })
                    .catch(error =>{
                        alert("Save knowledge error\n"+error)
                        this.props.setLoading(false)
                    })
            })
            .catch(error =>{
                alert("Upload image error\n"+error)
                this.props.setLoading(false)
            })
    }

    videosButtonClicked=()=>{
        this.setState({mode:"videos",videoNow:""})
    }

    reactPlayerOnReady=()=>{
        let totalVideoDurationSecond=Math.ceil(this._reactplayer.current.getDuration())
        let videoDurationMinute=Math.floor(totalVideoDurationSecond/60)
        if(videoDurationMinute<10){
            videoDurationMinute="0"+videoDurationMinute
        }
        let videoDurationSecond=totalVideoDurationSecond%60
        if(videoDurationSecond<10){
            videoDurationSecond="0"+videoDurationSecond
        }
        this.setState({
            totalVideoDurationSecond:totalVideoDurationSecond,
            videoDurationSecond:""+videoDurationSecond,
            videoDurationMinute:""+videoDurationMinute,
        })
    }
    reactPlayerOnProgress=(data)=>{
        let playedVideoMinute=Math.floor(Math.ceil(data.playedSeconds)/60)
        if(playedVideoMinute<10){
            playedVideoMinute="0"+playedVideoMinute
        }
        let playedVideoSecond=Math.ceil(data.playedSeconds)%60
        if(playedVideoSecond<10){
            playedVideoSecond="0"+playedVideoSecond
        }
        this.setState({
            bufferedVideo:data.loaded,
            playedVideo:data.played,
            playedVideoSecond:playedVideoSecond,
            playedVideoMinute:playedVideoMinute,
        })
    }
    changePlaybackRate=()=>{
        if(this.state.playbackRate<3){
            this.setState({playbackRate:this.state.playbackRate+0.5})
        }else{
            this.setState({playbackRate:0.5})
        }
    }
    playButtonPressed=()=>{
        if(this.state.audioMode){
            let boo=!this.state.audioPlaying
            this.setState({
                audioPlaying:boo,
                videoPlaying:boo,
            })
        }else{
            let boo=!this.state.videoPlaying
            this.setState({
                videoPlaying:boo,
                audioPlaying:boo,
            })
        }
    }

    render(){

        return(
            <div>
                <div style={{padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey"}}>
                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.searchButtonClicked()}}>Search</Button>
                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.createButtonClicked()}}>Create</Button>
                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.videosButtonClicked()}}>Videos</Button>
                </div>
                <div style={{padding:20}}>
                    {
                        this.state.mode=="videos"?
                            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
                                {
                                this.state.videoNow==""?
                                    <div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/23092020-XLS-1.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>Day 1 企业指南 - 商业指南</div>
                                        </div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/24092020-XLS-2.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>Day 2 企业指南 - 战略指南</div>
                                        </div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/25092020-XLS-3.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>Day 3 企业指南 - 营销指南</div>
                                        </div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/26092020-XLS-4.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>Day 4 企业指南 - 组织指南</div>
                                        </div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/27092020-XLS-5.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>Day 5 企业指南 - 模式指南</div>
                                        </div>

                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/20201120eliteclass.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>Nov 2020 - 商业模式转型班</div>
                                        </div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/20210322_entre.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>创业指南</div>
                                        </div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/20210323_eliteclass.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>商业模式</div>
                                        </div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/20210204membermode.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>会员模式</div>
                                        </div>
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,backgroundColor:"#f7fdff",alignItems:"center",cursor:"pointer",marginTop:10,
                                            borderStyle:"solid",borderColor:"#b3c0c4",borderWidth:1,borderRadius:10}} onClick={()=>{
                                                this.setState({videoNow:"https://enllianceappmedia.s3.amazonaws.com/media/videos/ManageMode20210304.mp4"})
                                            }}>
                                            <MdLocalMovies />
                                            <div style={{marginLeft:10}}>管理模式</div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}} ref={this._videoComponent}>
                                        <ReactPlayer
                                            width="100%"
                                            height={screenfull.isFullscreen?null:600}
                                            url={this.state.videoNow}
                                            ref={this._reactplayer}
                                            playing={this.state.videoPlaying}
                                            style={{flex:1,backgroundColor:"black"}}
                                            onContextMenu={e => e.preventDefault()}
                                            volume={this.state.videoVolume}
                                            muted={this.state.videoMuted}
                                            playbackRate={this.state.playbackRate}
                                            onReady={()=>{this.reactPlayerOnReady();this.setState({videoPlaying:true})}}
                                            onProgress={(data)=>{this.reactPlayerOnProgress(data)}}
                                            onPlay={()=>{this.setState({videoPlaying:true})}}
                                            onPause={()=>{this.setState({videoPlaying:false})}}
                                            config={{ file: {
                                                attributes: {
                                                    controls: false,
                                                    controlsList:'nodownload',
                                                    disableremoteplayback:"true",
                                                    disablePictureInPicture:true,
                                                    crossOrigin: 'true',    //for the subtitles to load from remote source
                                                },
                                            }}}
                                        />
                                        <div style={{backgroundColor:"lightgrey",paddingBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row"}}>
                                                <div ref={this._timelineContainer} style={{height:30,flex:1}}
                                                    onMouseMove={(event)=>{
                                                        let rect = this._timelineContainer.current.getBoundingClientRect()
                                                        let clickX=event.clientX-rect.left
                                                        let barRange=rect.right-rect.left
                                                        let percentageClicked=(clickX/barRange)*100
                                                        styles.showTimelineInfo={
                                                            position:"absolute",
                                                            left:event.clientX-20,
                                                            top:rect.top-55+window.pageYOffset,
                                                            zIndex:999
                                                        }
                                                        //calculate seek bar minute and second
                                                        let seekSecondTotal=Math.ceil(this.state.totalVideoDurationSecond*(percentageClicked/100))
                                                        let seekBarMinute=Math.floor(seekSecondTotal/60)
                                                        if(seekBarMinute<10){
                                                            seekBarMinute="0"+seekBarMinute
                                                        }
                                                        let seekBarSecond=seekSecondTotal%60
                                                        if(seekBarSecond<10){
                                                            seekBarSecond="0"+seekBarSecond
                                                        }
                                                        this.setState({
                                                            seekBarMinute:seekBarMinute,
                                                            seekBarSecond:seekBarSecond,
                                                        })   //simple refresh
                                                    }}
                                                    onMouseLeave={(e)=>{
                                                        styles.showTimelineInfo={
                                                            position:"absolute",
                                                            visibility:"hidden"
                                                        }
                                                        this.setState({})   //simple refresh
                                                    }}
                                                    onClick={(event)=>{
                                                        let rect = this._timelineContainer.current.getBoundingClientRect()
                                                        let clickX=event.clientX-rect.left
                                                        let barRange=rect.right-rect.left
                                                        let percentageClicked=(clickX/barRange)*100
                                                        this._reactplayer.current.seekTo(percentageClicked/100,"fraction")
                                                    }}
                                                >
                                                    <div ref={this._timelineInfo} style={styles.showTimelineInfo}>
                                                        <Label style={{fontFamily:"Lucida Console",backgroundColor:"white",paddingLeft:10,paddingRight:10,height:40,borderRadius:10,display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                            {this.state.seekBarMinute}:{this.state.seekBarSecond}
                                                        </Label>
                                                    </div>
                                                    <div
                                                        style={{width:"100%",height:30,backgroundColor:"grey"}}
                                                    />
                                                    <div
                                                        style={{width:(this.state.bufferedVideo*100)+"%",height:30,backgroundColor:"white",position:"relative",bottom:30}}
                                                    />
                                                    <div
                                                        style={{width:(this.state.playedVideo*100)+"%",height:30,backgroundColor:"red",position:"relative",bottom:60}}
                                                    />
                                                </div>
                                                <div style={{justifyContent:"center",paddingLeft:10,paddingRight:10}}>
                                                    <Label style={{fontFamily:"Lucida Console"}}>
                                                        {this.state.playedVideoMinute}:{this.state.playedVideoSecond}/{this.state.videoDurationMinute}:{this.state.videoDurationSecond}
                                                    </Label>
                                                </div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",paddingLeft:5,paddingRight:5}}>
                                                <Button onClick={()=>{this.playButtonPressed()}}>
                                                    {
                                                    this.state.videoPlaying?
                                                        <MdPause />
                                                        :
                                                        <MdPlayArrow />
                                                    }
                                                </Button>
                                                <div style={{display:"flex",flexDirection:"row",fontFamily:'Microsoft YaHei semibold',}}>
                                                    <Button style={{marginLeft:10, marginTop:2}} onClick={()=>{
                                                        this.changePlaybackRate()
                                                    }}>
                                                        {this.state.playbackRate.toFixed(1)}x
                                                    </Button>

                                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",paddingLeft:10,paddingRight:10}}>
                                                        <div style={{display:"flex",marginRight:5,backgroundColor:"grey",justifyContent:"center",paddingLeft:10,paddingRight:10,paddingTop:10,paddingBottom:10,borderRadius:5}}
                                                            onClick={()=>{this.setState({videoMuted:!this.state.videoMuted})}}>
                                                            {
                                                            this.state.videoMuted?
                                                                <FaVolumeMute size={18} style={{color:"white"}} />
                                                                :
                                                                this.state.videoVolume>0.5?
                                                                    <FaVolumeUp size={18} style={{color:"white"}} />
                                                                    :
                                                                    <FaVolumeDown size={18} style={{color:"white"}} />
                                                            }
                                                        </div>
                                                        <div style={styles.volume}>
                                                            <input type="range" min="0" max="1" step="any" ref={this._volumeInput}/>
                                                        </div>
                                                    </div>

                                                    {
                                                        screenfull.isEnabled?
                                                        <div style={styles.sizing}>
                                                            <div style={{display:"flex",marginTop:1,backgroundColor:"grey",justifyContent:"center",paddingLeft:10,paddingRight:10,paddingTop:10,paddingBottom:10,borderRadius:5}}
                                                                onClick={()=>{
                                                                    if(!screenfull.isEnabled){
                                                                        alert("Full screen is not support in your current browser")
                                                                        return
                                                                    }
                                                                    if(screenfull.isFullscreen){
                                                                        screenfull.exit()
                                                                    }else{
                                                                        screenfull.request(this._videoComponent.current)
                                                                    }
                                                                }}>
                                                                {
                                                                    screenfull.isFullscreen?
                                                                        <MdFullscreenExit size={18} style={{color:"white"}} />
                                                                        :
                                                                        <MdFullscreen size={18} style={{color:"white"}} />
                                                                }
                                                            </div>
                                                        </div>
                                                        :null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                        this.state.mode=="search"?
                            <div>
                                <div style={{display:"flex",flexDirection:"row"}}>

                                    <Input type="text" placeholder="Search knowledgebase with title"
                                        style={{marginRight:10}}
                                        value={this.state.searchKnowledgebaseField}
                                        onChange={event => {
                                          this.setState({searchKnowledgebaseField: event.target.value})
                                          this.searchKnowledgebase(event.target.value)
                                        }}
                                        onKeyDown={(e)=>{
                                            if(e.keyCode==13){
                                                e.preventDefault()
                                                this.searchKnowledgebase()
                                            }
                                        }}/>
                                </div>
                                <div style={{paddingTop:10}}>
                                    {
                                    this.state.searchKnowledgebaseField.length==0?
                                      this.state.knowledgeList.map((item)=>{
                                          return(
                                              <div style={{borderStyle:"solid",marginBottom:5,borderWidth:1,borderColor:"grey",width:"100%",cursor:"pointer",backgroundColor:"#fffff2",padding:10,borderRadius:5}}
                                                  onClick={()=>{this.viewKnowledge(item)}}>
                                                  <div style={{textAlign:"left"}}>{item.title}</div>
                                                  <div style={{display:"flex",flexDirection:"row",color:"grey",fontSize:12}}>
                                                      <div style={{}}>{item.creatorName}</div>
                                                      <div style={{marginLeft:20}}>Created: {item.createDate}</div>
                                                      <div style={{marginLeft:20}}>Last edited: {item.lastEditDate}</div>
                                                  </div>
                                              </div>
                                          )
                                      })
                                      :
                                      this.state.knowledgeListSearch.map((item)=>{
                                          return(
                                              <div style={{borderStyle:"solid",marginBottom:5,borderWidth:1,borderColor:"grey",width:"100%",cursor:"pointer",backgroundColor:"#fffff2",padding:10,borderRadius:5}}
                                                  onClick={()=>{this.viewKnowledge(item)}}>
                                                  <div style={{textAlign:"left"}}>{item.title}</div>
                                                  <div style={{display:"flex",flexDirection:"row",color:"grey",fontSize:12}}>
                                                      <div style={{}}>{item.creatorName}</div>
                                                      <div style={{marginLeft:20}}>Created: {item.createDate}</div>
                                                      <div style={{marginLeft:20}}>Last edited: {item.lastEditDate}</div>
                                                  </div>
                                              </div>
                                          )
                                      })
                                    }
                                </div>
                            </div>
                        :
                        this.state.mode=="viewKnowledge"?
                            <div>
                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingBottom:20,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderTopWidth:0,borderRightWidth:0,borderColor:"lightgrey"}}>
                                    <div style={{}}>
                                        <div style={{fontSize:20,fontWeight:"bold"}}>{this.state.currentKnowledge.title}</div>
                                        <div style={{fontSize:12,color:"grey"}}>Creator: {this.state.currentKnowledge.creatorName}</div>
                                        <div style={{fontSize:12,color:"grey"}}>Create Date: {this.state.currentKnowledge.createDate}</div>
                                        <div style={{fontSize:12,color:"grey"}}>Last Edit Date: {this.state.currentKnowledge.lastEditDate}</div>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-end"}}>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <Button color="danger" style={{marginRight:10}} onClick={()=>{this.deleteCurrentKnowledge()}}>Delete knowledge</Button>
                                            <Button color="primary" onClick={()=>this.editCurrentKnowledge()}>Edit knowledge</Button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginTop:10,padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                    <Editor style={{}}
                                        editorState={this.state.editModeEditorState}
                                        readOnly={true}
                                        toolbarHidden={true}
                                        toolbar={{
                                            image:{
                                                uploadEnabled:true,
                                                uploadCallback:this.imageUploadCallback,
                                                previewImage: true,
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            }
                                        }}
                                        />
                                </div>
                            </div>
                        :
                        this.state.mode=="edit"?
                            <div>
                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",paddingBottom:20,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderTopWidth:0,borderRightWidth:0,borderColor:"lightgrey"}}>
                                    <div style={{display:"flex",flexDirection:"column",flex:1}}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5}}>
                                            <div style={{marginRight:10}}>Title:</div>
                                            <Input type="text" placeholder="Title of this knowledge"
                                                style={{marginRight:10,flex:1}}
                                                value={this.state.editModeTitle}
                                                onChange={event => this.setState({editModeTitle: event.target.value})}
                                                />
                                        </div>
                                        <div style={{fontSize:12,color:"grey"}}>Creator: {this.state.currentKnowledge.creatorName}</div>
                                        <div style={{fontSize:12,color:"grey"}}>Create Date: {this.state.currentKnowledge.createDate}</div>
                                        <div style={{fontSize:12,color:"grey"}}>Last Edit Date: {this.state.currentKnowledge.lastEditDate}</div>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-end"}}>
                                        <Button color="primary" onClick={()=>this.saveEditKnowledge()}>Save</Button>
                                    </div>
                                </div>
                                <div style={{marginTop:10,padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                    <Editor style={{}}
                                        editorState={this.state.editModeEditorState}
                                        onEditorStateChange={this.onEditModeEditorStateChange}
                                        toolbar={{
                                            image:{
                                                uploadEnabled:true,
                                                uploadCallback:this.imageUploadCallback,
                                                previewImage: true,
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            }
                                        }}
                                        />
                                </div>
                            </div>
                        :
                        this.state.mode=="create"?
                            <div>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                    <div style={{marginRight:10}}>Title:</div>
                                    <Input type="text" placeholder="Title of this knowledge"
                                        style={{marginRight:10}}
                                        value={this.state.createTitle}
                                        onChange={event => this.setState({createTitle: event.target.value})}
                                        />
                                </div>
                                <div style={{marginTop:10,padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                    <Editor style={{}}
                                        editorState={this.state.editorState}
                                        onEditorStateChange={this.onEditorStateChange}
                                        toolbar={{
                                            image:{
                                                uploadEnabled:true,
                                                uploadCallback:this.imageUploadCallback,
                                                previewImage: true,
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            }
                                        }}
                                        />
                                </div>
                                <div>
                                    <div style={{color:"blue"}} onClick={()=>this.htmlPreview()}>HTML preview</div>
                                    <div>
                                        {
                                        this.state.htmlPreviewMode?
                                            <textarea
                                                  disabled
                                                  style={{width:"100%"}}
                                                  height={200}
                                                  value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}
                                                />
                                            :null
                                        }
                                    </div>
                                </div>
                                <div style={{marginTop:10,display:"flex",flexDirection:"row",justifyContent:"flex-end"}}>
                                    <Button color="danger" style={{marginRight:10}} onClick={()=>{this.publish()}}>Publish</Button>
                                </div>
                            </div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

Knowledgebase = Radium(Knowledgebase);

let styles = {
    columnTitleStyle:{
        fontWeight:"bold",
    },
    searchResultRow:{
        paddingTop:10,
        paddingBottom:10,
        borderBottomWidth:1,
        borderLeftWidth:0,
        borderTopWidth:0,
        borderRightWidth:0,
        borderStyle:"solid",
        borderColor:"#e6e6e6",
        ':hover':{
            backgroundColor:"grey",
            color:"white",
        },
    },
    memberDetailCell:{
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:5,
        paddingRight:5,
    },
}
