import React from 'react';
import {Input,Button,Alert,Row,Col,Table,Modal} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'
import QrReader from 'react-qr-reader'
import QRCode from "qrcode.react";

import {FaCheckCircle,FaTimesCircle,FaCopy,FaInfoCircle} from 'react-icons/fa'
import {AiOutlineQrcode} from 'react-icons/ai'

export default class OfflineCourse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,

            courseSessionModal:false,
            courseSessionList:[],
            selectedCourseSession:null,
            courseSessionDetail:[], //list of people joined the course session
            totalRevision:"-",
            totalNew:"-",
            addCourseSessionParticipantModal:false,
            removeCourseSessionParticipantModal:false,
            editEnrollerModal:false,
            editEnroller:"",
            userSelected:"",
            addParticipantUsername:"",
            removeParticipantUsername:"",
            csParticipantRevision:true,

            createNewSessionModal:false,
            courseList:[],
            createSessionSelectedCourse:null,
            newSessionStartDate:null,
            newSessionEndDate:null,
            newSessionVenue:null,
            newSessionSeatForNew:null,
            newSessionSeatForRevision:null,
            newSessionRevisionCost:null,

            editCourseSessionModal:false,

            editFormInfoModal:false,
            headerText:null,
            headerImage:null,
            headerImageUpload:null,
            headerImageUploadString:null,

            //QR code scanning
            scanQRCodeModal: false,
            scanInfo:"Scanning...",
            openQRCamera:false,
            scannerData:null,

            ticketStatusModal:false,
            ticketStatus:null,
            memberDetail:null,

            manualMarkAttendanceModal:false,
            selectedParticipant:null,

            ticketQRCodeModal:false,
            ticketQRCodeUserDetail:"",
            ticketQRCode:null,
        }
        this.divRef = React.createRef()
        this.runningBarcodeScanner=false
        this.scanBarcodeCooldown = 500
        this.sessionMode="coming"
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        this.comingCourseSession()
    }

    fallbackCopyTextToClipboard=(text)=>{
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
    copyTextToClipboard=(text)=>{
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            alert('Copy to clipboard successful');
        }, function(err) {
            alert('Could not copy text: ', err);
        });
    }
    copyList=()=>{
        var copyString="Phone\tName\tSignup Date\tEnroller\tRevision\tTicket Number\tVegetarian\tRemark\tAttendance\n"
        for(var cs of this.state.courseSessionDetail){
            copyString+=cs.user.username+"\t"
            copyString+=cs.user.name+"\t"
            copyString+=cs.signupDate+"\t"
            if(cs.enrollerUsername !== undefined ){
                copyString+=cs.enrollerUsername+" "+cs.enrollerName+"\t"
            }else{
                copyString+="-\t"
            }
            if(cs.revision){
                copyString+="Yes\t"
            }else{
                copyString+="No\t"
            }
            copyString+=cs.ticketNumber+"\t"
            console.log(cs)
            if(cs.bookingForm){
                if(cs.bookingForm.vegetarian==true){
                    copyString+="Yes\t"
                }else{
                    copyString+="No\t"
                }
            }else{
                copyString+="No\t"
            }
            copyString+=cs.remark+"\t"
            copyString+=JSON.stringify(cs.attendance)
            copyString+="\n"
        }
        this.copyTextToClipboard(copyString)
    }

    comingCourseSession=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getcomingcoursesessionlist`)
            .then(async response => {
                //determine how many days span is each courses session, used for json attendance record of each day
                for(let cs of response.data){
                    let attendance={}
                    let startDate=new Date(cs.eventDate)
                    cs.eventDateObj=new Date(cs.eventDate)
                    let endDate=new Date(cs.endDate)
                    cs.endDateObj=endDate
                    let currentDate=startDate
                    while(currentDate<=endDate){
                        attendance[""+currentDate.getDate()]=0
                        currentDate.setDate(currentDate.getDate() + 1)
                    }
                    cs.attendance=attendance
                }

                //parse event date
                for(let cs of response.data){
                    var dateSplit=cs.eventDate.toString().split("-")
                    cs.eventDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }

                this.sessionMode="coming"
                this.setState({
                    loading: false,
                    courseSessionModal:true,
                    courseSessionList:response.data,
                    courseSessionDetail:[],
                    selectedCourseSession:null,
                })
            })
            .catch(error => {
                alert("Get coming course session error\n" + error)
                this.setState({loading: false,})
            });
    }

    loadCourseSessionDetail=(courseSession)=>{
        this.setState({loading:true})
        const payLoad = {
            courseSessionString:courseSession.courseTitle,
        }
        axios
            .post(`sales/loadcoursesessiondetail`, qs.stringify(payLoad))
            .then(async response => {
                //parse date
                for(let f=0;f<response.data.attendeeList.length;f++){
                    let rawDateSplit=response.data.attendeeList[f].signupDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.attendeeList[f].signupDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //count some stats
                let totalRevision=0
                let totalNew=0
                for(let booking of response.data.attendeeList){
                    if(booking.revision){
                        totalRevision+=1
                    }else{
                        totalNew+=1
                    }
                }

                this.setState({
                    loading:false,
                    courseSessionDetail:response.data.attendeeList,
                    totalRevision:totalRevision,
                    totalNew:totalNew,
                    totalSeat:response.data.seatForNew+response.data.seatForRevision,
                    seatForNew:response.data.seatForNew,
                    seatForRevision:response.data.seatForRevision,
                    revisionCost:response.data.revisionCost,
                    selectedCourseSession:courseSession,
                })
            })
            .catch(error => {
                alert("Load course session detail error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    addCourseSessionParticipant=()=>{
        this.setState({loading:true})
        const payLoad = {
            courseSessionString:this.state.selectedCourseSession.courseTitle,
            participantUsername:this.state.addParticipantUsername,
            revision:this.state.csParticipantRevision,
        }
        axios
            .post(`sales/addparticipanttocoursesession`, qs.stringify(payLoad))
            .then(async response => {
                alert(this.state.addParticipantUsername+" is added as participant to "+this.state.selectedCourseSession.courseTitle)
                this.setState({
                    loading:false,
                    addParticipantUsername:"",
                    addCourseSessionParticipantModal:false,
                })
                this.loadCourseSessionDetail(this.state.selectedCourseSession)
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account is logged in another device, please relog")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==444){
                        alert("The person is already participant")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Add participant to course session error\n" + error)
                this.setState({loading: false,})
            });
    }

    editEnrollerClicked=(enrollerUsername)=>{
        this.setState({loading:true})
        const payLoad = {
            enrollerUsername:enrollerUsername,
            userSelected:this.state.userSelected,
            courseString:this.state.selectedCourseSession.courseTitle,
        }
        axios
            .post(`sales/editenroller`, qs.stringify(payLoad))
            .then(async response => {
                alert(this.state.userSelected+"'s enroller changed to "+enrollerUsername)
                this.setState({
                    loading:false,
                    userSelected:"",
                    editEnrollerModal:false,
                })
                this.loadCourseSessionDetail(this.state.selectedCourseSession)
            })
            .catch(error => {
                if(error.response.status==449){   //cannot find enroller
                    alert("Invalid enroller")
                    this.setState({loading:false,editEnrollerModal:false})
                    return
                }else if(error.response.status==460){
                    alert("Your account is logged in another device, please relog")
                    this.setState({loading:false})
                    return
                }else if(error.response.status==401){
                    alert("Only Staff can edit enroller")
                    this.setState({loading:false})
                    return
                }else if(error.response.status==444){
                    alert("Username doesn't exist")
                    this.setState({loading:false})
                    return}
                alert("Edit enroller error\n" + error)
                this.setState({loading: false,})
            });
    }

    removeCourseSessionParticipant=()=>{
        this.setState({loading:true})
        const payLoad = {
            courseSessionString:this.state.selectedCourseSession.courseTitle,
            participantUsername:this.state.removeParticipantUsername,
        }
        axios
            .post(`sales/removeparticipanttocoursesession`, qs.stringify(payLoad))
            .then(async response => {
                alert(this.state.removeParticipantUsername+" is removed from "+this.state.selectedCourseSession.courseTitle)
                this.setState({
                    loading:false,
                    removeParticipantUsername:"",
                    removeCourseSessionParticipantModal:false,
                })
                this.loadCourseSessionDetail(this.state.selectedCourseSession)
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account is logged in another device, please relog")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Remove participant from course session error\n" + error)
                this.setState({loading: false,})
            });
    }

    saveRemark=(ticketNumber,remark)=>{
        this.setState({loading:true})
        const payLoad = {
            ticketNumber:ticketNumber,
            remark:remark,
        }
        axios
            .post(`sales/saveticketremark`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({loading:false})
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account is logged in another device, please relog")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Save ticket remark error\n" + error)
                this.setState({loading: false,})
            });
    }

    getCourseList=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getcourselist`)
            .then(async response => {
                //reform into array for select
                var courseArray=[]
                for(var course of response.data){
                    courseArray.push(course.title)
                }

                this.setState({
                    loading:false,
                    courseList:courseArray,
                    createNewSessionModal:true
                })
            })
            .catch(error => {
                alert("Load course list error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    createNewCourseSession=()=>{
        this.setState({loading:true})
        const payLoad = {
            createSessionSelectedCourse:this.state.createSessionSelectedCourse,
            newSessionStartDate:this.state.newSessionStartDate,
            newSessionEndDate:this.state.newSessionEndDate,
            newSessionVenue:this.state.newSessionVenue,
            newSessionSeatForRevision:this.state.newSessionSeatForRevision,
            newSessionSeatForNew:this.state.newSessionSeatForNew,
            newSessionRevisionCost:this.state.newSessionRevisionCost,
        }
        axios
            .post(`sales/createnewcoursesession`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({
                    createNewSessionModal:false,
                })
                this.comingCourseSession()
            })
            .catch(error => {
                alert("Create new course session error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    deleteCourseSession=()=>{
        if(!window.confirm("Are you sure you want to delete "+this.state.selectedCourseSession.courseTitle+"?\n This action is irreversible and the system will NOT return course credit to booked participants.")){
            return
        }
        this.setState({loading:true})
        const payLoad = {
            courseSessionID:this.state.selectedCourseSession.id,
        }
        axios
            .post(`sales/deletecoursesession`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({
                    selectedCourseSession:null,
                })
                this.comingCourseSession()
            })
            .catch(error => {
                alert("Delete course session error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    archiveCourseSession=()=>{
        if(!window.confirm("Are you sure you want to archive "+this.state.selectedCourseSession.courseTitle+"?\n This action is irreversible.")){
            return
        }
        this.setState({loading:true})
        const payLoad = {
            courseSessionID:this.state.selectedCourseSession.id,
        }
        axios
            .post(`membership/v1/archivedCourseSession`, qs.stringify(payLoad),{timeout:60000})
            .then(async response => {
                alert("Course session successfully archived")
                this.comingCourseSession()
            })
            .catch(error => {
                alert("Archive course session error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    editCourseSession=()=>{
        this.setState({loading:true})
        const payLoad = {
            courseSessionID:this.state.selectedCourseSession.id,
            createSessionSelectedCourse:this.state.createSessionSelectedCourse,
            newSessionStartDate:this.state.newSessionStartDate,
            newSessionEndDate:this.state.newSessionEndDate,
            newSessionVenue:this.state.newSessionVenue,
            newSessionSeatForRevision:this.state.newSessionSeatForRevision,
            newSessionSeatForNew:this.state.newSessionSeatForNew,
            newSessionRevisionCost:this.state.newSessionRevisionCost,
        }
        axios
            .post(`sales/editcoursesession`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({
                    editCourseSessionModal:false,
                    selectedCourseSession:null,
                })
                this.comingCourseSession()
            })
            .catch(error => {
                alert("Edit course session error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    getCourseSessionInfo=()=>{
        this.setState({loading:true})
        const payLoad = {
            courseSessionID:this.state.selectedCourseSession.id,
            sessionMode:this.sessionMode,
        }
        axios
            .post(`sales/getcoursesessioninfo`, qs.stringify(payLoad))
            .then(async response => {
                //reform into array for select
                var courseArray=[]
                for(var course of response.data.courseList){
                    courseArray.push(course.title)
                }

                var selectedCourse=null
                for(var course of courseArray){
                    if(response.data.courseSessionInfo.courseTitle.includes(course)){
                        selectedCourse=course
                        break
                    }
                }

                this.setState({
                    loading:false,
                    editCourseSessionModal:true,
                    courseList:courseArray,
                    createSessionSelectedCourse:selectedCourse,
                    newSessionStartDate:response.data.courseSessionInfo.eventDate,
                    newSessionEndDate:response.data.courseSessionInfo.endDate,
                    newSessionVenue:response.data.courseSessionInfo.venueCity,
                    newSessionSeatForRevision:parseInt(response.data.courseSessionInfo.seatForRevision),
                    newSessionSeatForNew:parseInt(response.data.courseSessionInfo.seatForNew),
                    newSessionRevisionCost:parseFloat(response.data.courseSessionInfo.revisionCost),
                })
                // this.comingCourseSession()
            })
            .catch(error => {
                alert("Get course session info error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    getCourseSessionHeader=()=>{
        this.setState({loading:true})
        const payLoad = {
            courseSessionID:this.state.selectedCourseSession.id,
        }
        axios
            .post(`sales/getcoursesessionheader`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({
                    loading:false,
                    editFormInfoModal:true,
                    headerText:response.data.headerText,
                    headerImage:response.data.headerImage,
                    headerImageUpload:null,
                    headerImageUploadString:null,
                })
            })
            .catch(error => {
                alert("Get course session form info error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    saveFormInfo=()=>{
        this.setState({loading:true})
        const formData = new FormData()
        formData.append("courseSessionID",this.state.selectedCourseSession.id)
        if(this.state.headerImageUpload){
            formData.append("headerImageUpload", this.state.headerImageUpload,"image/png")
        }
        formData.append("headerText",this.state.headerText)
        axios({
            method: "POST",
            url: `sales/saveforminfo`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async response => {
            alert("Form data updated")
            this.setState({
                loading:false,
                editFormInfoModal:false,
            })
        })
        .catch(error => {
            alert("Update form info error\n"+error)
            this.setState({
                loading:false,
            })
        });
    }

    markBookedCourseSessionAttend=(key)=>{
        this.setState({loading:true})
        const payload = {
            bookedCourseSessionID:this.state.selectedParticipant.id,
            attendanceKey:key,
        }
        axios
            .post(`sales/markbookedcoursesessionattend`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //mark
                let participantListClone=this.state.courseSessionDetail
                for(let participant of participantListClone){
                    if(participant.id==response.data.id){
                        participant.checked=response.data.checked
                        participant.attendance=response.data.attendance
                        break
                    }
                }
                this.setState({
                    loading:false,
                    courseSessionDetail:participantListClone,
                })
            })
            .catch(error =>{
                alert("Mark attend conference error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    calculateAndGenerateAttendance=()=>{
        if(this.state.selectedCourseSession==null){
            return null
        }

        let theAttendance=this.state.selectedCourseSession.attendance
        let totalParticipant=this.state.courseSessionDetail.length
        let courseSessionDetail=this.state.courseSessionDetail
        let ele=[]
        Object.keys(theAttendance).forEach(function(key,index) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object

            //count
            theAttendance[key]=0
            for(let csd of courseSessionDetail){
                if(csd.attendance!=null&&csd.attendance[key]!=null&&csd.attendance[key]==true){
                    theAttendance[key]+=1
                }
            }

            //generate ui component
            ele.push(
                <div style={{padding:"5px 10px",border:"1px solid lightgrey"}}>
                    <i style={{color:"grey"}}>{key}th</i> : {theAttendance[key]} / {totalParticipant}
                </div>
            )
        })
        return ele
    }

    generateAttendanceButton=(csd)=>{
        let theAttendance=this.state.selectedCourseSession.attendance
        let ele=[]
        let inEventDate=false
        let attendingToday=false

        if(this.sessionMode=="coming"){
            //check if today is event date
            let startDate=this.state.selectedCourseSession.eventDateObj.setHours(0,0,0)
            let endDate=this.state.selectedCourseSession.endDateObj.setHours(23,59,59)
            let currentDate=new Date()
            if(currentDate>=startDate&&currentDate<=endDate){
                inEventDate=true
            }else{
                inEventDate=false
            }
        }else if(this.sessionMode=="previous"){
            inEventDate=true
        }

        //check the member is attending today
        let todayDigit=""+new Date().getDate()
        if(this.sessionMode=="coming"){
            if(csd.attendance!=null&&inEventDate==true&&todayDigit in csd.attendance&&csd.attendance[todayDigit]==true){
                attendingToday=true
            }
        }else if(this.sessionMode=="previous"){
            if(csd.checked==true){
                attendingToday=true
            }
        }

        //draw three notifications
        Object.keys(theAttendance).forEach(function(key,index) {
            if(csd.attendance!=null&&key in csd.attendance&&csd.attendance[key]==true){   //checked
                ele.push(
                    <div style={{height:5,width:5,borderRadius:10,backgroundColor:"green"}} />
                )
            }else{  //not checked
                ele.push(
                    <div style={{height:5,width:5,borderRadius:10,backgroundColor:"red"}} />
                )
            }
        })

        //draw final button
        return(
            <div style={{display:"flex",flexDirection:"row"}}>
                <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",gap:2,marginRight:2}}>{ele}</div>
                {
                inEventDate==true?
                    attendingToday==true?
                        <Button color="primary" style={{padding:"2px 10px",fontSize:15,lineHieght:1,backgroundColor:"green",border:"none"}} onClick={()=>{
                            if(this.sessionMode=="previous"){
                                return
                            }
                            this.setState({manualMarkAttendanceModal:true,selectedParticipant:csd})
                        }}>
                            V
                        </Button>
                        :
                        <Button color="primary" style={{padding:"2px 10px",fontSize:15,lineHieght:1,backgroundColor:"red",border:"none"}} onClick={()=>{
                            if(this.sessionMode=="previous"){
                                return
                            }
                            this.setState({manualMarkAttendanceModal:true,selectedParticipant:csd})
                        }}>
                            X
                        </Button>
                    :
                    <Button color="secondary" style={{padding:"2px 10px",fontSize:15,lineHieght:1,}} onClick={()=>{
                        if(this.sessionMode=="previous"){
                            return
                        }
                        this.setState({manualMarkAttendanceModal:true,selectedParticipant:csd})
                    }}>
                        -
                    </Button>
                }
            </div>
        )
    }

    //QR code scanning
    handleScan = data => {
        if(this.state.scanInfo=="Checking..."||this.state.ticketStatusModal==true)return
        if (data) {
            this.setState({loading:true,scanInfo:"Checking..."})
            const payload = {
                data:data,
                courseTitle:this.state.selectedCourseSession.courseTitle,
            }
            axios
                .post(`sales/scanbookedcoursesessionattendance`, qs.stringify(payload),{timeout:20000}) //with extra timeout
                .then(async response => {
                    //mark
                    let participantListClone=this.state.courseSessionDetail
                    for(let participant of participantListClone){
                        if(participant.id==response.data.csdDetail.id){
                            participant.checked=response.data.csdDetail.checked
                            participant.attendance=response.data.csdDetail.attendance
                            break
                        }
                    }
                    this.setState({
                        loading:false,
                        scanInfo:"Scanning...",
                        scannerData:"",
                        ticketStatusModal:true,
                        ticketStatus:"success",
                        memberDetail:response.data.memberDetail,
                        courseSessionDetail:participantListClone,
                    })
                })
                .catch(error =>{
                    if(error.response){
                        if(error.response.status==444){
                            this.setState({
                                loading:false,
                                scanInfo:"Scanning...",
                                scannerData:"",
                                ticketStatusModal:true,
                                ticketStatus:"already",
                                memberDetail:error.response.data.memberDetail,
                            })
                            return
                        }else if(error.response.status==445){
                            this.setState({
                                loading:false,
                                scanInfo:"Scanning...",
                                scannerData:"",
                                ticketStatusModal:true,
                                ticketStatus:"notFound",
                                memberDetail:null,
                            })
                            return
                        }
                    }
                    alert("Scan attendance error\n"+error)
                    this.setState({
                        loading:false,
                        scanInfo:"Scanning...",
                        scannerData:"",
                        memberDetail:null,
                    })
                })
        }
    }
    handleError = err => {
        alert("QR code scanner error\n" + err)
        this.setState({ scanQRCodeModal: false })
    }

    generatePaticipantAttendance=()=>{
        let ele=[]
        let theAttendance=this.state.selectedCourseSession.attendance
        let selectedParticipant=this.state.selectedParticipant
        let component=this
        Object.keys(theAttendance).forEach(function(key,index) {
            if(selectedParticipant.attendance!=null&&key in selectedParticipant.attendance&&selectedParticipant.attendance[key]==true){   //checked
                ele.push(
                    <Button color="primary" style={{backgroundColor:"green",border:"none"}} onClick={()=>{
                        component.markBookedCourseSessionAttend(key)
                    }}>
                        {key}th
                    </Button>
                )
            }else{  //not checked
                ele.push(
                    <Button color="primary" style={{backgroundColor:"red",border:"none"}} onClick={()=>{
                        component.markBookedCourseSessionAttend(key)
                    }}>
                        {key}th
                    </Button>
                )
            }
        })
        return ele
    }

    loadListOfArchivedCourseSession=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/loadlistofarchivedcoursesession`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //determine how many days span is each courses session, used for json attendance record of each day
                for(let cs of response.data){
                    let attendance={}
                    let startDate=new Date(cs.eventDate)
                    cs.eventDateObj=new Date(cs.eventDate)
                    let endDate=new Date(cs.endDate)
                    cs.endDateObj=endDate
                    let currentDate=startDate
                    while(currentDate<=endDate){
                        attendance[""+currentDate.getDate()]=0
                        currentDate.setDate(currentDate.getDate() + 1)
                    }
                    cs.attendance=attendance
                }

                //parse event date
                for(let cs of response.data){
                    var dateSplit=cs.eventDate.toString().split("-")
                    cs.eventDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }

                this.sessionMode="previous"
                this.setState({
                    loading:false,
                    courseSessionList:response.data,
                    courseSessionDetail:[],
                    selectedCourseSession:null,
                })
            })
            .catch(error =>{
                alert("Load archived course session list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    loadArchivedCourseSessionDetail=(courseSession)=>{
        this.setState({loading:true})
        const payLoad = {
            courseSessionString:courseSession.courseTitle,
        }
        axios
            .post(`sales/loadarchivedcoursesessiondetail`, qs.stringify(payLoad))
            .then(async response => {
                //parse date
                for(var f=0;f<response.data.attendeeList.length;f++){
                    let rawDateSplit=response.data.attendeeList[f].signupDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.attendeeList[f].signupDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //count some stats
                var totalRevision=0
                var totalNew=0
                for(var booking of response.data.attendeeList){
                    if(booking.revision){
                        totalRevision+=1
                    }else{
                        totalNew+=1
                    }
                }

                this.setState({
                    loading:false,
                    courseSessionDetail:response.data.attendeeList,
                    totalRevision:totalRevision,
                    totalNew:totalNew,
                    totalSeat:response.data.seatForNew+response.data.seatForRevision,
                    seatForNew:response.data.seatForNew,
                    seatForRevision:response.data.seatForRevision,
                    revisionCost:response.data.revisionCost,
                    selectedCourseSession:courseSession,
                })
            })
            .catch(error => {
                alert("Load archived course session detail error\n" + error)
                this.setState({
                    loading: false,
                })
            });
    }

    getTicketQRCodeLink=(item)=>{
        // item.ticketNumber
        this.setState({
            ticketQRCodeModal:true,
            ticketQRCodeUserDetail:item.user.username+" "+item.user.name,
            ticketQRCode:item.ticketNumber,
        })
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal style={{}} isOpen={this.state.ticketQRCodeModal} toggle={()=>this.setState({ticketQRCodeModal:false})}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Ticket QR Code</div>
                        <div style={{width:"100%",textAlign:"center"}}>
                            {this.state.ticketQRCodeUserDetail}

                        </div>
                        {
                        this.state.ticketQRCode&&
                            <QRCode
                                id="QR"
                                value={this.state.ticketQRCode}
                                level="M"
                                renderAs="svg"
                                size={256}
                                imageSettings={{
                                    height:70,
                                    width:70,
                                }}
                                style={{marginTop:10}}
                            />
                        }
                        <div style={{display:"flex",flexDirection:"row",gap:5,width:"100%",marginTop:20}}>
                            <Button color="success" style={{flex:1}} onClick={() => {
                                let svg = document.getElementById("QR")
                                let serializedSVG = new XMLSerializer().serializeToString(svg)
                                let base64Data = window.btoa(serializedSVG)
                                let imageUrl = "data:image/svg+xml;base64," + base64Data

                                let a = document.createElement("a")
                                a.setAttribute("href", imageUrl)
                                a.setAttribute("download", this.state.ticketQRCode.replaceAll(" ", "_") + "_qrcode.svg")
                                a.dispatchEvent(new MouseEvent('click', { view: window, bubbles: false, cancelable: true }))
                            }}>Download</Button>
                            <Button style={{backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                this.setState({ticketQRCodeModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{}} isOpen={this.state.manualMarkAttendanceModal} toggle={()=>this.setState({manualMarkAttendanceModal:false})}>
                    {
                    this.state.manualMarkAttendanceModal==true?
                        <div style={{padding:20}}>
                            <div style={{fontSize:20,fontWeight:"bold"}}>Manual Mark Attendance</div>
                            <div style={{width:"100%",textAlign:"center"}}>
                                {
                                this.state.selectedParticipant.user.username==this.state.selectedParticipant.user.name?
                                    this.state.selectedParticipant.user.name
                                    :
                                    this.state.selectedParticipant.user.username+" "+this.state.selectedParticipant.user.name
                                }
                            </div>
                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:10,marginTop:10,justifyContent:"center"}}>
                                {this.generatePaticipantAttendance()}
                            </div>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <Button color="primary" style={{marginTop:20,paddingLeft:50,paddingRight:50}} onClick={()=>{
                                    this.setState({manualMarkAttendanceModal:false})
                                }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                        :
                        null
                    }
                </Modal>

                <Modal style={{}} isOpen={this.state.ticketStatusModal} toggle={()=>this.setState({ticketStatusModal:false})} autoFocus={false}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold",marginBottom:15,textAlign:"center",width:"100%"}}>
                            {this.state.memberDetail}
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            {
                            this.state.ticketStatus=="success"?
                                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <FaCheckCircle style={{fontSize:120,color:"#abd64f"}} />
                                    <div style={{marginTop:15}}>Ticket check success. Attendance marked.</div>
                                </div>
                                :
                            this.state.ticketStatus=="notFound"?
                                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <FaTimesCircle style={{fontSize:120,color:"salmon"}} />
                                    <div style={{marginTop:15}}>Ticket not found.</div>
                                </div>
                                :
                            this.state.ticketStatus=="already"?
                                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <FaTimesCircle style={{fontSize:120,color:"salmon"}} />
                                    <div style={{marginTop:15}}>This ticket is already checked for today.</div>
                                </div>
                                :
                                null
                            }
                            <Button color="primary" style={{marginTop:20,paddingLeft:50,paddingRight:50}} autoFocus={true} onClick={()=>{
                                this.setState({ticketStatusModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.scanQRCodeModal} toggle={() => this.setState({ scanQRCodeModal: false })} autoFocus={false}>
                    {
                    this.state.selectedCourseSession!=null?
                        <div style={{ padding: 20, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <div style={{marginBottom:10,display:"flex",flexDirection:"row",width:"100%",alignItems:"center"}}>
                                <Button color={this.state.openQRCamera==true?"secondary":"primary"} onClick={()=>{
                                    this.setState({openQRCamera:false})
                                }}>
                                    Scanner
                                </Button>
                                <Button color={this.state.openQRCamera==true?"primary":"secondary"} style={{marginLeft:10}} onClick={()=>{
                                    this.setState({openQRCamera:true})
                                }}>
                                    Camera
                                </Button>
                                <div style={{marginLeft:20}}>
                                    Attendance for: {this.state.selectedCourseSession.courseTitle+" "+new Date().getDate()+"th"}
                                </div>
                            </div>
                            {
                            this.state.scanQRCodeModal==true?
                                this.state.openQRCamera==true?
                                    <div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                                        <QrReader
                                            delay={500}
                                            onError={this.handleError}
                                            onScan={this.handleScan}
                                            style={{ width: '100%' }}
                                        />
                                        <div>{this.state.scanInfo}</div>
                                    </div>
                                    :
                                    <div style={{width:"100%"}}>
                                        <Input value={this.state.scannerData} id={"ScanBarcode"} type="text" autoFocus={true} onChange={(e)=>{
                                            this.setState({scannerData:e.target.value},()=>{
                                                //wait for Xms to start getting the item
                                                if(this.runningBarcodeScanner==false){
                                                    this.runningBarcodeScanner=true
                                                    setTimeout(()=>{
                                                        var found=false
                                                        //find the user from server
                                                        this.handleScan(this.state.scannerData)
                                                        this.runningBarcodeScanner=false
                                                    }, this.scanBarcodeCooldown)
                                                }
                                            })
                                        }}  />
                                    </div>
                                : null
                            }
                        </div>
                        :
                        null
                    }
                </Modal>

                <Modal style={{maxWidth:800}} isOpen={this.state.editFormInfoModal} toggle={()=>this.setState({editFormInfoModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Edit Booking Form Info</div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <div style={{}}>
                                <div>Header Image</div>
                                {
                                this.state.headerImage?
                                    <img src={this.state.headerImage} style={{width:"100%"}} />
                                    :
                                    null
                                }
                                {
                                this.state.headerImageUpload?
                                    <img src={window.URL.createObjectURL(this.state.headerImageUpload)} style={{width:"100%"}}/>
                                    :
                                    null
                                }
                                <Input type="file" style={{}} value={this.state.headerImageUploadString} onChange={(e)=>{
                                    this.setState({headerImageUpload:e.target.files[0],headerImageUploadString:e.target.value})
                                }}/>
                                <div style={{marginTop:20}}>Header Text</div>
                                <textarea rows={20} style={{width:"100%"}} value={this.state.headerText} onChange={(e)=>{
                                    this.setState({headerText:e.target.value})
                                }}/>
                            </div>
                            <Button color="primary" style={{marginTop:20,paddingLeft:50,paddingRight:50}} onClick={()=>{
                                this.saveFormInfo()
                            }}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{}} isOpen={this.state.editCourseSessionModal} toggle={()=>this.setState({editCourseSessionModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Edit course session</div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <div style={{}}>
                                <div>Course</div>
                                <select style={{width:"100%"}} value={this.state.createSessionSelectedCourse} onChange={(e)=>{
                                    this.setState({createSessionSelectedCourse:e.target.value})
                                }}>
                                    {
                                    this.state.createSessionSelectedCourse==null?
                                        <option value={null} selected={true}>--</option>
                                        :null
                                    }
                                    {
                                        this.state.courseList.map((option,ind)=>{
                                            return(
                                                <option value={option} selected={this.state.createSessionSelectedCourse==option?true:false}>{option}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div>Start Date</div>
                                <Input type="date" style={{}} value={this.state.newSessionStartDate} onChange={(e)=>{
                                    this.setState({newSessionStartDate:e.target.value})
                                }}/>
                                <div>End Date</div>
                                <Input type="date" style={{}} value={this.state.newSessionEndDate} onChange={(e)=>{
                                    this.setState({newSessionEndDate:e.target.value})
                                }}/>
                                <div>Venue</div>
                                <Input type="text" style={{}} value={this.state.newSessionVenue} onChange={(e)=>{
                                    this.setState({newSessionVenue:e.target.value})
                                }}/>
                                <div>*Revision seat can be booked by New user but not vice versa.</div>
                                <div>Available Seat For New</div>
                                <Input type="number" style={{}} value={this.state.newSessionSeatForNew} onChange={(e)=>{
                                    this.setState({newSessionSeatForNew:e.target.value})
                                }}/>
                                <div>Available Seat For Revision</div>
                                <Input type="number" style={{}} value={this.state.newSessionSeatForRevision} onChange={(e)=>{
                                    this.setState({newSessionSeatForRevision:e.target.value})
                                }}/>
                                <div>Revision Cost (RM)</div>
                                <Input type="number" style={{}} value={this.state.newSessionRevisionCost} onChange={(e)=>{
                                    this.setState({newSessionRevisionCost:e.target.value})
                                }}/>
                            </div>
                            <Button color="primary" style={{marginTop:20,paddingLeft:50,paddingRight:50}} onClick={()=>{
                                this.editCourseSession()
                            }}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{}} isOpen={this.state.createNewSessionModal} toggle={()=>this.setState({createNewSessionModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Create new course session</div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <div style={{}}>
                                <div>Course</div>
                                <select style={{width:"100%"}} value={this.state.createSessionSelectedCourse} onChange={(e)=>{
                                    this.setState({createSessionSelectedCourse:e.target.value})
                                }}>
                                    {
                                    this.state.createSessionSelectedCourse==null?
                                        <option value={null} selected={true}>--</option>
                                        :null
                                    }
                                    {
                                        this.state.courseList.map((option,ind)=>{
                                            return(
                                                <option value={option} selected={this.state.createSessionSelectedCourse==option?true:false}>{option}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div>Start Date</div>
                                <Input type="date" style={{}} value={this.state.newSessionStartDate} onChange={(e)=>{
                                    this.setState({newSessionStartDate:e.target.value})
                                }}/>
                                <div>End Date</div>
                                <Input type="date" style={{}} value={this.state.newSessionEndDate} onChange={(e)=>{
                                    this.setState({newSessionEndDate:e.target.value})
                                }}/>
                                <div>Venue</div>
                                <Input type="text" style={{}} value={this.state.newSessionVenue} onChange={(e)=>{
                                    this.setState({newSessionVenue:e.target.value})
                                }}/>
                                <div>*Revision seat can be booked by New user but not vice versa.</div>
                                <div>Available Seat For New</div>
                                <Input type="number" style={{}} value={this.state.newSessionSeatForNew} onChange={(e)=>{
                                    this.setState({newSessionSeatForNew:e.target.value})
                                }}/>
                                <div>Available Seat For Revision</div>
                                <Input type="number" style={{}} value={this.state.newSessionSeatForRevision} onChange={(e)=>{
                                    this.setState({newSessionSeatForRevision:e.target.value})
                                }}/>
                                <div>Revision Cost (RM)</div>
                                <Input type="number" style={{}} value={this.state.newSessionRevisionCost} onChange={(e)=>{
                                    this.setState({newSessionRevisionCost:e.target.value})
                                }}/>
                            </div>
                            <Button color="primary" style={{marginTop:20,paddingLeft:50,paddingRight:50}} onClick={()=>{
                                this.createNewCourseSession()
                            }}>
                                Create
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:1000}} isOpen={this.state.addCourseSessionParticipantModal} toggle={()=>this.setState({addCourseSessionParticipantModal:false})}>
                    <div style={{padding:20}}>
                        <div>Add Course Session Participant</div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <Input type="text" placeholder="Phone number (username)" style={{marginTop:10}} value={this.state.addParticipantUsername} onChange={(e)=>{
                                this.setState({addParticipantUsername:e.target.value.replace(/\D/g,'')})
                            }} />
                            <div style={{display:"flex",flexDirection:"row",marginTop:10,alignItems:"center"}}>
                                <div>Revision:</div>
                                <FaCheckCircle style={this.state.csParticipantRevision?{marginLeft:10,fontSize:35,color:"#abd64f"}:{marginLeft:10,fontSize:35,color:"lightgrey"}} onClick={()=>{
                                    this.setState({csParticipantRevision:true})
                                }} />
                                <FaTimesCircle style={this.state.csParticipantRevision?{marginLeft:10,fontSize:35,color:"lightgrey"}:{marginLeft:10,fontSize:35,color:"salmon"}} onClick={()=>{
                                    this.setState({csParticipantRevision:false})
                                }}/>
                            </div>
                            <Button color="primary" style={{marginTop:20,paddingLeft:50,paddingRight:50}} onClick={()=>{
                                this.addCourseSessionParticipant()
                            }}>
                                Add
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:1000}} isOpen={this.state.editEnrollerModal} toggle={()=>this.setState({editEnrollerModal:false})}>
                    <div style={{padding:20}}>
                        <div>Edit Enroller</div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input type="text" placeholder="Phone number (username)" value={this.state.editEnroller} onChange={event => this.setState({editEnroller: event.target.value})} />
                            <Button color="primary" style={{marginLeft:10,paddingLeft:50,paddingRight:50}} onClick={()=>{this.editEnrollerClicked(this.state.editEnroller)}}>
                                Edit
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:1000}} isOpen={this.state.removeCourseSessionParticipantModal} toggle={()=>this.setState({removeCourseSessionParticipantModal:false})}>
                    <div style={{padding:20}}>
                        <div>Remove Course Session Participant</div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input type="text" placeholder="Phone number (username)" value={this.state.removeParticipantUsername} onChange={(e)=>{
                                this.setState({removeParticipantUsername:e.target.value.replace(/\D/g,'')})
                            }} />
                            <Button color="primary" style={{marginLeft:10,paddingLeft:50,paddingRight:50}} onClick={()=>{
                                this.removeCourseSessionParticipant()
                            }}>
                                Remove
                            </Button>
                        </div>
                    </div>
                </Modal>

                <NavigationBar currentPage="offlinecourse" />
                {
                window.innerWidth >= 600 ?
                    <div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",justifyContent:"space-between",alignItems:"center"}}>
                            {
                            this.sessionMode=="coming"?
                                <div style={{display:"flex",flexDirection:"row",padding:10}}>
                                    <Button color="primary" onClick={()=>{
                                        this.getCourseList()
                                    }}>
                                        Create New Session
                                    </Button>
                                    {
                                    this.state.selectedCourseSession?
                                        <Button color="info" style={{marginLeft:5}} onClick={()=>{
                                            this.getCourseSessionHeader()
                                        }}>
                                            Edit Booking Form Info
                                        </Button>
                                        :
                                        null
                                    }
                                    {
                                    this.state.selectedCourseSession?
                                        <Button color="warning" style={{marginLeft:5}} onClick={()=>{
                                            this.getCourseSessionInfo()
                                        }}>
                                            Edit Course Session
                                        </Button>
                                        :
                                        null
                                    }
                                    {
                                    this.state.selectedCourseSession?
                                        <Button color="danger" style={{marginLeft:5}} onClick={()=>{
                                            this.deleteCourseSession()
                                        }}>
                                            Delete Course Session
                                        </Button>
                                        :
                                        null
                                    }
                                    {
                                    this.state.selectedCourseSession?
                                        <Button color="secondary" style={{marginLeft:5}} onClick={()=>{
                                            this.archiveCourseSession()
                                        }}>
                                            Archive Course Session
                                        </Button>
                                        :
                                        null
                                    }
                                </div>
                                :
                                <div style={{flex:1}} />
                            }
                            {
                            this.sessionMode=="coming"?
                                <div style={{padding:10}}>
                                    <Button color="light" style={{marginLeft:5,border:"1px solid lightgrey"}} onClick={()=>{
                                        this.loadListOfArchivedCourseSession()
                                    }}>
                                        Previous Course Session
                                    </Button>
                                </div>
                                :
                            this.sessionMode=="previous"?
                                <div style={{padding:10}}>
                                    <Button color="light" style={{marginLeft:5,border:"1px solid lightgrey"}} onClick={()=>{
                                        this.comingCourseSession()
                                    }}>
                                        Coming Course Session
                                    </Button>
                                </div>
                                :
                                null
                            }
                        </div>

                        <div style={{padding:"0px 10px"}}>
                            {
                            this.sessionMode=="coming"?
                                <div>Coming Course Sessions</div>
                                :
                            this.sessionMode=="previous"?
                                <div>Previous Course Sessions</div>
                                :
                                null
                            }
                        </div>
                        <div style={{padding:10,rowGap:5,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"grid",gridTemplateRows:"auto auto",gridAutoFlow:"column",overflowX:"auto",gridAutoColumns:"max-content"}}>
                            {
                            this.state.courseSessionList.map((item)=>{
                                return(
                                    <Button color={this.state.selectedCourseSession!=null&&this.state.selectedCourseSession.courseTitle==item.courseTitle?"primary":"secondary"} style={{borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,marginLeft:10,width:"160px",fontSize:12,}} onClick={()=>{
                                        if(this.sessionMode=="coming"){
                                            this.loadCourseSessionDetail(item)
                                        }else if(this.sessionMode=="previous"){
                                            this.loadArchivedCourseSessionDetail(item)
                                        }
                                    }}>
                                        <div>{item.courseTitle}</div>
                                        <div>{item.eventDate}</div>
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <div style={{padding:"0px 20px 20px 20px"}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:10}}>
                                <div>Total: {this.state.courseSessionDetail.length}/{this.state.totalSeat}</div>
                                <div style={{marginLeft:10}}>Revision: {this.state.totalRevision}/{this.state.seatForRevision}</div>
                                <div style={{marginLeft:10}}>New: {this.state.totalNew}/{this.state.seatForNew}</div>
                                <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",
                                    paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                    borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                         this.copyList()
                                    }}>
                                    <FaCopy style={{width:20,height:20,padding:4}}/>
                                    <div style={{fontSize:12}}>Copy list</div>
                                </Button>
                                {
                                this.state.selectedCourseSession!=null?
                                    <div style={{marginLeft:20,display:"flex",flexDirection:"row",alignItems:"center"}}>
                                        <div style={{}}>Attendance:</div>
                                        {this.calculateAndGenerateAttendance()}
                                        {
                                        this.sessionMode=="coming"?
                                            <Button color="primary" style={{marginLeft:10,padding:"5px 10px",fontSize:15}} onClick={()=>{
                                                this.setState({ scanQRCodeModal: true,openQRCamera:false })
                                            }}>
                                                <AiOutlineQrcode style={{marginRight:5}} />
                                                Scan attendance
                                            </Button>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                }
                                <div style={{flex:1}} />
                                    {
                                    this.state.selectedCourseSession!=null&&this.sessionMode=="coming"?
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10}}>
                                            <Button color="light" style={{border:"none",backgroundColor:"#cce4ed"}} onClick={()=>{
                                                GLOBAL.copyTextToClipboard("https://www.enlliance.com/userprofile?course="+this.state.selectedCourseSession.courseTitle)
                                            }}>
                                                Attend Link
                                            </Button>
                                            <Button color="warning" onClick={()=>{
                                                GLOBAL.copyTextToClipboard("https://enlliance.com/bookclass?classid="+this.state.selectedCourseSession.id)
                                            }}>
                                                Booking Link
                                            </Button>
                                            <Button color="primary" style={{}} onClick={()=>{
                                                this.setState({addCourseSessionParticipantModal:true})
                                            }}>
                                                Add Participant
                                            </Button>
                                            <Button color="secondary" style={{}} onClick={()=>{
                                                this.setState({removeCourseSessionParticipantModal:true})
                                            }}>
                                                Remove Participant
                                            </Button>
                                        </div>
                                        :null
                                    }
                            </div>
                            
                            {
                            this.state.selectedCourseSession!=null?
                                <div ref={this.divRef} style={{marginTop:10,padding:10,borderRadius:10,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey"}}>
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <div style={{flex:0.08,textDecoration:"underline"}}>Phone</div>
                                        {/* hide it because the copy list function need to use it to become heading */}
                                        <div style={{ flex: 0.2, textDecoration: "underline", position: "absolute", left: "-9999px" }}>Membership Type</div> 
                                        <div style={{flex:0.15,textDecoration:"underline"}}>Name</div>
                                        {
                                        this.state.selectedCourseSession!=null&&this.state.selectedCourseSession.course.title=="总裁三力"?
                                            <div style={{flex:0.2,textDecoration:"underline"}}>
                                                Enroller
                                            </div>
                                            :
                                            null
                                        }
                                        <div style={{flex:0.1,textDecoration:"underline"}}>Signup Date</div>
                                        <div style={{flex:0.1,textDecoration:"underline",textAlign:"center"}}>PIC</div>
                                        <div style={{flex:0.07,textDecoration:"underline",textAlign:"center"}}>Revision</div>
                                        <div style={{flex:0.07,textDecoration:"underline",textAlign:"center"}}>Vaccinated</div>
                                        <div style={{flex:0.07,textDecoration:"underline",textAlign:"center"}}>Medical</div>
                                        <div style={{flex:0.07,textDecoration:"underline",textAlign:"center"}}>Vegetarian</div>
                                        <div style={{flex:0.1,textDecoration:"underline"}}>Ticket Number</div>
                                        <div style={{flex:0.25,display:"flex",flexDirection:"row",textDecoration:"underline"}}>Remark</div>
                                        <div style={{flex:0.05,textDecoration:"underline",textAlign:"center"}}>Attend</div>
                                    </div>
                                    {
                                    this.state.courseSessionDetail.map((item)=>{
                                        return(
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:2}}>
                                                <div style={{flex:0.08,fontSize:12}}>{item.user.username}</div>
                                                <div style={{flex:0.15,fontSize:12,display:"flex",flexDirection:"row",flexWrap:"wrap",alignItems:"center"}}>
                                                    {
                                                    item.user.membership_type=="SP"?
                                                        <div style={{marginRight:5,backgroundColor:"#fffcf2",color:"red",border:"1px solid red",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                            SP
                                                        </div>
                                                        :
                                                        <div style={{marginRight:5,backgroundColor:"lightgrey",border:"1px solid grey",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                            {item.user.membership_type}
                                                        </div>
                                                    }
                                                    {item.user.name}
                                                </div>
                                                {
                                                this.state.selectedCourseSession!=null&&this.state.selectedCourseSession.course.title=="总裁三力"?
                                                    <div style={{flex:0.2,fontSize:12}}>
                                                        {
                                                        this.sessionMode=="coming"?
                                                            <Button color="primary" style={{fontSize:13,padding:"2px 5px"}}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        userSelected:item.user.username,
                                                                        editEnrollerModal:true
                                                                    })
                                                                }}
                                                            >
                                                                {
                                                                item.enrollerUsername&&item.enrollerName?
                                                                    item.enrollerUsername+" "+item.enrollerName
                                                                    :
                                                                    "-"
                                                                }
                                                            </Button>
                                                            :
                                                            <div>
                                                                {
                                                                item.enrollerUsername&&item.enrollerName?
                                                                    item.enrollerUsername+" "+item.enrollerName
                                                                    :
                                                                    "-"
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }

                                                <div style={{flex:0.1,fontSize:12}}>{item.signupDate}</div>
                                                <div style={{flex:0.1,fontSize:12,textAlign:"center"}}>
                                                    {
                                                    item.pic!=null?
                                                        item.pic.username!=item.pic.name?
                                                            item.pic.username+" "+item.pic.name
                                                            :
                                                            item.pic.name
                                                        :
                                                        "-"
                                                    }
                                                </div>
                                                <div style={{flex:0.07,textAlign:"center"}}>
                                                    {
                                                    item.revision?
                                                        <FaCheckCircle style={{fontSize:25,color:"#abd64f"}} />
                                                        :
                                                        <FaTimesCircle style={{fontSize:25,color:"salmon"}} />
                                                    }
                                                </div>
                                                <div style={{flex:0.07,textAlign:"center"}}>
                                                    {
                                                    item.bookingForm&&item.bookingForm.vaccination?
                                                        <FaCheckCircle style={{fontSize:25,color:"#abd64f"}} />
                                                        :
                                                        <FaTimesCircle style={{fontSize:25,color:"salmon"}} />
                                                    }
                                                </div>
                                                <div style={{flex:0.07,textAlign:"center"}}>
                                                    {
                                                    item.bookingForm&&item.bookingForm.medicalCondition&&item.bookingForm.medicalCondition.trim().length>0?
                                                        <FaInfoCircle style={{fontSize:25,color:"red"}} onClick={()=>{
                                                            alert("Medical condition: "+item.bookingForm.medicalCondition)
                                                        }}/>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div style={{flex:0.07,textAlign:"center"}}>
                                                    {
                                                    item.bookingForm&&item.bookingForm.vegetarian?
                                                        <FaCheckCircle style={{fontSize:25,color:"#abd64f"}} />
                                                        :
                                                        <FaTimesCircle style={{fontSize:25,color:"salmon"}} />
                                                    }
                                                </div>
                                                <div style={{flex:0.1,display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"nowrap",fontSize:13}}>
                                                    <div style={{}}>{item.ticketNumber}</div>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <Button color="light" style={{border:"1px solid lightgrey",padding:5,margin:0}} onClick={()=>{
                                                            this.getTicketQRCodeLink(item)
                                                        }}>
                                                            <AiOutlineQrcode />
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div style={{flex:0.25,display:"flex",flexDirection:"row"}}>
                                                    <Input type="text" style={{lineHeight:1,padding:3,height:"auto"}} value={item.remark!=null?item.remark:""}
                                                        onChange={(e)=>{
                                                            if(this.sessionMode=="previous"){
                                                                return
                                                            }
                                                            var cloneCSD=this.state.courseSessionDetail
                                                            for(var cs of cloneCSD){
                                                                if(cs.ticketNumber==item.ticketNumber){
                                                                    cs.remark=e.target.value
                                                                }
                                                            }
                                                            this.setState({courseSessionDetail:cloneCSD})
                                                        }}
                                                        onKeyDown={(e)=>{
                                                            if(this.sessionMode=="previous"){
                                                                return
                                                            }
                                                            if(e.keyCode==13){
                                                                e.preventDefault()
                                                                this.saveRemark(item.ticketNumber,e.target.value)
                                                            }
                                                        }}
                                                        onBlur={(e)=>{
                                                            if(this.sessionMode=="previous"){
                                                                return
                                                            }
                                                            this.saveRemark(item.ticketNumber,e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div style={{flex:0.05,display:"flex",flexDirection:"column",alignItems:"center"}}>
                                                    {this.generateAttendanceButton(item)}
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    <div>
                        {
                        this.sessionMode=="coming"?
                            <div style={{display:"flex",flexDirection:"column",gap:5,padding:10}}>
                                <Button color="primary" style={{fontSize:13}} onClick={()=>{
                                    this.getCourseList()
                                }}>
                                    Create New Session
                                </Button>
                                {
                                this.state.selectedCourseSession?
                                    <Button color="info" style={{fontSize:13}} onClick={()=>{
                                        this.getCourseSessionHeader()
                                    }}>
                                        Edit Booking Form Info
                                    </Button>
                                    :
                                    null
                                }
                                {
                                this.state.selectedCourseSession?
                                    <Button color="warning" style={{fontSize:13}} onClick={()=>{
                                        this.getCourseSessionInfo()
                                    }}>
                                        Edit Course Session
                                    </Button>
                                    :
                                    null
                                }
                                {
                                this.state.selectedCourseSession?
                                    <Button color="danger" style={{fontSize:13}} onClick={()=>{
                                        this.deleteCourseSession()
                                    }}>
                                        Delete Course Session
                                    </Button>
                                    :
                                    null
                                }
                                {
                                this.state.selectedCourseSession?
                                    <Button color="secondary" style={{fontSize:13}} onClick={()=>{
                                        this.archiveCourseSession()
                                    }}>
                                        Archive Course Session
                                    </Button>
                                    :
                                    null
                                }

                                <div style={{flex:1}} />

                                {
                                this.sessionMode=="coming"?
                                    <Button color="light" style={{border:"1px solid lightgrey",fontSize:13}} onClick={()=>{
                                        this.loadListOfArchivedCourseSession()
                                    }}>
                                        Previous Course Session
                                    </Button>
                                    :
                                this.sessionMode=="previous"?
                                    <Button color="light" style={{border:"1px solid lightgrey",fontSize:13}} onClick={()=>{
                                        this.comingCourseSession()
                                    }}>
                                        Coming Course Session
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                            :
                            null
                        }
                        <div style={{padding:"0px 10px"}}>
                            {
                            this.sessionMode=="coming"?
                                <div>Coming Course Sessions</div>
                                :
                            this.sessionMode=="previous"?
                                <div>Previous Course Sessions</div>
                                :
                                null
                            }
                        </div>
                        <div style={{padding:10,rowGap:5,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"grid",gridTemplateRows:"auto auto",gridAutoFlow:"column",overflowX:"auto",gridAutoColumns:"max-content"}}>
                            {
                            this.state.courseSessionList.map((item)=>{
                                return(
                                    <Button color={this.state.selectedCourseSession!=null&&this.state.selectedCourseSession.courseTitle==item.courseTitle?"primary":"secondary"} style={{borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,marginLeft:10,width:"160px",fontSize:12}} onClick={()=>{
                                        if(this.sessionMode=="coming"){
                                            this.loadCourseSessionDetail(item)
                                        }else if(this.sessionMode=="previous"){
                                            this.loadArchivedCourseSessionDetail(item)
                                        }
                                        this.setState({
                                            selectedCourseSession:item,
                                        })
                                    }}>
                                        <div>{item.courseTitle}</div>
                                        <div>{item.eventDate}</div>
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <div style={{padding:"0px 0px 20px 0px"}}>
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:10}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",fontSize:13}}>
                                    <div>Total: {this.state.courseSessionDetail.length}/{this.state.totalSeat}</div>
                                    <div style={{marginLeft:10}}>Revision: {this.state.totalRevision}/{this.state.seatForRevision}</div>
                                    <div style={{marginLeft:10}}>New: {this.state.totalNew}/{this.state.seatForNew}</div>
                                    <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                        borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                            this.copyList()
                                        }}>
                                        <FaCopy style={{width:20,height:20,padding:4}}/>
                                        <div style={{fontSize:12}}>Copy list</div>
                                    </Button>
                                </div>
                                {
                                this.state.selectedCourseSession!=null?
                                    <div style={{gap:15,display:"flex",flexDirection:"column",alignItems:"center",fontSize:13,padding:"0px 10px"}}>
                                        <div style={{marginTop:8}}>Attendance:</div>
                                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:10}}>
                                            {this.calculateAndGenerateAttendance()}
                                        </div>
                                        {
                                        this.sessionMode=="coming"?
                                            <Button color="primary" style={{marginLeft:10,padding:"5px 10px",fontSize:15,alignItems:"center",marginTop:13,marginBottom:13}} onClick={()=>{
                                                this.setState({ scanQRCodeModal: true,openQRCamera:false })
                                            }}>
                                                <AiOutlineQrcode style={{marginRight:5}} />
                                                Scan attendance
                                            </Button>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                }
                                <div style={{flex:1}} />
                                {
                                this.state.selectedCourseSession!=null&&this.sessionMode=="coming"?
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10,flexWrap:"wrap",padding:"0px 10px"}}>
                                        <Button color="light" style={{border:"none",backgroundColor:"#cce4ed",fontSize:13}} onClick={()=>{
                                            GLOBAL.copyTextToClipboard("https://www.enlliance.com/userprofile?course="+this.state.selectedCourseSession.courseTitle)
                                        }}>
                                            Attend Link
                                        </Button>
                                        <Button color="warning" style={{fontSize:13}} onClick={()=>{
                                            GLOBAL.copyTextToClipboard("https://enlliance.com/bookclass?classid="+this.state.selectedCourseSession.id)
                                        }}>
                                            Booking Link
                                        </Button>
                                        <Button color="primary" style={{fontSize:13}} onClick={()=>{
                                            this.setState({addCourseSessionParticipantModal:true})
                                        }}>
                                            Add Participant
                                        </Button>
                                        <Button color="secondary" style={{fontSize:13}} onClick={()=>{
                                            this.setState({removeCourseSessionParticipantModal:true})
                                        }}>
                                            Remove Participant
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div style={{marginTop:10,padding:10,borderRadius:10,borderWidth:1,borderColor:"lightgrey"}}>
                                {
                                this.state.courseSessionDetail.map((item)=>{
                                    return(
                                        <div style={{width:"100%",display:"flex",flexDirection:"column",borderRadius:"15px",border:"1px solid",borderColor:"lightgrey",marginBottom:10}}>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                <div style={{display:"flex",flexDirection:"column",padding:10,width:"100%"}}>
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                        <div style={{display:"flex",flexDirection:"column"}}>
                                                            {item.user.username}
                                                            <div style={{flex:0.2,display:"flex",flexDirection:"row",flexWrap:"wrap",alignItems:"center"}}>
                                                                {
                                                                item.user.membership_type=="SP"?
                                                                    <div style={{marginRight:5,backgroundColor:"#fffcf2",color:"red",border:"1px solid red",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                                        SP
                                                                    </div>
                                                                    :
                                                                    <div style={{marginRight:5,backgroundColor:"lightgrey",border:"1px solid grey",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                                        {item.user.membership_type}
                                                                    </div>
                                                                }
                                                                {item.user.name}
                                                            </div>
                                                            <div style={{flex:0.1,fontSize:12}}>
                                                                Signup Date:{item.signupDate}
                                                            </div>
                                                            {
                                                            this.state.selectedCourseSession!=null&&this.state.selectedCourseSession.course.title=="总裁三力"?
                                                                <div style={{flex:0.1,fontSize:12,marginTop:3,marginBottom:3,display:"flex",flexDirection:"row"}}>
                                                                    Enroller:&nbsp;
                                                                    {
                                                                    this.sessionMode=="coming"?
                                                                        <Button color="primary" style={{fontSize:13,padding:"2px 5px"}}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    userSelected:item.user.username,
                                                                                    editEnrollerModal:true
                                                                                })
                                                                            }}
                                                                        >
                                                                            {
                                                                            item.enrollerUsername&&item.enrollerName?
                                                                                item.enrollerUsername+" "+item.enrollerName
                                                                                :
                                                                                "-"
                                                                            }
                                                                        </Button>
                                                                        :
                                                                        <div>
                                                                            {
                                                                            item.enrollerUsername&&item.enrollerName?
                                                                                item.enrollerUsername+" "+item.enrollerName
                                                                                :
                                                                                "-"
                                                                            }
                                                                        </div>
                                                                    }                                                                    
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div style={{flex:0.05,display:"flex",flexDirection:"column"}}>
                                                            Attend:{this.generateAttendanceButton(item)}
                                                        </div>
                                                    </div>
                                                    <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:5,marginBottom:5,width:"100%",color:"lightgrey"}}/>
                                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                <div>
                                                                    Revision{
                                                                    item.revision?
                                                                        <FaCheckCircle style={{fontSize:25,color:"#abd64f",marginLeft:7}} />
                                                                        :
                                                                        <FaTimesCircle style={{fontSize:25,color:"salmon",marginLeft:7}} />
                                                                    }
                                                                </div>
                                                                <div>
                                                                    Vaccinated{
                                                                    item.bookingForm&&item.bookingForm.vaccination?
                                                                        <FaCheckCircle style={{fontSize:25,color:"#abd64f",marginLeft:7}} />
                                                                        :
                                                                        <FaTimesCircle style={{fontSize:25,color:"salmon",marginLeft:7}} />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                <div>
                                                                    Medical{
                                                                    item.bookingForm&&item.bookingForm.medicalCondition&&item.bookingForm.medicalCondition.trim().length>0?
                                                                        <FaInfoCircle style={{fontSize:25,color:"red",marginLeft:7}} onClick={()=>{
                                                                            alert("Medical condition: "+item.bookingForm.medicalCondition)
                                                                        }}/>
                                                                        :
                                                                        <div></div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    Vegetarian{
                                                                        item.bookingForm&&item.bookingForm.vegetarian?
                                                                            <FaCheckCircle style={{fontSize:25,color:"#abd64f",marginLeft:7}} />
                                                                            :
                                                                            <FaTimesCircle style={{fontSize:25,color:"salmon",marginLeft:7}} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <div style={{flex:0.1,display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"nowrap",fontSize:15}}>
                                                        Ticket Number:
                                                        <div style={{}}>{item.ticketNumber}</div>
                                                        <div style={{display:"flex",flexDirection:"column"}}>
                                                            <Button color="light" style={{border:"1px solid lightgrey",padding:2}} onClick={()=>{
                                                                this.getTicketQRCodeLink(item)
                                                            }}>
                                                                <AiOutlineQrcode />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div style={{flex:0.25,display:"flex",flexDirection:"column"}}>
                                                        Remark:
                                                        <div>
                                                            <Input type="textarea" rows={3} style={{lineHeight:1.2,padding:3,height:"auto",resize:"none"}} value={item.remark!=null?item.remark:""}
                                                                onChange={(e)=>{
                                                                    if(this.sessionMode=="previous"){
                                                                        return
                                                                    }
                                                                    var cloneCSD=this.state.courseSessionDetail
                                                                    for(var cs of cloneCSD){
                                                                        if(cs.ticketNumber==item.ticketNumber){
                                                                            cs.remark=e.target.value
                                                                        }
                                                                    }
                                                                    this.setState({courseSessionDetail:cloneCSD})
                                                                }}
                                                                onKeyDown={(e)=>{
                                                                    if(this.sessionMode=="previous"){
                                                                        return
                                                                    }
                                                                    if(e.keyCode==13){
                                                                        e.preventDefault()
                                                                        this.saveRemark(item.ticketNumber,e.target.value)
                                                                    }
                                                                }}
                                                                onBlur={(e)=>{
                                                                    if(this.sessionMode=="previous"){
                                                                        return
                                                                    }
                                                                    this.saveRemark(item.ticketNumber,e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

let styles = {

}
