import React from 'react'
import Radium from 'radium'
import * as XLSX from 'xlsx'
import {Input,Button,Alert,Row,Col,Table,Modal,Spinner} from 'reactstrap'
import axios from 'axios'
import qs from 'qs'
import history from '../history'
import {reactLocalStorage} from 'reactjs-localstorage'
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen'
import * as GLOBAL from './global.js'

import ReactPaginate from 'react-paginate'
import {ImCross} from 'react-icons/im'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {AiFillClockCircle,AiOutlineSearch,AiOutlineRight} from 'react-icons/ai'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw,ContentState } from 'draft-js';

export default class Ticket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            viewMode:"dashboard",

            currentUser:"",
            findField:"",
            ticketList:[],
            itemPerPage:15,
            page:0,
            pageCount:0,
            ticketCount:0,
            totalCount:0,

            statusList:[],
            selectedStatusList:[],
            
            filterModal:false,
            filterMode:null,
            filterTicketNumber:"",
            filterClientPhone:"",
            filterClientName:"",
            filterClientEmail:"",
            filterState:[],
            filterChannel:[],
            filterInterest:"",
            filterLastUpdateFrom:"",
            filterLastUpdateTo:"",
            filterCreateDateFrom:"",
            filterCreateDateTo:"",
            filterReferrerPhone:"",
            filterPICPhone:"",
            filterMember:"",

            currentTicket:{
                state: []
            },
            currentLogList:[],
            updateStatusModal:false,
            selectedUpdateStatus:null,
            updateStatusLog:"",

            ticketLogModal:false,
            ticketLogText:"",
            ticketLogSearch:"",

            statisticsList:[],

            ageReportModal:false,
            lastUpdateAgeReport:null,

            pageTicketLogHistory:0,
            ticketLogHistoryPages:0,

            editorState:EditorState.createEmpty(),
            ticketLogMessage:[],
            editField:false,
            toChangeCurrentTicket:{
                state: []
            },
            selectMemberModal:false,
            selectMemberMode:"Lead Pool",
            memberList:[],
            leadPoolList:[],
            searchField:"",
            memberPageItem:10,
            theReferrer:[],
            thePIC:[],
            editingField:"",
            theEmployee:[],
            modified:{
                phone:false,
                name:false,
                email:false,
                state:false,
                channel:false,
                interest:false,
            },

            ticketHistoryModal:false,
            currentHistoryList:[],
            historyPageItem:5,
            historyPage:0,

            agentListModal:false,
            agentAndLeaderList:[],
            selectedAgentPhone:"",
            selectedAgentName:"",
            agentPageItem:10,
            agentPage:0,

            selectStateModal:false,
            editStateModal:false,
            clientStateList:[],
            toChangeState:[],
        }
        this.extensionList=[]
    }

    componentWillMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        //get list of status
        const payload = {

        }
        axios
            .post(`sales/getticketstatuslist`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                this.setState({
                    statusList:response.data,
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get status list error\n"+error)
            })

        //get list of client state
        axios
            .post(`sales/getclientstatelist`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                this.setState({
                    clientStateList:response.data,
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get client state list error\n"+error)
            })

        this.parseSearchPath()
    }

    copyTextToClipboard=(text)=>{
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        console.log("My Text" + text);
        navigator.clipboard.writeText(text).then(function() {
            alert('Copy to clipboard successful');
        }, function(err) {
            alert('Could not copy text: ', err);
        });
    }
    fallbackCopyTextToClipboard=(text)=>{
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
    copyListToExcel=()=>{
        this.setState({loading:true});
        const findField = this.state.findField
            .replace(/\s{2,}/g, ' ')
            .trim();
        const payload={
            findField:findField,
            page:0,
            itemPerPage:this.state.ticketCount
        }

        if (this.state.ticketCount > 500) {
            alert("Cannot Export Excel because it is too large.");
            this.setState({ loading: false });
            return;
        }

        axios
            .post(`sales/searchfollowupticket`, qs.stringify(payload), {timeout:100000})
            .then(async response=>{
                // parse the date
                for(let item of response.data.ticketList){
                    let parsedDate=item.createDate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.createDate=parsedDate
                }
                //parse the date
                for(let item of response.data.ticketList){
                    let parsedDate=item.lastUpdate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.lastUpdate=parsedDate
                }
                this.setState({
                    ticketList:response.data.ticketList,
                });
                let ticket_phone;
                let ticket_name;
                let ticket_email;
                let ticket_member;
                // copy the data in excel format to paste
                let theText="TixNum\tPhone\tName\tEmail\tMember\tChannel\tInterest\tReferrer\tPIC\tStatus\tLastUpdate\tCreateDate\n"
                for(let ticket of this.state.ticketList){
                    if(ticket.lead!=null){
                        ticket_phone = ticket.lead.phone;
                        ticket_name = ticket.lead.name;
                        ticket_email = ticket.lead.email;
                        ticket_member = "Lead";
                    }
                    else if(ticket.target!=null){
                        ticket_phone = ticket.target.username;
                        ticket_name = ticket.target.name;
                        ticket_email = ticket.target.email;
                        ticket_member = "Member";
                    }
                    else{
                        ticket_member = "Ghost";
                    }
                    theText+= (ticket.id || "")+"\t"
                    theText+= "'" + (ticket_phone || "") +"\t"
                    theText+= (ticket_name || "")+"\t"
                    theText+= (ticket_email || "")+"\t"
                    theText+= (ticket_member || "")+"\t"
                    theText+= (ticket.channel || "")+"\t"
                    theText+= (ticket.interest.replace(/\s/g, "") || "")+"\t"
                    /*/(?<=[a-zA-Z])\s+(?=[\u4e00-\u9fa5])/g*/
                    theText+= (ticket.referrerString || "")+"\t"
                    theText+= (ticket.picString || "")+"\t"
                    theText+= (ticket.status || "")+"\t"
                    theText+= (ticket.lastUpdate || "")+"\t"
                    theText+= (ticket.createDate || "")+"\t"

                    theText+="\n"
            }
            console.log(theText);
            const data = theText.split('\n').map(row => row.split('\t'));

            // Filter out empty rows
            const nonEmptyData = data.filter(row => row.length > 1);

            // Create a worksheet from the data
            const ws = XLSX.utils.aoa_to_sheet(nonEmptyData);

            // Create a new workbook and append the worksheet
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            // Save the workbook to a file
            XLSX.writeFile(wb, 'ticket.xlsx');

            this.setState({loading:false});
        })
        .catch(error=>{
            if(error.response?.status==444){
                alert("Cannot search empty or No such field")
                this.setState({loading:false})
            }else if(error.response?.status==445){
                this.setState({
                    viewMode:"search",
                    allTicket:[],
                    loading:false,
                })
            }else{
                console.log(error);
            }
        })
    }
    copyListToClipboard=()=>{
        this.setState({loading:true});

        const findField = this.state.findField
            .replace(/\s{2,}/g, ' ')// replace two or more space to single space
            .trim();
        const payload={
            findField:findField,
            page:0,
            itemPerPage:this.state.ticketCount
        }

        axios
            .post(`sales/searchfollowupticket`, qs.stringify(payload), {timeout:100000})
            .then(async response=>{
                // parse the date
                for(let item of response.data.ticketList){
                    let parsedDate=item.createDate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.createDate=parsedDate
                }
                //parse the date
                for(let item of response.data.ticketList){
                    let parsedDate=item.lastUpdate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.lastUpdate=parsedDate
                }

                this.setState({
                    loading:false,
                    ticketList:response.data.ticketList,
                },()=>{
                    let ticket_phone;
                    let ticket_name;
                    let ticket_email;
                    let ticket_member;
                    // copy the data in excel format to paste
                    var theText="TixNum\tPhone\tName\tEmail\tMember\tChannel\tInterest\tReferrer\tPIC\tStatus\tLastUpdate\tCreateDate\n"
                    for(var ticket of this.state.ticketList){
                        if(ticket.lead!=null){
                            ticket_phone = ticket.lead.phone;
                            ticket_name = ticket.lead.name;
                            ticket_email = ticket.lead.email;
                            ticket_member = "Lead";
                        }
                        else if(ticket.target!=null){
                            ticket_phone = ticket.target.username;
                            ticket_name = ticket.target.name;
                            ticket_email = ticket.target.email;
                            ticket_member = "Member";
                        }
                        else{
                            ticket_member = "Ghost";
                        }
                        theText+=ticket.id+"\t"
                        theText+="'" + ticket_phone +"\t"
                        theText+=ticket_name+"\t"
                        theText+=ticket_email+"\t"
                        theText+=ticket_member+"\t"
                        theText+=ticket.channel+"\t"
                        theText+=ticket.interest.replace(/\s/g, "")+"\t"
                        /*/(?<=[a-zA-Z])\s+(?=[\u4e00-\u9fa5])/g*/
                        theText+=ticket.referrerString+"\t"
                        theText+=ticket.picString+"\t"
                        theText+=ticket.status+"\t"
                        theText+=ticket.lastUpdate+"\t"
                        theText+=ticket.createDate+"\t"

                        theText+="\n"
                    }
                    this.copyTextToClipboard(theText);

                })
                console.log(response.data);
            })
            .catch(error=>{
                if(error.response?.status==444){
                    alert("Cannot search empty or No such field")
                    this.setState({loading:false})
                }else if(error.response?.status==445){
                    this.setState({
                        viewMode:"search",
                        allTicket:[],
                        loading:false,
                    })
                }else{
                    console.log(error);
                }
            })
    }
    
    parseSearchPath=()=>{
        let runContactSearch=null
        let idSearch=null
        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        for(var input of inputSplit){
            let param=input.split("=")[0]
            let value=input.split("=")[1]
            if(param=="search"){
                runContactSearch=decodeURI(value)
            }else if(param=="id"){
                idSearch=value
            }
        }

        if(runContactSearch!=null){
            this.setState({findField:runContactSearch},()=>this.searchTicket())
        }else if(idSearch!=null){
            this.viewTicketDetail(idSearch)
            this.getTicketLogList(idSearch)
        }else{
            this.searchTicket()
        }
    }

    searchTicket=()=>{
        if(this.state.viewMode=="statistics"){
            this.getTicketStatistics()
            return
        }

        this.setState({loading:true})
        const payload = {
            findField:this.state.findField,
            itemPerPage:this.state.itemPerPage,
            page:this.state.page,
        }
        axios
            .post(`sales/searchfollowupticket`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //parse date
                for(let f=0;f<response.data.ticketList.length;f++){
                    let rawDateSplit=response.data.ticketList[f].lastUpdate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.ticketList[f].lastUpdate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.ticketList.length;f++){
                    let rawDateSplit=response.data.ticketList[f].createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.ticketList[f].createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    viewMode:"dashboard",
                    ticketList:response.data.ticketList,
                    pageCount:response.data.pageCount,
                    page:response.data.page,
                    ticketCount:response.data.ticketCount,
                    totalCount:response.data.totalCount,

                    modified:{
                        phone:false,
                        name:false,
                        email:false,
                        state:false,
                        channel:false,
                        interest:false,
                    },
                    theReferrer:[],
                    thePIC:[],
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Invalid filter parameter")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Search error\n"+error)
                this.setState({loading:false})
            })
    }


    removeParam=(find,param)=>{
        let findSplit=find.split(";")
        let indexToRemove=-1
        for(let [index,f] of findSplit.entries()){
            let fSplit=f.split(":")
            let thePar=fSplit[0]
            if(thePar==param){
                indexToRemove=index
            }
        }
        if(indexToRemove!=-1){
            findSplit.splice(indexToRemove,1)
            return findSplit.join(";")
        }else{
            return find
        }
    }

    cleanFilter=(findFilter)=>{
        let find=this.state.findField
        //remove all existing same param
        if(this.state.filterMode=="Last Update"||this.state.filterMode=="Create Date"){
            find=this.removeParam(find,findFilter+" From")
            find=this.removeParam(find,findFilter+" To")
        }else{
            find=this.removeParam(find,findFilter)
        }
        if(find.length>0){
            find+=";"
        }
        if(this.state.filterMode=="Ticket Number"){
            find+=findFilter+":"+this.state.filterTicketNumber
        }else if(this.state.filterMode=="Client Phone"){
            find+=findFilter+":"+this.state.filterClientPhone
        }else if(this.state.filterMode=="Client Name"){
            find+=findFilter+":"+this.state.filterClientName
        }else if(this.state.filterMode=="Client Email"){
            find+=findFilter+":"+this.state.filterClientEmail
        }else if(this.state.filterMode=="State"){
            const stateName = this.state.filterState.map(state => state.name || state).join(",")
            find += findFilter + ":" + stateName
        }else if(this.state.filterMode=="Channel"){
            find+=findFilter+":"+this.state.filterChannel.join(",")
        }else if(this.state.filterMode=="Interest"){
            find+=findFilter+":"+this.state.filterInterest
        }else if(this.state.filterMode=="Last Update"){
            let gotFrom=false
            if(this.state.filterLastUpdateFrom!=""){
                find+=findFilter+" From:"+this.state.filterLastUpdateFrom
                gotFrom=true
            }
            if(this.state.filterLastUpdateTo!=""){
                if(gotFrom==true){
                    find+=";"
                }
                find+=findFilter+" To:"+this.state.filterLastUpdateTo
            }
        }else if(this.state.filterMode=="Create Date"){
            let gotFrom=false
            if(this.state.filterCreateDateFrom!=""){
                find+=findFilter+" From:"+this.state.filterCreateDateFrom
                gotFrom=true
            }
            if(this.state.filterCreateDateTo!=""){
                if(gotFrom==true){
                    find+=";"
                }
                find+=findFilter+" To:"+this.state.filterCreateDateTo
            }
        }else if(this.state.filterMode=="Referrer Phone"){
            find+=findFilter+":"+this.state.filterReferrerPhone
        }else if(this.state.filterMode=="PIC Phone"){
            find+=findFilter+":"+this.state.filterPICPhone
        }else if(this.state.filterMode=="Status"){
            find+=findFilter+":"+this.state.selectedStatusList.join(",")
        }else if(this.state.filterMode=="Member"){
            find+=findFilter+":"+this.state.filterMember
        }

        return find
    }

    filterTicket=(findFilter)=>{
        let find=this.cleanFilter(findFilter)
        this.setState({
            findField:find,
            filterModal:false,
            filterMode:null,
        },()=>{
            this.searchTicket()
        })
    }

    viewTicketDetail=(ticketID)=>{
        this.setState({loading:true})
        const payload = {
            ticketID:ticketID,
        }
        axios
            .post(`sales/getfollowupticketdetail`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //parse date
                let rawDateSplit=response.data.lastUpdate.split("T")
                let dateSplit=rawDateSplit[0].split("-")
                let timeSplit=rawDateSplit[1].split(":")
                response.data.lastUpdate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                let rawDateSplit2=response.data.createDate.split("T")
                let dateSplit2=rawDateSplit2[0].split("-")
                let timeSplit2=rawDateSplit2[1].split(":")
                response.data.createDate=dateSplit2[2]+"."+dateSplit2[1]+"."+dateSplit2[0]+"  "+timeSplit2[0]+":"+timeSplit2[1]
                if(response.data.expiryDate!=null){
                    let rawDateSplit3=response.data.expiryDate.split("T")
                    let dateSplit3=rawDateSplit3[0].split("-")
                    let timeSplit3=rawDateSplit3[1].split(":")
                    response.data.expiryDate=dateSplit3[2]+"."+dateSplit3[1]+"."+dateSplit3[0]+"  "+timeSplit3[0]+":"+timeSplit3[1]
                }

                this.setState({
                    // loading:false,
                    currentTicket:response.data,
                    viewMode:"detail",
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get follow up ticket detail error\n"+error)
                this.setState({loading:false})
            })
    }

    // To toggle client state selection
    toggleStateSelection = (state) => {
        const {toChangeCurrentTicket} = this.state
        const index = toChangeCurrentTicket.state.findIndex(s => s.id === state.id)
        const newState = [...toChangeCurrentTicket.state]
        var modifiedField = {...this.state.modified}

        if (index !== -1) {
            newState.splice(index, 1); // Remove state if already selected
        } else {
            newState.push(state); // Add state if not selected
        }
        newState.sort((a, b) => a.id - b.id)

        const arraysEqual = (a, b) => {
            if (a.length !== b.length) return false
            const sortedA = a.map(item => item.id).sort()
            const sortedB = b.map(item => item.id).sort()
            for (let i = 0; i < sortedA.length; i++) {
                if (sortedA[i] !== sortedB[i]) return false
            }
            return true
        }

        if (!arraysEqual(newState, this.state.currentTicket.state)) {
            modifiedField.state = true
        } else {
            modifiedField.state = false
        }

        this.setState({
            toChangeCurrentTicket: {
                ...toChangeCurrentTicket,
                state: newState,
            },
            modified:modifiedField,
        })
    }

    toggleToChangeState = (state) => {
        const {toChangeState} = this.state
        const index = toChangeState.findIndex(s => s.id === state.id)
        const newState = [...toChangeState]

        if (index !== -1) {
            newState.splice(index, 1); // Remove state if already selected
        } else {
            newState.push(state); // Add state if not selected
        }

        this.setState({toChangeState: newState})
    }
    
    getTicketLogList=(ticketID,page=0)=>{
        this.setState({loading:true})
        if(!ticketID){
            var ticketID=this.state.currentTicket.id
        }
        const payload = {
            ticketID:ticketID,
            page:page,
            itemPerPage:10,
            searchTerm:this.state.ticketLogSearch,
            
        }
        axios
            .post(`sales/getticketloglist`, qs.stringify(payload),{timeout:40000})
            .then(async response => {    
                var username = []                     
                //parse date
                for(let f=0;f<response.data.ticketLogHistory.length;f++){
                    let rawDateSplit=response.data.ticketLogHistory[f].createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.ticketLogHistory[f].createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                for(let i=0;i<response.data.ticketLogHistory.length;i++){
                    if(response.data.ticketLogHistory[i].log.startsWith("{")){
                        let content= convertFromRaw(JSON.parse(response.data.ticketLogHistory[i].log))
                        response.data.ticketLogHistory[i].log=EditorState.createWithContent(content)
                    }
                }
                
                
                for(let i=0;i<response.data.ticketLogHistory.length;i++){
                    if(response.data.ticketLogHistory[i].loggerString != null){
                        let content = response.data.ticketLogHistory[i].loggerString.split(" ")
                        username[i] = content
                    }else{
                        username[i] = " "
                    }                    
                }                
                                                
                this.setState({
                    loading:false,
                    currentLogList:response.data.ticketLogHistory,
                    viewMode:"detail",
                    theLogsCount:response.data.theLogsCount,
                    totalTicketLogHistoryPages:response.data.totalPages,
                    currentTicketLogPage:page,
                    currentUser:response.data.currentUser,  
                    username:username                
                })

                console.log(username)
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get ticket log list error\n"+error)
                this.setState({loading:false})
            })
    }

    deleteTicketLog=(ticketID,ticketLogID)=>{
        this.setState({loading:true})
        const payload = {
            ticketLogID:ticketLogID,
            ticketID:ticketID
        }
        axios
            .post(`/sales/deleteticketlog`, qs.stringify(payload))
            .then(async response => {
              
                this.setState({                                  
                    loading:false,
                })
                this.getTicketLogList(this.state.currentTicket.id)               
            })
            .catch(error =>{
                alert("Delete ticket log history error\n"+error)
                this.setState({loading:false})
            })
    }

    updateStatus=()=>{
        this.setState({loading:true})
        const payload = {
            ticketID:this.state.currentTicket.id,
            status:this.state.selectedUpdateStatus,
            log:this.state.updateStatusLog,
        }
        axios
            .post(`sales/updateticketstatus`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //parse date
                let rawDateSplit=response.data.lastUpdate.split("T")
                let dateSplit=rawDateSplit[0].split("-")
                let timeSplit=rawDateSplit[1].split(":")
                response.data.lastUpdate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                let rawDateSplit2=response.data.createDate.split("T")
                let dateSplit2=rawDateSplit2[0].split("-")
                let timeSplit2=rawDateSplit2[1].split(":")
                response.data.createDate=dateSplit2[2]+"."+dateSplit2[1]+"."+dateSplit2[0]+"  "+timeSplit2[0]+":"+timeSplit2[1]

                this.setState({
                    currentTicket:response.data,
                    viewMode:"detail",
                    updateStatusModal:false,
                },()=>{
                    this.getTicketLogList(this.state.currentTicket.id)
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("You do not have permission to update this ticket")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Update ticket status error\n"+error)
                this.setState({loading:false})
            })
    }

    postTicketLog=()=>{
        //validate
        if(this.state.ticketLogText.trim().length==0){
            alert("Please enter ticket log properly")
            return
        }

        this.setState({loading:true,ticketLogHistory:[]})
        const payload = {
            ticketID:this.state.currentTicket.id,
            message:this.state.ticketLogText,
            page:this.state.page,
            itemPerPage:10,
        }
        axios
            .post(`sales/postticketlog`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    ticketLogModal:false,
                    ticketLogText:"",
                },()=>{
                    this.getTicketLogList(this.state.currentTicket.id)
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("You do not have permission to post ticket log to this ticket")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Post ticket log error\n"+error)
                this.setState({loading:false})
            })
    }

    addSelectedState=(state)=>{
        let foundSame=-1
        let listClone=this.state.filterState
        for(let [index,s] of listClone.entries()){
            if(s==state){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(state)
        }
        this.setState({filterState:listClone})
    }

    addSelectedChannel=(channel)=>{
        let foundSame=-1
        let listClone=this.state.filterChannel
        for(let [index,c] of listClone.entries()){
            if(c==channel){
                foundSame=index
                break
            }
        }
        if(foundSame!=-1){
            //remove
            listClone.splice(foundSame,1)
        }else{
            listClone.push(channel)
        }
        this.setState({filterChannel:listClone})
    }

    getTicketStatistics=()=>{
        this.setState({loading:true})
        const payload = {
            findField:this.state.findField,
        }
        axios
            .post(`sales/getticketstatistics`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //do some math
                for(let category of response.data){
                    let total=0
                    for(let status of category.statusList){
                        total+=status.count
                    }
                    category.total=total
                }

                this.setState({
                    loading:false,
                    statisticsList:response.data,
                    viewMode:"statistics",
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get ticket statistics error\n"+error)
                this.setState({loading:false})
            })
    }

    generateSearchArea=()=>{
        return(
            <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10,}}>
                <Input placeholder="Use filter by below to create query"
                    value={this.state.findField} style={{width:"100%"}}
                    onKeyDown={(e)=>{
                        if(e.keyCode==13){
                            e.preventDefault()
                            this.searchTicket()
                        }
                    }}
                    onChange={(e)=>{
                        this.setState({findField:e.target.value})
                }} />
                <Button color="danger" style={{whiteSpace:"nowrap"}} onClick={()=>{
                    this.setState({findField:""})
                }}>
                    Clear Query
                </Button>
                <Button color="primary" style={{paddingLeft:30,paddingRight:30}} onClick={()=>{
                    this.searchTicket()
                }}>
                    Search
                </Button>
            </div>
        )
    }

    generateOperationArea=()=>{
        return(
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10}}>
                    {
                    this.state.viewMode=="statistics"?
                        <div>
                            Total {this.getTotalCount()} results found.
                        </div>
                        :
                        <div>
                            Total {this.state.ticketCount} results found.
                        </div>
                    }

                    <Button color="primary" style={{padding:"2px 10px",fontSize:12}} onClick={()=>{
                        history.push("/leadpool")
                        // window.open("/leadpool","_blank")
                    }}>
                        Lead Pool
                    </Button>

                    {
                    this.state.viewMode=="dashboard"&&
                        <Button color="success" style={{padding:"2px 10px",fontSize:12}} onClick={()=>{
                            this.getTicketStatistics()
                        }}>
                            Ticket Statistics
                        </Button>
                    }

                    {
                    this.state.viewMode=="dashboard"&&
                        <Button color="light" style={{padding:"2px 10px",border:"1px solid lightgrey",fontSize:12}} onClick={()=>{
                            GLOBAL.copyTable()
                        }}>
                            Copy List
                        </Button>
                    }
                    {
                    this.state.viewMode=="dashboard"&&
                        <Button color="light" style={{padding:"2px 10px",border:"1px solid lightgrey",fontSize:12}} onClick={()=>{
                            this.copyListToExcel()
                        }}>
                            Export Excel
                        </Button>
                    }
                </div>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10}}>

                </div>
            </div>
        )
    }
    generateFilterArea=()=>{
        return(
            <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",padding:"0px 0px 5px 0px",gap:10}}>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"wrap"}}>
                    <div>Filter by:</div>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Ticket Number",filterTicketNumber:""})
                    }}>
                        Ticket Number
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Client Phone",filterClientPhone:""})
                    }}>
                        Client Phone
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Client Name",filterClientName:""})
                    }}>
                        Client Name
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Client Email",filterClientEmail:""})
                    }}>
                        Client Email
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"State",filterState:[]})
                    }}>
                        State
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Channel",filterChannel:[]})
                    }}>
                        Channel
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Interest",filterInterest:""})
                    }}>
                        Interest
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Last Update",filterLastUpdateFrom:"",filterLastUpdateTo:"",})
                    }}>
                        Last Update
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Create Date",filterCreateDateFrom:"",filterCreateDateTo:""})
                    }}>
                        Create Date
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Referrer Phone",filterReferrerPhone:""})
                    }}>
                        Referrer Phone
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"PIC Phone",filterPICPhone:""})
                    }}>
                        PIC Phone
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Status",selectedStatusList:[]})
                    }}>
                        Status
                    </Button>
                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                        this.setState({filterModal:true,filterMode:"Member",filterMember:""})
                    }}>
                        Member
                    </Button>
                </div>
                <div style={{display:"flex",flexDirection:"row",gap:5}}>
                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"maroon",color:"white"}}
                        onClick={()=>{
                            this.setState({findField:"Status:还没联系,等待回复,安排预约,已经回复,有兴趣,购买课程"},()=>{
                                this.searchTicket()
                            })
                    }}>
                        Hot
                    </Button>
                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"yellow",color:"black"}}
                        onClick={()=>{
                            this.setState({findField:"Status:安排汇款,预付定金,分期付款,全额付款"},()=>{
                                this.searchTicket()
                            })
                    }}>
                        Payment
                    </Button>
                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"steelblue",color:"white"}}
                        onClick={()=>{
                            this.setState({findField:"Status:不精准客户,无法联系,没兴趣"},()=>{
                                this.searchTicket()
                            })
                    }}>
                        Cold
                    </Button>
                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"grey",color:"white"}}
                        onClick={()=>{
                            this.setState({findField:"Status:完成报名,他人完成,Task Terminated"},()=>{
                                this.searchTicket()
                            })
                    }}>
                        Closed
                    </Button>
                </div>
            </div>
        )
    }

    getTotalCount=()=>{
        let total=0
        for(let category of this.state.statisticsList){
            for(let stat of category.statusList){
                total+=stat.count
            }
        }
        return total
    }

    getTicketAgeReport=(statusArray)=>{
        //formulate the search params
        let find=this.state.findField
        find=this.removeParam(find,"Status")
        if(find.length>0){
            find+=";"
        }
        find+="Status:"+statusArray.join(",")

        //get the age report
        this.setState({loading:true})
        const payload = {
            findField:find,
        }
        axios
            .post(`sales/getticketagereport`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //do some categorization by date
                let lastUpdateAgeReport={   //less than days
                    1:[],
                    3:[],
                    7:[],
                    14:[],
                    21:[],
                    30:[],
                    60:[],
                }
                //calculate date difference of each ticket from now
                let theForgotten=[]
                for(let ticket of response.data){
                    ticket.diff=(new Date()-new Date(ticket.lastUpdate))/1000/60/60/24  //in days
                    let foundSlot=false
                    for(let [key,value] of Object.entries(lastUpdateAgeReport)){
                        if(ticket.diff<key){
                            lastUpdateAgeReport[key].push(ticket)
                            foundSlot=true
                            break
                        }
                    }
                    if(foundSlot==false){
                        theForgotten.push(ticket)
                    }
                }
                lastUpdateAgeReport["≥60"]=theForgotten
                this.setState({
                    loading:false,
                    lastUpdateAgeReport:lastUpdateAgeReport,
                    selectedStatusList:statusArray,    //for later view use
                    ageReportModal:true,
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get ticket age report error\n"+error)
                this.setState({loading:false})
            })
    }

    imageUploadCallback=(file)=>{
        const imageObject = {
          file: file,
          localSrc: URL.createObjectURL(file),
        }
        let re = /(?:\.([^.]+))?$/
        let extension=re.exec(file.name)[1]
        this.extensionList.push([imageObject.localSrc,extension])
        return new Promise(
          (resolve, reject) => {
            resolve({ data: { link: imageObject.localSrc } })
          }
        )
    }

    onEditorStateChange=(editorState) => {
        this.setState({
            editorState,
        })
    }
    post=async ()=>{
        let theJSON=convertToRaw(this.state.editorState.getCurrentContent())
        var theImages=theJSON.entityMap
        let theKeys=Object.keys(theImages)
        var imagesToUpload=[]
        for(const key of theKeys){
            let srcLink=theImages[key].data.src
            if(srcLink.includes("blob:")){
                //add to the upload queue
                imagesToUpload.push(srcLink)
            }
        }
        this.setState({loading:true,ticketLogHistory:[]})
        const formData = new FormData();
        for(var v=0;v<imagesToUpload.length;v++){
            let blob = await fetch(imagesToUpload[v]).then(r => r.blob());  //get dat blob
            var theType="image/"
            var ext=""
            //find out the extension
            for(const extList of this.extensionList){
                if(extList[0]==imagesToUpload[v]){
                    ext=extList[1]
                    break
                }
            }
            const imageFile = new File([blob],`${Date.now()}.${ext}`,{type:theType,});
            formData.append('image'+v,imageFile)
            formData.append('image'+v+'_originalName',imagesToUpload[v])
        }
        axios
        .post(`sales/uploadticketimage`, formData, {headers: {"Content-type": "multipart/form-data"}},{timeout:40000})
        .then(async response => {
            //replace the blobs with returned server image links {serverLink:,imageName:}
            let imageLinks=response.data.processedLinks
            for(const image of imageLinks){
                for(const key of theKeys){
                    let srcLink=theImages[key].data.src
                    if(srcLink==image.originalName){
                        theJSON.entityMap[key].data.src=image.serverLink
                    }
                }
            }
            var fileName=[]
            for(var i=0;i<response.data.fileName.length;i++){
                fileName.push(response.data.fileName[i])
            }
            const payload = {
                message:JSON.stringify(theJSON),
                ticketID:this.state.currentTicket.id,
                fileName:JSON.stringify(fileName)
            }
            axios
            .post(`sales/postticketlog`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                this.setState({
                    loading:false,
                    ticketLogModal:false,
                    ticketLogText:"",
                    editorState:EditorState.createEmpty(),
                },()=>{
                    this.getTicketLogList(this.state.currentTicket.id)
                })
            })
        })
        .catch(error =>{
            if(error.response){
                if(error.response.status==444){
                    alert("You do not have permission to post ticket log to this ticket")
                    this.setState({loading:false})
                    return
                }
            }
            alert("Post ticket log error\n"+error)
            this.setState({loading:false})
        })
    }

    editFieldMode=()=>{
        this.setState({
            editField:true,
            toChangeCurrentTicket:JSON.parse(JSON.stringify(this.state.currentTicket)),
        })
    }

    saveFieldTicket = () => {
        this.setState({ loading: true })
        // Clone the current ticket to compare the changes
        const oldTicket = JSON.parse(JSON.stringify(this.state.currentTicket))
        const newTicket = this.state.toChangeCurrentTicket

        let payload = {
            ticketid: this.state.currentTicket.id,
            state: JSON.stringify(this.state.toChangeCurrentTicket.state),
            channel: this.state.toChangeCurrentTicket.channel,
            interest: this.state.toChangeCurrentTicket.interest,
            referrer: this.state.theReferrer.id,
            pic: (this.state.thePIC === "Lead Pool") ? "Lead Pool" : this.state.thePIC.id,
            picType:this.state.thePIC!=null?this.state.thePIC.type:null,
            target: this.state.toChangeCurrentTicket.target
        }

        if (this.state.currentTicket.lead != null) {
            if(oldTicket.lead.phone!=newTicket.lead.phone){
                payload.phone = newTicket.lead.phone
            }

            if(oldTicket.lead.email!=newTicket.lead.email){
                payload.email = newTicket.lead.email
            }
            payload.leadid= this.state.currentTicket.lead.id
            payload.name=this.state.toChangeCurrentTicket.lead.name
        }
        else{
            payload = payload
        }

        axios.post('sales/updateticketdetail', qs.stringify(payload))
        .then((response) => {
            // Check for changes and generate ticket log
            const updatedLead = this.state.toChangeCurrentTicket.lead? {
                    ...this.state.currentTicket.lead,
                    phone: this.state.toChangeCurrentTicket.lead.phone,
                    name: this.state.toChangeCurrentTicket.lead.name,
                    email: this.state.toChangeCurrentTicket.lead.email,
                }
                : null

            this.setState({
                editField: false,
                currentTicket: {
                    ...this.state.currentTicket,
                    lead: updatedLead,
                    target: this.state.toChangeCurrentTicket.target,
                    state: this.state.toChangeCurrentTicket.state,
                    channel: this.state.toChangeCurrentTicket.channel,
                    interest: this.state.toChangeCurrentTicket.interest,
                    referrer: this.state.theReferrer,
                    pic: this.state.thePIC,
                },
            },()=>{
                this.launchFunc()
            })
            //this.launchFunc()
        })
        .catch(error => {
            if(error.response){
                if(error.response.status==448){
                    alert("This phone number and email already exist\n")
                    this.setState({
                        loading:false,
                    })
                    return
                }
                else if(error.response.status==445){
                    alert("This phone number already exist\n")
                    this.setState({
                        loading:false,
                    })
                    return
                }
                else if(error.response.status==447){
                    alert("This email already exists\n")
                    this.setState({
                        loading:false,
                    })
                    return
                }
                else if(error.response.status==446){
                    alert("Phone number cannot be left empty\n")
                    this.setState({
                        loading:false,
                    })
                    return
                }
                else if(error.response.status==449){
                    alert("This PIC is not eligible to take this ticket")
                    this.setState({loading:false})
                    return
                }else if(error.response.status==450){
                    alert("Ticket with same PIC already exists")
                    this.setState({loading:false})
                    return
                }
            }
            alert('Error updating ticket details '+ error)
            this.setState({
                loading:false,
            })
        })
    }

    handleSelectBtnClick=()=>{
        this.setState({loading:true},()=>this.setState({loading:false}))
        if(this.state.editingField ==="referrer"){
            this.state.theReferrer = this.state.theEmployee
        }
        else if(this.state.editingField ==="pic"){
            this.state.thePIC = this.state.theEmployee
        }
    }

    getAllMember=()=>{
        this.setState({loading:true})
        const payload = {
            itemPerPage:this.state.memberPageItem,
            page:this.state.page,
            searchField:this.state.searchField,
        }
        axios
            .post('sales/getallmember',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                //parse the date
                for(let item of response.data.memberList){
                    let parsedDate=item.date_joined
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.date_joined=parsedDate
                }
                //parse the date
                for(let item of response.data.memberList){
                    let parsedDate=item.expirydate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.expirydate=parsedDate
                }

                this.setState({
                    memberList:response.data.memberList,
                    page:response.data.page,
                    memberCount:response.data.theMemberCount,
                    totalpage:response.data.totalPages,
                    loading:false,
                })
            })
            .catch(error => {
                if (error.response) {
                    
                }
                alert('Load all member list error\n' + error.message)
                this.setState({ loading: false })
            })
    }

    getAllLeadPool=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post('sales/getallleadpool',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                this.setState({
                    leadPoolList:response.data,
                    loading:false,
                })
            })
            .catch(error => {
                if (error.response) {

                }
                alert('Load lead pool list error\n' + error.message)
                this.setState({ loading: false })
            })
    }

    getTicketHistory=()=>{
        this.setState({loading:true})
        const payload = {
            ticketID:this.state.currentTicket.id,
            itemPerPage:this.state.historyPageItem,
            page:this.state.historyPage,
        }
        axios
            .post('sales/gettickethistory',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                for(let history of response.data.currentHistoryList){
                    if(history.createDate){
                        let rawDateSplit=history.createDate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        history.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                    }
                }
                for(let history of response.data.currentHistoryList){
                    if(history.expiryDate){
                        let rawDateSplit=history.expiryDate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        history.expiryDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                   }
                }

                this.setState({
                    loading:false,
                    ticketHistoryModal:true,
                    currentHistoryList:response.data.currentHistoryList,
                    totalHistoryPage:response.data.totalHistoryPage,
                    historyPage:response.data.historyPage,
                    allHistoryCount:response.data.allHistoryCount,
                })
                console.log(this.state.currentHistoryList)
            })
            .catch(error => {
                if(error.response){

                }
                // General error message directly from error object
                alert('Get ticket history error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    getAgentAndLeaderList=()=>{
        this.setState({loading:true})
        const payload = {
            itemPerPage:this.state.agentPageItem,
            page:this.state.agentPage,
        }
        axios
            .post('sales/getagentandleaderlist',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                this.setState({
                    loading:false,
                    agentListModal:true,
                    agentAndLeaderList:response.data.agentAndLeaderList,
                    totalAgentPage:response.data.totalAgentPage,
                    agentPage:response.data.agentPage,
                    agentAndAgentLeaderCount:response.data.agentAndAgentLeaderCount,
                })
            })
            .catch(error => {
                if(error.response){

                }
                // General error message directly from error object
                alert('Get agent list error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    transferTicket=()=>{
        // Check if the new PIC phone is the same as the current PIC phone
        if (this.state.selectedAgentPhone === this.state.currentTicket.picString.split(' ')[0]) {
            alert('Cannot transfer ticket to the same PIC')
            return
        }

        let isConfirmed
        if (this.state.selectedAgentPhone === null) {
            isConfirmed = window.confirm('Are you sure you want to transfer this ticket to Lead Pool?')

        } else {
            isConfirmed = window.confirm('Are you sure you want to transfer this ticket to ' + this.state.selectedAgentPhone + ' ' + this.state.selectedAgentName + '?')
        }
        
        // If the user does not confirm, stop the function
        if (!isConfirmed) {
            return
        }

        this.setState({loading:true})
        const payload = {
            ticketID:this.state.currentTicket.id,
            newPicPhone: this.state.selectedAgentPhone,
        }
        axios
            .post('sales/updateticketpic',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                alert('Ticket transferred successfully')
                this.setState({
                    agentListModal:false,
                },()=>{
                    this.searchTicket()
                })
            })
            .catch(error =>{
                if(error.response!=null){
                    if(error.response.status==449){
                        alert("This PIC is not eligible to take this ticket")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==450){
                        alert("Ticket with same PIC already exists")
                        this.setState({loading:false})
                        return
                    }
                }
                // General error message directly from error object
                alert('Transfer ticket error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    updateClientState=()=>{
        this.setState({loading:true})
        const payload = {
            ticketID:this.state.currentTicket.id,
            newState:JSON.stringify(this.state.toChangeState),
        }
        axios
            .post('sales/updateclientstate',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                alert('Client state updated successfully')
                this.setState({
                    editStateModal:false,
                },()=>{
                    this.launchFunc()
                })
            })
            .catch(error =>{
                if(error.response){

                }
                // General error message directly from error object
                alert('Edit client state error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    getBadgeString=(badges)=>{
        console.log(this.state.currentTicket.target)
        if(badges.length==0)return "-"
        var badgeString=""
        for(var g=0;g<badges.length;g++){
            badgeString+=badges[g].title+", "
        }
        badgeString=badgeString.substring(0,badgeString.length-2)
        return badgeString
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal isOpen={this.state.ticketHistoryModal} toggle={()=>this.setState({ticketHistoryModal:false})} >
                    <div style={{padding:20}}>
                        <div style={{display:"flex",justifyContent:"center",fontSize:20}}>Ticket History</div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,marginBottom:20,gap:5,justifyContent:"center"}}>
                            {
                            this.state.currentHistoryList!=null?
                                this.state.currentHistoryList.map((history)=>{
                                    return(
                                        <div style={{border:"1px solid lightgrey",padding:10,borderRadius:5,width:"100%"}}>
                                            <div>Date: {history.createDate}</div>
                                            <div>PIC: {
                                                history.pic!=null?
                                                    history.pic.username==history.pic.name?
                                                        history.pic.name
                                                        :
                                                        history.pic.username+" "+history.pic.name
                                                    :
                                                    "Lead Pool"
                                                }
                                            </div>
                                            <div>Expiry: {history.expiryDate!=null?history.expiryDate:"∞"}</div>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                            <ReactPaginate
                            pageCount={this.state.totalHistoryPage}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                            forcePage={this.state.historyPage}
                            onPageChange={async ({selected}) => {
                                this.setState({historyPage:selected},()=>{
                                    this.getTicketHistory()
                                })
                            }}/>

                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:500}} isOpen={this.state.agentListModal} toggle={()=>this.setState({agentListModal:false})}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",width:"100%"}}>
                        <div style={{display:"flex",flexDirection:"column",alignItems:"center",flexWrap:"wrap",marginTop:15,marginBottom:15,gap:10,padding:"0px 20px 0px 20px"}}>
                            <Button 
                                color="primary"
                                style={{
                                    width:"100%",
                                    display:"flex",
                                    flexDirection:"row",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    border:"1px solid lightgrey",
                                }}
                                onClick={() => {
                                    this.setState({selectedAgentPhone:null,selectedAgentName:null}, () => {
                                        this.transferTicket()
                                    })
                                }}>
                                <div>Lead Pool</div>
                            </Button>
                            {this.state.agentAndLeaderList.map(agentAndLeaderList => (
                                <Button 
                                    key={agentAndLeaderList.id}
                                    color="light"
                                    style={{
                                        width:"100%",
                                        display:"flex",
                                        flexDirection:"row",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        border:"1px solid lightgrey",
                                    }}
                                    onClick={() => {
                                        this.setState({selectedAgentPhone:agentAndLeaderList.phone,selectedAgentName:agentAndLeaderList.name}, () => {
                                            this.transferTicket()
                                        })
                                    }}>
                                    <div>{agentAndLeaderList.phone} {agentAndLeaderList.name}</div>
                                </Button>
                            ))}
                        </div>
                        <ReactPaginate
                            pageCount={this.state.totalAgentPage}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                            forcePage={this.state.agentPage}
                            onPageChange={async ({selected}) => {
                                this.setState({agentPage:selected},()=>{
                                    this.getAgentAndLeaderList()
                                })
                            }}/>
                    </div>
                </Modal>

                <Modal isOpen={this.state.selectStateModal} toggle={()=>this.setState({selectStateModal:false})} >
                    <div style={{padding:"10px 15px 15px 15px",display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{display:"flex",justifyContent:"left",fontSize:20,fontWeight:"bold"}}>Select State:</div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,gap:5,justifyContent:"center"}}>
                            {this.state.clientStateList.map((state)=>{
                                return(
                                    <Button key={state.id}
                                            color="primary" 
                                            style={{boxShadow:this.state.toChangeCurrentTicket.state.some(s => s.id === state.id)?"0px 0px 0px 5px orange inset":"none"}}
                                            onClick={() => this.toggleStateSelection(state)}
                                    >
                                        {state.name}
                                    </Button>
                                )
                            })}
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:20,gap:10,width:"100%"}}>
                                <Button color="light" style={{flex:1,backgroundColor:"salmon",color:"white"}} onClick={()=>{
                                    this.setState({selectStateModal:false})
                                }}>
                                    Close
                                </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.editStateModal} toggle={()=>this.setState({editStateModal:false})} >
                    <div style={{padding:"10px 15px 15px 15px",display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{display:"flex",justifyContent:"left",fontSize:20,fontWeight:"bold"}}>Select State:</div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,gap:5,justifyContent:"center"}}>
                            {this.state.clientStateList.map((state)=>{
                                return(
                                    <Button key={state.id}
                                            color="primary" 
                                            style={{boxShadow:this.state.toChangeState.some(s => s.id === state.id)?"0px 0px 0px 5px orange inset":"none"}}
                                            onClick={() => this.toggleToChangeState(state)}
                                    >
                                        {state.name}
                                    </Button>
                                )
                            })}
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:20,gap:10,width:"100%"}}>
                                <Button color="primary" style={{flex:1}} onClick={()=>{
                                    this.updateClientState()
                                }}>
                                    Update
                                </Button>
                                <Button color="light" style={{flex:1,backgroundColor:"salmon",color:"white"}} onClick={()=>{
                                    this.setState({editStateModal:false})
                                }}>
                                    Close
                                </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.selectChannelModal} toggle={()=>this.setState({selectChannelModal:false})} >
                    <div style={{padding:"10px 15px 15px 15px",display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{display:"flex",justifyContent:"left",fontSize:20,fontWeight:"bold"}}>Select Channel:</div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,marginBottom:20,gap:5,justifyContent:"center"}}>
                            {
                            GLOBAL.channelList
                            .filter(channel=>channel!=="Contact Form")
                            .map((channel)=>{
                                return(
                                    <Button color="light" style={{backgroundColor:GLOBAL.getChannelColor(channel),color:"white",
                                        }}
                                        onClick={()=>{
                                            var newDetail = this.state.toChangeCurrentTicket
                                            newDetail.channel = channel
                                            this.setState({toChangeCurrentTicket:newDetail,selectChannelModal:false},()=>this.launchFunc())
                                    }}>
                                        {channel}
                                    </Button>
                                )
                            })
                            }
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:700}} isOpen={this.state.selectMemberModal} toggle={()=>this.setState({selectMemberModal:false})}>
                    <div style={{padding:15,}}>
                        {
                        this.state.editingField=="pic"? //referrer mode does not need to choose lead pools
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:10,alignItems:"center",justifyContent:"center"}}>
                                <Button color={this.state.selectMemberMode=="Lead Pool"?"primary":"secondary"} style={{}} onClick={()=>{
                                    this.setState({selectMemberMode:"Lead Pool"},()=>{
                                        this.getAllLeadPool()
                                    })
                                }}>
                                    Lead Pool
                                </Button>
                                <Button color={this.state.selectMemberMode=="Member"?"primary":"secondary"} style={{}} onClick={()=>{
                                    this.setState({selectMemberMode:"Member"},()=>{
                                        this.getAllMember()
                                    })
                                }}>
                                    Member
                                </Button>
                            </div>
                            :
                            null
                        }

                        {
                        this.state.selectMemberMode=="Member"?
                            <div style={{marginTop:5}}>
                                <div style={{width:"100%",display:"flex",flexDirection:"row",flexWrap:"nowrap",gap:5}}>
                                    <Input value={this.state.searchField}
                                        placeholder='Enter phone number or name'
                                        style={{width:"100%"}}
                                        onChange={(e)=>{this.setState({searchField:e.target.value})}}
                                        onKeyDown={(e)=>{
                                            if(e.keyCode==13){
                                                e.preventDefault()
                                                this.getAllMember()
                                            }
                                    }}/>
                                    <Button color="primary" style={{}} onClick={()=>{
                                        this.getAllMember()
                                    }}>
                                        Search
                                    </Button>
                                </div>
                                {
                                window.innerWidth>600?
                                    <table style={{width:"100%",marginTop:10}}>
                                        <thead>
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Join Date</th>
                                            <th></th>
                                        </thead>
                                        {
                                        this.state.memberList.map((item)=>{
                                            return(
                                                <tbody style={{width:"100%"}}>
                                                    <td style={{paddingRight:30}}>{item.username}</td>
                                                    <td style={{paddingRight:30}}>{item.name}</td>
                                                    <td style={{paddingRight:30}}>{item.date_joined}</td>
                                                    <td>
                                                        <Button value={item.id}
                                                            style={{width:"100%"}}
                                                            color='secondary'
                                                            onClick={(e)=>{
                                                                let theItem=JSON.parse(JSON.stringify(item))
                                                                theItem.type="Member"
                                                                this.setState({
                                                                    selectMemberModal:false,
                                                                    theEmployee:theItem,
                                                                },this.handleSelectBtnClick)
                                                        }}>
                                                            Select
                                                        </Button>
                                                    </td>
                                                </tbody>
                                            )
                                        })}
                                    </table>
                                    :
                                    <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5,marginTop:10,marginBottom:10}}>
                                        {
                                        this.state.memberList.map((item)=>{
                                            return(
                                                <div style={{padding:5,borderRadious:5,border:"1px solid lightgrey",display:"flex",flexDirection:"row",
                                                    alignItems:"center",width:"100%"}}>
                                                    <div style={{width:"100%"}}>
                                                        <div style={{fontWeight:"bold"}}>
                                                            {item.username+" "+item.name}
                                                        </div>
                                                        <div style={{fontSize:10,color:"grey"}}>{item.date_joined}</div>
                                                    </div>
                                                    <Button value={item.id}
                                                        style={{padding:"5px 10px",fontSize:12}}
                                                        color='secondary'
                                                        onClick={(e)=>{
                                                            let theItem=JSON.parse(JSON.stringify(item))
                                                            theItem.type="Member"
                                                            this.setState({
                                                                selectMemberModal:false,
                                                                theEmployee:theItem,
                                                            },this.handleSelectBtnClick)
                                                    }}>
                                                        Select
                                                    </Button>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                                
                                <ReactPaginate
                                    pageCount={this.state.totalpage}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLabel={"..."}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    disabledClassName={"disabled"}
                                    forcePage={this.state.page}
                                    onPageChange={async ({selected}) => {
                                        this.setState({page:selected},()=>{
                                            this.getAllMember()
                                        })
                                    }}/>
                            </div>
                            :
                        this.state.selectMemberMode=="Lead Pool"?
                            <div style={{marginTop:5,display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                {
                                this.state.leadPoolList.map((leadPool)=>{
                                    return(
                                        <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10,alignItems:"center",
                                            padding:5,border:"1px solid lightgrey",borderRadius:5}}>
                                            <div style={{width:"100%"}}>{leadPool.name}</div>
                                            <Button color="secondary" style={{fontSize:12,padding:"5px 10px"}} onClick={()=>{
                                                this.setState({
                                                    selectMemberModal:false,
                                                    theEmployee:{
                                                        type:"Lead Pool",
                                                        id:leadPool.id,
                                                        name:"[Lead Pool] "+leadPool.name
                                                    },
                                                },this.handleSelectBtnClick)
                                            }}>
                                                Select
                                            </Button>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            :
                            null
                        }

                        <div style={{display:"flex",flexDirection:"row",gap:10,width:"100%",marginTop:10}}>
                            <Button style={{backgroundColor:"salmon",color:"white",border:"none",width:"100%"}} 
                                onClick={()=>this.setState({selectMemberModal:false})}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.filterModal} toggle={()=>this.setState({filterModal:false,filterMode:null})} style={{maxWidth:500}}>
                    <div style={{padding:"10px 15px 15px 15px",display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Filter by: {this.state.filterMode}</div>
                        {
                        this.state.filterMode=="Ticket Number"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}}
                                    value={this.state.filterTicketNumber}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterTicketNumber:e.target.value.replace(/\D/g,"")})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Client Phone"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterClientPhone}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterClientPhone:e.target.value.replace(/\D/g,"")})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Client Name"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterClientName}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterClientName:e.target.value})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Client Email"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterClientEmail}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterClientEmail:e.target.value})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="State"?
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,gap:5}}>
                                {
                                this.state.clientStateList.map((state)=>{
                                    return(
                                        <Button color="primary" style={{boxShadow:this.state.filterState.includes(state)?"0px 0px 0px 5px orange inset":"none"}}
                                            onClick={()=>{
                                                this.addSelectedState(state)
                                        }}>
                                            {state.name}
                                        </Button>
                                    )
                                })
                                }
                                <Button color="primary" style={{boxShadow:this.state.filterState.includes("No State")?"0px 0px 0px 5px orange inset":"none"}}
                                    onClick={()=>{
                                        this.addSelectedState("No State")
                                }}>
                                    No State
                                </Button>
                            </div>
                            :
                        this.state.filterMode=="Channel"?
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,gap:5}}>
                                {
                                GLOBAL.channelList.map((channel)=>{
                                    return(
                                        <Button color="light" style={{backgroundColor:GLOBAL.getChannelColor(channel),color:"white",
                                            boxShadow:this.state.filterChannel.includes(channel)?"0px 0px 0px 5px orange inset":"none"}}
                                            onClick={()=>{
                                                this.addSelectedChannel(channel)
                                        }}>
                                            {channel}
                                        </Button>
                                    )
                                })
                                }
                            </div>
                            :
                        this.state.filterMode=="Interest"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterInterest}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterInterest:e.target.value})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Last Update"||this.state.filterMode=="Create Date"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <div style={{display:"flex",flexDirection:"row",gap:10,overflowX:"scroll"}}>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setHours(0,0,0)
                                        let toDate=new Date()
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE",{
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE",{
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }
                                    }}>
                                        Today
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setHours(0,0,0)
                                        fromDate.setDate(fromDate.getDate() - 13)   //today count as one day as well
                                        let toDate=new Date()
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }
                                    }}>
                                        Last 14 Days
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setHours(0,0,0)
                                        fromDate.setDate(fromDate.getDate() - 6)   //today count as one day as well
                                        let toDate=new Date()
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }
                                    }}>
                                        Last 7 Days
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let curr = new Date()
                                        let fromDate = new Date(curr.setDate(curr.getDate() - curr.getDay()))
                                        fromDate.setHours(0,0,0)
                                        let toDate = new Date(); // First day is the day of the month - the day of the week
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }
                                    }}>
                                        This Week
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setMonth(fromDate.getMonth()-1)
                                        fromDate.setDate(1)
                                        fromDate.setHours(0,0,0)
                                        let toDate=new Date()
                                        toDate.setDate(0)
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }
                                    }}>
                                        Last Month
                                    </Button>
                                    <Button color="primary" style={{whiteSpace:"nowrap",padding:"5px 5px",fontSize:13}} onClick={()=>{
                                        let fromDate=new Date()
                                        fromDate.setDate(1)
                                        fromDate.setHours(0,0,0)
                                        let toDate=new Date()
                                        toDate.setMonth(toDate.getMonth()+1)
                                        toDate.setDate(0)
                                        toDate.setHours(23,59,59)
                                        let fromDateString=fromDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        let toDateString=toDate.toLocaleString("sv-SE", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }).replace(" ", "T")
                                        if(this.state.filterMode=="Last Update"){
                                            this.setState({
                                                filterLastUpdateFrom:fromDateString,
                                                filterLastUpdateTo:toDateString,
                                            })
                                        }else if(this.state.filterMode=="Create Date"){
                                            this.setState({
                                                filterCreateDateFrom:fromDateString,
                                                filterCreateDateTo:toDateString,
                                            })
                                        }
                                    }}>
                                        This Month
                                    </Button>
                                </div>
                                {
                                this.state.filterMode=="Last Update"?
                                    <div>
                                        <div>Last Update From</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterLastUpdateFrom}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterLastUpdateFrom:e.target.value})
                                        }} />
                                        <div>Last Update To</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterLastUpdateTo}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterLastUpdateTo:e.target.value})
                                        }} />
                                    </div>
                                    :
                                this.state.filterMode=="Create Date"?
                                    <div>
                                        <div>Create Date From</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterCreateDateFrom}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterCreateDateFrom:e.target.value})
                                        }} />
                                        <div>Create Date To</div>
                                        <Input type="datetime-local" style={{}} value={this.state.filterCreateDateTo}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.filterTicket(this.state.filterMode)
                                                }
                                            }}
                                            onChange={(e)=>{
                                                this.setState({filterCreateDateTo:e.target.value})
                                        }} />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                        this.state.filterMode=="Referrer Phone"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterReferrerPhone}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterReferrerPhone:e.target.value.replace(/\D/g,"")})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="PIC Phone"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <Input style={{}} value={this.state.filterPICPhone}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.filterTicket(this.state.filterMode)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({filterPICPhone:e.target.value.replace(/\D/g,"")})
                                }} />
                            </div>
                            :
                        this.state.filterMode=="Status"?
                            <div style={{display:"flex",flexDirection:"column",marginTop:10,gap:5}}>
                                <div style={{padding:10,borderRadius:10,border:"1px solid steelblue",width:"100%",display:"flex",flexDirection:"row",gap:7,flexWrap:"wrap"}}>
                                    {
                                    this.state.selectedStatusList.map((status)=>{
                                        return(
                                            <div style={{
                                                backgroundColor:GLOBAL.getStatusColor(status),
                                                border:"none",
                                                color:GLOBAL.getStatusColor(status)=="yellow"?"black":"white",
                                                padding:5,
                                                display:"flex",flexDirection:"row",alignItems:"center",gap:7,flexWrap:"nowrap",
                                            }}>
                                                {status}
                                                <ImCross style={{cursor:"pointer"}} onClick={()=>{
                                                    let listClone=this.state.selectedStatusList
                                                    for(let [index,sta] of listClone.entries()){
                                                        if(sta==status){
                                                            listClone.splice(index,1)
                                                            break
                                                        }
                                                    }
                                                    this.setState({selectedStatusList:listClone})
                                                }} />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                <div>
                                    {
                                    this.state.statusList.map((category)=>{
                                        return(
                                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:7,marginTop:10}}>
                                                {
                                                category.statusList.map((stat)=>{
                                                    return(
                                                        <Button color="light"
                                                            style={{
                                                                backgroundColor:GLOBAL.getStatusColor(stat.title),
                                                                border:"none",
                                                                color:GLOBAL.getStatusColor(stat.title)=="yellow"?"black":"white",
                                                            }}
                                                            onClick={()=>{
                                                                let listClone=this.state.selectedStatusList
                                                                let foundSame=false
                                                                for(let sta of listClone){  //check for repeat
                                                                    if(sta==stat.title){
                                                                        foundSame=true
                                                                        break
                                                                    }
                                                                }
                                                                if(foundSame==false){
                                                                    listClone.push(stat.title)
                                                                    this.setState({selectedStatusList:listClone})
                                                                }
                                                            }}
                                                        >
                                                            {stat.title}
                                                        </Button>
                                                    )
                                                })
                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            :
                        this.state.filterMode=="Member"?
                            <div style={{display:"flex",flexDirection:"row",marginTop:10,gap:5}}>
                                <Button color="primary" style={{flex:1}} onClick={()=>{
                                    this.setState({filterMember:"Member"},()=>{
                                        this.filterTicket(this.state.filterMode)
                                    })
                                }}>
                                    Member
                                </Button>
                                <Button color="secondary" style={{flex:1}} onClick={()=>{
                                    this.setState({filterMember:"Lead"},()=>{
                                        this.filterTicket(this.state.filterMode)
                                    })
                                }}>
                                    Lead
                                </Button>
                            </div>
                            :
                            null
                        }

                        {
                        this.state.filterMode!="Member"?
                            <div style={{display:"flex",flexDirection:"row",marginTop:20,gap:10,width:"100%"}}>
                                <Button color="primary" style={{flex:1}} onClick={()=>{
                                    this.filterTicket(this.state.filterMode)
                                }}>
                                    Filter
                                </Button>
                                <Button color="light" style={{flex:1,backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                    this.setState({filterModal:false})
                                }}>
                                    Close
                                </Button>
                            </div>
                            :
                            null
                        }
                    </div>
                </Modal>

                <Modal isOpen={this.state.updateStatusModal} toggle={()=>this.setState({updateStatusModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:"10px 15px 15px 15px",display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div>
                            {
                            this.state.statusList.map((category)=>{
                                return(
                                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:7,marginTop:10}}>
                                        {
                                        category.statusList.map((stat)=>{
                                            return(
                                                <Button color="light"
                                                    style={{
                                                        backgroundColor:GLOBAL.getStatusColor(stat.title),
                                                        boxShadow:this.state.selectedUpdateStatus==stat.title?"0px 0px 0px 5px inset purple":"none",
                                                        color:GLOBAL.getStatusColor(stat.title)=="yellow"?"black":"white",
                                                    }}
                                                    onClick={()=>{
                                                        this.setState({selectedUpdateStatus:stat.title})
                                                    }}
                                                >
                                                    {stat.title}
                                                </Button>
                                            )
                                        })
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>

                        <div style={{marginTop:10}}>Comment</div>
                        <textarea rows={4} style={{width:"100%",padding:10}} value={this.state.updateStatusLog} onChange={(e)=>{
                            this.setState({updateStatusLog:e.target.value})
                        }} />
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="primary" style={{flex:1,}} onClick={()=>{
                                //validate
                                if(this.state.updateStatusLog.trim().length==0){
                                    alert("Please fill in comment for status update")
                                    return
                                }

                                this.updateStatus()
                            }}>
                                Update
                            </Button>
                            <Button color="light" style={{flex:1,backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                this.setState({updateStatusModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.ageReportModal} toggle={()=>this.setState({ageReportModal:false})} style={{width:"95%"}}>

                    <div style={{padding:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontWeight:"bold",marginBottom:10,fontSize:14,textAlign:"center"}}>Last Update Status Age Report</div>
                        <table style={{width:"100%"}}>
                            <tr>
                                <td style={styles.cellBorder}>Age (&#60;Days)</td>
                                <td style={styles.cellBorder}>Count</td>
                                <td style={styles.cellBorder}></td>
                            </tr>
                            {
                            this.state.lastUpdateAgeReport!=null?
                                Object.keys(this.state.lastUpdateAgeReport).map((key,index)=>{
                                    return(
                                        <tr>
                                            <td style={styles.cellBorder}>{key}</td>
                                            <td style={Object.assign({textAlign:"right"},styles.cellBorder)}>{this.state.lastUpdateAgeReport[key].length}</td>
                                            <td style={styles.cellBorder}>
                                                <AiOutlineSearch style={{marginLeft:5,cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                    let lastUpdateFrom=new Date()
                                                    if(key=="≥60"){
                                                        lastUpdateFrom=null
                                                    }else{
                                                        lastUpdateFrom.setHours(0,0,0)
                                                        lastUpdateFrom.setDate(lastUpdateFrom.getDate() - (key-1))
                                                    }

                                                    let lastUpdateTo=new Date()
                                                    lastUpdateTo.setHours(23,59,59)
                                                    if(index>0){
                                                        lastUpdateTo.setDate(lastUpdateTo.getDate() - (Object.keys(this.state.lastUpdateAgeReport)[index-1]))
                                                    }


                                                    let fromDateString=null
                                                    if(lastUpdateFrom!=null){
                                                        fromDateString=lastUpdateFrom.toLocaleString("sv-SE",{
                                                            year: "numeric",
                                                            month: "2-digit",
                                                            day: "2-digit",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }).replace(" ", "T")
                                                    }
                                                    let toDateString=lastUpdateTo.toLocaleString("sv-SE",{
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }).replace(" ", "T")

                                                    this.setState({
                                                        filterMode:"Last Update",
                                                        filterLastUpdateFrom:fromDateString,
                                                        filterLastUpdateTo:toDateString,
                                                    },()=>{
                                                        let find=this.cleanFilter("Last Update")
                                                        let oldFindField=this.state.findField
                                                        this.setState({
                                                            findField:find,
                                                            filterMode:"Status",
                                                        },()=>{
                                                            window.open("/ticket?search="+encodeURI(this.cleanFilter("Status")),"_blank")
                                                            this.setState({findField:oldFindField})
                                                        })
                                                    })
                                                }}/>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                null
                            }
                        </table>
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="light" style={{flex:1,backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                this.setState({ageReportModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <NavigationBar currentPage="ticket" />

                {
                window.innerWidth<600 ?
                    //mobile
                    this.state.viewMode=="dashboard"?
                        <div style={{width:"100%",padding:"20px 20px",gap:10,display:"flex",flexDirection:"column",fontSize:14}}>
                            <div style={{display:"flex",flexDirection:"row",width:"100%",gap:5,}}>
                                <Input placeHolder="Use filter by below to create query"
                                    value={this.state.findField} style={{width:"100%"}}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.searchTicket()
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({findField:e.target.value})
                                    }} />
                                    <Button color="danger" style={{whiteSpace:"nowrap",paddingLeft:5,paddingRight:5}} onClick={()=>{
                                        this.setState({findField:""})
                                    }}>
                                        Clear Query
                                    </Button>
                                    <Button color="primary" style={{paddingLeft:5,paddingRight:5}} onClick={()=>{
                                        this.searchTicket()
                                    }}>
                                        Search
                                    </Button>
                            </div>

                            {this.generateOperationArea()}

                            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"space-between",padding:"0px 0px 5px 0px",gap:10}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"wrap"}}>
                                    <div>Filter by:</div>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Ticket Number",filterTicketNumber:""})
                                    }}>
                                        Ticket Number
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Client Phone",filterClientPhone:""})
                                    }}>
                                        Client Phone
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Client Name",filterClientName:""})
                                    }}>
                                        Client Name
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Client Email",filterClientEmail:""})
                                    }}>
                                        Client Email
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"State",filterState:[]})
                                    }}>
                                        State
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Channel",filterChannel:[]})
                                    }}>
                                        Channel
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Interest",filterInterest:""})
                                    }}>
                                        Interest
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Last Update",filterLastUpdateFrom:"",filterLastUpdateTo:"",})
                                    }}>
                                        Last Update
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Create Date",filterCreateDateFrom:"",filterCreateDateTo:""})
                                    }}>
                                        Create Date
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Referrer Phone",filterReferrerPhone:""})
                                    }}>
                                        Referrer Phone
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"PIC Phone",filterPICPhone:""})
                                    }}>
                                        PIC Phone
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Status",selectedStatusList:[]})
                                    }}>
                                        Status
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Member",filterMember:""})
                                    }}>
                                        Member
                                    </Button>
                                </div>
                                <div style={{display:"flex",flexDirection:"row",gap:5}}>
                                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"maroon",color:"white"}}
                                        onClick={()=>{
                                            this.setState({findField:"Status:还没联系,等待回复,安排预约,已经回复,有兴趣,购买课程"},()=>{
                                                this.searchTicket()
                                            })
                                    }}>
                                        Hot
                                    </Button>
                                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"yellow",color:"black"}}
                                        onClick={()=>{
                                            this.setState({findField:"Status:安排汇款,预付定金,分期付款,全额付款"},()=>{
                                                this.searchTicket()
                                            })
                                    }}>
                                        Payment
                                    </Button>
                                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"steelblue",color:"white"}}
                                        onClick={()=>{
                                            this.setState({findField:"Status:不精准客户,无法联系,没兴趣"},()=>{
                                                this.searchTicket()
                                            })
                                    }}>
                                        Cold
                                    </Button>
                                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"grey",color:"white"}}
                                        onClick={()=>{
                                            this.setState({findField:"Status:完成报名,他人完成,Task Terminated"},()=>{
                                                this.searchTicket()
                                            })
                                    }}>
                                        Closed
                                    </Button>
                                </div>
                            </div>

                            <div style={{width:"100%"}}>
                            {
                            this.state.ticketList.length>0?
                                this.state.ticketList.map((ticket,index)=>{
                                    return(
                                    <div style={{display:"flex",flexDirection:"column",borderRadius:"15px",padding:5,marginBottom:10,border:"1px solid",borderColor:"lightgrey"}}
                                        onClick={()=>{
                                            history.push("/ticket?id="+ticket.id)
                                            this.viewTicketDetail(ticket.id)
                                            this.getTicketLogList(ticket.id)
                                            }}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                            <div style={{display:"flex",flexDirection:"column",padding:10,width:"100%"}}>

                                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                    <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                                        <div style={{fontWeight:"bold",alignSelf:"flex-start",fontSize:16}}>
                                                            Tix Num: {ticket.id}
                                                        </div>

                                                        <div style={{fontWeight:"bold",alignSelf:"flex-start",fontSize:10}}>
                                                            Status: {ticket.status}
                                                        </div>
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                                        <div style={{fontWeight:"bold",alignSelf:"flex-start",fontSize:10}}>
                                                            Create Date: {ticket.createDate}
                                                        </div>

                                                        <div style={{fontWeight:"bold",alignSelf:"flex-start",fontSize:10}}>
                                                            Last Update: {ticket.lastUpdate}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:5,marginBottom:5,width:"100%",color:"lightgrey"}}/>
                                                <table style={{lineHeight:1.5, fontSize:10}}>
                                                    <tr>
                                                        <td>
                                                            Name:&nbsp;
                                                            {
                                                            ticket.lead!=null&&ticket.lead.name!=null?
                                                                ticket.lead.name
                                                                :
                                                            ticket.target!=null?
                                                                ticket.target.name
                                                                :
                                                                null
                                                            }
                                                        </td>
                                                        <td>
                                                            Member:&nbsp;
                                                            {
                                                            ticket.lead!=null?
                                                                "Lead"
                                                                :
                                                            ticket.target!=null?
                                                                "Member"
                                                                :
                                                                "Ghost"
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Phone:&nbsp;
                                                            {
                                                            ticket.lead!=null?
                                                                ticket.lead.phone
                                                                :
                                                            ticket.target!=null?
                                                                ticket.target.username
                                                                :
                                                                null
                                                            }
                                                        </td>
                                                        <td>Referrer: {ticket.referrerString}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Email:&nbsp;
                                                            {
                                                            ticket.lead!=null&&ticket.lead.email!=null?
                                                                ticket.lead.email
                                                                :
                                                            ticket.target!=null&&ticket.target.email!=null?
                                                                ticket.target.email
                                                                :
                                                                null
                                                            }
                                                        </td>
                                                        <td>
                                                            PIC: {ticket.picString!="None"?ticket.picString:"Lead Pool"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            State:&nbsp;
                                                            {ticket.state && ticket.state.length > 0
                                                                ? ticket.state.map(state => state.name).join(", ")
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                </table>
                                                    <div>
                                                        <span style={Object.assign({backgroundColor:GLOBAL.getChannelColor(ticket.channel),color:"white",textAlign:"center",padding:"0px 5px",margin:"0px 5px 0px 0px", fontSize:10})}>
                                                        {ticket.channel}
                                                        </span>
                                                        <span style={{fontSize:10,lineHeight:1}}>
                                                                {ticket.interest}
                                                        </span>
                                                    </div>
                                            </div>

                                            <div style={{display:"flex",alignSelf:"baseline",paddingTop:15}}>
                                                <AiOutlineRight color='grey' size={17} />
                                            </div>
                                        </div>
                                    </div>
                                    )})
                                :
                                <div>
                                    --------
                                </div>
                            }
                            </div>
                            <ReactPaginate
                                pageCount={this.state.pageCount}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLabel={"..."}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                disabledClassName={"disabled"}
                                forcePage={this.state.page}
                                onPageChange={async ({selected}) => {
                                    this.setState({page:selected},()=>{
                                        this.searchTicket()
                                    })
                                }}
                            />
                        </div>
                    :
                    this.state.viewMode=="detail"?
                    <div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <div style={{width:"100%",padding:"20px 20px",maxWidth:1200}}>
                            <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",marginBottom:10,gap:10}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:2,border:"1px solid lightgrey",borderRadius:5,
                                    padding:"2px 10px",flexWrap:"nowrap",cursor:"pointer"}}
                                    onClick={()=>{
                                        history.push("/ticket")
                                        this.setState({viewMode:"dashboard",editField: false},()=>{this.searchTicket()})
                                        }}>
                                    <IoMdArrowRoundBack />
                                    Back to Dashboard
                                </div>
                                {GLOBAL.checkPermission("h4@}a=jU")?                                                                   
                                    <div>
                                        <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                            this.setState({
                                                selectedAgentPhone: this.state.currentTicket.picString.split(' ')[0],
                                            },()=>{this.getAgentAndLeaderList()})
                                        }}>
                                            Transfer Ticket
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {
                            this.state.currentTicket!=null?
                                <div style={{gap:10,display:"flex",flexDirection:"column",justifyContent:"flex-start",maxWidth:"100%",fontSize:13}}>
                                    <div style={{maxWidth:450}}>
                                       {
                                        this.state.editField?
                                            <table style={{width:"100%"}}>
                                                <tr>
                                                    <td style={styles.cellBorder}>Ticket Number</td>
                                                    <td style={styles.cellBorder}>{this.state.currentTicket.id}</td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>Member</td>
                                                    <td style={styles.cellBorder}>
                                                    {
                                                    this.state.currentTicket.target!=null?
                                                        "Member"
                                                        :
                                                    this.state.currentTicket.lead!=null?
                                                        "Lead"
                                                        :
                                                        "Ghost"
                                                    }
                                                    </td>
                                                </tr>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    <tr>
                                                        <td style={styles.cellBorder}>Member Referrer</td>
                                                        <td style={styles.cellBorder}>
                                                            {
                                                            this.state.currentTicket.memberReferrer!=null?
                                                                this.state.currentTicket.memberReferrer
                                                                :
                                                                "-"
                                                            }
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                {
                                                this.state.currentTicket.target!=null?
                                                    <tr>
                                                        <td style={styles.cellBorder}>Member PIC</td>
                                                        <td style={styles.cellBorder}>
                                                            {
                                                            this.state.currentTicket.memberPIC!=null?
                                                                this.state.currentTicket.memberPIC
                                                                :
                                                                "-"
                                                            }
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                <tr>
                                                    <td style={styles.cellBorder}>Phone</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.target!=null?
                                                            this.state.currentTicket.target.username
                                                        :
                                                        this.state.currentTicket.lead!=null?
                                                        <Input
                                                        value={this.state.toChangeCurrentTicket.lead.phone}
                                                        onChange={(e) => {
                                                            var newDetail = this.state.toChangeCurrentTicket
                                                            var modifiedField ={...this.state.modified}
                                                            newDetail.lead.phone = e.target.value

                                                            // Use the correct syntax for updating the modifiedField state
                                                            if (newDetail.lead.phone !== this.state.currentTicket.lead.phone) {
                                                            modifiedField.phone = true
                                                            } else {
                                                            modifiedField.phone = false
                                                            }

                                                            // Update both newDetail and modifiedField in the state
                                                            this.setState({
                                                            toChangeCurrentTicket: newDetail,
                                                            modified: modifiedField,
                                                            })
                                                        }}
                                                        style={{
                                                            color: this.state.modified.phone?"blue":"black", // Set the font color, you can adjust it accordingly
                                                        }}
                                                        />
                                                        :
                                                        null
                                                        }
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>Name</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.target!=null?
                                                            this.state.currentTicket.target.name
                                                        :
                                                        this.state.currentTicket.lead!=null?
                                                        <Input
                                                        value={this.state.toChangeCurrentTicket.lead.name}
                                                        onChange={(e) => {
                                                            var newDetail = this.state.toChangeCurrentTicket
                                                            var modifiedField = {...this.state.modified}
                                                            newDetail.lead.name = e.target.value

                                                            // Use the correct syntax for updating the modifiedField state
                                                            if (newDetail.lead.name !== this.state.currentTicket.lead.name) {
                                                            modifiedField.name = true
                                                            } else {
                                                            modifiedField.name = false
                                                            }

                                                            // Update both newDetail and modifiedField in the state
                                                            this.setState({
                                                            toChangeCurrentTicket: newDetail,
                                                            modified: modifiedField,
                                                            })
                                                        }}
                                                        style={{
                                                            color: this.state.modified.name?"blue":"black", // Set the font color, you can adjust it accordingly
                                                        }}
                                                        />
                                                        :
                                                        null
                                                        }
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>Email</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.target!=null?
                                                            // this.setState({toChangeCurrentTicket:newDetail})}}/>
                                                            this.state.currentTicket.target.email
                                                        :
                                                        this.state.currentTicket.lead!=null?
                                                            <Input value={this.state.toChangeCurrentTicket.lead.email} onChange={(e)=>{
                                                            var newDetail=this.state.toChangeCurrentTicket
                                                            var modifiedField = {...this.state.modified}
                                                            newDetail.lead.email=e.target.value
                                                            // Use the correct syntax for updating the modifiedField state
                                                            if (newDetail.lead.email !== this.state.currentTicket.lead.email) {
                                                                modifiedField.email = true
                                                                } else {
                                                                modifiedField.email = false
                                                                }

                                                                // Update both newDetail and modifiedField in the state
                                                                this.setState({
                                                                toChangeCurrentTicket: newDetail,
                                                                modified: modifiedField,
                                                                })
                                                            }}
                                                            style={{

                                                                color: this.state.modified.email?"blue":"black", // Set the font color, you can adjust it accordingly
                                                            }}
                                                        />
                                                        :
                                                        null
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>State</td>
                                                    <td style={styles.cellBorder}>
                                                        <span style={{color:this.state.modified.state?"blue":"black"}}>
                                                            {this.state.toChangeCurrentTicket.state && this.state.toChangeCurrentTicket.state.length > 0
                                                                ? this.state.toChangeCurrentTicket.state.map(state => state.name).join(", ")
                                                                : "-"}
                                                        </span>
                                                        <Button color='secondary' style={{marginLeft:10}} onClick={()=>this.setState({selectStateModal:true})}>Edit</Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>Channel</td>
                                                    <td style={Object.assign({backgroundColor:GLOBAL.getChannelColor(this.state.toChangeCurrentTicket.channel),color:"white"},
                                                        styles.cellBorder)}>
                                                        {this.state.toChangeCurrentTicket.channel}
                                                        <Button color='secondary' style={{marginLeft:10}} onClick={()=>this.setState({selectChannelModal:true})}>Edit</Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>Interest</td>
                                                    <td style={styles.cellBorder}>
                                                    <Input
                                                    value={this.state.toChangeCurrentTicket.interest}
                                                    onChange={(e) => {
                                                        var newDetail = this.state.toChangeCurrentTicket
                                                        var modifiedField = {...this.state.modified}
                                                        newDetail.interest = e.target.value

                                                        // Use the correct syntax for updating the modifiedField state
                                                        if (newDetail.interest !== this.state.currentTicket.interest) {
                                                        modifiedField.interest = true
                                                        } else {
                                                        modifiedField.interest = false
                                                        }

                                                        // Update both newDetail and modifiedField in the state
                                                        this.setState({
                                                        toChangeCurrentTicket: newDetail,
                                                        modified: modifiedField,
                                                        })
                                                    }}
                                                    style={{
                                                        color: this.state.modified.interest?"blue":"black", // Set the font color, you can adjust it accordingly
                                                    }}
                                                    />
                                                    </td>

                                                </tr>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    <tr>
                                                        <td style={styles.cellBorder}>Badge</td>
                                                        <td style={styles.cellBorder}>{this.getBadgeString(this.state.currentTicket.target.badge)}</td>
                                                    
                                                    </tr>
                                                    :
                                                    null
                                                }
                                                <tr>
                                                    <td style={styles.cellBorder}>Ticket Referrer</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.theReferrer.length === 0 ?
                                                            this.state.currentTicket.referrerString
                                                            :
                                                            <span style={{color:"blue"}}>
                                                                {
                                                                this.state.theReferrer.username==this.state.theReferrer.name?
                                                                    this.state.theReferrer.name
                                                                    :
                                                                    this.state.theReferrer.username+" "+this.state.theReferrer.name
                                                                }
                                                            </span>
                                                        }
                                                        <Button color="secondary" style={{marginLeft:10}} 
                                                            onClick={()=>{
                                                                this.setState({
                                                                    selectMemberModal:true,
                                                                    editingField:"referrer",
                                                                    selectMemberMode:"Member",
                                                                },()=>{
                                                                    this.getAllMember()
                                                                }
                                                                )
                                                            }}>
                                                            Edit
                                                        </Button>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>Ticket PIC</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.thePIC.length === 0 ?
                                                            this.state.currentTicket.picString
                                                            :
                                                            this.state.thePIC === "Lead Pool" ?
                                                                <span style={{color: "blue"}}>Lead Pool</span>
                                                                :
                                                                <span style={{color: "blue"}}>
                                                                    {
                                                                    this.state.thePIC.type=="Member"?
                                                                        this.state.thePIC.username + " " + this.state.thePIC.name
                                                                        :
                                                                    this.state.thePIC.type=="Lead Pool"?
                                                                        this.state.thePIC.name
                                                                        :
                                                                        "Undefined"
                                                                    }
                                                                </span>
                                                        }
                                                        <Button color="secondary" style={{marginLeft:10}} 
                                                            onClick={()=>{
                                                                this.setState({
                                                                    selectMemberModal:true,
                                                                    editingField:"pic",
                                                                    selectMemberMode:"Lead Pool",
                                                                },()=>{
                                                                    this.getAllLeadPool()
                                                                })
                                                        }}>
                                                            Edit
                                                        </Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>Create Date</td>
                                                    <td style={styles.cellBorder}>{this.state.currentTicket.createDate}</td>
                                                </tr>
                                            </table>
                                            :
                                            <table style={{width:"100%"}}>
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket Number</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.id}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Member</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    "Member"
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    "Lead"
                                                    :
                                                    "Ghost"
                                                }
                                                </td>
                                            </tr>
                                            {
                                            this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Member Referrer</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.memberReferrer!=null?
                                                            this.state.currentTicket.memberReferrer
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                            {
                                            this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Member PIC</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.memberPIC!=null?
                                                            this.state.currentTicket.memberPIC
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                            <tr>
                                                <td style={styles.cellBorder}>Phone</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    this.state.currentTicket.target.username
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    this.state.currentTicket.lead.phone
                                                    :
                                                    null
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Name</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    this.state.currentTicket.target.name
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    this.state.currentTicket.lead.name
                                                    :
                                                    null
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Email</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    this.state.currentTicket.target.email
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    this.state.currentTicket.lead.email
                                                    :
                                                    null
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>
                                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                        <span>State</span>
                                                        {GLOBAL.checkPermission("B%3/gyd6")?
                                                            <Button color="primary" style={{padding:"2px 10px",fontSize:13,marginLeft:8}} onClick={()=>this.setState({toChangeState:this.state.currentTicket.state,editStateModal:true})}>Edit</Button>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </td>
                                                <td style={styles.cellBorder}>
                                                    {this.state.currentTicket.state && this.state.currentTicket.state.length > 0
                                                        ? this.state.currentTicket.state.map(state => state.name).join(", ")
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Channel</td>
                                                {
                                                GLOBAL.getChannelColor(this.state.currentTicket.channel)=="white"?
                                                    <td style={Object.assign({backgroundColor:GLOBAL.getChannelColor(this.state.currentTicket.channel),color:"black"},
                                                    styles.cellBorder)}>
                                                        {this.state.currentTicket.channel}
                                                    </td>
                                                    :
                                                    <td style={Object.assign({backgroundColor:GLOBAL.getChannelColor(this.state.currentTicket.channel),color:"white"},
                                                    styles.cellBorder)}>
                                                        {this.state.currentTicket.channel}
                                                    </td>

                                                }
                                            </tr>
                                            {
                                            this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Badge</td>
                                                    <td style={styles.cellBorder}>{this.getBadgeString(this.state.currentTicket.target.badge)}</td>
                                                
                                                </tr>
                                                :
                                                null
                                            }
                                            <tr>
                                                <td style={styles.cellBorder}>Interest</td>
                                                <td style={styles.cellBorder}>
                                                    {this.state.currentTicket.interest}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket Referrer</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.referrerString}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket PIC</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.picString}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Create Date</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.createDate}</td>
                                            </tr>
                                        </table>
                                       }
                                       <div>
                                            {
                                            GLOBAL.checkPermission("C@mjfL[X")?
                                                <div>
                                                    {this.state.editField ? (
                                                        // If editField state is true, render the Save and Cancel buttons:
                                                        <div style={{ textAlign: "center", marginTop: 5 }}>
                                                            <div>
                                                                <Button color="primary" style={{ marginRight: 30, width: 70 }} onClick={() => this.saveFieldTicket()}>Save</Button>
                                                                <Button color="primary" style={{}} onClick={() => this.setState({ editField: false })}>Cancel</Button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{textAlign:"center",marginTop:5}}>
                                                        {/* If editField state is false, render the Edit button: */}
                                                        <Button color="primary" style={{ right: 0, padding: "5px 10px"}} onClick={this.editFieldMode}>
                                                            Edit
                                                        </Button>
                                                        </div>
                                                    )}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",gap:10,flex:1}}>
                                        <div>
                                            <table>
                                                <tr>
                                                    <td style={styles.cellBorder}>Status</td>
                                                    <td style={Object.assign({backgroundColor:GLOBAL.getStatusColor(this.state.currentTicket.status),
                                                        color:GLOBAL.getStatusColor(this.state.currentTicket.status)=="yellow"?"black":"white"},
                                                        styles.cellBorder)}>
                                                        {this.state.currentTicket.status}
                                                    </td>
                                                    {
                                                    this.state.currentTicket.status=="Task Terminated"||this.state.currentTicket.status=="他人完成"||this.state.currentTicket.status=="完成报名"?
                                                        null
                                                        :
                                                        <td style={styles.cellBorder}>
                                                            <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                                                this.setState({
                                                                    updateStatusModal:true,
                                                                    selectedUpdateStatus:this.state.currentTicket.status,
                                                                    updateStatusLog:"",
                                                                    })
                                                                }}>
                                                                Update Status
                                                            </Button>
                                                        </td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>
                                                        Last Update
                                                    </td>
                                                    <td colspan={2} style={styles.cellBorder}>
                                                        {this.state.currentTicket.lastUpdate}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>
                                                        Expiry
                                                    </td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.expiryDate!=null?
                                                            this.state.currentTicket.expiryDate
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                    <td style={styles.cellBorder}>
                                                        <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                                            this.getTicketHistory()
                                                        }}>
                                                            Ticket History
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div style={{flex:0,display:"flex",flexDirection:"column",gap:5,width:"100%"}}>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10,flexWrap:"nowrap"}}>
                                                <div style={{whiteSpace:"nowrap"}}>Ticket Log</div>
                                                <Button color="primary" style={{padding:"2px 10px",fontSize:13,whiteSpace:"nowrap"}} onClick={()=>{
                                                    this.setState({ticketLogModal:true})
                                                }}>
                                                    New Log
                                                </Button>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",gap:5,alignItems:"center"}}>
                                                <Input style={{flex:"1",padding:3,fontSize:14}}
                                                    value={this.state.ticketLogSearch} onChange={(e)=>{
                                                        this.setState({ticketLogSearch:e.target.value})
                                                    }}
                                                    onKeyDown={(e)=>{
                                                        if(e.keyCode==13){
                                                            e.preventDefault()
                                                            this.getTicketLogList(this.state.currentTicket.id)
                                                        }
                                                    }}/>
                                                <Button color="primary" style={{padding:"5px 10px",fontSize:14}} onClick={()=>{this.getTicketLogList(this.state.currentTicket.id)}} >
                                                    Search
                                                </Button>
                                            </div>
                                        </div>
                                        <div style={{marginBottom:20,paddingLeft:0}}>
                                            {this.state.theLogsCount} results found.
                                            <Button color="danger" style={{fontSize: '13px',marginLeft:5,width:60,height:20,textAlign: 'center',padding: 0}} onClick={()=>{this.setState({ticketLogSearch:""}, ()=>{ this.getTicketLogList(this.state.currentTicket.id); })}}>Cancel</Button>
                                                {
                                                this.state.ticketLogModal==true?
                                                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                                                        <div style={{fontWeight:"bold"}}>New Ticket Log</div>
                                                        <div style={{width:"100%",marginTop:10,padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                                            <Editor style={{}}
                                                                editorState={this.state.editorState}
                                                                onEditorStateChange={this.onEditorStateChange}
                                                                toolbar={{
                                                                    options:['inline','image','fontSize','emoji','history'],
                                                                    inline:{
                                                                        options: ['bold', 'italic', 'underline'],
                                                                    },
                                                                    image:{
                                                                        uploadEnabled:true,
                                                                        uploadCallback:this.imageUploadCallback,
                                                                        previewImage: true,
                                                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                                        defaultSize: {
                                                                            height: 300,
                                                                            width: 300,
                                                                        },
                                                                    }
                                                                }}
                                                                />
                                                        </div>
                                                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:10}}>
                                                            <Button color="primary" style={{flex:1,}} onClick={()=>{
                                                                this.post()
                                                            }}>
                                                                Post
                                                            </Button>
                                                            <Button color="light" style={{flex:1,backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                                                this.setState({ticketLogModal:false})
                                                            }}>
                                                                Close
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5,marginTop:10}}>
                                                {
                                                this.state.currentLogList.length>0?
                                                    this.state.currentLogList.map((ticketLog,index)=>{
                                                        return(
                                                            <div style={{width:"100%",display:"flex",flexDirection:"column",
                                                                border:"1px solid lightgrey",borderRadius:10,padding:10}}>
                                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                                    <div style={{fontWeight:"bold",fontSize:12}}>
                                                                        {
                                                                        ticketLog.loggerString!=null?
                                                                            ticketLog.loggerString
                                                                            :
                                                                            "System"
                                                                        }
                                                                    </div>
                                                                    <div style={{display:"flex",flexDirection:"row",gap:7,alignItems:"center",fontSize:12}}>
                                                                        {
                                                                        ticketLog.relatedStatus!=null&&ticketLog.relatedStatus!=""?
                                                                            <div style={{backgroundColor:GLOBAL.getStatusColor(ticketLog.relatedStatus),padding:"2px 7px",borderRadius:5,
                                                                                color:GLOBAL.getStatusColor(ticketLog.relatedStatus)=="yellow"?"black":"white"}}>
                                                                                {ticketLog.relatedStatus}
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {(reactLocalStorage.get("job") === "Sales Supervisor" ||reactLocalStorage.get("username") === this.state.username[index][0])&&(                                                                    
                                                                            <div>
                                                                                <Button color="danger" style={{fontSize:10,padding:"0px 0px 15px 0px",width:50,height:15}} onClick={()=>{
                                                                                    if(window.confirm("Delete the ticket log \nAre you sure?")){
                                                                                        this.deleteTicketLog(this.state.currentTicket.id,ticketLog.id)
                                                                                    }                                                                                                                                                                                                      
                                                                            }}>
                                                                                Delete
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                        <div style={{fontSize:10}}>{ticketLog.createDate}</div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {
                                                                    typeof ticketLog.log !='string'?
                                                                        <Editor
                                                                            editorState={ticketLog.log}
                                                                            readOnly={true}
                                                                            toolbarHidden={true}
                                                                            />
                                                                    :
                                                                        ticketLog.log.split("\n").map((line)=>{
                                                                            return(
                                                                                <div style={{width:"100%" ,fontSize:12}}>
                                                                                    {line.replace("\t","&nbsp;&nbsp;")}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div style={{padding:20,width:"100%",color:"grey",border:"1px solid grey",borderRadius:10}}>
                                                    No Ticket Log
                                                </div>
                                                }
                                                {
                                                this.state.totalTicketLogHistoryPages > 0 ?
                                                    <div>
                                                        <ReactPaginate
                                                            pageCount={this.state.totalTicketLogHistoryPages}
                                                            previousLabel={'<'}
                                                            nextLabel={'>'}
                                                            pageRangeDisplayed={3}
                                                            marginPagesDisplayed={1}
                                                            containerClassName={"pagination justify-content-center"}
                                                            pageClassName={"page-item"}
                                                            pageLinkClassName={"page-link"}
                                                            breakLabel={"..."}
                                                            breakClassName={"page-item"}
                                                            breakLinkClassName={"page-link"}
                                                            previousClassName={"page-item"}
                                                            previousLinkClassName={"page-link"}
                                                            nextClassName={"page-item"}
                                                            nextLinkClassName={"page-link"}
                                                            activeClassName={"active"}
                                                            disabledClassName={"disabled"}
                                                            forcePage={this.state.currentTicketLogPage}
                                                            onPageChange={async ({selected}) => {
                                                                this.getTicketLogList(this.state.currentTicket.id,selected);
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Spinner color="light" style={{width:80,height:80}}/>
                            }
                        </div>
                    </div>
                    :
                    this.state.viewMode=="statistics"?
                        <div style={{width:"100%",display:"flex",flexDirection:"column",width:"100%",gap:10,padding:"20px 20px"}}>
                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:2,border:"1px solid lightgrey",borderRadius:5,
                                    padding:"2px 10px",flexWrap:"nowrap",cursor:"pointer"}}
                                    onClick={()=>{
                                        history.push("/ticket")
                                        this.setState({viewMode:"dashboard"})
                                }}>
                                    <IoMdArrowRoundBack />
                                    Back to Dashboard
                                </div>

                                <div style={{marginLeft:30,fontWeight:"bold"}}>Ticket Statistics</div>
                            </div>

                            {this.generateSearchArea()}
                            {this.generateOperationArea()}

                            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"space-between",padding:"10px 0px 5px 10px",gap:10}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"wrap"}}>
                                    <div>Filter by:</div>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Ticket Number",filterTicketNumber:""})
                                    }}>
                                        Ticket Number
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Client Phone",filterClientPhone:""})
                                    }}>
                                        Client Phone
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Client Name",filterClientName:""})
                                    }}>
                                        Client Name
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Client Email",filterClientEmail:""})
                                    }}>
                                        Client Email
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"State",filterState:[]})
                                    }}>
                                        State
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Channel",filterChannel:[]})
                                    }}>
                                        Channel
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Interest",filterInterest:""})
                                    }}>
                                        Interest
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Last Update",filterLastUpdateFrom:"",filterLastUpdateTo:"",})
                                    }}>
                                        Last Update
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Create Date",filterCreateDateFrom:"",filterCreateDateTo:""})
                                    }}>
                                        Create Date
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Referrer Phone",filterReferrerPhone:""})
                                    }}>
                                        Referrer Phone
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"PIC Phone",filterPICPhone:""})
                                    }}>
                                        PIC Phone
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Status",selectedStatusList:[]})
                                    }}>
                                        Status
                                    </Button>
                                    <Button color="light" style={styles.filterButtonStyle} onClick={()=>{
                                        this.setState({filterModal:true,filterMode:"Member",filterMember:""})
                                    }}>
                                        Member
                                    </Button>
                                </div>
                                <div style={{display:"flex",flexDirection:"row",gap:5}}>
                                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"maroon",color:"white"}}
                                        onClick={()=>{
                                            this.setState({findField:"Status:还没联系,等待回复,安排预约,已经回复,有兴趣,购买课程"},()=>{
                                                this.searchTicket()
                                            })
                                    }}>
                                        Hot
                                    </Button>
                                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"yellow",color:"black"}}
                                        onClick={()=>{
                                            this.setState({findField:"Status:安排汇款,预付定金,分期付款,全额付款"},()=>{
                                                this.searchTicket()
                                            })
                                    }}>
                                        Payment
                                    </Button>
                                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"steelblue",color:"white"}}
                                        onClick={()=>{
                                            this.setState({findField:"Status:不精准客户,无法联系,没兴趣"},()=>{
                                                this.searchTicket()
                                            })
                                    }}>
                                        Cold
                                    </Button>
                                    <Button color="light" style={{padding:"2px 10px",border:"1px solid black",fontSize:13,backgroundColor:"grey",color:"white"}}
                                        onClick={()=>{
                                            this.setState({findField:"Status:完成报名,他人完成,Task Terminated"},()=>{
                                                this.searchTicket()
                                            })
                                    }}>
                                        Closed
                                    </Button>
                                </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                {
                                this.state.statisticsList.map((category)=>{
                                    return(
                                        <div style={{display:"flex",flexDirection:"column"}}>
                                            <table style={{padding:"10px 10px"}}>
                                                <tr>
                                                    <td colspan={3} style={{fontSize:16,border:"1px solid lightgrey",color:"maroon",
                                                        padding:"10px 10px 5px 10px",fontWeight:"bold"}}>
                                                        {category.title}
                                                    </td>
                                                </tr>
                                                {
                                                category.statusList.map((stat)=>{
                                                    return(
                                                        <tr>
                                                            <td style={Object.assign({backgroundColor:GLOBAL.getStatusColor(stat.title),color:GLOBAL.getStatusColor(stat.title)=="yellow"?"black":"white"},styles.cellBorder)}>
                                                                {stat.title}
                                                            </td>
                                                            <td style={Object.assign({textAlign:"right"},styles.cellBorder)}>{stat.count}</td>
                                                            <td style={styles.cellBorder}>
                                                                <AiFillClockCircle style={{cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                                    this.getTicketAgeReport([stat.title])
                                                                }}/>
                                                                <AiOutlineSearch style={{marginLeft:5,cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                                    this.setState({
                                                                        filterMode:"Status",
                                                                        selectedStatusList:[stat.title,],
                                                                    },()=>{
                                                                        window.open("/ticket?search="+encodeURI(this.cleanFilter("Status")),"_blank")
                                                                    })
                                                                }}/>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                                <tr>
                                                    <td style={styles.cellBorder}>Total</td>
                                                    <td style={Object.assign({textAlign:"right"},styles.cellBorder)}>{category.total}</td>
                                                    <td style={styles.cellBorder}>
                                                        <AiFillClockCircle style={{cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                            let statArray=[]
                                                            for(let stat of category.statusList){
                                                                statArray.push(stat.title)
                                                            }
                                                            this.getTicketAgeReport(statArray)
                                                        }}/>
                                                        <AiOutlineSearch style={{marginLeft:5,cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                            let statArray=[]
                                                            for(let stat of category.statusList){
                                                                statArray.push(stat.title)
                                                            }
                                                            this.setState({
                                                                filterMode:"Status",
                                                                selectedStatusList:statArray,
                                                            },()=>{
                                                                window.open("/ticket?search="+encodeURI(this.cleanFilter("Status")),"_blank")
                                                            })
                                                        }}/>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :
                        null
                    :
                    //desktop
                    this.state.viewMode=="dashboard"?
                        <div style={{width:"100%",padding:"20px 20px",gap:10,display:"flex",flexDirection:"column",width:"100%",fontSize:14}}>
                            {this.generateSearchArea()}
                            {this.generateOperationArea()}
                            {this.generateFilterArea()}

                            <table style={{width:"100%",fontSize:13}}>
                                <tr>
                                    <td style={styles.cellBorder}>Tix Num.</td>
                                    <td style={styles.cellBorder}>Phone</td>
                                    <td style={styles.cellBorder}>Name</td>
                                    <td style={styles.cellBorder}>Email</td>
                                    <td style={styles.cellBorder}>Member</td>
                                    <td style={styles.cellBorder}>State</td>
                                    <td style={styles.cellBorder}>Channel</td>
                                    <td style={Object.assign({width:"100%"},styles.cellBorder)}>Interest</td>
                                    <td style={styles.cellBorder}>Referrer</td>
                                    <td style={styles.cellBorder}>PIC</td>
                                    <td style={styles.cellBorder}>Status</td>
                                    <td style={styles.cellBorder}>Last Update</td>
                                    <td style={styles.cellBorder}>Create Date</td>
                                    <td style={styles.cellBorder}>Served</td>
                                    <td style={styles.cellBorder}>Remain</td>
                                    <td style={styles.cellBorder}></td>
                                </tr>
                                {
                                this.state.ticketList.map((ticket)=>{
                                    return(
                                        <tr>
                                            <td style={styles.cellBorder}>{ticket.id}</td>
                                            <td style={styles.cellBorder}>
                                                {
                                                ticket.lead!=null?
                                                    ticket.lead.phone
                                                    :
                                                ticket.target!=null?
                                                    ticket.target.username
                                                    :
                                                    null
                                                }
                                            </td>
                                            <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                                {
                                                ticket.lead!=null&&ticket.lead.name!=null?
                                                    ticket.lead.name
                                                    :
                                                ticket.target!=null?
                                                    ticket.target.name
                                                    :
                                                    null
                                                }
                                            </td>
                                            <td style={styles.cellBorder}>
                                                {
                                                ticket.lead!=null&&ticket.lead.email!=null?
                                                    ticket.lead.email
                                                    :
                                                ticket.target!=null&&ticket.target.email!=null?
                                                    ticket.target.email
                                                    :
                                                    null
                                                }
                                            </td>
                                            <td style={styles.cellBorder}>
                                                {
                                                ticket.lead!=null?
                                                    "Lead"
                                                    :
                                                ticket.target!=null?
                                                    "Member"
                                                    :
                                                    "Ghost"
                                                }
                                            </td>
                                            <td style={styles.cellBorder}>
                                                {ticket.state && ticket.state.length > 0
                                                    ? ticket.state.map(state => state.name).join(", ")
                                                    : "-"}
                                            </td>
                                            <td style={Object.assign({backgroundColor:GLOBAL.getChannelColor(ticket.channel),color:"white"},styles.cellBorder)}>
                                                {ticket.channel}
                                            </td>
                                            <td style={styles.cellBorder}>
                                                {ticket.interest}
                                            </td>
                                            <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                                {ticket.referrerString}
                                            </td>
                                            <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                                {ticket.picString!="None"?ticket.picString:"Lead Pool"}
                                            </td>
                                            <td style={Object.assign({backgroundColor:GLOBAL.getStatusColor(ticket.status),
                                                color:GLOBAL.getStatusColor(ticket.status)=="yellow"?"black":"white",whiteSpace:"nowrap"},
                                                styles.cellBorder)}>
                                                {ticket.status}
                                            </td>
                                            <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                                {ticket.lastUpdate}
                                            </td>
                                            <td style={Object.assign({whiteSpace:"nowrap"},styles.cellBorder)}>
                                                {ticket.createDate}
                                            </td>
                                            <td style={Object.assign({whiteSpace:"nowrap",textAlign:"right"},styles.cellBorder)}>
                                                {ticket.served}d
                                            </td>
                                            <td style={Object.assign({whiteSpace:"nowrap",textAlign:"right"},styles.cellBorder)}>
                                                {ticket.remain!=null?
                                                    <div>{ticket.remain}d</div>
                                                    :
                                                    <div>-</div>
                                                }
                                            </td>
                                            <td style={styles.cellBorder}>
                                                <Button color="primary" style={{fontSize:14,padding:"5px 10px"}} onClick={()=>{
                                                    history.push("/ticket?id="+ticket.id)
                                                    this.viewTicketDetail(ticket.id)
                                                    this.getTicketLogList(ticket.id)
                                                }}>
                                                    View
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </table>

                            <ReactPaginate
                                pageCount={this.state.pageCount}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLabel={"..."}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                disabledClassName={"disabled"}
                                forcePage={this.state.page}
                                onPageChange={async ({selected}) => {
                                    this.setState({page:selected},()=>{
                                        this.searchTicket()
                                    })
                                }}
                            />
                        </div>
                    :
                    this.state.viewMode=="detail"?
                        <div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <div style={{width:"100%",padding:"20px 20px",maxWidth:1200}}>
                            <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",marginBottom:10,gap:10}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:2,border:"1px solid lightgrey",borderRadius:5,
                                    padding:"2px 10px",flexWrap:"nowrap",cursor:"pointer"}}
                                    onClick={()=>{
                                        history.push("/ticket")
                                        this.setState({viewMode:"dashboard",editField: false},()=>{this.searchTicket()})
                                }}>
                                    <IoMdArrowRoundBack />
                                    Back to Dashboard
                                </div>
                                {GLOBAL.checkPermission("h4@}a=jU")?                                                                   
                                    <div>
                                        <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                            this.setState({
                                                selectedAgentPhone: this.state.currentTicket.picString.split(' ')[0],
                                            },()=>{this.getAgentAndLeaderList()})
                                        }}>
                                            Transfer Ticket
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {
                            this.state.currentTicket!=null?
                                <div style={{gap:20,display:"flex",flexDirection:"row",justifyContent:"flex-start",width:"100%"}}>
                                    <div style={{maxWidth:450}}>
                                    {
                                    this.state.editField?
                                    <table style={{}}>
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket Number</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.id}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Member</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    "Member"
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    "Lead"
                                                    :
                                                    "Ghost"
                                                }
                                                </td>
                                            </tr>
                                            {
                                            this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Member Referrer</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.memberReferrer!=null?
                                                            this.state.currentTicket.memberReferrer
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                            {
                                            this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Member PIC</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.memberPIC!=null?
                                                            this.state.currentTicket.memberPIC
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                            <tr>
                                                <td style={styles.cellBorder}>Phone</td>
                                                <td style={styles.cellBorder}>
                                                    {
                                                    this.state.currentTicket.target!=null?
                                                        // <Input value={this.state.toChangeCurrentTicket.target.username} onChange={(e)=>{
                                                        //     var newDetail=this.state.toChangeCurrentTicket
                                                        //     newDetail.target.username=e.target.value
                                                        //     this.setState({toChangeCurrentTicket:newDetail})}}/>
                                                        this.state.currentTicket.target.username
                                                    :
                                                    this.state.currentTicket.lead!=null?
                                                        <Input value={this.state.toChangeCurrentTicket.lead.phone}
                                                        onChange={(e)=>{
                                                        var newDetail=this.state.toChangeCurrentTicket
                                                        var modifiedField = {...this.state.modified}
                                                        newDetail.lead.phone=e.target.value
                                                        // Use the correct syntax for updating the modifiedField state
                                                        if (newDetail.lead.phone !== this.state.currentTicket.lead.phone) {
                                                            modifiedField.phone = true
                                                            } else {
                                                            modifiedField.phone = false
                                                            }

                                                            // Update both newDetail and modifiedField in the state
                                                            this.setState({
                                                            toChangeCurrentTicket: newDetail,
                                                            modified: modifiedField,
                                                            })
                                                        }}
                                                        style={{

                                                            color: this.state.modified.phone?"blue":"black", // Set the font color, you can adjust it accordingly
                                                        }}
                                                        />
                                                    :
                                                    null
                                                    }
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Name</td>
                                                <td style={styles.cellBorder}>
                                                    {
                                                    this.state.currentTicket.target!=null?
                                                        // <Input value={this.state.toChangeCurrentTicket.target.name}  onChange={(e)=>{
                                                        // var newDetail=this.state.toChangeCurrentTicket
                                                        // newDetail.target.name=e.target.value
                                                        // this.setState({toChangeCurrentTicket:newDetail})}}/>
                                                        this.state.currentTicket.target.name
                                                    :
                                                    this.state.currentTicket.lead!=null?
                                                        <Input value={this.state.toChangeCurrentTicket.lead.name}  onChange={(e)=>{
                                                        var newDetail=this.state.toChangeCurrentTicket
                                                        var modifiedField = {...this.state.modified}
                                                        newDetail.lead.name=e.target.value
                                                        // Use the correct syntax for updating the modifiedField state
                                                        if (newDetail.lead.name !== this.state.currentTicket.lead.name) {
                                                            modifiedField.name = true
                                                            } else {
                                                            modifiedField.name = false
                                                            }

                                                            // Update both newDetail and modifiedField in the state
                                                            this.setState({
                                                            toChangeCurrentTicket: newDetail,
                                                            modified: modifiedField,
                                                            })
                                                        }}
                                                        style={{

                                                            color: this.state.modified.name?"blue":"black", // Set the font color, you can adjust it accordingly
                                                        }}
                                                        />
                                                    :
                                                    null
                                                    }
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Email</td>
                                                <td style={styles.cellBorder}>
                                                    {
                                                    this.state.currentTicket.target!=null?
                                                        // <Input value={this.state.toChangeCurrentTicket.target.email} onChange={(e)=>{
                                                        // var newDetail=this.state.toChangeCurrentTicket
                                                        // newDetail.target.email=e.target.value
                                                        // this.setState({toChangeCurrentTicket:newDetail})}}/>
                                                        this.state.currentTicket.target.email
                                                    :
                                                    this.state.currentTicket.lead!=null?
                                                        <Input value={this.state.toChangeCurrentTicket.lead.email} onChange={(e)=>{
                                                        var newDetail=this.state.toChangeCurrentTicket
                                                        var modifiedField = {...this.state.modified}
                                                        newDetail.lead.email=e.target.value
                                                        // Use the correct syntax for updating the modifiedField state
                                                        if (newDetail.lead.email !== this.state.currentTicket.lead.email) {
                                                            modifiedField.email = true
                                                            } else {
                                                            modifiedField.email = false
                                                            }

                                                            // Update both newDetail and modifiedField in the state
                                                            this.setState({
                                                            toChangeCurrentTicket: newDetail,
                                                            modified: modifiedField,
                                                            })
                                                        }}
                                                        style={{

                                                            color: this.state.modified.email?"blue":"black", // Set the font color, you can adjust it accordingly
                                                        }}
                                                        />
                                                    :
                                                    null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>State</td>
                                                <td style={styles.cellBorder}>
                                                    <span style={{color:this.state.modified.state?"blue":"black"}}>
                                                        {this.state.toChangeCurrentTicket.state && this.state.toChangeCurrentTicket.state.length > 0
                                                            ? this.state.toChangeCurrentTicket.state.map(state => state.name).join(", ")
                                                            : "-"}
                                                    </span>
                                                    <Button color='secondary' style={{marginLeft:10}} onClick={()=>this.setState({selectStateModal:true})}>Edit</Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Channel</td>
                                                <td style={Object.assign({backgroundColor:GLOBAL.getChannelColor(this.state.toChangeCurrentTicket.channel),color:"white"},
                                                    styles.cellBorder)}>
                                                   {/* <Input value={this.state.toChangeCurrentTicket.channel} placeholder={"Facebook/WhatsApp/小红书/线下课/Internal"} onChange={(e)=>{
                                                    var newDetail=this.state.toChangeCurrentTicket
                                                    newDetail.channel=e.target.value
                                                    this.setState({toChangeCurrentTicket:newDetail})}}/> */}
                                                    {this.state.toChangeCurrentTicket.channel}
                                                    <Button color='secondary' style={{marginLeft:10}} onClick={()=>this.setState({selectChannelModal:true})}>Edit</Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Interest</td>
                                                <td style={styles.cellBorder}>
                                                <Input
                                                    value={this.state.toChangeCurrentTicket.interest}
                                                    onChange={(e) => {
                                                        var newDetail = this.state.toChangeCurrentTicket
                                                        var modifiedField = {...this.state.modified}
                                                        newDetail.interest = e.target.value

                                                        // Use the correct syntax for updating the modifiedField state
                                                        if (newDetail.interest !== this.state.currentTicket.interest) {
                                                        modifiedField.interest = true
                                                        } else {
                                                        modifiedField.interest = false
                                                        }

                                                        // Update both newDetail and modifiedField in the state
                                                        this.setState({
                                                        toChangeCurrentTicket: newDetail,
                                                        modified: modifiedField,
                                                        })
                                                    }}
                                                    style={{
                                                        color: this.state.modified.interest?"blue":"black", // Set the font color, you can adjust it accordingly
                                                    }}
                                                    />
                                                </td>
                                            </tr>
                                            {
                                                this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Badge</td>
                                                    <td style={styles.cellBorder}>{this.getBadgeString(this.state.currentTicket.target.badge)}</td>
                                                
                                                </tr>
                                                :
                                                null
                                            }
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket Referrer</td>
                                                <td style={styles.cellBorder}>
                                                    {
                                                    this.state.theReferrer.length === 0 ?
                                                        this.state.currentTicket.referrerString
                                                        :
                                                        <span style={{color:"blue"}}>
                                                            {
                                                            this.state.theReferrer.username==this.state.theReferrer.name?
                                                                this.state.theReferrer.name
                                                                :
                                                                this.state.theReferrer.username+" "+this.state.theReferrer.name
                                                            }
                                                        </span>
                                                    }
                                                    <Button color="secondary" style={{marginLeft:10}} 
                                                        onClick={()=>{
                                                            this.setState({
                                                                selectMemberModal:true,
                                                                editingField:"referrer",
                                                                selectMemberMode:"Member",
                                                            },()=>{
                                                                this.getAllMember()
                                                            })
                                                        }}>
                                                            Edit
                                                        </Button>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket PIC</td>
                                                <td style={styles.cellBorder}>
                                                    {
                                                    this.state.thePIC.length === 0 ?
                                                        this.state.currentTicket.picString
                                                        :
                                                        this.state.thePIC === "Lead Pool" ?
                                                            <span style={{color: "blue"}}>Lead Pool</span>
                                                            :
                                                            <span style={{color: "blue"}}>
                                                                {
                                                                this.state.thePIC.type=="Member"?
                                                                    this.state.thePIC.username + " " + this.state.thePIC.name
                                                                    :
                                                                this.state.thePIC.type=="Lead Pool"?
                                                                    this.state.thePIC.name
                                                                    :
                                                                    "Undefined"
                                                                }
                                                            </span>
                                                    }
                                                    <Button color="secondary" style={{marginLeft:10}}
                                                        onClick={()=>{
                                                            this.setState({
                                                                selectMemberModal:true,
                                                                editingField:"pic",
                                                                selectMemberMode:"Lead Pool",
                                                            },()=>{
                                                                this.getAllLeadPool()
                                                            })
                                                    }}>
                                                        Edit
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Create Date</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.createDate}</td>
                                            </tr>
                                        </table>
                                    :
                                        <table style={{}}>
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket Number</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.id}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Member</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    "Member"
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    "Lead"
                                                    :
                                                    "Ghost"
                                                }
                                                </td>
                                            </tr>
                                            {
                                            this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Member Referrer</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.memberReferrer!=null?
                                                            this.state.currentTicket.memberReferrer
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                            {
                                            this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Member PIC</td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.memberPIC!=null?
                                                            this.state.currentTicket.memberPIC
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                            <tr>
                                                <td style={styles.cellBorder}>Phone</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    this.state.currentTicket.target.username
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    this.state.currentTicket.lead.phone
                                                    :
                                                    null
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Name</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    this.state.currentTicket.target.name
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    this.state.currentTicket.lead.name
                                                    :
                                                    null
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Email</td>
                                                <td style={styles.cellBorder}>
                                                {
                                                this.state.currentTicket.target!=null?
                                                    this.state.currentTicket.target.email
                                                    :
                                                this.state.currentTicket.lead!=null?
                                                    this.state.currentTicket.lead.email
                                                    :
                                                    null
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>
                                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                        <span>State</span>
                                                        {GLOBAL.checkPermission("B%3/gyd6")?
                                                            <Button color="primary" style={{padding:"2px 10px",fontSize:13,marginLeft:8}} onClick={()=>this.setState({toChangeState:this.state.currentTicket.state,editStateModal:true})}>Edit</Button>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </td>
                                                <td style={styles.cellBorder}>
                                                    {this.state.currentTicket.state && this.state.currentTicket.state.length > 0
                                                        ? this.state.currentTicket.state.map(state => state.name).join(", ")
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Channel</td>
                                                {
                                                    GLOBAL.getChannelColor(this.state.currentTicket.channel)=="white"?
                                                    <td style={Object.assign({backgroundColor:GLOBAL.getChannelColor(this.state.currentTicket.channel),color:"black"},
                                                    styles.cellBorder)}>
                                                        {this.state.currentTicket.channel}
                                                    </td>
                                                    :
                                                    <td style={Object.assign({backgroundColor:GLOBAL.getChannelColor(this.state.currentTicket.channel),color:"white"},
                                                    styles.cellBorder)}>
                                                        {this.state.currentTicket.channel}
                                                    </td>

                                                }

                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Interest</td>
                                                <td style={styles.cellBorder}>
                                                    {this.state.currentTicket.interest}
                                                </td>
                                            </tr>
                                            {
                                                this.state.currentTicket.target!=null?
                                                <tr>
                                                    <td style={styles.cellBorder}>Badge</td>
                                                    <td style={styles.cellBorder}>{this.getBadgeString(this.state.currentTicket.target.badge)}</td>
                                                
                                                </tr>
                                                :
                                                null
                                            }
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket Referrer</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.referrerString}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Ticket PIC</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.picString}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.cellBorder}>Create Date</td>
                                                <td style={styles.cellBorder}>{this.state.currentTicket.createDate}</td>
                                            </tr>
                                        </table>
                                    }
                                    <div>
                                        {
                                        GLOBAL.checkPermission("C@mjfL[X")?
                                            <div>
                                                {this.state.editField ? (
                                                    // If editField state is true, render the Save and Cancel buttons:
                                                    <div style={{ textAlign: "center", marginTop: 5 }}>
                                                        <div>
                                                            <Button color="primary" style={{ marginRight: 30, width: 70 }} onClick={() => this.saveFieldTicket()}>Save</Button>
                                                            <Button color="primary" style={{}} onClick={() => this.setState({ editField: false })}>Cancel</Button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{textAlign:"center",marginTop:5}}>
                                                    {/* If editField state is false, render the Edit button: */}
                                                    <Button color="primary" style={{ right: 0, padding: "5px 10px"}} onClick={this.editFieldMode}>
                                                        Edit
                                                    </Button>
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                    <div style={{display:"flex",flexDirection:"column",gap:10,flex:1}}>
                                        <div>
                                            <table>
                                                <tr>
                                                    <td style={styles.cellBorder}>Status</td>
                                                    <td style={Object.assign({backgroundColor:GLOBAL.getStatusColor(this.state.currentTicket.status),
                                                        color:GLOBAL.getStatusColor(this.state.currentTicket.status)=="yellow"?"black":"white"},
                                                        styles.cellBorder)}>
                                                        {this.state.currentTicket.status}
                                                    </td>
                                                    {
                                                    this.state.currentTicket.status=="Task Terminated"||this.state.currentTicket.status=="他人完成"||this.state.currentTicket.status=="完成报名"?
                                                        null
                                                        :
                                                        <td style={styles.cellBorder}>
                                                            <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                                                this.setState({
                                                                    updateStatusModal:true,
                                                                    selectedUpdateStatus:this.state.currentTicket.status,
                                                                    updateStatusLog:"",
                                                                })
                                                            }}>
                                                                Update Status
                                                            </Button>
                                                        </td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>
                                                        Last Update
                                                    </td>
                                                    <td colspan={2} style={styles.cellBorder}>
                                                        {this.state.currentTicket.lastUpdate}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={styles.cellBorder}>
                                                        Expiry
                                                    </td>
                                                    <td style={styles.cellBorder}>
                                                        {
                                                        this.state.currentTicket.expiryDate!=null?
                                                            this.state.currentTicket.expiryDate
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                    <td style={styles.cellBorder}>
                                                        <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                                            this.getTicketHistory()
                                                        }}>
                                                            Ticket History
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div style={{flex:0,display:"flex",flexDirection:"column",gap:5,width:"100%"}}>
                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10,flexWrap:"nowrap"}}>
                                                <div style={{whiteSpace:"nowrap"}}>Ticket Log</div>
                                                <Button color="primary" style={{padding:"2px 10px",fontSize:13,whiteSpace:"nowrap"}} onClick={()=>{
                                                    this.setState({ticketLogModal:true})
                                                }}>
                                                    New Log
                                                </Button>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",gap:5,alignItems:"center"}}>
                                                <Input style={{flex:"1",padding:3,fontSize:14}}
                                                    value={this.state.ticketLogSearch} onChange={(e)=>{
                                                        this.setState({ticketLogSearch:e.target.value})
                                                    }}
                                                    onKeyDown={(e)=>{
                                                        if(e.keyCode==13){
                                                            e.preventDefault()
                                                            this.getTicketLogList(this.state.currentTicket.id)
                                                        }
                                                    }}/>
                                                <Button color="primary" style={{padding:"5px 10px",fontSize:14}} onClick={()=>{this.getTicketLogList(this.state.currentTicket.id)}} >
                                                    Search
                                                </Button>
                                            </div>
                                             </div>
                                        <div style={{marginBottom:20,paddingLeft:0}}>
                                        {this.state.theLogsCount} results found.
                                        <Button color="danger" style={{fontSize: '13px',marginLeft:5,width:60,height:20,textAlign: 'center',padding: 0}} 
                                            onClick={()=>{this.setState({ticketLogSearch:""}, ()=>{ this.getTicketLogList(this.state.currentTicket.id); })}}>Cancel</Button>
                                            {
                                            this.state.ticketLogModal==true?
                                                <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                                                    <div style={{fontWeight:"bold"}}>New Ticket Log</div>
                                                    <div style={{width:"100%",marginTop:10,padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                                        <Editor style={{}}
                                                            editorState={this.state.editorState}
                                                            onEditorStateChange={this.onEditorStateChange}
                                                            toolbar={{
                                                                image:{
                                                                    uploadEnabled:true,
                                                                    uploadCallback:this.imageUploadCallback,
                                                                    previewImage: true,
                                                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                                    defaultSize: {
                                                                        height: 300,
                                                                        width: 300,
                                                                    },
                                                                }
                                                            }}
                                                            />
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:10}}>
                                                        <Button color="primary" style={{flex:1,}} onClick={()=>{
                                                            this.post()
                                                        }}>
                                                            Post
                                                        </Button>
                                                        <Button color="light" style={{flex:1,backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                                            this.setState({ticketLogModal:false})
                                                        }}>
                                                            Close
                                                        </Button>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5,marginTop:10}}>
                                                {
                                                this.state.currentLogList.length>0?
                                                
                                                    this.state.currentLogList.map((ticketLog,index)=>{
                                                        return(
                                                            <div style={{width:"100%",display:"flex",flexDirection:"column",
                                                                border:"1px solid lightgrey",borderRadius:10,padding:10}}>
                                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                                    <div style={{fontWeight:"bold"}}>
                                                                        {
                                                                        ticketLog.loggerString!=null?
                                                                            ticketLog.loggerString
                                                                            :
                                                                            "System"
                                                                        }
                                                                    </div>
                                                                    <div style={{display:"flex",flexDirection:"row",gap:10,alignItems:"center"}}>
                                                                        {
                                                                        ticketLog.relatedStatus!=null&&ticketLog.relatedStatus!=""?
                                                                            <div style={{backgroundColor:GLOBAL.getStatusColor(ticketLog.relatedStatus),padding:"2px 7px",borderRadius:5,
                                                                                color:GLOBAL.getStatusColor(ticketLog.relatedStatus)=="yellow"?"black":"white"}}>
                                                                                {ticketLog.relatedStatus}
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {(reactLocalStorage.get("job") === "Sales Supervisor" ||reactLocalStorage.get("username") === this.state.username[index][0])&&(                                                                    
                                                                            <div>
                                                                                <Button color="danger" style={{fontSize:14,padding:"0px 0px 22px 0px",width:60,height:20}} onClick={()=>{
                                                                                    if(window.confirm("Delete the ticket log \nAre you sure?")){
                                                                                        this.deleteTicketLog(this.state.currentTicket.id,ticketLog.id)
                                                                                    }                                                                                                                                                                                                      
                                                                            }}>
                                                                                Delete
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                        <div>{ticketLog.createDate}</div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {
                                                                    typeof ticketLog.log != 'string'?
                                                                        <Editor
                                                                            editorState={ticketLog.log}
                                                                            readOnly={true}
                                                                            toolbarHidden={true}
                                                                            />
                                                                    :
                                                                        ticketLog.log.split("\n").map((line)=>{
                                                                            return(
                                                                                <div style={{width:"100%"}}>
                                                                                    {line.replace("\t","&nbsp;&nbsp;")}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>                                                              
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div style={{padding:20,width:"100%",color:"grey",border:"1px solid grey",borderRadius:10}}>
                                                        No Ticket Log
                                                    </div>
                                                }
                                                {
                                                    this.state.totalTicketLogHistoryPages > 0 ?
                                                        <div>
                                                            <ReactPaginate
                                                                pageCount={this.state.totalTicketLogHistoryPages}
                                                                previousLabel={'<'}
                                                                nextLabel={'>'}
                                                                pageRangeDisplayed={3}
                                                                marginPagesDisplayed={1}
                                                                containerClassName={"pagination justify-content-center"}
                                                                pageClassName={"page-item"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLabel={"..."}
                                                                breakClassName={"page-item"}
                                                                breakLinkClassName={"page-link"}
                                                                previousClassName={"page-item"}
                                                                previousLinkClassName={"page-link"}
                                                                nextClassName={"page-item"}
                                                                nextLinkClassName={"page-link"}
                                                                activeClassName={"active"}
                                                                disabledClassName={"disabled"}
                                                                forcePage={this.state.currentTicketLogPage}
                                                                onPageChange={async ({selected}) => {
                                                                    this.getTicketLogList(this.state.currentTicket.id,selected);
                                                                }}
                                                            />
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Spinner color="light" style={{width:80,height:80}}/>
                            }
                        </div>
                        </div>
                    :
                    this.state.viewMode=="statistics"?
                        <div style={{width:"100%",display:"flex",flexDirection:"column",width:"100%",gap:10,padding:10}}>
                            <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:2,border:"1px solid lightgrey",borderRadius:5,
                                    padding:"2px 10px",flexWrap:"nowrap",cursor:"pointer"}}
                                    onClick={()=>{
                                        history.push("/ticket")
                                        this.setState({viewMode:"dashboard"})
                                }}>
                                    <IoMdArrowRoundBack />
                                    Back to Dashboard
                                </div>
                                <div style={{marginLeft:30,fontWeight:"bold"}}>Ticket Statistics</div>
                            </div>
                            {this.generateSearchArea()}
                            {this.generateOperationArea()}
                            {this.generateFilterArea()}

                            <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                            {
                            this.state.statisticsList.map((category)=>{
                                return(
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <table style={{margin:"0px 0px"}}>
                                            <tr>
                                                <td colspan={3} style={{fontSize:16,border:"1px solid lightgrey",color:"maroon",
                                                    padding:"10px 10px 5px 10px",fontWeight:"bold"}}>
                                                    {category.title}
                                                </td>
                                            </tr>
                                            {
                                            category.statusList.map((stat)=>{
                                                return(
                                                    <tr>
                                                        <td style={Object.assign({backgroundColor:GLOBAL.getStatusColor(stat.title),color:GLOBAL.getStatusColor(stat.title)=="yellow"?"black":"white"},styles.cellBorder)}>
                                                            {stat.title}
                                                        </td>
                                                        <td style={Object.assign({textAlign:"right"},styles.cellBorder)}>{stat.count}</td>
                                                        <td style={styles.cellBorder}>
                                                            <AiFillClockCircle style={{cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                                this.getTicketAgeReport([stat.title])
                                                            }}/>
                                                            <AiOutlineSearch style={{marginLeft:5,cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                                this.setState({
                                                                    filterMode:"Status",
                                                                    selectedStatusList:[stat.title,],
                                                                },()=>{
                                                                    window.open("/ticket?search="+encodeURI(this.cleanFilter("Status")),"_blank")
                                                                })
                                                            }}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            <tr>
                                                <td style={styles.cellBorder}>Total</td>
                                                <td style={Object.assign({textAlign:"right"},styles.cellBorder)}>{category.total}</td>
                                                <td style={styles.cellBorder}>
                                                    <AiFillClockCircle style={{cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                        let statArray=[]
                                                        for(let stat of category.statusList){
                                                            statArray.push(stat.title)
                                                        }
                                                        this.getTicketAgeReport(statArray)
                                                    }}/>
                                                    <AiOutlineSearch style={{marginLeft:5,cursor:"pointer",width:25,height:25,color:"grey"}} onClick={()=>{
                                                        let statArray=[]
                                                        for(let stat of category.statusList){
                                                            statArray.push(stat.title)
                                                        }
                                                        this.setState({
                                                            filterMode:"Status",
                                                            selectedStatusList:statArray,
                                                        },()=>{
                                                            window.open("/ticket?search="+encodeURI(this.cleanFilter("Status")),"_blank")
                                                        })
                                                    }}/>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                )
                            })
                            }
                            </div>
                        </div>
                    :
                     null
                }
            </div>
        )
    }
}

Ticket = Radium(Ticket);

let styles = {
    cellBorder:{
        border:"1px solid lightgrey",
        padding:5,
        minWidth:60
    },
    filterButtonStyle:{
        fontSize:13,
        padding:"2px 10px",
        border:"1px solid grey"
    }
}
