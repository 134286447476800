import React from 'react'
import Radium from 'radium'
import {Input,Button,Table,Modal,Form} from 'reactstrap'
import axios from 'axios'
import qs from 'qs'
import history from '../history'
import {reactLocalStorage} from 'reactjs-localstorage'
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen'
import ReactPaginate from 'react-paginate'
import * as GLOBAL from './global.js'
import { TiTick } from "react-icons/ti"
import { FaBold } from 'react-icons/fa'

export default class Permissions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading:false,

            permissionsList: [],
            permissions: [], // Permissions assigned to the selected job type
            permissionCategory: [], // List of permission categories
            selectedPermissions: [],
            selectedCategory: '',
            showPermissionCategoryData: false,

            jobType:[],
            selectedJobType: '',
            jobId: 0,

            //kl part
            currentSelectedEmployeeJobId:0,
            currentSelectedEmployeeJobName:"",
            currentSelectedEmployeePhoneNum:0,
            currentSelectedEmployeeName:"",

            newPermissionModal:false,
			newPermissionTitle:"",
			newPermissionCategory:"",
			newPermissionDescription:"",
			newPermissionUrl:"",
            newPermissionSendToClient:"No",

            editPermissionId:0,
            editPermissionModal: false,
            editPermissionTitle:"",
            editPermissionCategory:"",
            editPermissionDescription:"",
            editPermissionUrl:"",
            permissionCode:"",
            editPermissionSendToClient:"",

            displayTable: null,
            employees: [],
            jobList:[],

            newEmployeeModal:false,
            newEmployee:[],
            newEmployeeJob:"",
            jobSelect:[],
            editJobModal:false,
            selectMemberModal:false,
            itemPerPage:10,
            page:0,
            newSeletectedEmployee:"",
            memberList:[],
            searchField:"",

            pagePermission:0,
            itemPerPagePermission:10,
            searchFieldPermission:"",

            editExtraJobModal:false,
            extraJobList:[],
            selectedExtraJobList:[],
            selectedEmployee:null,
        }
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
    }

    launchFunc = () => {
        if (!reactLocalStorage.get("token")) {
          history.push("./");
          return;
        }
      
        this.setState({ loading: true })
        Promise.all([
            this.loadAllPermissionData(),
            this.loadAllPermissionCategoryAndJobTypes()
        ])
        .then(()=> {
            this.setState({ loading: false })
          })
      }

    loadAllPermissionData = () => {
        this.setState({
            itemPerPagePermission: 10,
            loading: true,
        });

        const selectedJob = [];
        for (let i = 0; i < this.state.jobType.length; i++) {
            if (this.state.jobType[i].selected === true) {
                selectedJob.push(this.state.jobType[i].id);
            }
        }

        const payload = {
            category: this.state.selectedCategory,
            selectedJob: selectedJob,
            itemPerPage: this.state.itemPerPagePermission,
            page: this.state.pagePermission,
            searchField: this.state.searchFieldPermission,
        };
    
        return axios
            .post('sales/getallpermissions', payload, { timeout: 40000 })
            .then(permissionResponse => {
                this.setState({
                    permissionsList: permissionResponse.data.permissionList,
                    displayTable: "permissions",
                    page: permissionResponse.data.page,
                    totalpagePermission: permissionResponse.data.totalPages,
                    loading: false,
                });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 444) {
                        // Error message returned by the server
                        alert('Error loading permissions page data:\n' + error.response.data.message);
                        return;
                    }
                }
                // General error message directly from error object
                alert('Error loading data:\n' + error.message);
            });
    };    

    loadAllPermissionCategoryAndJobTypes = () => {
        return axios
            .post('sales/getallpermissioncategoryandjobtypes', qs.stringify({}), { timeout: 30000 })
            .then(categoryAndJobTypesResponse => {
                const { jobTypesData, permissionCategoryData } = categoryAndJobTypesResponse.data
                // Preserve selected job types and permission data
                const selectedJobTypeIds = this.state.jobType.filter(jobType => jobType.selected).map(jobType => jobType.id);
                const permissionsList = this.state.permissionsList;
                this.setState({
                    jobType: jobTypesData.map(jobType => ({
                        ...jobType,
                        selected: selectedJobTypeIds.includes(jobType.id) // Preserve selection state
                    })),
                    permissionCategory: permissionCategoryData,
                    displayTable: "permissions",
                    permissionsList: permissionsList // Preserve permission data
                })
                this.loadAllPermissionData()
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 444) {
                        // Error message returned by the server
                        alert('Error loading permissions page data:\n' + error.response.data.message)
                        return
                    }
                }
                // General error message directly from error object
                alert('Error loading data:\n' + error.message)
            })
    }

    createNewPermission = () => {
        this.setState({
            newPermissionModal:true,
            newPermissionTitle:"",
            newPermissionCategory:"",
            newPermissionDescription:"",
            newPermissionUrl:"",
            newPermissionSendToClient:"No",
        })
    }

    createNewPermissionFunc = () => {
        this.setState({loading:true})
        const payload = {
            title: this.state.newPermissionTitle,
            category: this.state.newPermissionCategory,
            description: this.state.newPermissionDescription,
            url: this.state.newPermissionUrl,
            jobType: this.state.selectedJobType,
            selectedCategory: this.state.selectedCategory,
            sendToClient: this.state.newPermissionSendToClient
        }

        axios
            .post('sales/createemployeejobpermission', qs.stringify(payload), { timeout: 30000 })
            .then(response => {
                alert('Permission created successfully')
                this.setState({
                    newPermissionModal: false,
                })
                this.loadAllPermissionData(() => {
                    this.setState({ loading: false })
                })
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 444) {
                        // Error message returned by the server
                        alert('Error creating new permission modal:\n' + error.response.data.message)
                        this.setState({ loading: false })
                        return
                    }
                }
                // General error message directly from error object
                alert('Create new permission modal error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    createNewEmployee=()=> {
        this.setState({
            newEmployeeModal:true,
        })
                  
    }

    getAllMember=()=>{
        this.setState({
            itemPerPage:10,
            loading:true,
        })
        const payload = {
            itemPerPage:this.state.itemPerPage,
            page:this.state.page,
            searchField:this.state.searchField,
        }
        axios
            .post('sales/getallmember',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                //parse the date
                for(var item of response.data.memberList){
                    var parsedDate=item.date_joined
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.date_joined=parsedDate
                }
                //parse the date
                for(var item of response.data.memberList){
                    var parsedDate=item.expirydate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.expirydate=parsedDate
                }

                this.setState({
                    memberList:response.data.memberList,
                    page:response.data.page,
                    memberCount:response.data.theMemberCount,
                    totalpage:response.data.totalPages,
                    loading:false,
                })
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 444) {
                        // Error message returned by the server
                        alert('Select Member modal error:\n' + error.response.data.message)
                        this.setState({ loading: false })
                        return
                    }
                }
                // General error message directly from error object
                alert('Select Member modal error:\n' + error.message)
                this.setState({ loading: false })
            })  
    }

    createNewEmployeeFunc = () => {
        const payload = {
            member: this.state.newEmployee.id,
            job: this.state.newEmployeeJob,
            username: this.state.newEmployee.username
            }
        axios
            .post('sales/createnewemployee', qs.stringify(payload), { timeout: 30000 })
            .then(async response => {
                this.setState({
                newEmployeeModal: false,
                })
                alert('New Employee added successfully')
                this.showEmployees()
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 444) {
                        // Error message returned by the server
                        alert('Add new employee modal error:\n' + error.response.data.message)
                        this.setState({ loading: false })
                        return
                    }
                }
                // General error message directly from error object
                alert('Add new employee modal error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    handleNewJobType=(e)=>{
        this.setState({newEmployeeJob:e.target.value})
    }

    editPermission=(permission)=>{
        this.setState({
            editPermissionId: permission.id,
            editPermissionTitle: permission.title,
            editPermissionCategory: permission.category,
            editPermissionDescription: permission.description,
            editPermissionUrl: permission.url,
            permissionCode: permission.code,
            editPermissionSendToClient: permission.sendToClient ? 'Yes' : 'No',
            editPermissionModal: true
        })
    }

    handleSubmitEditPermission=(e)=>{
        this.setState({loading:true})
        e.preventDefault()
        const payload = {
            id: this.state.editPermissionId,
            title: this.state.editPermissionTitle,
            category: this.state.editPermissionCategory,
            description: this.state.editPermissionDescription,
            url: this.state.editPermissionUrl,
            sendToClient: this.state.editPermissionSendToClient,
            selectedCategory: this.state.selectedCategory
        }
        axios
            .post('sales/updateemployeejobpermission', qs.stringify(payload),{timeout:30000})
            .then(response => {
                alert('Permission updated successfully')
                this.setState({
                    editPermissionModal: false,
                    editPermissionId: null,
                    editPermissionTitle: '',
                    editPermissionCategory: '',
                    editPermissionDescription: '',
                    editPermissionUrl: '',
                    editPermissionSendToClient: ''
                })
                this.loadAllPermissionData(() => {
                    this.setState({ loading: false })
                })
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 444) {
                        // Error message returned by the server
                        alert('Error updating changes for permission:\n' + error.response.data.message)
                        this.setState({ loading: false })
                        return
                    }
                }
                // General error message directly from error object
                alert('Error updating permission:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    deletePermission=(permissionId)=>{
        const confirmed = window.confirm("Are you sure you want to delete this permission?")
        if (confirmed) {
            this.setState({loading:true})
            const payload = {
                id: permissionId,
                category: this.state.selectedCategory
            }

            axios
                .post('sales/deleteemployeejobpermission', qs.stringify(payload),{timeout:30000}) //with extra timeout
                .then(response => {
                    alert('Permission deleted successfully')
                    this.loadAllPermissionData(() => {
                        this.setState({ loading: false })
                    })
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 444) {
                            // Error message returned by the server
                            alert('Error deleting selected permission:\n' + error.response.data.message)
                            this.setState({ loading: false })
                            return
                        }
                    }
                    // General error message directly from error object
                    alert('Error deleting permission:\n' + error.message)
                    this.setState({ loading: false })
                })
        }
    }

    deleteEmployee=(employeeId,member)=>{
        const confirmed = window.confirm("Are you sure you want to delete this employee " +"( "+ member.name +" ) ?" )
        if (confirmed) {
            const payload = {
                id: employeeId,
                username:member.username
            }
            axios
                .post('sales/deleteemployees', qs.stringify(payload),{timeout:30000})
                .then(response => {
                    alert('Employee is deleted successfully')
                    this.showEmployees()
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 444) {
                            // Error message returned by the server
                            alert('Error deleting selected employee:\n' + error.response.data.message)
                            this.setState({ loading: false })
                            return
                        }
                    }
                    // General error message directly from error object
                    alert('Error deleting employee:\n' + error.message)
                    this.setState({ loading: false })                  
                })
        }
    }

    handleCategorySelect = (category) => {
        this.setState({ selectedCategory: category, loading: true }, () => {
            // Load the permissions based on the selected category
            this.loadAllPermissionCategoryAndJobTypes()
        })
    }

    handleCheckboxChange = (permissionId, jobId) => {
        this.setState(prevState => {
            const updatedPermissionsList = prevState.permissionsList.map(permission => {
                if (permission.id === permissionId) {
                    const updatedSelectedJobs = [...permission.selected];
                    const index = updatedSelectedJobs.indexOf(jobId);
                    if (index !== -1) {
                        updatedSelectedJobs.splice(index, 1); // Remove jobId from selected jobs
                    } else {
                        updatedSelectedJobs.push(jobId); // Add jobId to selected jobs
                    }
                    return {
                        ...permission,
                        selected: updatedSelectedJobs
                    }
                }
                return permission;
            });
    
            // Update the permissions state to include the newly selected permission
            const updatedPermissions = updatedPermissionsList.filter(permission => permission.selected.length > 0);
    
            return {
                permissionsList: updatedPermissionsList,
                permissions: updatedPermissions,
            };
        }, () => {
            // Call the save function after updating the state
            const editedPermission = this.state.permissionsList.find(permission => permission.id === permissionId);
            if (editedPermission) {
                this.handleSavePermissions(editedPermission, jobId);
            }
        });
    }

    handleSavePermissions = (editedPermission, jobId) => {
        this.setState({ loading: true });
        const permissionId = editedPermission.id;
        const permissionSelected = editedPermission.selected.includes(jobId); // Check if jobId is included in selected jobs
    
        // Create the payload to be sent to the backend
        const payload = {
            jobId: jobId,
            permissionId: permissionId,
            permissionSelected: permissionSelected,
        };
    
        // Send a POST request to save the permissions
        axios
            .post('sales/savepermissionswithjob', qs.stringify(payload), {timeout:30000})
            .then(response => {
                // Handle success
                this.setState({ loading: false });
                // Update the permissions state to include the newly added permission
                if (permissionSelected) {
                    const newPermission = this.state.permissionsList.find(permission => permission.id === permissionId);
                    if (newPermission) {
                        this.setState(prevState => ({
                            permissions: [...prevState.permissions, newPermission]
                        }));
                    }
                }
            })
            .catch(error => {
                // Handle error
                this.setState({ loading: false });
                if (error.response) {
                    // Error message returned by the server
                    alert('Error saving permissions, please select a job type!' + error.response.data.message);
                    console.error('Error saving permissions:\n', error.response.data.message);
                } else {
                    // General error message directly from error object
                    alert('Error saving permissions, please select a job type!' + error.message);
                    console.error('Error saving permissions:\n', error.message);
                }
            });
    }

    showEmployees=()=> {
        this.setState({ loading: true })
        const payload={

        }
        axios
            .post('sales/getallemployees', qs.stringify(payload), {timeout: 40000}) //with extra timeout
            .then(async response=> {
                this.setState({
                    loading: false,
                    employees:response.data.employees,
                    jobList:response.data.jobList,
                })
            })
            .catch(error => {
                if(error.response){

                }
                alert('Error load employees:\n'+error)
                this.setState({ loading: false })
            })
            
    }

    editEmployee=(employeeID)=>{
        this.setState({
            loading:true
        })
        const payload={
            id:employeeID,
            newJob:this.state.editEmployeeJob,
            username:this.state.currentSelectedEmployeePhoneNum,
        }
        axios
            .post('sales/editemployeejob',qs.stringify(payload),{timeout:30000})
            .then(async response=>{
                alert('Successful edit selected employee')
                this.showEmployees(()=>this.setState({loading:false}))
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status===444){
                        alert('Error editing selected employee:\n' + error.response.data.message)
                        this.setState({
                            loading:false,
                        })
                        return
                    }
                }
            })
    }

    getExtraJobList=()=>{
        this.setState({
            loading:true
        })
        const payload={
            employeeID:this.state.selectedEmployee.id,
        }
        axios
            .post('sales/getextrajoblist',qs.stringify(payload),{timeout:30000})
            .then(async response=>{
                this.setState({
                    editExtraJobModal:true,
                    selectedExtraJobList:response.data.selectedExtraJobList,
                    extraJobList:response.data.extraJobList,
                    loading:false,
                })
            })
            .catch(error =>{
                if(error.response){
                    
                }
                alert('Error get extra job list\n'+error)
                this.setState({loading:false})
            })
    }

    saveExtraJob=()=>{
        this.setState({
            loading:true
        })
        const payload={
            employeeID:this.state.selectedEmployee.id,
            selectedExtraJobList:JSON.stringify(this.state.selectedExtraJobList),
        }
        axios
            .post('sales/saveextrajob',qs.stringify(payload),{timeout:30000})
            .then(async response=>{
                this.setState({editExtraJobModal:false})
                this.showEmployees()
            })
            .catch(error =>{
                if(error.response){
                    
                }
                alert('Error save extra job list\n'+error)
                this.setState({loading:false})
            })
    }

    generateExtraJobButton=(extraJob)=>{
        //check if this extraJob is selected
        let foundIndex=null
        for(let [index,job] of this.state.selectedExtraJobList.entries()){
            if(job.id==extraJob.id){
                foundIndex=index
                break
            }
        }
        return(
            <Button color="light" style={{border:"1px solid lightgrey",display:"flex",flexDirection:"row",alignItems:"center",gap:2}} 
                onClick={()=>{
                    let cloneSelectList=this.state.selectedExtraJobList
                    if(foundIndex==null){
                        cloneSelectList.push(extraJob)
                    }else{
                        cloneSelectList.splice(foundIndex,1)
                    }
                    this.setState({selectedExtraJobList:cloneSelectList})
            }}>
                {
                foundIndex!=null?
                    <TiTick style={{color:"green",fontSize:20}}/>
                    :
                    null
                }
                {extraJob.jobName}
            </Button>
        )                         
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }
                <Modal style={{maxWidth:600}} isOpen={this.state.newPermissionModal} toggle={()=>this.setState({newPermissionModal:false})}>
                    <div style={{padding:20}}>
                        <div>Create new permission</div>
                        <Form onSubmit={(e)=>{
                            e.preventDefault()
                            this.createNewPermissionFunc()
                        }}>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div>
                                    Title:
                                </div>
                                <Input type="text" required style={{}} value={this.state.newPermissionTitle} onChange={(e)=>{
                                    this.setState({newPermissionTitle:e.target.value})
                                }} />
                                <div>
                                    Category:
                                </div>
                                <select required style={{height:35}} value={this.state.newPermissionCategory} onChange={(e) => {
                                    this.setState({ newPermissionCategory: e.target.value })
                                }}>
                                    <option value="">Select a category</option>
                                    {
                                    this.state.permissionCategory.map((category) => ( 
                                        <option key={category.category} value={category.category}>
                                            {category.title}
                                        </option>
                                    ))
                                    }
                                </select>
                                <div>
                                    Description:
                                </div>
                                <Input type="text" style={{}} value={this.state.newPermissionDescription} onChange={(e)=>{
                                    this.setState({newPermissionDescription:e.target.value})
                                }} />
                                <div>
                                    Url:
                                </div>
                                <Input type="text" placeholder="Example: /sales/getemployeedetail" style={{}} value={this.state.newPermissionUrl} onChange={(e)=>{
                                    this.setState({newPermissionUrl:e.target.value})
                                }} />
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "15px"}}>
                                    <div style={{ marginRight: '10px' }}>Send to Client:</div>
                                    <input
                                        type="checkbox"
                                        style={{ height: "15px", width: "15px" }}
                                        checked={this.state.newPermissionSendToClient === "Yes"}
                                        onChange={(e) => {
                                            this.setState({ newPermissionSendToClient: e.target.checked ? "Yes" : "No" })
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                <Button type="submit" color="primary">
                                    Create
                                </Button>
                                <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                    this.setState({newPermissionModal:false})
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal style={{maxWidth: 600}} isOpen={this.state.editPermissionModal} toggle={() => this.setState({editPermissionModal:false})}>
                    <div style={{padding: 20}}>
                        <div>Edit Permission</div>
                        <Form onSubmit={this.handleSubmitEditPermission}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div>Title:</div>
                            <Input type="text" required value={this.state.editPermissionTitle} onChange={(e) => this.setState({editPermissionTitle: e.target.value})} />
                            <div>Category:</div>
                            <select required style={{height:35}} value={this.state.editPermissionCategory} onChange={(e) => {
                                this.setState({ editPermissionCategory: e.target.value })
                            }}>
                                {
                                this.state.permissionCategory.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.title}
                                    </option>
                                ))
                                }
                            </select>
                            <div>Description:</div>
                            <Input type="text" value={this.state.editPermissionDescription} onChange={(e) => this.setState({editPermissionDescription: e.target.value})} />
                            <div>Permission Code:</div>
                            <Input type="text" value={this.state.permissionCode} readOnly style={{backgroundColor: 'white'}} />
                            <div>Url:</div>
                            <Input type="text" value={this.state.editPermissionUrl} onChange={(e) => this.setState({editPermissionUrl: e.target.value})} />
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "15px"}}>
                                <div style={{ marginRight: '10px' }}>Send to Client:</div>
                                <input
                                    type="checkbox"
                                    style={{ height: "15px", width: "15px" }}
                                    checked={this.state.editPermissionSendToClient === "Yes"}
                                    onChange={(e) => {
                                        this.setState({ editPermissionSendToClient: e.target.checked ? "Yes" : "No" })
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: 20, justifyContent: "center"}}>
                            <Input type="submit" value="Update" color="primary" />
                            <Button color="secondary" style={{marginLeft: 20}} onClick={() => this.setState({editPermissionModal: false})}>
                            Cancel
                            </Button>
                        </div>
                        </Form>
                    </div>
                </Modal>


                <Modal style={{maxWidth:500}} isOpen={this.state.newEmployeeModal} toggle={()=>this.setState({newEmployeeModal:false})}>
                    {
                    window.innerWidth > 600 ?
                        <div style={{padding:20}}>
                            <div>Add new Employee</div>
                            <Form onSubmit={(e)=>{
                                this.createNewEmployeeFunc()
                            }}>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}> 
                                        <div style={{display:"flex",flexDirection:"column"}}>
                                            <div>
                                                Username:{this.state.newEmployee.username}
                                            </div>
                                            <div>
                                                Name:{this.state.newEmployee.name}
                                            </div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                                <Button style={{marginLeft:10}} onClick={()=>this.setState({selectMemberModal:true},()=>this.getAllMember())}>Search Member</Button>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        Job:
                                    </div>
                                    <select value={this.state.newEmployeeJob} style={{height:'35px'}} onChange={(e)=>this.handleNewJobType(e)}>
                                                <option value="">Select Job Type</option>
                                                {
                                                this.state.jobList.map((item) => (
                                                    <option value={item.id}>{item.jobName}</option>
                                                ))
                                                }
                                    </select>
                                    

                                </div>
                                <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                    <Input type="submit" value="Create" color="primary" style={{border:"solid 1px grey"}}/>
                                    <Button color="danger" style={{marginLeft:20}} onClick={()=>{
                                        this.setState({newEmployeeModal:false})
                                        }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </div>
                        :
                        <div style={{padding:20}}>
                            <div>Add new Employee</div>
                            <Form onSubmit={(e)=>{
                                this.createNewEmployeeFunc()
                            }}>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}> 
                                        <div style={{display:"flex",flexDirection:"column"}}>
                                            <div>
                                                Username:{this.state.newEmployee.username}
                                            </div>
                                            <div>
                                                Name:{this.state.newEmployee.name}
                                            </div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                                <Button style={{marginLeft:10}} onClick={()=>this.setState({selectMemberModal:true},()=>this.getAllMember())}>Search Member</Button>
                                        </div>
                                    </div>
                                    <div>
                                        Job:
                                    </div>
                                    <select value={this.state.newEmployeeJob} style={{height:'35px'}} onChange={(e)=>this.handleNewJobType(e)}>
                                                <option value="">Select Job Type</option>
                                                {
                                                this.state.jobList.map((item) => (
                                                    <option value={item.id}>{item.jobName}</option>
                                                ))
                                                }
                                    </select>
                                    

                                </div>
                                <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                    <Input type="submit" value="Create" color="primary" style={{border:"solid 1px grey"}}/>
                                    <Button color="danger" style={{marginLeft:20}} onClick={()=>{
                                        this.setState({newEmployeeModal:false})
                                        }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    }
                </Modal>
                <Modal style={{maxWidth: 500}} isOpen={this.state.editJobModal} toggle={() => this.setState({ editJobModal: false })}>
                    <div style={{padding:"10px 15px 15px"}}>

                        <div style={{ padding: 20, fontFamily: 'Arial, sans-serif', fontSize: '18px', fontWeight: 'bold', color: '#333', backgroundColor: '#f0f0f0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>                            
                                {this.state.currentSelectedEmployeeName}  &nbsp;      
                                ( {this.state.currentSelectedEmployeeJobName} )                                                      
                        </div>                                                                                                                                    

                        <div style={{ display: "flex", flexDirection: "column", marginTop: 10, gap: 4 }}>
                        
                            {this.state.jobList.map((item) => (
                                <Button
                                    key={item.id}
                                    color="light"
                                    style={{
                                        color: "black",
                                        boxShadow: this.state.currentSelectedEmployeeJobId === item.id ? "0px 0px 0px 5px orange inset" : "none",
                                        border: "1px solid grey"
                                    }}
                                    onClick={() => {
                                        this.setState({ editEmployeeJob: item.id });
                                        this.setState({ currentSelectedEmployeeJobId: item.id });
                                    }}
                                >
                                    {item.jobName}
                                </Button>
                            ))}
                            
                            <div style={{display:"flex",flexDirection:"row",marginTop:10,gap:10,width:"100%"}}>
                                        <Button color="primary" style={{flex:1}} onClick={()=>{
                                            this.editEmployee(this.state.newSeletectedEmployee);
                                            this.setState({ editJobModal: false });
                                        }}>
                                            OK
                                        </Button>
                                        <Button color="light" style={{flex:1,backgroundColor:"salmon",color:"white"}} onClick={()=>{
                                        this.setState({ editJobModal: false });
                                        }}>
                                            Close
                                        </Button>
                            </div>
                        </div>   
                    </div>                                
                </Modal>               

                <Modal style={{maxWidth:600}} isOpen={this.state.editExtraJobModal} toggle={()=>this.setState({editExtraJobModal:false})}>
                    <div style={{padding:20,width:"100%"}}>
                        <div>Edit Extra Job</div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:5,marginTop:10,width:"100%"}}>
                            {
                            this.state.extraJobList.map((extraJob)=>{
                                return(
                                    this.generateExtraJobButton(extraJob)
                                )
                            })
                            }
                        </div>
                        <div style={{display:"flex",flex:1,width:"100%",gap:10,flexDirection:"row",marginTop:20}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.saveExtraJob()
                            }}>
                                Save
                            </Button>
                            <Button color="primary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({editExtraJobModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.selectMemberModal} toggle={()=>this.setState({selectMemberModal:false})}>
                    {
                    window.innerWidth > 600 ?
                        <div style={{padding:20}}>
                            <div style={{paddingBottom:20}}>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <Input value={this.state.searchField}
                                        placeholder='Enter phone number or name'
                                        style={{width:"100%"}}
                                        onChange={(e)=>{this.setState({searchField:e.target.value})}}
                                        onKeyDown={(e)=>{
                                            if(e.keyCode==13){
                                                e.preventDefault()
                                                this.getAllMember()
                                            }
                                    }}></Input>
                                    <Button style={{marginLeft:"5px"}} onClick={()=>this.setState({selectMemberModal:false})}>Close</Button>
                                </div>
                            </div>
                            <div style={{display:"flex",justifyContent:"center",padding:"0px 0px 20px 0px"}}>
                                <table>
                                    <thead>
                                        <th>Username</th>
                                        <th>Name</th>
                                        <th>Join Date</th>
                                        <th></th>
                                    </thead>
                                   
                                    {
                                    this.state.memberList.map((item)=>{
                                        
                                        return(
                                            <tbody style={{width:"100%"}}>
                                                <td style={{paddingRight:30}}>{item.username}</td>
                                                <td style={{paddingRight:30}}>{item.name}</td>
                                                <td style={{paddingRight:30}}>{item.date_joined}</td>
                                                <td>
                                                    <Button value={item.id} 
                                                        style={{width:"100%"}} 
                                                        color='secondary'
                                                        onClick={(e)=>
                                                            this.setState({selectMemberModal:false,newEmployee:item})}>
                                                        Select
                                                    </Button>
                                                </td>
                                            </tbody>
                                        )
                                    })
                                    }
                                </table>
                            </div>
                            <ReactPaginate
                                pageCount={this.state.totalpage}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLabel={"..."}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                disabledClassName={"disabled"}
                                forcePage={this.state.page}
                                onPageChange={async ({selected}) => {
                                    this.setState({page:selected},()=>{
                                        this.getAllMember()
                                    })
                                }}/>
                        </div>
                    :
                    <div>
                        <div style={{padding:20}}>
                            <div style={{display:"flex",flexDirection:"row"}}>
                                <Input value={this.state.searchField}
                                    placeholder='Enter phone number or name'
                                    style={{width:"100%"}}
                                    onChange={(e)=>{this.setState({searchField:e.target.value})}}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.getAllMember()
                                        }
                                }}></Input>
                                <Button style={{marginLeft:"5px"}} onClick={()=>this.setState({selectMemberModal:false})}>Close</Button>
                            </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"center",padding:"0px 0px 20px 0px"}}>
                            <table style={{fontSize:"10px",borderCollapse:"unset"}}>
                                <thead>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Join Date</th>
                                    <th></th>
                                </thead>
                                {
                                this.state.memberList.map((item)=>{
                                    return(
                                        <tbody style={{width:"100%"}}>
                                            <td style={{paddingRight:30}}>
                                            {item.username}
                                            </td>
                                            <td style={{paddingRight:30}}>{item.name}</td>
                                            <td style={{paddingRight:30}}>{item.date_joined}</td>
                                            <td>
                                                <Button value={item.id} 
                                                    style={{width:"100%",fontSize:"10px"}} 
                                                    color='secondary'
                                                    onClick={(e)=>
                                                        this.setState({selectMemberModal:false,newEmployee:item})}>
                                                    Select
                                                </Button>
                                            </td>
                                        </tbody>
                                    )
                                })
                                }
                            </table>
                        </div>
                        <ReactPaginate
                                pageCount={this.state.totalpage}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLabel={"..."}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                disabledClassName={"disabled"}
                                forcePage={this.state.page}
                                onPageChange={async ({selected}) => {
                                    this.setState({page:selected},()=>{
                                        this.getAllMember()
                                    })
                                }}/>
                    </div>
                    }
                </Modal>

                <NavigationBar currentPage="permissions" />
                    {
                    window.innerWidth >= 600 ?
                    //desktop
                        <div>
                            <div style={{ padding: 10, borderStyle: "solid", borderBottomWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0, borderColor: "lightgrey" }}>
                                <Button color="light" style={{ marginRight: 10, borderColor: "lightgrey", borderStyle: "solid", borderWidth: 1 }} onClick={() => {
                                    this.setState({
                                        displayTable: "employees"
                                    })
                                    this.showEmployees()
                                }}>
                                    Employees
                                </Button>

                                <Button color="light" style={{ marginRight: 10, borderColor: "lightgrey", borderStyle: "solid", borderWidth: 1 }} onClick={() => {
                                    this.setState({
                                        displayTable: "permissions"
                                    })
                                    this.launchFunc()
                                }}>
                                    Permissions
                                </Button>
                            </div>

                            <div>
                                {this.state.displayTable === "permissions" && (
                                    <div>
                                        {
                                        GLOBAL.checkPermission("}kfBMM&d")?
                                            <div style={{padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey"}}>
                                                <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.createNewPermission()}}>
                                                    Create new Permission
                                                </Button>
                                            </div>
                                            :
                                            null
                                        }
                                        <div style={{display:"flex"}}>
                                        <Input type="text" placeholder='Enter Permission Title'
                                            style={{width:"50%",margin:10}} 
                                            value={this.state.searchFieldPermission}
                                            onChange={(e)=> this.setState({searchFieldPermission: e.target.value})}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.setState({pagePermission:0},()=>{
                                                        this.loadAllPermissionData()
                                                    })
                                                }
                                            }}>
                                                
                                        </Input>
                                        <Button style={{margin:10}} onClick={()=>{
                                            this.loadAllPermissionData()
                                        }}>
                                            Search
                                        </Button>
                                        </div>
                                        <select style={{marginTop:15,marginLeft:10,height:'35px'}} onChange={(e) => this.handleCategorySelect(e.target.value)}>
                                            <option value="">All Categories</option>
                                            {
                                            this.state.permissionCategory.map((category, index) => (
                                                <option key={index} value={category.title}>{category.title} </option>
                                            ))
                                            }
                                        </select>

                                        <div style={{marginTop: 15, marginLeft: 10}}>
                                            {this.state.jobType.map(jobType => (
                                                <button 
                                                    key={jobType.id} 
                                                    style={{
                                                        marginRight: 10, 
                                                        marginBottom: 10, 
                                                        height: 30,
                                                        border: '1px solid',
                                                        borderColor: jobType.selected ? '#1e7e34' : 'black',
                                                        background: jobType.selected ? '#ccccff' : 'white',
                                                        color: jobType.selected ? 'black' : 'black',
                                                        width: '150px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        borderRadius: '5px',
                                                    }}  
                                                    onClick={() => {
                                                        let jobTypeClone=this.state.jobType
                                                        for(let job of jobTypeClone){
                                                            if(job.id === jobType.id){
                                                                job.selected = !job.selected;
                                                                break;
                                                            }
                                                        }
                                                        this.setState({
                                                            loading: true,
                                                            jobType: jobTypeClone,
                                                        }, () => {
                                                            this.loadAllPermissionData(() => {
                                                                this.setState({ loading: false });
                                                            });
                                                        })
                                                    }}
                                                >
                                                    {jobType.jobName}
                                                </button>
                                            ))}
                                        </div>

                                        <Table style={{ width: "100%", borderCollapse: "collapse" }}>
                                            <tr>
                                                <td style={{ borderTop: "none", fontWeight: 500 }}>Permissions:</td>
                                                {this.state.jobType.map(job => (
                                                    job.selected &&
                                                    <td key={job.id} style={{ borderTop: "none", fontSize: "14px", flexWrap:"wrap", width: "140px", fontWeight: 500, textAlign: "center" }}>{job.jobName}</td>
                                                ))}
                                            </tr>
                                            {this.state.permissionsList.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                        {item.title}
                                                    </td>
                                                    {this.state.jobType.map((job) => {
                                                        if (!job.selected) return null; // Skip rendering if the job is not selected
                                                        const isSelected = item.selected.includes(job.id);
                                                        return (
                                                            <td key={job.id} style={{ verticalAlign: "middle", textAlign: "center" }}>
                                                                {GLOBAL.checkPermission("S-o=g&f!") ? (
                                                                    job.selected && (
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={isSelected}
                                                                            onChange={() => this.handleCheckboxChange(item.id, job.id)}
                                                                        />
                                                                    )
                                                                ) : null}
                                                            </td>
                                                        );
                                                    })}
                                                    {GLOBAL.checkPermission("<XNb||pq")?
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <a href="#" className="delete-link" onClick={() => this.deletePermission(item.id)} style={{ textDecoration: "underline" }}>Remove</a>
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                    {GLOBAL.checkPermission("vkH5sz9-")?
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button color="primary" onClick={() => this.editPermission(item)}>Edit</Button>
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                </tr>
                                            ))}
                                        </Table>
                                        <ReactPaginate
                                            pageCount={this.state.totalpagePermission}
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={1}
                                            containerClassName={"pagination justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            breakLabel={"..."}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            disabledClassName={"disabled"}
                                            forcePage={this.state.pagePermission}
                                            onPageChange={async ({selected}) => {
                                                this.setState({pagePermission:selected},()=>{
                                                    this.loadAllPermissionData()
                                                })
                                            }}/>
                                    </div>
                                    )}
                            </div>

                            <div>
                                {this.state.displayTable === "employees" && (
                                <div style={{padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey"}}>
                                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.createNewEmployee()}}>
                                        Add new Employee
                                    </Button>
                                </div>
                                )}
                                {this.state.displayTable === "employees" && this.state.employees.length > 0 ? (
                                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <thead>
                                        <tr>
                                            <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}>Name</th>
                                            <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}>Username</th>
                                            <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}>Job</th>
                                            <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}></th>
                                            <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}>Extra Job</th>
                                            <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}></th>
                                            <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}></th>
                                        </tr>
                                    </thead>
                                    {
                                        console.log( this.state.employees)
                                    }
                                    <tbody>
                                        {
                                        this.state.employees.map((item) => (
                                            <tr key={item.id}>
                                                <td style={{padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>{item.member.name}</td>
                                                <td style={{padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>{item.member.username}</td>
                                                <td style={{padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>{item.job.jobName}</td>
                                                <td style={{verticalAlign:"middle",padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>                                             
                                                    <Button onClick={()=>this.setState
                                                        ({
                                                            editJobModal:true,
                                                            newSeletectedEmployee:item.id,
                                                            currentSelectedEmployeeJobId:item.job.id,
                                                            currentSelectedEmployeeJobName:item.job.jobName,
                                                            currentSelectedEmployeePhoneNum:item.member.username,
                                                            currentSelectedEmployeeName: item.member.name,
                                                            })}
                                                            >
                                                                Edit
                                                    </Button>
                                                </td>
                                                <td style={{padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>{item.extraJobString}</td>
                                                <td style={{verticalAlign:"middle",padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>
                                                    <Button onClick={()=>{
                                                        this.setState({selectedEmployee:item},()=>{
                                                            this.getExtraJobList()
                                                        })
                                                    }}>
                                                        Edit
                                                    </Button>
                                                </td>
                                                <td style={{verticalAlign:"middle",padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>
                                                    <a href="#" className="delete-link" onClick={() => this.deleteEmployee(item.id,item.member)} style={{textDecoration:"underline"}}>Remove</a>
                                                </td>                                                
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                                )
                                :
                                null
                                }
                            </div>
                        </div>
                        :
                        //mobile
                        <div>
                            <div style={{padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"flex",flexDirection:"column",gap:15}}>
                                <Button color="light" style={{ marginRight: 10, borderColor: "lightgrey", borderStyle: "solid", borderWidth: 1 }} onClick={() => {
                                    this.setState({
                                        displayTable: "employees"
                                    })
                                    this.showEmployees()
                                }}>
                                    Employees
                                </Button>

                                <Button color="light" style={{ marginRight: 10, borderColor: "lightgrey", borderStyle: "solid", borderWidth: 1 }} onClick={() => {
                                    this.setState({
                                        displayTable: "permissions"
                                    })
                                    this.launchFunc()
                                }}>
                                    Permissions
                                </Button>
                            </div>

                            <div>
                                {this.state.displayTable === "permissions" && (
                                    <div>
                                        {
                                        GLOBAL.checkPermission("}kfBMM&d")?
                                            <div style={{padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey"}}>
                                                <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.createNewPermission()}}>
                                                    Create new Permission
                                                </Button>
                                            </div>
                                            :
                                            null
                                        }
                                        <div style={{display:"flex"}}>
                                            <Input type="text" placeholder='Enter Permission Title'
                                                style={{width:"50%",margin:10}} 
                                                value={this.state.searchFieldPermission}
                                                onChange={(e)=> this.setState({searchFieldPermission: e.target.value})}
                                                onKeyDown={(e)=>{
                                                    if(e.keyCode==13){
                                                        e.preventDefault()
                                                        this.setState({pagePermission:0},()=>{
                                                            this.loadAllPermissionData()
                                                        })
                                                    }
                                                }}>
                                                    
                                            </Input>
                                            <Button style={{margin:10}} onClick={()=>{
                                                this.loadAllPermissionData()
                                            }}>
                                                Search
                                            </Button>
                                        </div>
                                        <select style={{marginTop:15,marginLeft:13,height:'35px'}} onChange={(e) => this.handleCategorySelect(e.target.value)}>
                                            <option value="">All Categories</option>
                                            {
                                            this.state.permissionCategory.map((category, index) => (
                                                <option key={index} value={category.title}>{category.title} </option>
                                            ))
                                            }
                                        </select>

                                        <select value={this.state.selectedJobType} style={{marginTop:15,marginLeft:10,height:'35px'}} onChange={this.handleJobTypeSelect}>
                                            <option value="">Select Job Type</option>
                                            {
                                            this.state.jobType.map(jobType => (
                                                <option key={jobType.id} value={jobType.jobName}>{jobType.jobName}</option>
                                            ))
                                            }
                                        </select>
                                            
                                        <Table style={{ width: "100%",borderCollapse: "collapse"}}>
                                        <tr>
                                            <td style={{ borderTop: "none" }}>Permissions:</td> {/* New column header */}
                                        </tr>
                                            {
                                            this.state.permissionsList.map((item) => {
                                                return (
                                                <tr key={item.id}>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                    {item.title}
                                                    </td>
                                                    {
                                                    GLOBAL.checkPermission("S-o=g&f!")?
                                                        <td style={{ verticalAlign: "middle"}}>
                                                            <input type="checkbox" checked={item.selected} onChange={() => this.handleCheckboxChange(item.id)} />
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                    {
                                                    GLOBAL.checkPermission("<XNb||pq")?
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <a href="#" className="delete-link" onClick={() => this.deletePermission(item.id)} style={{ textDecoration: "underline" }}>Remove</a>
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                    {
                                                    GLOBAL.checkPermission("vkH5sz9-")?
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <Button color="primary" onClick={() => this.editPermission(item)}>Edit</Button>
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                </tr>
                                                    )
                                                })
                                            }
                                        </Table>
                                        <ReactPaginate
                                            pageCount={this.state.totalpagePermission}
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={1}
                                            containerClassName={"pagination justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            breakLabel={"..."}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            disabledClassName={"disabled"}
                                            forcePage={this.state.pagePermission}
                                            onPageChange={async ({selected}) => {
                                                this.setState({pagePermission:selected},()=>{
                                                    this.loadAllPermissionData()
                                                })
                                            }}/>
                                    </div>
                                    )}
                            </div>

                            <div>
                                {this.state.displayTable === "employees" && (
                                <div style={{padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey"}}>
                                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.createNewEmployee()}}>
                                        Create new Employee
                                    </Button>
                                </div>
                                )}
                                {
                                this.state.displayTable === "employees" && this.state.employees.length > 0 ? (
                                    <div style={{overflow:"auto"}}>
                                    <table style={{ width: "100%", borderCollapse: "collapse",fontSize:"11px"}}>
                                        <thead>
                                            <tr>
                                                <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}>Name</th>
                                                <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}>Username</th>
                                                <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}>Job</th>
                                                <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}></th>
                                                <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}>Extra Job</th>
                                                <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}></th>
                                                <th style={{backgroundColor:"#f2f2f2", padding:"10px", textAlign:"left", borderBottom: "1px solid #ddd"}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            this.state.employees.map((item) => (
                                                <tr key={item.id}>
                                                    <td style={{padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>{item.member.name}</td>
                                                    <td style={{padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>{item.member.username}</td>
                                                    <td style={{padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>{item.job.jobName}</td>
                                                    <td style={{verticalAlign:"middle",padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>
                                                        <Button style={{fontSize:"11px",padding:"3px"}} onClick={()=>this.setState({
                                                            editJobModal:true,
                                                            newSeletectedEmployee:item.id,
                                                            currentSelectedEmployeeJobId:item.job.id, 
                                                            currentSelectedEmployeeJobName:item.job.jobName,
                                                            currentSelectedEmployeePhoneNum:item.member.username,
                                                            currentSelectedEmployeeName: item.member.name,
                                                            })}
                                                            >Edit
                                                        </Button>
                                                    </td>
                                                    <td style={{padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>{item.extraJobString}</td>
                                                    <td style={{verticalAlign:"middle",padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>
                                                        <Button style={{fontSize:"11px",padding:"3px"}} onClick={()=>{
                                                            this.setState({selectedEmployee:item},()=>{
                                                                this.getExtraJobList()
                                                            })
                                                        }}>
                                                            Edit
                                                        </Button>
                                                    </td>
                                                    <td style={{verticalAlign:"middle",padding:"10px", textAlign:"left", borderBottom:"1px solid #ddd"}}>
                                                        <a href="#" className="delete-link" onClick={() => this.deleteEmployee(item.id,item.member)} style={{textDecoration:"underline"}}>Remove</a>
                                                    </td>
                                                    {
                                                       console.log(item.member.username)
                                                    }
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                )
                                :
                                null
                                }
                            </div>
                        </div>
                    }
            </div>
        )
    }
}

Permissions = Radium(Permissions)

let styles={

}
