import React from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input,Modal,Alert } from 'reactstrap';
import history from '../history.js';
import axios from 'axios';
import qs from 'qs';
import {reactLocalStorage} from 'reactjs-localstorage';
import LoadingScreen from '../components/LoadingScreen';
import Radium from 'radium';

export default class SignUpPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            referCode:"",
            introCode:"",
            comeWithReferrer:false, //means freeVIPIntro referrer
            comeWithIntroducer:false,   //means introcode referrer
            referrerName:"",
            phoneNumber:"",
            messageSent:false,
            verifyCodeModal:false,
            resendCodeCountdown:30,
            verificationCode:"",
            verificationSuccess:false,
            email:"",
            reenterEmail:"",
            name:"",
            password:"",
            reenterPassword:"",
            promotionName:"",
            dropdownToggle:false,
            signupSuccess:false,
            loading:false,

            errorMessage:[],
            phoneNumberError:false,
            emailError:false,
            reenterEmailError:false,
            nameError:false,
            passwordError:false,
            reenterPasswordError:false,

            loginUsername:"",
            loginPassword:"",
            invalidLoginAlert:false,
            invalidLoginMessage:"",

            redirect:null,

            errorModal:false,
            errorMessage:"",
        }

        this.verificationInterval=null
        this.tempReferCode=null
    }

    componentDidMount(){
        //scroll to top
        window.scrollTo(0, 0)

        this.launchFunc()
    }

    launchFunc=()=>{
        //if user is logged in, do not let him enter signup page
        if(reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        //parse search path inputs and process accordingly
        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        var parsedInputSplit=[]
        for(var input of inputSplit){
            parsedInputSplit.push(input.split("="))
        }
        //process
        var referCode=""
        var introCode=""
        var redirectLink=""
        for(const input of parsedInputSplit){
            let param=input[0]
            let value=input[1]
            if(param=="ref"){
                referCode=value
            }else if(param=="intro"){
                introCode=value
            }else if(param=="fbclid"){  //when user shared via facebook
                //do nothing
            }else if(param=="redirect"){
                if(input[2]){
                    redirectLink=value+"="+input[2] //extra value parameter
                    this.setState({redirect: redirectLink})
                }else{
                    redirectLink=value //extra value parameter
                    this.setState({redirect: redirectLink})
                }
            }else if(param=="giftcode"){
                //append to redirect
                this.setState({redirect:redirectLink+"&giftcode="+value})
            }
        }
        if(introCode==""){  //no given introcode in the link, try the memory
            if(reactLocalStorage.get("introCode")){
                introCode=reactLocalStorage.get("introCode")
            }
        }
        if(introCode!=""||referCode!=""){
            this.lookForReferrer(referCode,introCode)
        }
    }

    lookForReferrer=(referCode,introCode)=>{
        //validate
        if(referCode!=""){  //no referral code (free VIP)
            if(referCode.length<5||referCode.length>6){
                alert("Refer code length invalid")
                return
            }
            this.tempReferCode=referCode
        }
        if(introCode!=""){
            if(introCode.length<5||introCode.length>6){
                alert("Intro code length invalid")
                return
            }
        }

        //seek for the promo voucher and referrer
        const payload = {
            referCode:referCode,
            introCode:introCode,
        }
        axios
            .post(`membership/v1/lookforreferrer`,qs.stringify(payload))
            .then(async response => {
                let introUsername=response.data.introUsername
                let introName=response.data.introName
                let promoUsername=response.data.promoUsername
                let promoName=response.data.promoName

                var theName=null
                if(promoName!=""){  //got promo voucher, prioritize promo referrer name
                    theName=promoName
                }else if(introName!=""){    //got introducer
                    theName=introName
                }

                if(this.tempReferCode&&promoName==""){  //refer code entered but still fail to find the promo voucher on server
                    alert("Promo code in the link is no longer valid, it may be depleted or changed."+
                        " Please check back with the person who shared the link to you if you are keen to get the promo.")
                }

                if(theName){
                    this.setState({
                        referCode:referCode,
                        referrerName:theName,   //can be null if there is none
                    })
                    reactLocalStorage.set("introCode",introCode)
                    if(promoName!=""){
                        this.setState({comeWithReferrer:true,})
                    }
                    if(introName!=""){
                        this.setState({
                            introCode:introCode,
                            comeWithIntroducer:true,
                        })
                    }
                }
            })
            .catch(error => {
                if(error.response.status==502){
                    alert("Intro code invalid")
                }else{
                    alert("Refer code validation error\n"+error)
                }
            });
    }

    validateEmail=(email)=>{
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    checkFieldError=()=>{
        if(this.state.phoneNumberError||this.state.emailError||this.state.nameError||this.state.passwordError||this.state.reenterPasswordError){
            return true
        }else{
            return false
        }
    }

    verifySignupForm=()=>{
        var errorMessageReborn=[]
        var hasError=false
        //validate all fields
        //phone number
        if(!this.state.verificationSuccess){
            this.setState({phoneNumberError:true})
            hasError=true
            errorMessageReborn.push("Please verify your phone number")
        }else{
            this.setState({phoneNumberError:false})
        }
        //email
        if(!this.validateEmail(this.state.email)){
            this.setState({emailError:true})
            hasError=true
            errorMessageReborn.push("Invalid email address")
        }else{
            this.setState({emailError:false})
        }
        //name
        if(this.state.name.length<3){
            this.setState({nameError:true})
            hasError=true
            errorMessageReborn.push("Name too short")
        }
        // else if(!/[a-zA-Z\\s]+$/i.test(this.state.name)){
        //     this.setState({nameError:true})
        //     hasError=true
        //     errorMessageReborn.push("\nName should not contain non-alphabet character")
        // }
        else{
            this.setState({nameError:false})
        }
        //password
        if(this.state.password.length<8){
            this.setState({passwordError:true})
            hasError=true
            errorMessageReborn.push("Password length must be at least 8 characters")
        }else{
            this.setState({passwordError:false})
        }
        //reenter password
        if(this.state.reenterPassword!=this.state.password){
            this.setState({reenterPasswordError:true})
            hasError=true
            errorMessageReborn.push("Reenter password incorrect")
        }else{
            this.setState({reenterPasswordError:false})
        }

        if(hasError){   //have error
            this.setState({errorMessage:errorMessageReborn})
            return false
        }else{
            return true
        }
    }

    registerFree=()=>{
        if(!this.verifySignupForm()){
            return
        }
        this.setState({loading:true})
        //send the registration to server
        let introCode=this.state.introCode
        if(reactLocalStorage.get("introCode")){
            introCode=reactLocalStorage.get("introCode")
        }
        //agent company code
        let agentCompanyCode=reactLocalStorage.get("agentCompanyCode")
        if(agentCompanyCode==null){
            agentCompanyCode=""
        }
        const payload = {
            referCode:this.state.referCode,
            introCode:introCode,
            agentCompanyCode:agentCompanyCode,
            username:this.state.phoneNumber,
            email:this.state.email,
            name:this.state.name,
            password:this.state.password,
            referphone: this.state.promotionName,
        }
        axios
            .post(`membership/v1/signup`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                    signupSuccess:true,
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==501){ //username taken
                        this.setState({errorModal:true,errorMessage:"Phone number "+this.state.phoneNumber+" is already taken by another account"})
                    }else if(error.response.status==502){   //email taken
                        this.setState({errorModal:true,errorMessage:"Email "+this.state.email+" is already taken by another account"})
                    }else if(error.response.status==503){   //name taken
                        this.setState({errorModal:true,errorMessage:"Name "+this.state.name+" is already taken by another account"})
                    }else if(error.response.status==505){   //refer code invalid
                        this.setState({errorModal:true,errorMessage:"Refer code invalid"})
                    }else if(error.response.status==506){
                        this.setState({errorModal:true,errorMessage:"Must provide valid promo code for to claim VIP referral"})
                    }else{
                        this.setState({errorModal:true,errorMessage:"Register new account error\n"+error.response.data.message+"\n"+error})
                    }
                }else{
                    this.setState({errorModal:true,errorMessage:"Register new account error\n"+error.response.data.message+"\n"+error})
                }
                this.setState({loading:false})
            });
    }

    registerVIP=()=>{
        if(!this.verifySignupForm()){
            return
        }

        //go to payment page to complete the payment (if no free referrer)
        this.setState({loading:true})
        //send the registration to server
        var introCode=""
        if(reactLocalStorage.get("introCode")){
            introCode=reactLocalStorage.get("introCode")
        }
        const payload = {
            introCode:introCode,
            phoneNumber:this.state.phoneNumber,
            email:this.state.email,
            name:this.state.name,
            password:this.state.password,
            sku:"VIP",
        }
        axios
            .post(`membership/v1/presignupinfo`,qs.stringify(payload),{timeout:15000})
            .then(async response => {
                reactLocalStorage.set("trace",response.data.trace)  //save the trace of generated invoice

                //clear the basket, add only this item
                var theBasket=new Array()    //new array
                theBasket.push("VIP")
                reactLocalStorage.set("basket",theBasket)
                history.push("./payment")

                this.setState({
                    loading:false,
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==501){ //username taken
                        alert("Phone number "+this.state.phoneNumber+" is already taken by another account")
                    }else if(error.response.status==502){   //email taken
                        alert("Email "+this.state.email+" is already taken by another account")
                    }else if(error.response.status==503){   //name taken
                        alert("Name "+this.state.name+" is already taken by another account")
                    }else{
                        alert("Register new account error\n"+error)
                    }
                }else{
                    alert("Register new account error\n"+error)
                }
                this.setState({loading:false})
            });
    }

    handlePhoneNumberChange=(e)=>{
        this.setState({phoneNumber:e.target.value.replace(/\D/,'')})
    }

    sendCode=(e)=>{
        //validate
        let phoneNumber=this.state.phoneNumber
        if(phoneNumber.length<10){
            alert("Phone number too short")
            return
        }else{
            let firstThree=phoneNumber.substring(0,3)
            if(firstThree!="010"&&firstThree!="011"&&firstThree!="012"&&firstThree!="013"&&firstThree!="014"&&firstThree!="015"&&firstThree!="016"&&firstThree!="017"&&firstThree!="018"&&firstThree!="019"){
                alert("Phone number prefix invalid: "+firstThree)
                return
            }
        }

        //get user details
        const payload = {
            phoneNumber:phoneNumber,
        }
        axios
            .post(`membership/v1/getphoneverificationcode`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    messageSent:true,
                    verifyCodeModal:true,
                    resendCodeCountdown:30,
                })
                this.verificationInterval=setInterval(this.countdown,1000)
            })
            .catch(error => {
                if(error.response.status==404){
                    alert("Verification code is already sent. Please check your phone or wait for 30s before requesting again.")
                    this.setState({
                        messageSent:true,
                        verifyCodeModal:true,
                    })
                    this.verificationInterval=setInterval(this.countdown,1000)
                }else if(error.response.status==405){
                    alert("Phone number "+phoneNumber+" is already taken by another account")
                }else{
                    alert("Get phone verification code error\n"+error)
                }
            });

        if(e!=null){
            e.preventDefault()  //prevent default submit form behaviour
        }
    }

    countdown=()=>{
        this.setState({resendCodeCountdown:this.state.resendCodeCountdown-1})
        if(this.state.resendCodeCountdown==0){
            clearInterval(this.verificationInterval)
            return
        }
    }

    resendCode=()=>{
        if(this.state.resendCodeCountdown>0){
            //have to wait to countdown
            return
        }
        this.sendCode()
    }

    verifyCode=()=>{
        //validation
        if(this.state.verificationCode.length!=5){
            alert("Verification code length incorrect")
            return
        }

        //verify with server
        const payload = {
            phoneNumber:this.state.phoneNumber,
            verificationCode:this.state.verificationCode,
        }
        axios
            .post(`membership/v1/verifycode`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    verifyCodeModal:false,
                    resendCodeCountdown:30,
                    verificationSuccess:true,
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==405){
                        alert("Verification code expired, please request another one.")
                    }else if(error.response.status==404){
                        alert("Verification code incorrect")
                    }else{
                        alert("Phone number verification fail",error)
                    }
                }else{
                    alert("Phone number verification fail",error)
                }
            });
    }

    parseMembershipTypeToChinese=(mType)=>{
        if(mType=="Free"){
            return "会员"
        }else if(mType=="CM"){   //Classic Member
            return "VIP会员"
        }else if(mType=="CP"){   //Classic Partner
            return "黄金伙伴"
        }else if(mType=="GP"){   //Gold Partner
            return "白金伙伴"
        }else if(mType=="SP"){   //Strategy Partner
            return "战略伙伴"
        }else if(mType=="Staff"){
            return "工作人员"
        }else if(mType=="Broker"){
            return "代理商"
        }else{
            return "--"
        }
    }

    loginFormSend=()=>{
        //collect login form data
        var username=this.state.loginUsername
        var password=this.state.loginPassword
        //validate login form data
        if(username.trim().length==0){
            this.setState({
                invalidLoginAlert:true,
                invalidLoginMessage:"Username cannot be empty",
            })
            return
        }
        if(password.length==0){
            this.setState({
                invalidLoginAlert:true,
                invalidLoginMessage:"Password cannot be empty",
            })
            return
        }
        //pass validation
        this.setState({invalidLoginAlert:false})

        //send login data to server for login
        const payload = {
            username: username,
            password: password
        }
        axios
            .post(`sales/saleslogin`, qs.stringify(payload),{timeout:15000})    //15s timeout
            .then(async response => {
                //save returned token
                reactLocalStorage.set("token",response.data.token)
                axios.defaults.headers.common.Authorization = `Token ${response.data.token}`

                //get user details
                axios
                    .get(`membership/v1/user`)
                    .then(async response => {
                        //login success
                        //save the returned data
                        reactLocalStorage.set('id', response.data.id)
                        reactLocalStorage.set('username', response.data.username)
                        reactLocalStorage.set('email', response.data.email)
                        reactLocalStorage.set('company', response.data.company)
                        reactLocalStorage.set('deliveryAddress',response.data.deliveryAddress)
                        reactLocalStorage.set('billingAddress', response.data.billingAddress)
                        reactLocalStorage.set('name', response.data.name)
                        reactLocalStorage.set('membership_type', response.data.membership_type)
                        //parse membership type to chinese
                        reactLocalStorage.set('membership_type_chinese',this.parseMembershipTypeToChinese(response.data.membership_type))
                        reactLocalStorage.set('profileImage', response.data.profileImage)
                        reactLocalStorage.set('topupWallet', response.data.topupWallet)
                        reactLocalStorage.set('point', response.data.point)
                        reactLocalStorage.set('badge',JSON.stringify(response.data.badge))
                        reactLocalStorage.set('certificate',JSON.stringify(response.data.certificate))
                        reactLocalStorage.set('referCode',response.data.referCode)
                        reactLocalStorage.set('personInCharge',JSON.stringify(response.data.personInCharge))
                        reactLocalStorage.remove("introCode")
                        this.setState({refreshTrigger:true})    //just a useless set state to trigger refresh of nav bar
                        if(this.state.redirect){
                            history.push(this.state.redirect)
                        }else{
                            history.push('/')
                        }
                    })
                    .catch(error => alert("Get user detail error\n"+error));
            })
            .catch(error =>{
                reactLocalStorage.clear()
                alert("Login error\n"+error)
            })
    }

    /*
    goBuyRegisterCode=()=>{
        let skuValue="IntroPack"

        //if basket already has this item, do not add the number
        var theBasket=reactLocalStorage.get("basket")
        var foundSameItem=false
        if(theBasket){
            theBasket=theBasket.split(",")
            for(var bItem of theBasket){
                if(bItem==skuValue){
                    foundSameItem=true
                    break
                }
            }
        }
        if(!foundSameItem){
            var theBasket=reactLocalStorage.get("basket")
            if(!theBasket){
                theBasket=new Array()    //new array
            }else{
                theBasket=theBasket.split(",")  //reactLocalStorage always return string representative, need to parse
            }
            theBasket.push(skuValue)
            reactLocalStorage.set("basket",theBasket)
        }

        history.push("./payment")
    }
    */

    render(){
        return(
            <div>
            {
            this.state.loading?
                <LoadingScreen/>
                :null
            }
            <Modal isOpen={this.state.errorModal} toggle={()=>this.setState({errorModal:false})}>
                <div style={{padding:20}}>
                    <Label>{this.state.errorMessage}</Label>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <Button color="primary" style={{marginTop:15,width:"100%"}} onClick={()=>{this.setState({errorModal:false})}}>好的</Button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={this.state.verifyCodeModal} toggle={()=>this.setState({verifyCodeModal:false})}>
                <div style={{padding:20}}>
                    <Label>验证码已发到您的电话，请查看电话信息：</Label>
                    <div style={{display:"flex",flexDirection:"row"}}>
                        <Input type="text"
                            style={{flex:1}}
                            value={this.state.verificationCode}
                            onChange={(e)=>{
                                this.setState({verificationCode:e.target.value})
                            }}
                            placeholder="验证码" />
                        <Button style={this.state.resendCodeCountdown<=0?styles.enableResend:styles.disableResend}
                            disabled={this.state.resendCodeCountdown<=0?false:true}
                            onClick={()=>{this.resendCode()}}>
                            再发一次{this.state.resendCodeCountdown==0?null:"("+this.state.resendCodeCountdown+"s)"}
                        </Button>
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <Button color="primary" style={{marginTop:15,width:"100%"}} onClick={()=>{this.verifyCode()}}>提交</Button>
                    </div>
                </div>
            </Modal>

            <div style={window.innerWidth<600?{fontFamily:'Microsoft YaHei',paddingTop:70,paddingBottom:40}:{fontFamily:'Microsoft YaHei',paddingTop:86,paddingBottom:40}}>
                {
                this.state.signupSuccess?
                    <Row form style={styles.rowStyle}>
                    <Col md={4} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                        <div style={{color:"green",fontSize:30,fontWeight:"bold"}}>注册成功</div>
                        <div>现在就登录吧!</div>
                        {
                        this.state.redirect&&this.state.redirect.includes("giftcode")?
                            <div>登录领取礼物🎁</div>
                            :null
                        }
                        <Form style={{marginTop:20,width:"100%",padding:15}}>
                            <Alert color="danger" isOpen={this.state.invalidLoginAlert} style={{borderRadius:0}}>
                                {this.state.invalidLoginMessage}
                            </Alert>
                            <Input type="text" name="username" placeholder="手机号码"
                                value={this.state.loginUsername}
                                onChange={event => this.setState({loginUsername: event.target.value.replace(/\D/,'')})}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.loginFormSend()
                                    }
                                }}
                                style={{marginBottom:15}}
                                />
                            <Input type="password" name="password" placeholder="密码"
                                value={this.state.loginPassword}
                                onChange={event => this.setState({loginPassword:event.target.value})}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.loginFormSend()
                                    }
                                }}
                                style={{marginBottom:30}}
                                />
                            <Button color="primary" onClick={()=>this.loginFormSend()} style={{width:"100%"}}>登入</Button>
                        </Form>
                    </Col>
                    </Row>
                    :
                    <Form className="container" style={{display:"flex",flexDirection:"column",paddingTop:30}}>
                        {
                        this.checkFieldError()?
                            <Alert color="danger">
                                <h4>Error</h4>
                                <div>{this.state.errorMessage.map((item)=>{
                                    return(
                                        <div>{item}</div>
                                    )
                                })}</div>
                            </Alert>
                            :
                            null
                        }

                        <Row form style={styles.rowStyle}>
                        <Col md={4} style={this.state.emailError?styles.errorBorder:null}>
                            <div>电子邮件</div>
                            <Input type="email" value={this.state.email} onChange={(e)=>{this.setState({email:e.target.value})}} placeholder="电子邮件"
                            onKeyPress={(e) => {
                                if (e.key === ' ') {
                                     e.preventDefault();
                                }
                            }} />
                        </Col>
                        </Row>

                        <Row form style={styles.rowStyle}>
                        <Col md={4} style={this.state.reenterEmailError?styles.errorBorder:null}>
                            <Input type="email" value={this.state.reenterEmail} onChange={(e)=>{this.setState({reenterEmail:e.target.value})}} placeholder="再输入电子邮件"
                            onKeyPress={(e) => {
                                if (e.key === ' ') {
                                     e.preventDefault();
                                }
                            }}
                            />
                        </Col>
                        </Row>

                        <Row form style={styles.rowStyle}>
                        <Col md={4} style={this.state.nameError?styles.errorBorder:null}>
                            <div>IC名字</div>
                            <Input type="text" value={this.state.name} onChange={(e)=>{this.setState({name:e.target.value})}} placeholder="IC名字"
                            onKeyUp={(e) => {
                                const inputValue = e.target.value;
                                const checkDoubleSpacing = /\s{2,}/g;
                                const checkFrontSpacing = /^\s+$/g;
                                if (checkDoubleSpacing.test(inputValue)) {
                                    e.target.value = inputValue.replace(checkDoubleSpacing, ' ');
                                    this.setState({ name: e.target.value });
                                }
                                else if (checkFrontSpacing.test(inputValue)) {
                                    e.target.value = inputValue.replace(checkFrontSpacing, '');
                                    this.setState({ name: e.target.value });
                                }
                            }}
                            />
                        </Col>
                        </Row>

                        <Row form style={styles.rowStyle}>
                        <Col md={4} style={this.state.phoneNumberError?styles.errorBorder:null}>
                            <div>手机号码</div>
                            <div style={{display:"flex",flexDirection:"row"}}>
                                <Input type="tel" value={this.state.phoneNumber}
                                    onChange={(e)=>{
                                        if(this.state.verificationSuccess){return}
                                        this.handlePhoneNumberChange(e)
                                    }}
                                    placeholder="手机号码" style={{flex:1,marginRight:10}}
                                    />
                                {
                                this.state.verificationSuccess?
                                    <Button color="success" style={{}} onClick={(e)=>e.preventDefault()}>
                                        验证成功
                                    </Button>
                                    :
                                    this.state.messageSent?
                                        <Button color="warning" onClick={()=>{this.setState({verifyCodeModal:true})}} style={{}}>
                                            输入验证码
                                        </Button>
                                        :
                                        <Button color="primary" onClick={(e)=>{this.sendCode(e)}} style={{}}>
                                            发验证码
                                        </Button>
                                }
                            </div>
                        </Col>
                        </Row>

                        <Row form style={styles.rowStyle}>
                        <Col md={4} style={this.state.passwordError?styles.errorBorder:null}>
                            <div>密码</div>
                            <Input type="password" value={this.state.password} onChange={(e)=>{this.setState({password:e.target.value})}} placeholder="密码" minLength="8" />
                        </Col>
                         </Row>
                        <Row form style={styles.rowStyle}>
                        <Col md={4} style={this.state.reenterPasswordError?styles.errorBorder:null}>
                            <Input type="password" value={this.state.reenterPassword} onChange={(e)=>{this.setState({reenterPassword:e.target.value})}} placeholder="再输入密码" minLength="8"/>
                        </Col>
                        </Row>

                        {
                            this.state.introCode === "" &&
                            <Row form style={styles.rowStyle}>
                            <Col md={4}>
                                <div>推荐人，如果有</div>
                                <Input type="text" value={this.state.promotionName} onChange={(e)=>{this.setState({promotionName:e.target.value})}} placeholder="手机号码"/>
                            </Col>
                            </Row>
                        }

                        <Row form style={styles.rowStyle}>
                        <Col md={4}>
                            <FormGroup check>
                                <Input type="checkbox" name="check" id="exampleCheck"/>
                                <Label for="exampleCheck" check>订阅通讯</Label>
                            </FormGroup>
                        </Col>
                        </Row>

                        <Row form style={styles.rowStyle}>
                        <Col md={4}>
                            <div style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",marginTop:15}}>
                                {
                                this.state.referrerName?
                                    <div style={{textAlign:"center"}}>
                                        介绍人：{this.state.referrerName}
                                    </div>
                                    :
                                    null
                                }
                                <Button color="primary" style={{width:"100%",marginTop:5}} onClick={()=>this.registerFree()}>注册会员</Button>
                            </div>
                        </Col>
                        </Row>
                    </Form>
                }
            </div>
            </div>
        )
    }
}

SignUpPage = Radium(SignUpPage);

const styles = {
    rowStyle:{
        justifyContent:"center",
        marginBottom:10,
    },
    enableResend:{
        marginLeft:10,
        backgroundColor:"skyblue"
    },
    disableResend:{
        marginLeft:10,
        backgroundColor:"grey",
    },

    coverTitle:{
        fontSize:'calc(70px - 0.5vw)',
        fontWeight:'bold',
        fontFamily:'Microsoft YaHei semibold',
        '@media (max-width: 600px)':{
            fontSize:50
        },
        '@media (max-width: 500px)':{
            fontSize:30
        },
    },
    errorBorder:{
        padding:10,
        borderRadius:5,
        borderStyle:"solid",
        borderWidth:2,
        borderColor:"pink",
    }
}
