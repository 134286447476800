import React, { useState } from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import ReactPaginate from 'react-paginate';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'

import Knowledgebase from '../components/knowledgebase'
import Enlight from '../components/enlight'

import {TiDelete} from 'react-icons/ti';
import {MdRefresh} from 'react-icons/md';
import {FaRegThumbsUp,FaChild} from 'react-icons/fa';
import {GiKoala} from 'react-icons/gi';
import {FiChevronsLeft,FiChevronsRight} from 'react-icons/fi'
import {IoIosHelpCircle} from 'react-icons/io';
import { set } from 'immutable';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw,ContentState } from 'draft-js';

export default class OtherPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            recentTeamLog:[],
            recentMyLog:[],

            dashboardData:null,
            dashboardYear:"-",
            dashboardMonth:"-",
            totalMonthFree:0,
            totalMonthCM:0,

            expiringData:null,
            contactedList:[],
            notContactedList:[],
            dueList:[],
            expiringYear:"-",
            expiringMonth:"-",
            expiringItem:"ALL",
            expiringPIC:"ALL",
            allStaff:[],
            staffList:["ALL","NOBODY"],
            contactThreshold:7, //days
            selectedExpiringMemberUsername:"",
            selectedExpiringMemberName:"",
            staffSelectionModal:false,

            courseAdvisorList:[],

            unclaimedData:[],

            jtBookSaleData:[],
            jtBookShowType:"pending",   //pending || dispatched
            jtBookStat:{totalBookSold:"?",pendingPostage:"?"},

            deliveryAddressEdit:"",
            selectedDeliveryIndex:null,

            staffList:[],
            selectedStaff:null,
            memberInChargeList:[],

            mode:"pic", //offlineMember,pic

            findField:"",
            memberList:[],
            assignPICModal:false,
            modalLoading:false,

            offlineMemberWithoutPIC:0,

            staffSelectionModal:false,
            selectedMember:null,

            contractCAList:[],
            addNewCAModal:false,
            removeCAModal:false,
            caUsername:"",

            selectedJobType: '',
            walletStatList:[],
            sortedWalletStatList:[],
            filteredWalletList:[],
            sortField:"",
            sortAsc:true,
            sortDirection:"",
            allUserRPCPartialWallet:[],
            staffRPCPartialWallet:[],
            agentRPCPartialWallet:[],
            affiliateRPCPartialWallet:[],
            memberRPCPartialWallet:[],
            allUserTotalWallet:0,
            staffTotalWallet:0,
            agentTotalWallet:0,
            memberTotalWallet:0,
            affiliateTotalWallet:0,
            quickFindField:"",

            setMasterModal:false,
            masterUsername:"",
            apprenticeUsername:"",

            selectedCA:null,
            achievementData:[],
            totalSales:0,
            monthTotalSales:[],
            monthTotalCommission:[],

            leadRosterRecord:[],

            recentTeamLogPageCount:0,
            recentMyLogPageCount:0,
            page:0,

            searchTerm:"",
            helpModal:false,
            totalMemberCount:0,
            searchDateFrom:"",
            searchDateTo: new Date().toISOString().substr(0, 10), //currentDate

            contactFormList:[],
            contactFormLogList:[],
            contactFormLogModal:false,
            contactFormPageCount:0,
            contactFormPage:0,
            contactFormLogPageCount:0,
            contactFormLogPage:0,

            corporateAccountList:[],
            corporateStaffList:[],
            corporateStaffModal:false,
            addCorporateStaffModal:false,
            addCorporateStaffUsername:null,
            selectedCorporateJob:"Staff",
            staffDetailList:[],
            totalCorporateAccountPage:0,
            corporateAccountPage:0,
            totalStaffDetailPage:0,
            staffDetailPage:0,
            searchMode:"",
            totalCorporateAccountListPage:0,
            corporateAccountListPage:0,
            totalStaffCount:0,
            totalCorporateAccountCount:0,

            editCorporateStaffModal:false,
            editCorporateStaffUsername:null,
            newSelectedCorporateJob:"Staff",
        }
        this.month=["January","February","March","April","May","June","July","August","September","October","November","December"]
        this.monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"]
        this.selectSwitch="Select All"  //Select All || Deselect All
    }

    componentWillMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        //parse search path inputs and process accordingly
        let pars=window.location.search.substr(1).split("&")
        let parsedInputSplit=[]
        for(let par of pars){
            parsedInputSplit.push(par.split("="))
        }
        //process
        for(let par of parsedInputSplit){
            if(par[0]=="mode"){
                if(par[1]=="pic"){
                    this.setState({
                        mode:"pic",
                    })
                    this.picClicked()
                }else if(par[1]=="offlineMember"){
                    this.setState({
                        mode:"offlineMember",
                    })
                    this.offlineMemberClicked()
                }else if(par[1]=="staff"){
                    this.setState({
                        mode:"staff",
                    })
                    this.staffClicked()
                }else if(par[1]=="member"){
                    this.setState({
                        mode:"member",
                    })
                    this.memberClicked()
                }
                else if(par[1]=="contractCA"){
                    this.setState({
                        mode:"contractCA",
                    })
                    this.contractCAClicked()
                }else if((par[1]=="achievement")){
                    this.setState({
                        mode:"achievement",
                    })
                    this.achievementClicked()
                }else if(par[1]=="contactForm"){
                    this.setState({
                        mode:"contactForm",
                    })
                    this.contactFormClicked()
                }else if(par[1]=="corporateAccount"){
                    this.setState({mode:"corporateAccount"})
                    this.corporateAccountClicked()
                }else{
                    history.push("./")
                    return
                }
            }
        }

    }

    loadAllStaffData=(callback)=>{
        this.setState({loading:true})
        axios
            .get(`sales/loadsalesteamdata`,{timeout:30000}) //wiht extra timeout
            .then(async response => {
                this.setState({
                    staffList:response.data.salesTeam,
                    offlineMemberWithoutPIC:response.data.offlineMemberWithoutPIC,
                })
                if(callback){
                    callback()
                }else{
                    this.setState({
                        loading:false,
                    })
                }
            })
            .catch(error =>{
                alert("Load staff data error\n"+error)
                this.setState({loading:false})
            })
    }

    getMemberInCharge=(staff)=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            staffUsername:staff.member.username
        }
        axios
            .post(`sales/getmemberincharge`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //cliet side sorting
                for(var member of response.data){
                    //parse into Date object
                    member.date_joinedObject=new Date(member.date_joined)
                }
                response.data.sort(function(a,b){
                    return b.date_joinedObject-a.date_joinedObject
                })

                //parse date
                for(var member of response.data){
                    var rawDateSplit=member.date_joined.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    var timeSplit=rawDateSplit[1].split(":")
                    member.date_joined=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    mode:"pic",
                    selectedStaff:staff,
                    memberInChargeList:response.data,
                    loading:false,
                })
            })
            .catch(error =>{
                alert("Get member in charge list error\n"+error)
                this.setState({loading:false})
            })
    }

    getOfflineMemberWithoutPICList=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        axios
            .get(`sales/getofflinememberwithoutpiclist`,{timeout:30000}) //with extra timeout
            .then(async response => {
                this.setState({
                    mode:"offlineMember",
                    memberInChargeList:response.data,
                    loading:false,
                })
            })
            .catch(error =>{
                alert("Get offline member without PIC list error\n"+error)
                this.setState({loading:false})
            })
    }

    searchMember=(page=0)=>{
        this.setState({modalLoading:true})
        const payload = {
            searchTerm:this.state.findField,
            page: page,
            itemPerPage:20,
        }
        axios
            .post(`sales/findmemberlist`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                this.setState({
                    mode:"search",
                    memberList:response.data.memberList,
                    modalLoading:false,
                    totalMemberCount:response.data.totalMemberCount,
                    memberPages:response.data.theEmployeePages,
                    page:response.data.page,
                })
            })
            .catch(error =>{
                alert("Find member error\n"+error)
                this.setState({modalLoading:false})
            })
    }

    assignMember=(theMember)=>{
        this.setState({modalLoading:true})
        const payload = {
            memberUsername:theMember.username,
            staffUsername:this.state.selectedStaff.member.username,
        }
        axios
            .post(`sales/assignpic`, qs.stringify(payload))
            .then(async response => {
                this.setState({
                    assignPICModal:false,
                    modalLoading:false,
                    findField:"",
                })
                this.loadAllStaffData()
                this.getMemberInCharge(this.state.selectedStaff)
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("You do not have the priviledge to assign PIC")
                        this.setState({modalLoading:false})
                        return
                    }
                }
                alert("Assign PIC error\n"+error)
                this.setState({modalLoading:false})
            })
    }

    reverseAssignPIC=(theMember)=>{
        //bring out the staff selection modal to select staff as PIC
        this.setState({
            staffSelectionModal:true,
            selectedMember:theMember,
        })
    }
    reverseAssignPICFunc=(theStaff)=>{
        this.setState({loading:true,staffSelectionModal:false})
        const payload = {
            memberUsername:this.state.selectedMember.username,
            staffUsername:theStaff.member.username,
        }
        axios
            .post(`sales/assignpic`, qs.stringify(payload))
            .then(async response => {
                this.loadAllStaffData(this.getOfflineMemberWithoutPICList)
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("You do not have the priviledge to assign PIC")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Assign PIC error\n"+error)
                this.setState({loading:false})
            })
    }

    removeAssignedMember=(theMember)=>{
        this.setState({loading:true})
        const payload = {
            memberUsername:theMember.username,
        }
        axios
            .post(`sales/removepic`, qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                })
                this.loadAllStaffData()
                this.getMemberInCharge(this.state.selectedStaff)
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status=444){
                        alert("You do not have the priviledge to remove PIC")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Assign PIC error\n"+error)
                this.setState({loading:false})
            })
    }

    viewMember=(member)=>{
        window.open(window.location.origin+"/saleslog?username="+member.username)
    }

    loadContractCAData=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getcontractcalist`,{timeout:30000}) //wiht extra timeout
            .then(async response => {
                //parse date
                for(var ca of response.data){
                    var rawDateSplit=ca.member.date_joined.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    ca.member.date_joined=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }

                this.setState({
                    loading:false,
                    contractCAList:response.data,
                })
            })
            .catch(error =>{
                alert("Get contract CA list error\n"+error)
                this.setState({loading:false})
            })
    }

    seniorCAClicked=()=>{
        this.loadAllStaffData()
        this.setState({mode:"seniorCA"})
    }

    contractCAClicked=()=>{
        this.loadContractCAData()
        this.setState({mode:"contractCA"})
    }

    leadRosterClicked=()=>{
        this.loadLeadRoster()
        this.setState({mode:"leadRoster"})
    }

    loadLeadRoster=()=>{
        this.setState({loading:true})
        const payload = {
            companyID:"-",
            page:1,
        }
        axios
            .post(`sales/loadleadrosterrecord`,qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //parse date
                for(var rosterHistory of response.data.rosterHistoryList){
                    let rawSplit = rosterHistory.createDate.split("T")[0].split("-")
                    rosterHistory.createDate = rawSplit[2] + "." + rawSplit[1] + "." + rawSplit[0]
                }

                this.setState({
                    leadRosterRecord:response.data.rosterHistoryList,
                    loading:false,
                })
            })
            .catch(error =>{
                alert("Load lead roster error\n"+error)
                this.setState({loading:false})
            })
    }

    addNewCAFunc=()=>{
        this.setState({loading:true})
        const payload = {
            newCAUsername:this.state.caUsername
        }
        axios
            .post(`sales/addnewcontractca`, qs.stringify(payload),{timeout:20000}) //with extra timeout
            .then(async response => {
                this.setState({
                    loading:false,
                    addNewCAModal:false,
                    caUsername:"",
                })
                this.loadContractCAData()
            })
            .catch(error =>{
                alert("Add new contract CA error\n"+error)
                this.setState({loading:false})
            })
    }

    removeCAFunc=()=>{
        this.setState({loading:true})
        const payload = {
            removeCAUsername:this.state.caUsername
        }
        axios
            .post(`sales/removeca`, qs.stringify(payload),{timeout:20000}) //with extra timeout
            .then(async response => {
                this.setState({
                    loading:false,
                    removeCAModal:false,
                    caUsername:"",
                })
                this.loadContractCAData()
            })
            .catch(error =>{
                alert("Remove contract CA error\n"+error)
                this.setState({loading:false})
            })
    }

    setMasterFunc=()=>{
        this.setState({loading:true})
        const payload = {
            masterUsername:this.state.masterUsername,
            apprenticeUsername:this.state.apprenticeUsername
        }
        axios
            .post(`sales/setcontractcamaster`, qs.stringify(payload),{timeout:20000}) //with extra timeout
            .then(async response => {
                this.setState({
                    loading:false,
                    setMasterModal:false,
                    masterUsername:"",
                    apprenticeUsername:"",
                })
                this.loadContractCAData()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){ //the user is not a CA
                        alert("The leader is not in CA program")
                        this.setState({loading:false,masterUsername:"",apprenticeUsername:""})
                        return
                    }else if(error.response.status==445){
                        alert("The apprentice is not in CA program")
                        this.setState({loading:false,masterUsername:"",apprenticeUsername:""})
                        return
                    }else if(error.response.status==446){
                        alert("Limit reached. Max 5 apprentices per CA")
                        this.setState({loading:false,masterUsername:"",apprenticeUsername:""})
                        return
                    }
                }
                alert("Set contract CA leader error\n"+error)
                this.setState({loading:false})
            })
    }

    viewContractCAAchievement=(ca)=>{
        this.setState({loading:true})
        const payload = {
            contractCAID:ca.id
        }
        axios
            .post(`sales/getcontractcaachievement`, qs.stringify(payload),{timeout:20000}) //with extra timeout
            .then(async response => {
                //parse date
                for(var history of response.data){
                    var rawDateSplit=history.createDate.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    var timeSplit=rawDateSplit[1].split(":")
                    history.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //count total sales
                var totalSales=0
                for(var history of response.data){
                    totalSales+=history.value
                }

                //split the history into months
                var dataInMonth=[]
                for(var history of response.data){
                    var foundSameMonth=-1
                    for(var [index,addedHistory] of dataInMonth.entries()){
                        var addedSplit=addedHistory[0].createDate.split(" ")[0].split(".")
                        var historySplit=history.createDate.split(" ")[0].split(".")
                        if(addedSplit[2]==historySplit[2]&&addedSplit[1]==historySplit[1]){
                            foundSameMonth=index
                            break
                        }
                    }
                    if(foundSameMonth!=-1){
                        dataInMonth[foundSameMonth].push(history)
                    }else{
                        dataInMonth.push([history,])
                    }
                }

                //calculate sales and commission of month
                var monthTotalSales=[]
                var monthTotalCommission=[]
                for(var month of dataInMonth){
                    var thisMonthSales=0
                    var thisMonthCommission=0
                    for(var history of month){
                        thisMonthSales+=history.value
                        thisMonthCommission+=history.commission
                    }
                    monthTotalSales.push(thisMonthSales.toFixed(2))
                    monthTotalCommission.push(thisMonthCommission.toFixed(2))
                }

                this.setState({
                    loading:false,
                    mode:"achievement",
                    selectedCA:ca,
                    achievementData:dataInMonth,
                    totalSales:totalSales,
                    monthTotalSales:monthTotalSales,
                    monthTotalCommission:monthTotalCommission,
                })
            })
            .catch(error =>{
                alert("View contract CA achievement error\n"+error)
                this.setState({loading:false})
            })
    }

    fallbackCopyTextToClipboard=(text)=>{
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
    copyTextToClipboard=(text)=>{
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            alert('Copy to clipboard successful');
        }, function(err) {
            alert('Could not copy text: ', err);
        });
    }
    copyList=(monthArray,index)=>{
        var theText=this.month[(parseInt(monthArray[0].createDate.split(".")[1])-1)]+" "+monthArray[0].createDate.split(" ")[0].split(".")[2]+"\n"
        theText+="Sales: RM"+this.state.monthTotalSales[index]+"\t"
        theText+="Commission: RM"+this.state.monthTotalCommission[index]+"\n"
        theText+="Date\tAgenda\tValue(RM)\tCommission(RM)\n"
        for(var item of monthArray){
            theText+=item.createDate+"\t"
            theText+=item.agenda+"\t"
            theText+=(parseFloat(item.value).toFixed(2))+"\t"
            theText+=(parseFloat(item.commission).toFixed(2))+"\n"
        }
        GLOBAL.copyTextToClipboard(theText)
    }

    walletStatsClicked = (sortField, searchTerm, selectedPage, selectedJobType, sortAsc) => {
        this.setState({ loading: true })
        const payload = {
            jobType: selectedJobType,
            searchTerm: searchTerm,
            itemPerPage: 20,
            page: selectedPage,
            sort_by: sortField,
            sort_asc: sortAsc ? 1 : -1
        }
        axios
            .post(`sales/getwalletstats`, qs.stringify(payload), { timeout: 30000 })
            .then(async response => {
                console.log("after click icon",this.state.sortAsc)
                this.setState({
                mode: "walletStats",
                loading: false,
                sortField,
                walletStatList: response.data.walletData,
                sortedWalletStatList: response.data.walletData,
                page: response.data.page,
                walletStatsPageCount: response.data.totalPages,
                allUserTotalWallet: response.data.allUserTotalWallet,
                staffTotalWallet: response.data.staffTotalWallet,
                agentTotalWallet: response.data.agentTotalWallet,
                memberTotalWallet: response.data.memberTotalWallet,
                affiliateTotalWallet: response.data.affiliateTotalWallet,
                allUserRPCPartialWallet: response.data.allUserRPCPartialWallet,
                staffRPCPartialWallet: response.data.staffRPCPartialWallet,
                agentRPCPartialWallet: response.data.agentRPCPartialWallet,
                affiliateRPCPartialWallet: response.data.affiliateRPCPartialWallet,
                memberRPCPartialWallet: response.data.memberRPCPartialWallet,
                })
            })
            .catch(error => {
                alert("Get wallet stat error\n" + error)
                this.setState({ loading: false })
            })
    }

    recentTeamLogClicked=(page=0)=>{
        this.setState({loading:true})
            const payload={
                itemPerPage:20,
                page:page,
                searchTerm:this.state.searchTerm,
                dateFrom:this.state.searchDateFrom,
                dateTo:this.state.searchDateTo,
            }
            axios
            .post(`sales/getrecentteamlog`, qs.stringify(payload),{timeout:30000})
            .then(async response => {

                for(let i=0;i<response.data.serializer.length;i++){
                    if(response.data.serializer[i].message.startsWith("{")){
                        let content= convertFromRaw(JSON.parse(response.data.serializer[i].message))
                        response.data.serializer[i].message=EditorState.createWithContent(content)
                    }
                }
                this.setState({
                    mode:"recentTagLog",
                    loading:false,
                    recentTeamLog:response.data.serializer,
                    page:response.data.page,
                    recentTeamLogPageCount:response.data.totalPages,
                })
            })
            .catch(error =>{
                alert("Get recent team log error\n"+error)
                this.setState({loading:false})
            })
        }

    recentMyLogClicked=(page=0)=>{
        this.setState({loading:true})
        const payload={
            itemPerPage:20,
            page:page,
            searchTerm:this.state.searchTerm,
            dateFrom:this.state.searchDateFrom,
            dateTo:this.state.searchDateTo,
        }
        axios
            .post(`sales/getrecentmylog`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //parse the date
                for(var item of response.data.serializer){
                    var parsedDate=item.dateTime
                    parsedDate=parsedDate.split("T")
                    var parsedTime=parsedDate[1].split(":")
                    parsedTime=parsedTime[0]+":"+parsedTime[1]+":"+parsedTime[2].split(".")[0]
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.dateTime=parsedDate+" - "+parsedTime
                }
                for(let i=0;i<response.data.serializer.length;i++){
                    if(response.data.serializer[i].message.startsWith("{")){
                        let content= convertFromRaw(JSON.parse(response.data.serializer[i].message))
                        response.data.serializer[i].message=EditorState.createWithContent(content)
                    }
                }

                this.setState({
                    mode:"recentMyLog",
                    loading:false,
                    recentMyLog:response.data.serializer,
                    page:response.data.page,
                    recentMyLogPageCount:response.data.totalPages,
                })
            })
            .catch(error =>{
                alert("Get recent self log error\n"+error)
                this.setState({loading:false})
            })
    }

    dashboardClicked=(inputYear,inputMonth)=>{
        this.setState({loading:true})
        let theYear=inputYear
        let theMonth=inputMonth
        //get current date
        if(!inputYear){
            let theDate=new Date()
            theYear=theDate.getFullYear()
            theMonth=theDate.getMonth()+1
        }
        const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"]
        this.setState({
            dashboardYear:theYear,
            dashboardMonth:monthNames[theMonth-1],
        })
        const payload={
            year:theYear,
            month:theMonth,
        }
        axios
            .post(`sales/getstatisticdashboard`,qs.stringify(payload))
            .then(async response => {
                //count the total free and CM members in this month
                var totalMonthFree=0
                var totalMonthCM=0
                for(var day of response.data.userAcquisition){
                    totalMonthFree+=parseInt(day.split(";")[1])
                    totalMonthCM+=parseInt(day.split(";")[2])
                }

                this.setState({
                    mode:"dashboard",
                    loading:false,
                    dashboardData:response.data,
                    totalMonthFree:totalMonthFree,
                    totalMonthCM:totalMonthCM,
                })
            })
            .catch(error =>{
                alert("Get dashboard error\n"+error)
                this.setState({loading:false})
            })
    }

    expiringItemClicked=()=>{
        //retrive latest list of staff from server
        this.setState({loading:true})
        axios
            .get(`sales/getstafflist`,{timeout:60000})
            .then(async response => {
                //unpack staff list to pure list of name
                let serverStaffList=["ALL","NOBODY"]
                for(let staff of response.data){
                    serverStaffList.push(staff.member.name)
                }
                let theDate=new Date()
                let theYear=theDate.getFullYear()
                let theMonth=theDate.getMonth()+1
                this.setState({
                    loading:false,
                    mode:"expiring",
                    expiringYear:theYear,
                    expiringMonth:theMonth,
                    staffList:serverStaffList,
                    allStaff:response.data,
                })
            })
            .catch(error =>{
                alert("Get staff list error\n"+error)
                this.setState({loading:false})
            })
    }

    expiringItemSeek=()=>{
        this.setState({loading:true})
        var picName=this.state.expiringPIC
        if(reactLocalStorage.get("job")=="Course Advisor"){
            picName=reactLocalStorage.get("name")
        }
        const payload={
            year:this.state.expiringYear,
            month:this.state.expiringMonth,
            expiringItem:this.state.expiringItem,
            pic:picName,
        }
        axios
            .post(`sales/getexpiringvoucherandmembership`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //process returned data, split into contacted, not contacted, due list
                let expPromoList=response.data.promo
                let expMemberList=response.data.member
                var contactedList=[]
                var notContactedList=[]
                var dueList=[]
                //process promo
                if(expPromoList){
                    //cliet side sorting
                    for(var promo of expPromoList){
                        //parse into Date object
                        promo.targetUserJoinDateObject=new Date(promo.targetUserJoinDate)
                    }
                    expPromoList.sort(function(a,b){
                        return b.targetUserJoinDateObject-a.targetUserJoinDateObject
                    })

                    for(var promo of expPromoList){
                        //parse date
                        var rawDateSplit=promo.expiryDate.split("T")
                        var dateSplit=rawDateSplit[0].split("-")
                        var timeSplit=rawDateSplit[1].split(":")
                        promo.expiryDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]

                        //parse date
                        rawDateSplit=promo.targetUserJoinDate.split("T")
                        dateSplit=rawDateSplit[0].split("-")
                        timeSplit=rawDateSplit[1].split(":")
                        promo.targetUserJoinDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]

                        if(promo.lastSaleslogPeriod>this.state.contactThreshold){  //not contacted
                            notContactedList.push(promo)
                        }else if(promo.lastSaleslogPeriod<=this.state.contactThreshold&&promo.lastSaleslogPeriod>=0){    //contacted
                            contactedList.push(promo)
                        }else if(promo.lastSaleslogPeriod==-999){ //no log ever written
                            notContactedList.push(promo)
                        }else{  //due, <0
                            dueList.push(promo)
                        }
                    }
                }
                //process member
                if(expMemberList){
                    //cliet side sorting
                    for(var member of expMemberList){
                        //parse into Date object
                        member.date_joinedObject=new Date(member.date_joined)
                    }
                    expMemberList.sort(function(a,b){
                        return b.date_joinedObject-a.date_joinedObject
                    })

                    for(var member of expMemberList){
                        //parse date
                        let rawDateSplit=member.expirydate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        member.expirydate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]

                        //parse date
                        rawDateSplit=member.date_joined.split("T")
                        dateSplit=rawDateSplit[0].split("-")
                        member.date_joined=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]

                        if(member.lastSaleslogPeriod>this.state.contactThreshold){  //not contacted
                            notContactedList.push(member)
                        }else if(member.lastSaleslogPeriod<=this.state.contactThreshold&&member.lastSaleslogPeriod>=0){    //contacted
                            contactedList.push(member)
                        }else if(member.lastSaleslogPeriod==-999){  //no log ever written
                            notContactedList.push(member)
                        }else{  //due, <0
                            dueList.push(member)
                        }
                    }
                }

                this.setState({
                    loading:false,
                    expiringData:response.data,
                    contactedList:contactedList,
                    notContactedList:notContactedList,
                    dueList:dueList,
                })
            })
            .catch(error =>{
                alert("Get expiring data error\n"+error)
                this.setState({loading:false})
            })
    }

    enlightmentClicked=()=>{
        this.setState({mode:"enlight"})
    }

    knowledgebaseClicked=()=>{
        this.setState({mode:"knowledgebase"})
    }

    listExpiring=(expiringList)=>{
        return(
            <div style={{flex:1,borderStyle:"solid",borderWidth:1,display:"flex",flexDirection:"column",width:350}}>
                {
                expiringList.map((item)=>{
                    return(
                        <div>
                            {
                            item.name?  //member
                                <div style={{margin:2,fontSize:12,padding:5,backgroundColor:"#f2fffe",display:"flex",flexDirection:"row",justifyContent:"space-between",borderStyle:"solid",borderWidth:1,borderColor:"grey"}}>
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{fontWeight:"bold",}}>{item.username} {item.name}</div>
                                        </div>
                                        <div>Membership type: {item.membership_type}</div>
                                        <div>Date joined: {item.date_joined}</div>
                                        <div>Expiry date: {item.expirydate}</div>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{marginRight:5}}>Last Saleslog:</div>
                                            {
                                            item.lastSaleslogPeriod==-999?
                                                <div style={{color:"red"}}>No log ever</div>
                                                :
                                                <div>{item.lastSaleslogPeriod+"days"}</div>
                                            }
                                        </div>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{marginRight:5}}>Person in charge: </div>
                                            {
                                            item.personInCharge?
                                                <div>{item.personInCharge.name}</div>
                                                :
                                                <div style={{color:"red"}}>Nobody</div>
                                            }
                                        </div>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <Button color="secondary" style={{padding:2,fontSize:8}}
                                            onClick={()=>{
                                                window.open(window.location.origin+"/saleslog?username="+item.username)
                                            }}>
                                            View User
                                        </Button>
                                        <Button color="secondary" style={{padding:2,fontSize:8,marginTop:2}}
                                            onClick={()=>{
                                                this.reverseAssignPIC(item)
                                            }}>
                                            Assign PIC
                                        </Button>
                                    </div>
                                </div>
                                :   //promo
                                <div style={{margin:2,fontSize:12,padding:5,backgroundColor:"#fffff5",display:"flex",flexDirection:"row",justifyContent:"space-between",borderStyle:"solid",borderWidth:1,borderColor:"grey"}}>
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <div style={{fontWeight:"bold"}}>{item.title}</div>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{fontWeight:"bold",}}>{item.targetUserUsername} {item.targetUserName}</div>
                                        </div>
                                        <div>Expiry date: {item.expiryDate}</div>
                                        <div>Date joined: {item.targetUserJoinDate}</div>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{marginRight:5}}>Last Saleslog:</div>
                                            {
                                            item.lastSaleslogPeriod==-999?
                                                <div style={{color:"red"}}>No log ever</div>
                                                :
                                                <div>{item.lastSaleslogPeriod+"days"}</div>
                                            }
                                        </div>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{marginRight:5}}>Person in charge: </div>
                                            {
                                            item.picName?
                                                <div>{item.picName}</div>
                                                :
                                                <div style={{color:"red"}}>Nobody</div>
                                            }
                                        </div>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <Button color="secondary" style={{padding:2,fontSize:8}}
                                            onClick={()=>{
                                                window.open(window.location.origin+"/saleslog?username="+item.targetUserUsername)
                                            }}>
                                            View User
                                        </Button>
                                        <Button color="secondary" style={{padding:2,fontSize:8,marginTop:2}}
                                            onClick={()=>{
                                                this.reverseAssignPIC(item)
                                            }}>
                                            Assign PIC
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
                }
            </div>
        )
    }

    reverseAssignPIC=(theMember)=>{
        //bring out the staff selection modal to select staff as PIC
        var selectedExpiringMemberUsername=""
        var selectedExpiringMemberName=""
        if(!theMember.name){ //it is a promo voucher, no direct name
            selectedExpiringMemberUsername=theMember.targetUserUsername
            selectedExpiringMemberName=theMember.targetUserName
        }else{  //member
            selectedExpiringMemberUsername=theMember.username
            selectedExpiringMemberName=theMember.name
        }
        this.setState({
            staffSelectionModal:true,
            selectedExpiringMemberUsername:selectedExpiringMemberUsername,
            selectedExpiringMemberName:selectedExpiringMemberName,
        })
    }
    reverseAssignPICFunc=(theStaff)=>{
        this.setState({loading:true,staffSelectionModal:false})
        const payload = {
            memberUsername:this.state.selectedExpiringMemberUsername,
            staffUsername:theStaff.member.username,
        }
        axios
            .post(`sales/assignpic`, qs.stringify(payload))
            .then(async response => {
                //modify existing data loaded to show change of PIC
                for(var member of this.state.contactedList){
                    //promo
                    if(!member.name){
                        if(member.targetUserUsername==this.state.selectedExpiringMemberUsername){
                            member.picName=theStaff.member.name
                        }
                    }else{//member
                        if(member.username==this.state.selectedExpiringMemberUsername){
                            member.personInCharge=theStaff.member
                        }
                    }
                }
                for(var member of this.state.notContactedList){
                    //promo
                    if(!member.name){
                        if(member.targetUserUsername==this.state.selectedExpiringMemberUsername){
                            member.picName=theStaff.member.name
                        }
                    }else{//member
                        if(member.username==this.state.selectedExpiringMemberUsername){
                            member.personInCharge=theStaff.member
                        }
                    }
                }
                for(var member of this.state.dueList){
                    //promo
                    if(!member.name){
                        if(member.targetUserUsername==this.state.selectedExpiringMemberUsername){
                            member.picName=theStaff.member.name
                        }
                    }else{//member
                        if(member.username==this.state.selectedExpiringMemberUsername){
                            member.personInCharge=theStaff.member
                        }
                    }
                }

                alert("New PIC assigned to "+this.state.selectedExpiringMemberUsername+" "+this.state.selectedExpiringMemberName)
                this.setState({loading:false})
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("You do not have the priviledge to assign PIC")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Assign PIC error\n"+error)
                this.setState({loading:false})
            })
    }
    removeAssignedMember=(theMemberUsername)=>{
        this.setState({loading:true,staffSelectionModal:false})
        const payload = {
            memberUsername:theMemberUsername,
        }
        axios
            .post(`sales/removepic`, qs.stringify(payload))
            .then(async response => {
                //modify existing data loaded to show change of PIC
                for(var member of this.state.contactedList){
                    //promo
                    if(!member.name){
                        if(member.targetUserUsername==this.state.selectedExpiringMemberUsername){
                            member.picName=null
                        }
                    }else{//member
                        if(member.username==this.state.selectedExpiringMemberUsername){
                            member.personInCharge=null
                        }
                    }
                }
                for(var member of this.state.notContactedList){
                    //promo
                    if(!member.name){
                        if(member.targetUserUsername==this.state.selectedExpiringMemberUsername){
                            member.picName=null
                        }
                    }else{//member
                        if(member.username==this.state.selectedExpiringMemberUsername){
                            member.personInCharge=null
                        }
                    }
                }
                for(var member of this.state.dueList){
                    //promo
                    if(!member.name){
                        if(member.targetUserUsername==this.state.selectedExpiringMemberUsername){
                            member.picName=null
                        }
                    }else{//member
                        if(member.username==this.state.selectedExpiringMemberUsername){
                            member.personInCharge=null
                        }
                    }
                }

                alert("Removed PIC of "+this.state.selectedExpiringMemberUsername+" "+this.state.selectedExpiringMemberName)
                this.setState({loading:false})
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status=444){
                        alert("You do not have the priviledge to remove PIC")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Assign PIC error\n"+error)
                this.setState({loading:false})
            })
    }

    previousMonthStat=()=>{
        var theYear=parseInt(this.state.dashboardYear)
        var theMonth=-1
        for(var i=0;i<this.monthNames.length;i++){
            var mon=this.monthNames[i]
            if(mon==this.state.dashboardMonth){
                theMonth=i+1
                break
            }
        }
        theMonth-=1
        if(theMonth==0){
            theMonth=12
            theYear-=1
        }
        this.dashboardClicked(theYear,theMonth)
    }

    nextMonthStat=()=>{
        var theYear=parseInt(this.state.dashboardYear)
        var theMonth=-1
        for(var i=0;i<this.monthNames.length;i++){
            var mon=this.monthNames[i]
            if(mon==this.state.dashboardMonth){
                theMonth=i+1
                break
            }
        }
        theMonth+=1
        if(theMonth==12){
            theMonth=1
            theYear+=1
        }
        this.dashboardClicked(theYear,theMonth)
    }

    unclaimedReferralClicked=()=>{
        this.setState({loading:true})
        var picUsername="ALL"
        if(reactLocalStorage.get("job")=="Course Advisor"){
            picUsername=reactLocalStorage.get("username")
        }
        const payload={
            picUsername:picUsername,
        }
        axios
            .post(`sales/getallunclaimedreferral`,qs.stringify(payload))
            .then(async response => {
                //parse date
                for(var unclaimed of response.data){
                    var rawDateSplit=unclaimed.dateTime.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    unclaimed.dateTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }

                //expire in
                for(var unclaimed of response.data){
                    var theDay=parseInt(unclaimed.expireIn)
                    if(theDay==0){
                        unclaimed.expireIn="today!"
                    }else if(theDay>0){
                        unclaimed.expireIn=unclaimed.expireIn+" days"
                    }else{
                        unclaimed.expireIn="missed "+unclaimed.expireIn+" days"
                    }
                }

                this.setState({
                    mode:"unclaimedReferral",
                    loading:false,
                    unclaimedData:response.data,
                })
            })
            .catch(error =>{
                alert("Get unclaimed referral error\n"+error)
                this.setState({loading:false})
            })
    }

    jtBook=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getjtbooksaledata`)
            .then(async response => {
                //parse the date
                for(var item of response.data){
                    var parsedDate=item.createDate
                    parsedDate=parsedDate.split("T")
                    var parsedTime=parsedDate[1].split(":")
                    parsedTime=parsedTime[0]+":"+parsedTime[1]+":"+parsedTime[2].split(".")[0]
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.createDate=parsedDate+" - "+parsedTime
                }

                //item name and unit
                for(var item of response.data){
                    var itemName=item.productList[0][1]
                    var unit=item.productList[0][3]
                    item.itemName=itemName
                    item.unit=unit
                }

                //stats
                var totalBookSold=0
                for(var item of response.data){
                    totalBookSold+=parseInt(item.unit)
                }

                //selected
                for(var item of response.data){
                    item.selected=false
                }

                this.setState({
                    mode:"jtBookSaleData",
                    loading:false,
                    jtBookSaleData:response.data,
                    jtBookStat:{
                        totalBookSold:totalBookSold,
                        pendingPostage:"?",
                    },
                })
            })
            .catch(error =>{
                alert("Get jt book sale data error\n"+error)
                this.setState({loading:false})
            })
    }

    updateBookPostageDeliveryAddress=(referenceNumber)=>{
        this.setState({loading:true})
        const payload={
            referenceNumber:referenceNumber,
            newDeliveryAddress:this.state.deliveryAddressEdit,
        }
        axios
            .post(`sales/updatebookpostagedeliveryaddress`,qs.stringify(payload))
            .then(async response => {
                alert("Delivery address update successful")
                this.setState({
                    loading:false,
                    deliveryAddressEdit:"",
                    selectedDeliveryIndex:null,
                })
                this.jtBook()
            })
            .catch(error =>{
                alert("Update delivery address error\n"+error)
                this.setState({
                    loading:false,
                    deliveryAddressEdit:"",
                    selectedDeliveryIndex:null,
                })
            })
    }

    selectAllBookOrder=()=>{
        var newData=this.state.jtBookSaleData
        if(this.selectSwitch=="Select All"){
            for(var item of newData){
                if(item.dispatchStatus==this.state.jtBookShowType){
                    item.selected=true
                }
            }
            this.selectSwitch="Deselect All"
        }else{
            for(var item of newData){
                item.selected=false
            }
            this.selectSwitch="Select All"
        }
        this.setState({jtBookSaleData:newData})
    }

    markBookAsDispatched=()=>{
        //get the selected
        var selectedArray=[]
        for(var item of this.state.jtBookSaleData){
            if(item.selected){
                selectedArray.push(item.referenceNumber)
            }
        }
        if(selectedArray.length==0){
            alert("Please select an item")
            return
        }
        //serialize
        var serializedArray=""
        for(var item of selectedArray){
            serializedArray+=item+","
        }
        serializedArray=serializedArray.substring(0,serializedArray.length-1)

        this.setState({loading:true})
        const payload={
            referenceNumber:serializedArray,
        }
        axios
            .post(`sales/markbookasdispatched`,qs.stringify(payload),{responseType:"blob",})
            .then(async response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
                var fileLink = document.createElement('a')
                fileLink.href = fileURL
                fileLink.setAttribute('download', 'jnt.xlsx')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()

                this.jtBook()
            })
            .catch(error =>{
                alert("Mark book dispatched error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    viewDispatched=()=>{
        this.setState({jtBookShowType:"dispatched"})
        //deselect everything
        var newData=this.state.jtBookSaleData
        for(var item of newData){
            item.selected=false
        }
        this.setState({jtBookSaleData:newData})
    }
    viewPending=()=>{
        this.setState({jtBookShowType:"pending"})
        //deselect everything
        var newData=this.state.jtBookSaleData
        for(var item of newData){
            item.selected=false
        }
        this.setState({jtBookSaleData:newData})
    }

    setLoading=(loading)=>{
        this.setState({loading:loading})
    }

    contactFormClicked=()=>{
        this.setState({loading:true,})
        const payload={
            itemPerPage:10,
            page:this.state.contactFormPage,    
        }
        return axios
        .post('sales/loadlpcontactform',qs.stringify(payload),{timeout:40000})
        .then(response =>{
            //parse the date
            for (let item of response.data.serializer) {
                let createDate = new Date(item.createDate);
            
                let day = createDate.getDate().toString().padStart(2, '0');
                let month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
                let year = createDate.getFullYear();
                
                let hours = createDate.getHours().toString().padStart(2, '0');
                let minutes = createDate.getMinutes().toString().padStart(2, '0');
                let seconds = createDate.getSeconds().toString().padStart(2, '0');
            
                item.createDate = day + '.' + month + '.' + year + ' ' + hours + ':' + minutes + ':' + seconds;
            }
            
            this.setState({
                mode:"contactForm",
                loading:false,
                contactFormList:response.data.serializer,
                contactFormPage:response.data.page,
                contactFormPageCount:response.data.totalPages,
            })
            
        })
        .catch(error =>{
            alert("Contact form error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    corporateAccountClicked=()=>{
        this.setState({loading:true,})
        const payload={
            itemPerPage:12,
            page:this.state.corporateAccountPage,
        }
        return axios
        .post('sales/getcorporateaccountlist',qs.stringify(payload),{timeout:30000})
        .then(response =>{
            this.setState({
                mode:"corporateAccount",
                loading:false,
                corporateAccountList:response.data.corporateAccountList,
                totalCorporateAccountPage:response.data.totalPages,
                corporateAccountPage:response.data.corporateAccountPage,
                totalCorporateAccountCount:response.data.totalCorporateAccountCount,
                staffDetailList:[],
                searchTerm:"",
                totalStaffDetailPage:0,
                searchMode:""
            })
            
        })
        .catch(error =>{
            alert("Get cooporate account list error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    checkContactType=(id)=>{
        this.setState({loading:true,})
        const payload={
            contactID:id,
        }
        axios
        .post("sales/checkcontacttype",qs.stringify(payload))
        .then(response=>{
            const confirmMessage=response.data.confirmMessage
            let confirm=window.confirm(confirmMessage)
            if(confirm==true){
                this.approveContactForm(id)
            }else{
                this.setState({loading:false,})
            }
        })
        .catch(error=>{
            alert("Check contact type error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    approveContactForm=(id)=>{
        this.setState({loading:true,})
        const payload={
            contactID:id,
        }
        axios
        .post("sales/approvecontactform",qs.stringify(payload))
        .then(response=>{
            alert("Approve contact item successful!")
            this.setState({loading:false,})
            
            this.contactFormClicked()
        })
        .catch(error=>{
            alert("Approve contact item error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    deleteContactItem=(id)=>{
        let confirm=window.confirm("Are you sure to delete this contact item?")
        if(confirm==false)return

        this.setState({loading:true})
        const payload={
            contactID:id,
        }
        axios
        .post('sales/deletecontactitem',qs.stringify(payload))
        .then(response =>{
            alert("Delete contact item successful!")
            this.setState({
                loading:false
            })
            this.contactFormClicked()
        })
        .catch(error=>{
            if (error.response){
                if(error.response.status==444){
                    alert("You have no permission to delete this contact form")
                    return
                }
            }
            alert("Delete contact item error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    loadContactFormLog=()=>{
        this.setState({loading:true})
        const payload={
            itemPerPage:8,
            page:this.state.contactFormLogPage,    
        }
        return axios
        .post('sales/loadcontactformlog',qs.stringify(payload),{timeout:40000})
        .then(response =>{
            //parse the date
            for (let item of response.data.serializer) {
                let activityDate = new Date(item.activityDate);
            
                let day = activityDate.getDate().toString().padStart(2, '0');
                let month = (activityDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
                let year = activityDate.getFullYear();
                
                let hours = activityDate.getHours().toString().padStart(2, '0');
                let minutes = activityDate.getMinutes().toString().padStart(2, '0');
                let seconds = activityDate.getSeconds().toString().padStart(2, '0');
            
                item.activityDate = day + '.' + month + '.' + year + ' ' + hours + ':' + minutes + ':' + seconds;
            }
            
            this.setState({
                loading:false,
                contactFormLogList:response.data.serializer,
                contactFormLogPage:response.data.page,
                contactFormLogPageCount:response.data.totalPages,
                contactFormLogModal:true,
            })
            
        })
        .catch(error=>{
            alert("Load contact form log error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    getCorporateStaffList=(corp)=>{
        this.setState({loading:true})
        const payload={
            corpID:corp.id,
        }
        return axios
        .post('sales/getcorporatestafflist',qs.stringify(payload),{timeout:40000})
        .then(response =>{
            this.setState({
                loading:false,
                corporateStaffList:response.data,
                selectedCorp:corp,
                corporateStaffModal:true,
            })
        })
        .catch(error=>{
            alert("Get corporate staff list error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    removeStaffFromCorporate=(staff)=>{
        this.setState({loading:true})
        const payload={
            staffID:staff.id
        }
        return axios
        .post('sales/removestafffromcorporate',qs.stringify(payload),{timeout:40000})
        .then(response =>{
            alert("Staff successfully removed")
            this.getCorporateStaffList(this.state.selectedCorp)
        })
        .catch(error=>{
            alert("Remove staff from corporate error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    addCorporateStaff=()=>{
        this.setState({loading:true})
        const payload={
            staffUsername:this.state.addCorporateStaffUsername,
            job:this.state.selectedCorporateJob,
            companyID:this.state.selectedCorp.id,
        }
        return axios
        .post('sales/addcorporatestaff',qs.stringify(payload),{timeout:40000})
        .then(response =>{
            alert("Staff successfully added")
            this.setState({addCorporateStaffModal:false},()=>{
                this.getCorporateStaffList(this.state.selectedCorp)
            })
        })
        .catch(error=>{
            alert("Add corporate staff error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    editCorporateStaff=()=>{
        this.setState({loading:true})
        const payload={
            staffUsername:this.state.editCorporateStaffUsername,
            newJob:this.state.newSelectedCorporateJob,
            companyID:this.state.selectedCorp.id,
        }
        return axios
        .post('sales/editcorporatestaff',qs.stringify(payload),{timeout:40000})
        .then(response=>{
            alert("Staff successfully edited")
            this.setState({editCorporateStaffModal:false},()=>{
                this.getCorporateStaffList(this.state.selectedCorp)
            })
        })
        .catch(error=>{
            alert("Edit corporate staff error\n"+error)
            this.setState({
                loading:false,
            })
        })
    }

    searchCorporateAccountList=()=>{
        this.setState({loading:true})
        const payload={
            itemPerPage:12,
            searchTerm:this.state.searchTerm,
            page:this.state.staffDetailPage,
            accountPage:this.state.corporateAccountListPage,
            staffItemPerPage:16
        }
        return axios
            .post('sales/searchcorporateaccountlist',qs.stringify(payload),{timeout:40000})
            .then(response =>{
                this.setState({
                    loading:false,
                    mode:"corporateAccount",
                    staffDetailList:response.data.staffDetailList,
                    totalStaffDetailPage:response.data.totalPages,
                    staffDetailPage:response.data.staffDetailPage,
                    corporateAccountList:response.data.corporateAccountList,
                    corporateAccountListPage:response.data.corporateAccountListPage,
                    totalCorporateAccountListPage:response.data.totalCorporateAccountPage,
                    searchMode:"search",
                    totalStaffCount:response.data.totalStaffCount
                })
        })
        .catch(error =>{
            alert("Get all corporate account list error\n" + error)
            this.setState({
                loading:false,
            })
        })
    }

    handleSearch=()=>{
        if (this.state.searchTerm.length>=3) {
            this.searchCorporateAccountList()
        } 
        else {
            this.corporateAccountClicked()
            this.setState({
                loading:false,
                mode:"corporateAccount",
            })
            alert("Search field must contain at least 3 characters.")
        }
    }
    handlePageChange=(selectedPage, listType)=>{
        if (listType ==='corporateAccount'){
            this.setState({ corporateAccountListPage:selectedPage}, () => {
                this.searchCorporateAccountList()
            })
        } else if (listType==='staffDetail'){
            this.setState({staffDetailPage:selectedPage}, () => {
                this.searchCorporateAccountList()
            })
        }
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal isOpen={this.state.addCorporateStaffModal} toggle={()=>this.setState({addCorporateStaffModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Add Staff to {this.state.selectedCorp!=null?this.state.selectedCorp.name:null}</div>
                        <Input placeHolder="Username" value={this.state.addCorporateStaffUsername} style={{width:"100%",marginTop:10}} onChange={(e)=>{
                            this.setState({addCorporateStaffUsername:e.target.value.replace(/\D/g,"")})
                        }} />
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10,marginTop:10}}>
                            <div>Job : </div>
                            <Button color={this.state.selectedCorporateJob=="Staff"?"primary":"secondary"} style={{}} onClick={()=>{
                                this.setState({selectedCorporateJob:"Staff"})
                            }}>
                                Staff
                            </Button>
                            <Button color={this.state.selectedCorporateJob=="Admin"?"primary":"secondary"} style={{}} onClick={()=>{
                                this.setState({selectedCorporateJob:"Admin"})
                            }}>
                                Admin
                            </Button>
                        </div>
                        
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.addCorporateStaff()
                            }}>
                                Add
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({addCorporateStaffModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.corporateStaffModal} toggle={()=>this.setState({corporateStaffModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:16,fontWeight:"bold"}}>{this.state.selectedCorp!=null?this.state.selectedCorp.name:null}</div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,padding:"10px 0px"}}>
                            <Button color="primary" style={{}} onClick={()=>{
                                this.setState({addCorporateStaffModal:true})
                            }}>
                                Add Staff
                            </Button>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",gap:5,width:"100%"}}>
                            {
                            this.state.corporateStaffList.map((staff)=>{
                                return(
                                    <div style={{width:"100%",backgroundColor:"#f8f7fc",border:"1px solid #6d54c7",borderRadius:5,padding:5,
                                        display:"flex",flexDirection:"row",gap:10,justifyContent:"space-between",
                                    }}>
                                        <div>
                                            <div>{staff.staff.username+" "+staff.staff.name}</div>
                                            <div>{staff.job}</div>
                                        </div>
                                        <div style={{display:"flex",flexDirection:"column",gap:10}}>
                                            <Button color="danger" style={{padding:"2px 5px",fontSize:12}} onClick={()=>{
                                                if(!window.confirm("Are you sure you want to remove "+staff.staff.username+" "+staff.staff.name+" from "+this.state.selectedCorp.name)){
                                                    return
                                                }
                                                this.removeStaffFromCorporate(staff)
                                            }}>
                                                Remove
                                            </Button>
                                            {staff.job !== "Principal" && (
                                            <Button color="primary" style={{padding:"2px 5px",fontSize:12}} onClick={()=>{
                                                 this.setState({
                                                    editCorporateStaffModal: true,
                                                    editCorporateStaffUsername: staff.staff.username,
                                                    newSelectedCorporateJob: staff.job
                                                })
                                            }}>
                                                Edit
                                            </Button>
                                            )}
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:20,gap:10,width:"100%"}}>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({corporateStaffModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.editCorporateStaffModal} toggle={()=>this.setState({editCorporateStaffModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{ fontSize: 20, fontWeight: "bold" }}>
                        Edit Staff Job for {this.state.selectedCorp ? this.state.selectedCorp.name : ""}
                        </div>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10,marginTop:10}}>
                            <div>Job : </div>
                            <Button color={this.state.newSelectedCorporateJob==="Staff"?"primary":"secondary"} style={{}} onClick={()=>{
                                this.setState({newSelectedCorporateJob:"Staff"})
                            }}>
                                Staff
                            </Button>
                            <Button color={this.state.newSelectedCorporateJob==="Admin"?"primary":"secondary"} style={{}} onClick={()=>{
                                this.setState({newSelectedCorporateJob:"Admin"})
                            }}>
                                Admin
                            </Button>
                        </div>
                        
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.editCorporateStaff(this.state.newSelectedCorporateJob)
                                this.setState({editCorporateStaffModal:false})
                            }}>
                                Edit
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({editCorporateStaffModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.contactFormLogModal} toggle={()=>this.setState({contactFormLogModal:false})} style={{maxWidth:600}}>
                    <div style={{padding:20}}>
                        <table style={{width:"100%",borderCollapse:"collapse"}}>
                            <thead>
                                <tr>
                                    {
                                    reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                        <th style={{padding:10,}}>Company</th>
                                        :
                                        null
                                    }
                                    <th style={{padding:10,}}>Date</th>
                                    <th style={{padding:10,}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.contactFormLogList.map((item)=>{
                                    return(
                                        <tr key={item.id} style={{border:"1px solid lightgrey"}}>
                                            {
                                            reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                                <td style={{padding:10,}}>{item.company.name}</td>
                                                :
                                                null
                                            }
                                            <td style={{padding:10,}}>{item.activityDate}</td>
                                            <td style={{padding:10,fontSize:13}}>{item.description}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                    <div>
                        <ReactPaginate
                            pageCount={this.state.contactFormLogPageCount}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                            forcePage={this.state.contactFormLogPage}
                            onPageChange={async ({selected}) => {
                                this.setState({contactFormLogPage:selected},()=>{
                                    this.loadContactFormLog()
                                })
                            }}
                            />
                        </div>
                    }
                </Modal>

                <Modal isOpen={this.state.helpModal} toggle={()=>this.setState({helpModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Tips</div>
                        <div style={{display:"flex",flexDirection:"column",marginBottom:10,gap:5}}>
                            <br/><b>Search for contents:</b> 营销三力<br/>
                            <b>Search for People:</b> David<br/>
                            <b>Search for Date:</b> Year-Month-Date <t>eg: 2023-4-11</t><br/>

                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({helpModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.staffSelectionModal} toggle={()=>this.setState({staffSelectionModal:false})} style={{width:"80%",maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap",marginBottom:5}}>
                            {
                            this.state.staffList.map((item)=>{
                                return(
                                    <div style={{backgroundColor:"#dedede",padding:10,borderRadius:5,margin:5,width:250}}
                                        onClick={()=>{
                                            this.reverseAssignPICFunc(item)
                                        }}>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div>{item.member?item.member.name:null}</div>
                                            {
                                            item.job=="Sales Supervisor"?
                                                <GiKoala style={{fontSize:18,marginLeft:5}}/>
                                                :null
                                            }
                                        </div>
                                        <div><FaChild style={{fontSize:20}}/>{item.PICCount}</div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.assignPICModal} toggle={()=>this.setState({assignPICModal:false})} style={{width:"90%",maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5}}>
                            <Input type="text" name="username" placeholder="Member's name / email / phone number / company name / refer code"
                                style={{marginRight:10}}
                                value={this.state.findField}
                                onChange={event => this.setState({findField: event.target.value})}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        this.searchMember()
                                    }
                                }}/>
                            <Button color="primary" onClick={()=>{this.searchMember()}}>Search</Button>
                        </div>
                        <div style={{marginBottom:20,color:"grey",paddingLeft:10,display:"flex",flexDirection:"row"}}>
                            {this.state.memberList.length} results found.
                        </div>
                        <div style={{paddingLeft:10,paddingRight:10}}>
                            <Row style={{paddingBottom:20,borderBottomWidth:1,borderLeftWidth:0,borderTopWidth:0,borderRightWidth:0,
                                borderStyle:"solid",borderColor:"lightgrey"}}>
                                <Col style={styles.columnTitleStyle} xs={2}>Phone</Col>
                                <Col style={styles.columnTitleStyle} xs={2}>Name</Col>
                                <Col style={styles.columnTitleStyle} xs={2}>Email</Col>
                                <Col style={styles.columnTitleStyle} xs={1}>Membership</Col>
                                <Col style={styles.columnTitleStyle} xs={2}>Join Date</Col>
                                <Col style={styles.columnTitleStyle} xs={2}>PIC</Col>
                                <Col xs={1}></Col>
                            </Row>
                            {
                            this.state.memberList.length>0?
                                this.state.memberList.map((item)=>{
                                    return(
                                        <Row style={styles.searchResultRow}>
                                            <Col xs={2}>{item.username}</Col>
                                            <Col xs={2}>{item.name}</Col>
                                            <Col xs={2}>{item.email}</Col>
                                            <Col xs={1}>{item.membership_type}</Col>
                                            <Col xs={2}>{item.date_joined}</Col>
                                            <Col xs={2}>{item.personInCharge?item.personInCharge.name:"-"}</Col>
                                            <Col xs={1}>
                                                <Button color="primary" onClick={()=>{this.assignMember(item)}}>
                                                    Assign
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                                :
                                <div> ------- </div>
                            }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.addNewCAModal} toggle={()=>this.setState({addNewCAModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>Add New Contract CA</div>
                        <Input type="text" style={{marginTop:20}} value={this.state.caUsername} placeHolder="Username (phone number)" onChange={(e)=>{
                            this.setState({caUsername:e.target.value.replace(/\D/g,"")})
                        }} />
                        <Button color="primary" style={{width:"100%",marginTop:20}} onClick={()=>{
                            this.addNewCAFunc()
                        }}>
                            Create
                        </Button>
                    </div>
                </Modal>

                <Modal isOpen={this.state.removeCAModal} toggle={()=>this.setState({removeCAModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>Remove Contract CA</div>
                        <Input type="text" style={{marginTop:20}} value={this.state.caUsername} placeHolder="Username (phone number)" onChange={(e)=>{
                            this.setState({caUsername:e.target.value.replace(/\D/g,"")})
                        }} />
                        <Button color="primary" style={{width:"100%",marginTop:20}} onClick={()=>{
                            this.removeCAFunc()
                        }}>
                            Remove
                        </Button>
                    </div>
                </Modal>

                <Modal isOpen={this.state.setMasterModal} toggle={()=>this.setState({setMasterModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>Set Leader</div>
                        <div style={{marginTop:20}}>The Team Member:</div>
                        <Input type="text" style={{marginTop:10}} value={this.state.apprenticeUsername} placeHolder="Apprentice's Username (phone number)" onChange={(e)=>{
                            this.setState({apprenticeUsername:e.target.value.replace(/\D/g,"")})
                        }} />
                        <div style={{marginTop:15}}>The Leader:</div>
                        <Input type="text" style={{marginTop:10}} value={this.state.masterUsername} placeHolder="Leader's Username, leave empty to remove" onChange={(e)=>{
                            this.setState({masterUsername:e.target.value.replace(/\D/g,"")})
                        }} />
                        <Button color="primary" style={{width:"100%",marginTop:20}} onClick={()=>{
                            this.setMasterFunc()
                        }}>
                            Set Leader
                        </Button>
                    </div>
                </Modal>

                <Modal isOpen={this.state.staffSelectionModal} toggle={()=>this.setState({staffSelectionModal:false})} style={{width:"80%",maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>
                            {this.state.selectedExpiringMemberUsername+" "+this.state.selectedExpiringMemberName}
                        </div>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap",marginBottom:5}}>
                            {
                            this.state.allStaff.map((item)=>{
                                return(
                                    <div style={{backgroundColor:"#dedede",padding:10,borderRadius:5,margin:5,width:250}}
                                        onClick={()=>{
                                            this.reverseAssignPICFunc(item)
                                        }}>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div>{item.member.name}</div>
                                            {
                                            item.job=="Sales Supervisor"?
                                                <GiKoala style={{fontSize:18,marginLeft:5}}/>
                                                :null
                                            }
                                        </div>
                                        <div><FaChild style={{fontSize:20}}/>{item.PICCount}</div>
                                    </div>
                                )
                            })
                            }
                            <Button color="primary" onClick={()=>{
                                this.removeAssignedMember(this.state.selectedExpiringMemberUsername)
                            }}>
                                NOBODY
                            </Button>
                        </div>
                    </div>
                </Modal>

                <NavigationBar currentPage="other" />
                <div style={{padding:10,borderBottom:"1px solid lightgrey",display:"flex",flexDirection:"row",gap:10,width:"100%",flexWrap:"wrap"}}>                   
                    {
                    GLOBAL.checkPermission("<(>-Q:e1")?
                        <Button color="danger" style={{}} onClick={()=>{this.dashboardClicked()}}>
                            Statistics
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("wy>Y^y-j")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.expiringItemClicked()}}>
                            Expiring Vouchers/Membership
                        </Button>   
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("AewsWXb]")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.unclaimedReferralClicked()}}>
                            Unclaimed Referral
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("=h)Gmk).")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.jtBook()}}>
                            讲透战略book
                        </Button>
                        :null
                    }
                    {
                    GLOBAL.checkPermission("KzexnUx]")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.enlightmentClicked()}}>
                            启蒙运动
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("&HKP$Y.4")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.knowledgebaseClicked()}}>
                            Knowledgebase
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("o~:&IKgI")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.recentTeamLogClicked()}}>
                            Team Recent Logs
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("($CkxVIT")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.recentMyLogClicked()}}>
                            My Recent Logs
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("$bK-]$Nu")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.leadRosterClicked()}}>
                            Lead Roster
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission(")lhAUita")?
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.walletStatsClicked()}}>
                            Wallet Stat
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("SWq~B9mV")? //Load Landing Page Contact Form
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.contactFormClicked()}}>
                            Contact Form 
                        </Button>
                        :
                        null
                    }
                    {
                    GLOBAL.checkPermission("lA_iIb>v")? //load all corporate account list
                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{this.corporateAccountClicked()}}>
                            Corporate Account
                        </Button>
                        :
                        null
                    }
                </div>
                <div style={{}}>
                    {
                    this.state.mode=="knowledgebase"?
                        <Knowledgebase setLoading={this.setLoading} />
                        :
                    this.state.mode=="enlight"?
                        <Enlight setLoading={this.setLoading} />
                        :
                    this.state.mode=="jtBookSaleData"?
                        <div style={{padding:20}}>
                            <div style={{display:"flex",flexDirection:"row",marginBottom:10}}>
                                <div>Total books sold: {this.state.jtBookStat.totalBookSold}</div>
                                <div style={{marginLeft:10}}>Pending postage: {this.state.jtBookStat.pendingPostage}</div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",marginBottom:10}}>
                                <Button color="primary" style={{}} onClick={()=>{
                                    this.markBookAsDispatched()
                                }}>
                                    Mark as dispatched
                                </Button>
                                <Button color="success" style={{marginLeft:30}} onClick={()=>{
                                    this.viewPending()
                                }}>
                                    View Pending
                                </Button>
                                <Button color="success" style={{marginLeft:10}} onClick={()=>{
                                    this.viewDispatched()
                                }}>
                                    View Dispatched
                                </Button>
                            </div>
                            <div style={{display:"flex",flexDirection:"row"}}>
                                <div style={{flex:0.05,fontWeight:"bold",cursor:"pointer",color:"blue",textDecoration:"underline",textAlign:"center"}} onClick={()=>{
                                    this.selectAllBookOrder()
                                }}>Σ</div>
                                <div style={{flex:0.1,fontWeight:"bold"}}>Date</div>
                                <div style={{flex:0.3,fontWeight:"bold"}}>User</div>
                                <div style={{flex:0.1,fontWeight:"bold"}}>Item</div>
                                <div style={{flex:0.1,fontWeight:"bold"}}>Units</div>
                                <div style={{flex:0.35,fontWeight:"bold"}}>Delivery Address</div>
                                <div style={{flex:0.2,fontWeight:"bold"}}>Comment</div>
                            </div>
                            {
                            this.state.jtBookSaleData.map((item,index) => {
                                if(item.dispatchStatus==this.state.jtBookShowType){
                                    return(
                                        <div style={{display:"flex",flexDirection:"row",borderStyle:"solid",borderWidth:0,borderTopWidth:1,borderColor:"lightgrey"}}>
                                            <div style={{flex:0.05,display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                <input type="checkbox" style={{width:20,height:20,cursor:"pointer",margin:2}} checked={item.selected} onClick={()=>{
                                                    var newData=this.state.jtBookSaleData
                                                    newData[index].selected=!newData[index].selected
                                                    this.setState({jtBookSaleData:newData})
                                                }} />
                                            </div>
                                            <div style={{flex:0.1,fontSize:12}}>{item.createDate}</div>
                                            <div style={{flex:0.3}}>{item.clientPhone+" "+item.clientName}</div>
                                            <div style={{flex:0.1}}>{item.itemName}</div>
                                            <div style={{flex:0.1}}>{item.unit}</div>
                                            <div style={{flex:0.35,fontSize:12}}>
                                                {
                                                this.state.selectedDeliveryIndex==index?
                                                    <div style={{paddingRight:5}}>
                                                        <textarea type="text" rows="5" style={{width:"100%",marginTop:5}} value={this.state.deliveryAddressEdit} onChange={(e)=>{
                                                            this.setState({deliveryAddressEdit:e.target.value})
                                                        }}/>
                                                        <div style={{marginLeft:5,display:"flex",flexDirection:"row"}}>
                                                            <Button color="danger" style={{padding:"0px 5px",fontSize:12}} onClick={()=>{
                                                                this.updateBookPostageDeliveryAddress(item.referenceNumber)
                                                            }}>
                                                                Save
                                                            </Button>
                                                            <Button color="secondary" style={{padding:"0px 5px",fontSize:12,marginLeft:5}} onClick={()=>{
                                                                this.setState({deliveryAddressEdit:"",selectedDeliveryIndex:null})
                                                            }}>
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        {item.clientDeliveryAddress}
                                                        <Button color="primary" style={{padding:"0px 5px",fontSize:12,marginLeft:5}} onClick={()=>{
                                                            this.setState({deliveryAddressEdit:item.clientDeliveryAddress,selectedDeliveryIndex:index})
                                                        }}>
                                                            Edit
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                            <div style={{flex:0.2,fontSize:12}}>{item.invoiceComment}</div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                        :
                    this.state.mode=="unclaimedReferral"?
                        <div style={{padding:20}}>
                            <div style={{display:"flex",flexDirection:"row"}}>
                                <div style={{flex:0.1,fontWeight:"bold"}}>Date</div>
                                <div style={{flex:0.4,fontWeight:"bold"}}>Agenda</div>
                                <div style={{flex:0.3,fontWeight:"bold"}}>User</div>
                                <div style={{flex:0.1,fontWeight:"bold"}}>Value</div>
                                <div style={{flex:0.1,fontWeight:"bold"}}>Expire in</div>
                            </div>
                            {
                            this.state.unclaimedData.map(item => {
                                return(
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <div style={{flex:0.1}}>{item.dateTime}</div>
                                        <div style={{flex:0.4}}>{item.agenda}</div>
                                        <div style={{flex:0.3}}>
                                            {
                                            item.user.username==item.user.name?
                                                item.user.name
                                                :
                                                item.user.username+" "+item.user.name
                                            }
                                        </div>
                                        <div style={{flex:0.1}}>RM{item.value}</div>
                                        <div style={{flex:0.1}}>{item.expireIn}</div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    :
                    this.state.mode=="recentTagLog"?
                        <div style={{padding:20}}>
                        <div style={{marginBottom:10}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center", marginTop:10}}>
                            <Input type="text" name="username" placeholder="Member's name / email / phone number / company name / refer code / PIC (filter function click ? on right)"
                                style={{marginRight:10}}
                                value={this.state.searchTerm}
                                onChange={event => this.setState({searchTerm: event.target.value})}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        if(this.state.mode=="recentTagLog")
                                            this.recentTeamLogClicked()
                                        else if(this.state.mode=="recentMyLog")
                                            this.recentMyLogClicked()

                                    }
                                }}/>
                            <div style={{position: "relative"}}>
                                <Input type = "date" style={{marginRight:10, width:"140px"}} onChange={(e)=>{this.setState({searchDateFrom:e.target.value})}}/>
                                <span style={{position: "absolute", top: -15, left: 5, fontSize: "12px", color: "#aaa"}}>
                                    From
                                </span>
                            </div>
                            {/* <Input type = "date" style={{marginRight:10, width:"140px", fontSize:12,boxSizing:"border-box"}} onChange={(e)=>{this.setState({searchDateFrom:e.target.value})}}/> */}
                            {/* <Input type = "date" style={{marginRight:10, width:"140px"}} value={this.state.searchDateTo} onChange={(e)=>{this.setState({searchDateTo:e.target.value})}}/> */}
                            <div style={{position: "relative"}}>
                            <Input type = "date" style={{marginRight:10, width:"140px"}} value={this.state.searchDateTo} onChange={(e)=>{this.setState({searchDateTo:e.target.value})}}/>
                                <span style={{position: "absolute", top: -15, left: 5, fontSize: "12px", color: "#aaa"}}>
                                    To
                                </span>
                            </div>
                            <IoIosHelpCircle style={{color:"skyblue",fontSize:35,marginRight:10,cursor:"pointer"}} onClick={()=>{
                                this.setState({helpModal:true})
                            }} />
                            <Button color="primary" style={{paddingLeft:20,paddingRight:20}} onClick={()=>{
                                if(this.state.mode=="recentTagLog")
                                    this.recentTeamLogClicked()
                                else if(this.state.mode=="recentMyLog")
                                    this.recentMyLogClicked()
                                }}>Search</Button>
                        </div>
                        </div>
                            {
                            this.state.recentTeamLog.map(item => {
                                return(
                                    <div style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",marginBottom:5,padding:5,borderRadius:5}} key={item.dateTime}>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                            <div style={{display:"flex",flexDirection:"row"}}>
                                                <div style={{fontWeight:"bold"}}>{item.loggerName}</div>
                                                <div style={{color:"grey",fontSize:14,marginLeft:20}}>{item.dateTime}</div>
                                            </div>
                                            <div style={{fontWeight:"bold"}}>{item.clientName}</div>
                                        </div>
                                        <div>{
                                        typeof item.message != 'string' ?
                                            <Editor
                                                editorState={item.message}
                                                readOnly={true}
                                                toolbarHidden={true}
                                            />
                                        :
                                        <div>{item.message}
                                        </div>
                                        }</div>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                                            {item.tag.map((item)=>{
                                                return(
                                                    <div style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 4px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,marginRight:5,paddingLeft:5,paddingRight:5,borderRadius:5}}>
                                                        {item.name}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                            }
                            {
                            <div>
                                <ReactPaginate
                                    pageCount={this.state.recentTeamLogPageCount}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLabel={"..."}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    disabledClassName={"disabled"}
                                    forcePage={this.state.page}
                                    onPageChange={async ({selected}) => {
                                        this.recentTeamLogClicked(selected);
                                    }}
                                    />
                                </div>
                            }
                        </div>
                    :
                    this.state.mode=="recentMyLog"?
                        <div style={{padding:20}}>
                        <div style={{marginBottom:10}}>
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center", marginTop:10}}>
                            <Input type="text" name="username" placeholder="Member's name / email / phone number / company name / refer code / PIC (filter function click ? on right)"
                                style={{marginRight:10}}
                                value={this.state.searchTerm}
                                onChange={event => this.setState({searchTerm: event.target.value})}
                                onKeyDown={(e)=>{
                                    if(e.keyCode==13){
                                        e.preventDefault()
                                        if(this.state.mode=="recentTagLog")
                                            this.recentTeamLogClicked()
                                        else if(this.state.mode=="recentMyLog")
                                            this.recentMyLogClicked()

                                    }
                                }}/>
                            <div style={{position: "relative"}}>
                                <Input type = "date" style={{marginRight:10, width:"140px"}} onChange={(e)=>{this.setState({searchDateFrom:e.target.value})}}/>
                                <span style={{position: "absolute", top: -15, left: 5, fontSize: "12px", color: "#aaa"}}>
                                    From
                                </span>
                            </div>
                            {/* <Input type = "date" style={{marginRight:10, width:"140px", fontSize:12,boxSizing:"border-box"}} onChange={(e)=>{this.setState({searchDateFrom:e.target.value})}}/> */}
                            {/* <Input type = "date" style={{marginRight:10, width:"140px"}} value={this.state.searchDateTo} onChange={(e)=>{this.setState({searchDateTo:e.target.value})}}/> */}
                            <div style={{position: "relative"}}>
                            <Input type = "date" style={{marginRight:10, width:"140px"}} value={this.state.searchDateTo} onChange={(e)=>{this.setState({searchDateTo:e.target.value})}}/>
                                <span style={{position: "absolute", top: -15, left: 5, fontSize: "12px", color: "#aaa"}}>
                                    To
                                </span>
                            </div>
                            <IoIosHelpCircle style={{color:"skyblue",fontSize:35,marginRight:10,cursor:"pointer"}} onClick={()=>{
                                this.setState({helpModal:true})
                            }} />
                            <Button color="primary" style={{paddingLeft:20,paddingRight:20}} onClick={()=>{
                                if(this.state.mode=="recentTagLog")
                                    this.recentTeamLogClicked()
                                else if(this.state.mode=="recentMyLog")
                                    this.recentMyLogClicked()
                                }}>Search</Button>
                        </div>
                        </div>
                        {
                        this.state.recentMyLog.map((item)=>{
                            return(
                                <div style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",marginBottom:5,padding:5,borderRadius:5}} key={item.dateTime}>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div style={{fontWeight:"bold"}}>{item.loggerName}</div>
                                            <div style={{color:"grey",fontSize:14,marginLeft:20}}>{item.dateTime}</div>
                                        </div>
                                        <div style={{fontWeight:"bold"}}>{item.clientName}</div>
                                    </div>
                                    <div>
                                        {
                                        typeof item.message != 'string' ?
                                            <Editor
                                                editorState={item.message}
                                                readOnly={true}
                                                toolbarHidden={true}
                                            />
                                        :
                                        <div>{item.message}</div>
                                        }
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                                        {item.tag.map((item)=>{
                                            return(
                                                <div style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 4px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,marginRight:5,paddingLeft:5,paddingRight:5,borderRadius:5}}>
                                                    {item.name}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                        }
                        {
                            <div>
                                <ReactPaginate
                                    pageCount={this.state.recentMyLogPageCount}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLabel={"..."}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    disabledClassName={"disabled"}
                                    forcePage={this.state.page}
                                    onPageChange={async ({selected}) => {
                                        this.recentMyLogClicked(selected);
                                    }}
                                    />
                            </div>
                        }
                        </div>
                    :
                    this.state.mode=="walletStats"?
                        <div style={{padding:20}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5}}>
                                <Input type="text" name="username" placeholder="Member's name / phone number"
                                    style={{marginRight:10}}
                                    value={this.state.searchTerm}
                                    onChange={event => this.setState({searchTerm: event.target.value})}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.walletStatsClicked(this.state.sortField, this.state.searchTerm, this.state.page, this.state.selectedJobType, this.state.sortAsc)
                                        }
                                    }}
                                    />
                                <Button color="primary" onClick={()=>{this.walletStatsClicked(this.state.sortField, this.state.searchTerm, this.state.page, this.state.selectedJobType, this.state.sortAsc)}}>Search</Button>
                            </div>
                            <div style={{ paddingLeft: 10, paddingRight: 10 ,marginBottom: 20}}>
                                <table style={{ width: "100%", borderCollapse: "collapse" }} >
                                    <thead>
                                        <tr>
                                            <th style={{ padding: 5, textAlign: 'center' }}>
                                                Username
                                                <button style={{cursor: 'pointer', padding: 3, border: "0px", backgroundColor: 'transparent' }}
                                                    onClick={() =>
                                                        this.setState({sortAsc:!this.state.sortAsc},()=>{
                                                            this.walletStatsClicked('username', "", this.state.page, this.state.selectedJobType, this.state.sortAsc)
                                                            })
                                                        }>
                                                </button>
                                            </th>
                                            <th style={{ padding: 5, textAlign: 'center' }}>
                                                Name
                                                <button style={{cursor: 'pointer', padding: 3, border: "0px", backgroundColor: 'transparent' }}
                                                    onClick={() =>
                                                        this.setState({sortAsc:!this.state.sortAsc},()=>{
                                                            this.walletStatsClicked('name', "", this.state.page, this.state.selectedJobType, this.state.sortAsc)
                                                            })
                                                        }>
                                                </button>
                                            </th>
                                            <th style={{ padding: 5, textAlign: 'right' }}>
                                                R Wallet
                                                <button style={{cursor: 'pointer', padding: 3, border: "0px", backgroundColor: 'transparent' }}
                                                    onClick={() =>
                                                        this.setState({sortAsc:!this.state.sortAsc},()=>{
                                                            this.walletStatsClicked('rWallet', "", this.state.page, this.state.selectedJobType, this.state.sortAsc)
                                                            })
                                                        }>
                                                        {this.state.sortField === 'rWallet' && this.state.sortAsc ? <span>&uarr;</span> : <span>&darr;</span>}
                                                </button>
                                            </th>
                                            <th style={{ padding: 5, textAlign: 'right' }}>
                                                Installment Wallet
                                                <button style={{cursor: 'pointer', padding: 3, border: "0px", backgroundColor: 'transparent' }}
                                                    onClick={() =>
                                                        this.setState({sortAsc:!this.state.sortAsc},()=>{
                                                            this.walletStatsClicked('partialWallet', "", this.state.page, this.state.selectedJobType, this.state.sortAsc)
                                                            })
                                                        }>
                                                        {this.state.sortField === 'partialWallet' && this.state.sortAsc ? <span>&uarr;</span> : <span>&darr;</span>}
                                                </button>
                                            </th>
                                            <th style={{ padding: 5, textAlign: 'right' }}>
                                                P Wallet
                                                <button style={{cursor: 'pointer', padding: 3, border: "0px", backgroundColor: 'transparent' }}
                                                    onClick={() =>
                                                        this.setState({sortAsc:!this.state.sortAsc},()=>{
                                                            this.walletStatsClicked('pWallet', "", this.state.page, this.state.selectedJobType, this.state.sortAsc)
                                                            })
                                                        }>
                                                        {this.state.sortField === 'pWallet' && this.state.sortAsc ? <span>&uarr;</span> : <span>&darr;</span>}
                                                </button>
                                            </th>
                                            <th style={{ padding: 5, textAlign: 'right' }}>
                                                C Wallet
                                                <button style={{cursor: 'pointer', padding: 3, border: "0px", backgroundColor: 'transparent' }}
                                                    onClick={() =>
                                                        this.setState({sortAsc:!this.state.sortAsc},()=>{
                                                            this.walletStatsClicked('cWallet', "", this.state.page, this.state.selectedJobType, this.state.sortAsc)
                                                            })
                                                        }>
                                                        {this.state.sortField === 'cWallet' && this.state.sortAsc ? <span>&uarr;</span> : <span>&darr;</span>}
                                                </button>
                                            </th>
                                            <th>
                                                <select style={{ padding: 5, textAlign:'left', cursor: 'pointer', border: "0px" }}
                                                    value={this.state.selectedJobType}
                                                    onChange={(event) => {
                                                        const selectedJobType = event.target.value
                                                        this.setState({ selectedJobType }, () => {
                                                            this.walletStatsClicked('','', 0, selectedJobType,'')
                                                        })
                                                    }}
                                                >
                                                    <option value="">All User</option>
                                                    <option value="Member">Member</option>
                                                    <option value="Staff">Staff</option>
                                                    <option value="Agent">Agent</option>
                                                    <option value="Affiliate">Affiliate</option>
                                                </select>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.sortedWalletStatList.map((item) => (
                                            <tr key={item.username} style={{ border: "1px solid lightgrey" }}>
                                                <td style={{ padding: 5, textAlign:'center', width: "15%" }}>{item.username}</td>
                                                <td style={{ padding: 5, textAlign:'center', width: "20%" }}>{item.name}</td>
                                                <td style={{ padding: 5, textAlign:'right', width: "13%" }}>{item.rWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                                <td style={{ padding: 5, textAlign:'right', width: "13%" }}>{item.partialWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                                <td style={{ padding: 5, textAlign:'right', width: "13%" }}>{item.pWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                                <td style={{ padding: 5, textAlign:'right', width: "13%" }}>{item.cWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                                <td style={{ padding: 5, textAlign:'center', width: "13%" }}>
                                                    <Button color="primary" onClick={() => {
                                                        history.push("/saleslog?username=" + item.username)
                                                    }}>
                                                        View Profile
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {
                            <div>
                                <ReactPaginate
                                    pageCount={this.state.walletStatsPageCount}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLabel={"..."}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    disabledClassName={"disabled"}
                                    forcePage={this.state.page}
                                    onPageChange={async ({selected}) => {
                                        this.walletStatsClicked(this.state.sortField,this.state.quickFindField,selected,this.state.selectedJobType,this.state.sortAsc)
                                    }}
                                />
                            </div>
                            }
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: 50 }}>
                            <table style={{ borderCollapse: "collapse", width: "50%" }}>
                                <thead>
                                <tr>
                                    <th style={{ border: "1px solid #ccc", padding: 5 }}></th>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>R Wallet</th>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>Installment Wallet</th>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>P Wallet</th>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>C Wallet</th>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>Total Wallet</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'center' }}>Member</th>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.memberRPCPartialWallet.rWallet !== null ? this.state.memberRPCPartialWallet.rWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : "0.00"}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.memberRPCPartialWallet.partialWallet !== null ? this.state.memberRPCPartialWallet.partialWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : "0.00"}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.memberRPCPartialWallet.pWallet !== null ? this.state.memberRPCPartialWallet.pWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : "0.00"}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.memberRPCPartialWallet.cWallet !== null ? this.state.memberRPCPartialWallet.cWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : "0.00"}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.memberTotalWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'center' }}>Staff</th>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.staffRPCPartialWallet.rWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.staffRPCPartialWallet.partialWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.staffRPCPartialWallet.pWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.staffRPCPartialWallet.cWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.staffTotalWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'center' }}>Agent</th>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.agentRPCPartialWallet.rWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.agentRPCPartialWallet.partialWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.agentRPCPartialWallet.pWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.agentRPCPartialWallet.cWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.agentTotalWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'center' }}>Affiliate</th>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.affiliateRPCPartialWallet.rWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.affiliateRPCPartialWallet.partialWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.affiliateRPCPartialWallet.pWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.affiliateRPCPartialWallet.cWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.affiliateTotalWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                </tr>
                                <tr>
                                    <th style={{ border: "1px solid #ccc", padding: 5, textAlign:'center' }}>All User</th>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.allUserRPCPartialWallet.rWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.allUserRPCPartialWallet.partialWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.allUserRPCPartialWallet.pWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.allUserRPCPartialWallet.cWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                    <td style={{ border: "1px solid #ccc", padding: 5, textAlign:'right' }}>{this.state.allUserTotalWallet.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    :
                    this.state.mode=="dashboard"?
                        <div style={{padding:20}}>
                            {
                            this.state.dashboardData?
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{borderStyle:"solid",borderWidth:1,borderColor:"black",padding:15,marginRight:10}}>
                                        <div>Total Members: {this.state.dashboardData.totalMembers}</div>
                                        <div>Free:{this.state.dashboardData.totalFree}</div>
                                        <div>CM:{this.state.dashboardData.totalCM}</div>
                                        <div>CP:{this.state.dashboardData.totalCP}</div>
                                        <div>GP:{this.state.dashboardData.totalGP}</div>
                                        <div>SP:{this.state.dashboardData.totalSP}</div>
                                    </div>

                                    <div style={{borderStyle:"solid",borderWidth:1,borderColor:"black",padding:15,marginRight:10}}>
                                        <div>User Acquisition:</div>
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <FiChevronsLeft style={{fontSize:25,cursor:"pointer"}} onClick={()=>{
                                                this.previousMonthStat()
                                            }} />
                                            <div>{this.state.dashboardMonth} {this.state.dashboardYear}</div>
                                            <FiChevronsRight style={{fontSize:25,cursor:"pointer"}} onClick={()=>{
                                                this.nextMonthStat()
                                            }} />
                                            <div style={{marginLeft:10}}>
                                                Month Total: Free({this.state.totalMonthFree}) VIP({this.state.totalMonthCM})
                                            </div>
                                        </div>
                                        <Table style={{}}>
                                            <tr>
                                                <th>Date</th>
                                                <th>Free</th>
                                                <th>CM/VIP</th>
                                                <th>CP</th>
                                                <th>GP</th>
                                                <th>SP</th>
                                            </tr>
                                            {
                                            this.state.dashboardData.userAcquisition.map((item)=>{
                                                return(
                                                    <tr>
                                                        <td>{item.split(";")[0]}</td>
                                                        <td>{item.split(";")[1]}</td>
                                                        <td>{item.split(";")[2]}</td>
                                                        <td>{item.split(";")[3]}</td>
                                                        <td>{item.split(";")[4]}</td>
                                                        <td>{item.split(";")[5]}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </Table>
                                    </div>
                                </div>
                                :
                                <div>Loading...</div>
                            }
                        </div>
                    :
                    this.state.mode=="expiring"?
                        <div style={{display:"flex",flexDirection:"column",padding:20}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                <div style={{marginRight:30}}>
                                    <div>Expiring Item:</div>
                                    <select onChange={(e)=>{
                                        this.setState({expiringItem:e.target.value})
                                    }}>
                                        <option value="ALL">ALL</option>
                                        <optgroup label="Promo vouchers">
                                            <option value="企业指南 减RM200">企业指南 减RM200</option>
                                            <option value="战略三力 代金劵">战略三力 代金劵</option>
                                            <option value="战略52讲 减RM200">战略52讲 减RM200</option>
                                            <option value="VIP通行证 减RM30">VIP通行证 减RM30</option>
                                        </optgroup>
                                        <optgroup label="Membership">
                                            <option value="VIP">VIP</option>
                                        </optgroup>
                                    </select>
                                </div>
                                <div style={{marginRight:30}}>
                                    <div>Month:</div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                        <Button color="secondary" onClick={()=>{
                                            var currentMonth=this.state.expiringMonth
                                            currentMonth-=1
                                            if(currentMonth==0){
                                                this.setState({
                                                    expiringMonth:12,
                                                    expiringYear:this.state.expiringYear-1,
                                                })
                                            }else{
                                                this.setState({expiringMonth:currentMonth})
                                            }
                                        }}>
                                            &lt;&lt;
                                        </Button>
                                        <div style={{marginLeft:10,marginRight:10,width:130,textAlign:"center"}}>
                                            {this.monthNames[this.state.expiringMonth-1]} {this.state.expiringYear}
                                        </div>
                                        <Button color="secondary" onClick={()=>{
                                            var currentMonth=this.state.expiringMonth
                                            currentMonth+=1
                                            if(currentMonth==13){
                                                this.setState({
                                                    expiringMonth:1,
                                                    expiringYear:this.state.expiringYear+1,
                                                })
                                            }else{
                                                this.setState({expiringMonth:currentMonth})
                                            }
                                        }}>
                                            &gt;&gt;
                                        </Button>
                                    </div>
                                </div>
                                {
                                reactLocalStorage.get("job")!="Course Advisor"?
                                    <div style={{marginRight:30}}>
                                        <div>PIC:</div>
                                        <select onChange={(e)=>{
                                            this.setState({expiringPIC:e.target.value})
                                        }}>
                                            {
                                                this.state.staffList.map((item)=>{
                                                    return(
                                                        <option value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    :null
                                }
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <Button color="primary" style={{paddingTop:15,paddingBottom:15,paddingLeft:30,paddingRight:30}}
                                        onClick={()=>{
                                            this.expiringItemSeek()
                                        }}>
                                        Seek
                                    </Button>
                                </div>
                            </div>
                            <div style={{marginTop:10,display:"flex",flexDirection:"row",alignItems:"center"}}>
                                <div style={{marginRight:10,marginBottom:10}}>Contact threshold (days):</div>
                                <div>
                                    <Input value={this.state.contactThreshold} onChange={(e)=>{
                                            this.setState({contactThreshold:e.target.value})
                                        }}/>
                                </div>
                            </div>

                            {
                            this.state.expiringData?
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{display:"flex",flexDirection:"column",marginRight:20}}>
                                        <div style={{color:"green"}}>Contacted: {this.state.contactedList.length}</div>
                                        <div style={{color:"green",fontSize:9}}>within last {this.state.contactThreshold} days from the expiry date</div>
                                        {this.listExpiring(this.state.contactedList)}
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",marginRight:20}}>
                                        <div style={{color:"grey"}}>Not Contacted: {this.state.notContactedList.length}</div>
                                        <div style={{color:"grey",fontSize:9}}>within last {this.state.contactThreshold} days from the expiry date</div>
                                        {this.listExpiring(this.state.notContactedList)}
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <div style={{color:"red"}}>Due: {this.state.dueList.length}</div>
                                        <div style={{color:"red",fontSize:9}}>expiry was over but never contacted</div>
                                        {this.listExpiring(this.state.dueList)}
                                    </div>
                                </div>
                                :
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{display:"flex",flexDirection:"column",marginRight:10}}>
                                        <div style={{color:"green"}}>Contacted:</div>
                                        <div style={{color:"green",fontSize:9}}>within last {this.state.contactThreshold} days from the expiry date</div>
                                        {this.listExpiring([])}
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",marginRight:10}}>
                                        <div style={{color:"grey"}}>Not Contacted:</div>
                                        <div style={{color:"grey",fontSize:9}}>within last {this.state.contactThreshold} days from the expiry date</div>
                                        {this.listExpiring([])}
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                        <div style={{color:"red"}}>Due:</div>
                                        <div style={{color:"red",fontSize:9}}>expiry was over but never contacted</div>
                                        {this.listExpiring([])}
                                    </div>
                                </div>
                            }
                        </div>
                    :
                    this.state.mode=="leadRoster"?
                        <div style={{width:"100%",display:"flex",flexDirection:"column",marginLeft:20,marginRight:20,padding:20}}>
                            <div style={{width:"100%",display:"flex",flexDirection:"row",paddingTop:10,paddingBottom:10,
                                borderStyle:"solid",borderColor:"lightgrey",borderWidth:0,borderBottomWidth:1}}>
                                <div style={{flex:0.25}}>Client</div>
                                <div style={{flex:0.25}}>PIC Course Advisor</div>
                                <div style={{flex:0.2}}>Roster Sequence</div>
                                <div style={{flex:0.3}}>Create Date</div>
                            </div>
                            {
                            this.state.leadRosterRecord.map((item)=>{
                                return(
                                    <div style={{width:"100%",display:"flex",flexDirection:"row"}}>
                                        <div style={{flex:0.25}}>
                                            {
                                            item.client.username==item.client.name?
                                                item.client.name
                                                :
                                                item.client.username+" "+item.client.name
                                            }
                                        </div>
                                        <div style={{flex:0.25}}>{item.employeeString}</div>
                                        <div style={{flex:0.2}}>{item.employee?item.employee.rosterSequence:"-"}</div>
                                        <div style={{flex:0.3}}>{item.createDate}</div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        :
                    this.state.mode=="seniorCA"&&reactLocalStorage.get("job")!="Course Advisor"?    //do not allow course advisor to access
                        <div style={{padding:20,paddingTop:15}}>
                            <div style={{backgroundColor:"#edf1fa",padding:10,borderRadius:5,margin:5,width:250}}
                                onClick={()=>{
                                    this.getOfflineMemberWithoutPICList()
                                }}>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div>线下学员without PIC</div>
                                </div>
                                <div><FaChild style={{fontSize:20}}/>
                                    {this.state.offlineMemberWithoutPIC}
                                </div>
                            </div>

                            <div style={{marginTop:10}}>The Sales Team</div>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                                {
                                this.state.staffList.map((item)=>{
                                    return(
                                        item.job=="Programmer"?
                                            <div style={{backgroundColor:"#f0fffd",padding:10,borderRadius:5,margin:5,width:250}}>
                                                <div style={{display:"flex",flexDirection:"row"}}>
                                                    <div>{item.member.name}</div>
                                                </div>
                                            </div>
                                            :
                                            <div style={{backgroundColor:"#dedede",padding:10,borderRadius:5,margin:5,width:250}}
                                                onClick={()=>{
                                                    this.getMemberInCharge(item)
                                                }}>
                                                <div style={{display:"flex",flexDirection:"row"}}>
                                                    <div>{item.member.name}</div>
                                                    {
                                                    item.job=="Sales Supervisor"?
                                                        <GiKoala style={{fontSize:18,marginLeft:5}}/>
                                                        :null
                                                    }
                                                </div>
                                                <div><FaChild style={{fontSize:20}}/>{item.PICCount}</div>
                                            </div>
                                    )
                                })
                                }
                            </div>
                            <div style={{marginTop:10}}>Person In Charge (PIC) assignment</div>
                            {
                            this.state.selectedStaff&&this.state.mode=="pic"?
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{backgroundColor:"#dedede",padding:10,borderRadius:5,margin:5,display:"flex",flexDirection:"row"}}>
                                        <div style={{marginRight:10}}>{this.state.selectedStaff.member.name}</div>
                                        <div style={{marginRight:10}}>{this.state.selectedStaff.member.username}</div>
                                        <div style={{marginRight:10}}>{this.state.selectedStaff.member.email}</div>
                                        <div style={{marginRight:10}}><FaChild style={{fontSize:20}}/>{this.state.memberInChargeList.length}</div>
                                    </div>
                                    <Button color="primary" onClick={()=>{this.setState({assignPICModal:true})}}>Assign Member</Button>
                                </div>
                                :
                                this.state.mode=="pic"?
                                    <div style={{color:"lightgrey"}}>Select a staff to view</div>
                                :
                                null
                            }
                            {
                            this.state.memberInChargeList.length>0?
                                <div style={{padding:10,margin:10,borderRadius:10,borderStyle:"solid",borderWidth:1}}>
                                <div style={{paddingLeft:10,paddingRight:10}}>
                                    <Row style={{paddingBottom:20,borderBottomWidth:1,borderLeftWidth:0,borderTopWidth:0,borderRightWidth:0,
                                        borderStyle:"solid",borderColor:"lightgrey"}}>
                                        <Col style={styles.columnTitleStyle} xs={1}>Phone</Col>
                                        <Col style={styles.columnTitleStyle} xs={2}>Name</Col>
                                        <Col style={styles.columnTitleStyle} xs={2}>Email</Col>
                                        <Col style={styles.columnTitleStyle} xs={1}>Membership</Col>
                                        <Col style={styles.columnTitleStyle} xs={2}>Join date</Col>
                                        <Col style={styles.columnTitleStyle} xs={2}>PIC</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    {
                                    this.state.memberInChargeList.length>0?
                                        this.state.memberInChargeList.map((item)=>{
                                            return(
                                                <Row style={styles.searchResultRow}>
                                                    <Col xs={1}>{item.username}</Col>
                                                    <Col xs={2}>{item.name}</Col>
                                                    <Col xs={2}>{item.email}</Col>
                                                    <Col xs={1}>{item.membership_type}</Col>
                                                    <Col xs={2}>{item.date_joined}</Col>
                                                    <Col xs={2}>{item.personInCharge?item.personInCharge.name:"-"}</Col>
                                                    <Col xs={2}>
                                                        {
                                                        this.state.mode=="pic"?
                                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                                <Button color="danger" onClick={()=>{this.removeAssignedMember(item)}}>
                                                                    Remove
                                                                </Button>
                                                                <Button color="primary" onClick={()=>{this.viewMember(item)}}>
                                                                    View
                                                                </Button>
                                                            </div>
                                                        :
                                                        this.state.mode=="offlineMember"?
                                                            <Button color="primary" onClick={()=>{this.reverseAssignPIC(item)}}>
                                                                Assign PIC
                                                            </Button>
                                                        :
                                                        null
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                        :
                                        <div> ------- </div>
                                    }
                                </div>
                                </div>
                            :
                            this.state.selectedStaff&&this.state.mode=="pic"?
                                <div style={{color:"lightgrey"}}>No member in charge, assign some now</div>
                            :
                            this.state.mode=="offlineMember"?
                                <div style={{color:"lightgrey"}}>No unassigned offline member</div>
                            :
                            null
                            }
                        </div>
                        :
                    this.state.mode=="contractCA"?
                        <div style={{padding:20}}>
                            <div style={{display:"flex",flexDirection:"row"}}>
                                <Button color="primary" style={{margin:10}} onClick={()=>{
                                    this.setState({addNewCAModal:true})
                                }}>
                                    Add New Contract CA
                                </Button>
                                <Button color="primary" style={{margin:10}} onClick={()=>{
                                    this.setState({removeCAModal:true})
                                }}>
                                    Remove Contract CA
                                </Button>
                                <Button color="primary" style={{margin:10}} onClick={()=>{
                                    this.setState({setMasterModal:true})
                                }}>
                                    Set Leader
                                </Button>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",paddingLeft:10,paddingRight:10}}>
                                <div style={{display:"flex",flexDirection:"row",padding:5}}>
                                    <div style={{flex:0.1}}>Username</div>
                                    <div style={{flex:0.2}}>Name</div>
                                    <div style={{flex:0.1}}>Job</div>
                                    <div style={{flex:0.1}}>Roster Sequence</div>
                                    <div style={{flex:0.2}}>Leader</div>
                                    <div style={{flex:0.15}}>Date Joined</div>
                                    <div style={{flex:0.15}}>Achievements</div>
                                </div>
                                {
                                this.state.contractCAList.map((item)=>{
                                    return(
                                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:5,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,borderRadius:5}}>
                                            <div style={{flex:0.1}}>{item.member.username}</div>
                                            <div style={{flex:0.2}}>{item.member.name}</div>
                                            <div style={{flex:0.2}}>{item.job}</div>
                                            <div style={{flex:0.1}}>{item.rosterSequence}</div>
                                            <div style={{flex:0.2}}>
                                                {
                                                item.master?
                                                    item.master.username==item.master.name?
                                                        item.master.name
                                                        :
                                                        item.master.username+" "+item.master.name
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div style={{flex:0.15}}>{item.member.date_joined}</div>
                                            <div style={{flex:0.15}}>
                                                <Button color="success" style={{fontSize:13,padding:0,paddingLeft:10,paddingRight:10}} onClick={()=>{
                                                    this.viewContractCAAchievement(item)
                                                }}>
                                                    View
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                        :
                    this.state.mode=="achievement"?
                        <div style={{padding:"20px 20px 40px 20px"}}>
                            {
                            this.state.selectedCA?
                                <div style={{marginLeft:10,marginRight:10}}>
                                    <div style={{fontSize:25,fontWeight:"bold"}}>
                                        {
                                        this.state.selectedCA.member.username==this.state.selectedCA.member.name?
                                            this.state.selectedCA.member.name
                                            :
                                            this.state.selectedCA.member.username+" "+this.state.selectedCA.member.name
                                        }
                                    </div>
                                    <div>Total Sales: RM{this.state.totalSales}</div>
                                    <div style={{display:"flex",flexDirection:"row",padding:5,
                                        borderStyle:"solid",borderColor:"lightgrey",borderWidth:0,borderTopWidth:1,borderBottomWidth:1}}>
                                        <div style={{flex:0.3}}>Date</div>
                                        <div style={{flex:0.3}}>Agenda</div>
                                        <div style={{flex:0.2}}>Value (RM)</div>
                                        <div style={{flex:0.2}}>Commission (RM)</div>
                                    </div>
                                    {
                                    this.state.achievementData.map((monthArray,index)=>{
                                        return(
                                            <div>
                                                <div style={{display:"flex",flexDirection:"column"}}>
                                                    <div style={{fontSize:20,fontWeight:"bold"}}>
                                                        {this.month[(parseInt(monthArray[0].createDate.split(".")[1])-1)]+" "+monthArray[0].createDate.split(" ")[0].split(".")[2]}
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"row",marginBottom:5,alignItems:"row"}}>
                                                        <div style={{marginLeft:10}}>Sales: RM{this.state.monthTotalSales[index]}</div>
                                                        <div style={{marginLeft:10}}>Commission: RM{this.state.monthTotalCommission[index]}</div>
                                                        <div style={{flex:1}}/>
                                                        <Button color="light" style={{marginLeft:10,padding:0,paddingLeft:5,paddingRight:5,fontSize:12}} onClick={()=>{
                                                            this.copyList(monthArray,index)
                                                        }}>
                                                            Copy
                                                        </Button>
                                                    </div>
                                                </div>
                                                {
                                                    monthArray.map((item)=>{
                                                        return(
                                                            <div style={{display:"flex",flexDirection:"row",padding:5,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,borderRadius:5}}>
                                                                <div style={{flex:0.3}}>{item.createDate}</div>
                                                                <div style={{flex:0.3}}>{item.agenda}</div>
                                                                <div style={{flex:0.2}}>{item.value}</div>
                                                                <div style={{flex:0.2}}>{item.commission}</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                :null
                            }
                        </div>
                    :
                    this.state.mode=="contactForm"?
                        <div style={{padding:20}}>
                            <Button color="primary" onClick={()=>{this.loadContactFormLog()}}>
                                Contact Form Log
                            </Button>
                            <div style={{paddingLeft:10, paddingRight:10,marginBottom:20}}>
                                <table style={{width:"100%",borderCollapse: "collapse", }}>
                                    <thead>
                                        <tr>
                                            {
                                            GLOBAL.checkPermission("l5MirN{&")?
                                                <th style={{ padding: 10, textAlign: "center", width:"10%"}}>
                                                    Company
                                                </th>
                                                :
                                                null
                                            }
                                            <th style={{ padding: 10, textAlign: "center", width:"10%"}}>
                                                Name
                                            </th>
                                            <th style={{ padding: 10, textAlign: "center", width: "10%" }}>
                                                Phone
                                            </th>
                                            <th style={{ padding: 10, textAlign: "center", width: "10%" }}>
                                                Email
                                            </th>
                                            <th style={{ padding: 10, textAlign: "center", width: "10%" }}>
                                                Industry
                                            </th>
                                            <th style={{ padding: 10, textAlign: "center", width: "5%" }}>
                                                Outlet Qty
                                            </th>
                                            <th style={{ padding: 10, textAlign: "center", width:"15%" }}>
                                                Interest
                                            </th>
                                            <th style={{ padding: 10, textAlign: "center", width:"15%" }}>
                                                Remark
                                            </th>
                                            <th style={{ padding: 10, textAlign: "center", width: "10%" }}>
                                                Date Submitted
                                            </th>
                                            <th style={{ padding: 10, textAlign: "center", width: "15%" }}>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.contactFormList.map((item) => (
                                                <tr key={item.id} style={{ border: "1px solid lightgrey" }}>
                                                    {
                                                    GLOBAL.checkPermission("l5MirN{&")?
                                                        <td style={{ padding: 10, textAlign: "center", width: "10%" }}>
                                                            {item.company!=null?item.company.name:"Enlliance"}
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                    <td style={{ padding: 10, textAlign: "center", width: "10%" }}>{item.name}</td>
                                                    <td style={{ padding: 10, textAlign: "center", width: "10%" }}>{item.phone}</td>
                                                    <td style={{ padding: 10, textAlign: "center", width: "10%" }}>{item.email}</td>
                                                    <td style={{ padding: 10, textAlign: "center", width: "10%" }}>{item.industry}</td>
                                                    <td style={{ padding: 10, textAlign: "center", width: "5%" }}>{item.outletqty}</td>
                                                    <td style={{ padding: 10, textAlign: "center", width: "10%" }}>{item.pageName}</td>
                                                    <td style={{ padding: 10, textAlign: "center", maxWidth:50,wordWrap:"break-word"}}>{item.remark}</td>
                                                    <td style={{ padding: 10, textAlign: "center", width: "10%" }}>{item.createDate}</td>
                                                    {
                                                    (item.company!=null&&reactLocalStorage.get("companyName")==item.company.name)||
                                                    ((item.company==null||(item.company!=null&&item.company.name=="总部"))&&(reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"))?
                                                        <td style={{ padding: 10, textAlign: "center", width: "15%",display:"flex",flexDirection:"row"}}>
                                                            <Button color="primary" style={{ paddingLeft: 10, paddingRight: 10 }} onClick={() => {
                                                                this.checkContactType(item.id)
                                                            }}>
                                                                Approve
                                                            </Button>
                                                            <Button color="danger" style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }} onClick={() => {
                                                                this.deleteContactItem(item.id)
                                                            }}>
                                                                Delete
                                                            </Button>
                                                        </td>
                                                        :
                                                        <td style={{ padding: 10, textAlign: "center", width: "15%",display:"flex",flexDirection:"row"}}>
                                                            
                                                        </td>
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div style={{marginTop:10}}>
                                    <ReactPaginate
                                        pageCount={this.state.contactFormPageCount}
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={1}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        breakLabel={"..."}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        disabledClassName={"disabled"}
                                        forcePage={this.state.contactFormPage}
                                        onPageChange={async ({selected}) => {
                                            this.setState({contactFormPage:selected},()=>{
                                                this.contactFormClicked()
                                            })
                                        }}
                                        />
                                </div>
                            </div>
                        </div>
                    :
                    this.state.mode==="corporateAccount"?
                        <div style={{display:"flex",flexDirection:"column",width:"100%",padding:"15px 20px 0px 15px"}}>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"center",gap:10}}>
                                <Input placeholder="Search company, agent and principal"
                                    value={this.state.searchTerm} style={{width:"100%"}}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode===13){
                                            e.preventDefault()
                                            this.handleSearch()
                                        }
                                    }}
                                    onChange={(e)=>{
                                        this.setState({searchTerm:e.target.value})
                                    }} />
                                <Button color="primary" style={{paddingLeft:30,paddingRight:30}} onClick={()=>{
                                    this.handleSearch()
                                    }}>
                                    Search
                                </Button>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",padding:10,gap:10}}>
                                {
                                this.state.staffDetailList.map((staff)=>(
                                    <div style={{display:"flex",flexDirection:"row",border:"1px solid skyblue",alignItems:"center",
                                        borderRadius:5,padding:"8px 10px",fontSize:15,backgroundColor:"#f5f9ff"
                                        }}>
                                        <div style={{display:"flex",flexDirection:"column"}}>
                                            <div>{"["+staff.company.name+"]"}</div>
                                            <div>{staff.staff.username} {staff.staff.name}</div>
                                        </div>    
                                        <Button color="primary" style={{marginLeft:14,padding: "5px 10px",fontSize:13}} onClick={() => {
                                            this.getCorporateStaffList(staff.company)
                                        }}>
                                            View
                                        </Button>
                                    </div>
                                    ))
                                }
                            </div>
                            {
                            this.state.totalStaffCount>0&&this.state.searchMode==="search"?
                                <ReactPaginate
                                    pageCount={this.state.totalStaffDetailPage}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLabel={"..."}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    disabledClassName={"disabled"}
                                    forcePage={this.state.staffDetailPage}
                                    onPageChange={({selected}) => {
                                        console.log("Page selected:", {selected})
                                            this.setState({staffDetailPage:selected},()=>{
                                                this.handlePageChange(selected, 'staffDetail')
                                        })
                                    }}/>
                                :
                                null
                            }
                            {
                            this.state.corporateAccountList.length>0?
                                <div style={{width:"100%",display:"flex",flexDirection:"column",fontSize:14,gap:10}}>
                                    <table style={{width:"100%",fontSize:16}}>   
                                        <tr>
                                            <th style={styles.cellBorder}>Company Name</th>
                                            <th style={styles.cellBorder}>Principal</th>
                                            <th style={styles.cellBorder}>Staff count</th>
                                            <th style={styles.cellBorder}></th>
                                        </tr>
                                        {
                                        this.state.corporateAccountList.map((corp) => (
                                            <tr>
                                                <td style={styles.cellBorder}>{corp.name}</td>
                                                <td style={styles.cellBorder}>
                                                    {corp.principal.username} {corp.principal.name}
                                                </td>
                                                <td style={styles.cellBorder}>{corp.staffCount}</td>
                                                <td style={styles.cellBorder}>
                                                    <Button color="primary" style={{fontSize: 14, padding: "5px 10px"}} onClick={() => 
                                                        this.getCorporateStaffList(corp)
                                                        }>
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                            ))
                                        }
                                    </table>
                                    {
                                    this.state.searchMode!=="search"?
                                        <ReactPaginate
                                            pageCount={this.state.totalCorporateAccountPage}
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={1}
                                            containerClassName={"pagination justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            breakLabel={"..."}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            disabledClassName={"disabled"}
                                            forcePage={this.state.corporateAccountPage}
                                            onPageChange={({selected}) => {
                                                console.log("Page selected:", {selected})
                                                    this.setState({corporateAccountPage:selected},()=>{
                                                        this.corporateAccountClicked()
                                                })
                                            }}/>
                                        :
                                        <ReactPaginate
                                            pageCount={this.state.totalCorporateAccountListPage}
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={1}
                                            containerClassName={"pagination justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            breakLabel={"..."}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            disabledClassName={"disabled"}
                                            forcePage={this.state.corporateAccountListPage}
                                            onPageChange={({selected}) => {
                                                console.log("Page selected:", {selected})
                                                    this.setState({corporateAccountListPage:selected},()=>{
                                                        this.handlePageChange(selected,'corporateAccount')
                                                })
                                            }}/>
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    :
                    null
                    }
                </div>
            </div>
        )
    }
}

OtherPage = Radium(OtherPage);

let styles={
    columnTitleStyle:{
        fontWeight:"bold",
    },
    searchResultRow:{
        paddingTop:10,
        paddingBottom:10,
        borderBottomWidth:1,
        borderLeftWidth:0,
        borderTopWidth:0,
        borderRightWidth:0,
        borderStyle:"solid",
        borderColor:"#e6e6e6",
        ':hover':{
            backgroundColor:"grey",
            color:"white",
        },
    },
    memberDetailCell:{
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:5,
        paddingRight:5,
    },
    cellBorder:{
        border:"1px solid lightgrey",
        padding:5,
        minWidth:45,
    },
}
