import React from 'react';
import {Input,Button,Alert, Modal} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import * as GLOBAL from '../pages/global'
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import {GiHamburgerMenu} from 'react-icons/gi';
import {AiOutlineCloseCircle} from 'react-icons/ai';

export default class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMenuModal:false,
            classDropdown:false,
        }
        this.classDropdownDiv = React.createRef()
    }

    componentDidMount=()=>{
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentWillUnmount=()=>{
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    // handleClickOutside=(event)=>{
    //     if (this.classDropdownDiv && !this.classDropdownDiv.current.contains(event.target)) {
    //         this.setState({classDropdown:false})
    //     }
    // }

    handleClickOutside = (event) => {
        if (this.classDropdownDiv && this.classDropdownDiv.current && !this.classDropdownDiv.current.contains(event.target)) {
          this.setState({ classDropdown: false });
        }
      };

    logout=()=>{
        //logout send to server
        axios
            .get(`sales/saleslogout`)
            .then(async response => {
                //clear all data
                reactLocalStorage.clear()
                axios.defaults.headers.common.Authorization = ``
                history.push('/')
            })
            .catch(error => {
                if(error.response){
                    
                }
                // alert("Logout error\n"+error)
                //clear all data
                reactLocalStorage.clear()
                axios.defaults.headers.common.Authorization = ``
                history.push('/')
            })
    }

    render(){
        return(
            <div style={{width:"100%"}}>
                <Modal style={styles.modalMobile} isOpen={this.state.mobileMenuModal} toggle={()=>this.setState({mobileMenuModal:false})}>
                    <div style={{padding:"30px 10px 30px 10px",display:'block',marginLeft:'auto',marginRight:'auto',width:"100%"}}>
                        <AiOutlineCloseCircle onClick={()=>this.setState({mobileMenuModal: false})} style={styles.closeButton} />
                        <div style={{width:"100%"}}>
                            {
                            GLOBAL.checkPermission("-!95U_]G")?
                                <div>
                                    <div style={this.props.currentPage=="saleslog"?styles.selectedMobilePageStyle:styles.MobilePageStyle} onClick={()=>history.push('/saleslog')}>SalesLog</div>
                                    <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                                </div>
                                :
                                null
                            }
                            
                            {
                            GLOBAL.checkPermission("cy)sI>SA")?
                                <div>
                                    <div style={this.props.currentPage=="lead"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/lead')}>Lead</div>
                                    <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                                </div>
                                :
                                null
                            }
                            {
                            GLOBAL.checkPermission("Rnw%-%bQ")?
                                <div>
                                    <div style={this.props.currentPage=="ticket"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/ticket')}>Ticket</div>
                                    <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                                </div>
                                :
                                null
                            }
                            {
                            GLOBAL.checkPermission("%:9rsY_/")? //Show Classes
                                <div>
                                    {
                                    reactLocalStorage.get("companyOfflineEvent")!=null&&JSON.parse(reactLocalStorage.get("companyOfflineEvent")).map((offlineEvent)=>{
                                        return(
                                            <div>
                                                <div style={this.props.currentPage=="offlineevent"&&this.props.currentType==offlineEvent.sku?styles.selectedMobilePageStyle:styles.MobilePageStyle}
                                                    onClick={()=>window.open('/offlineevent?type='+offlineEvent.sku+"&name="+offlineEvent.name,"_self")}>
                                                    {offlineEvent.name}
                                                </div>
                                                <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                :
                                null
                            }
                            {
                            GLOBAL.checkPermission("rr`~Jhf[")?
                                <div style={this.props.currentPage=="conference"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/conference')}>Conference</div>
                                :
                                null
                            }
                            <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                            {
                            GLOBAL.checkPermission("5=go4bgp")?
                                <div style={this.props.currentPage=="offlinecourse"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/offlinecourse')}>OfflineCourse</div>
                                :
                                null
                            }
                            <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                            {
                            GLOBAL.checkPermission("*[4T$P.*")?
                                <div style={this.props.currentPage=="agency"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/agency')}>Agency</div>
                                :
                                null
                            }
                            <div style={{color:'grey',borderBottom:"0.5px solid lightgrey",paddingLeft:15,paddingRight:15}}/>
                            {/*
                                reactLocalStorage.get("job")=="Sales Supervisor"||reactLocalStorage.get("job")=="Sales"?
                                    <div style={this.props.currentPage=="affiliate"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/affiliate')}>Affiliate</div>
                                    :
                                    null
                            */}
                            <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                            {
                            GLOBAL.checkPermission("$S4/vrR%")?
                                <div style={this.props.currentPage=="execution"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/execution')}>Execution</div>
                                :
                                null
                            }
                            <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                            {   
                            GLOBAL.checkPermission(":!IK^f:w")?
                                <div style={this.props.currentPage=="other"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/other')}>Other</div>
                                :
                                null
                            }

                            {
                            GLOBAL.checkPermission("5w]>hsY>")?
                                <div>
                                    <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                                    <div style={this.props.currentPage=="achievement"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/achievement')}>Achievement</div>
                                </div>
                                :null
                            }
                            {
                            GLOBAL.checkPermission(")lI3_[ZO")?
                                <div>
                                    <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                                    <div style={this.props.currentPage=="agents"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/agents')}>Agents</div>
                                </div>
                                :
                                null
                            }
                            {
                            GLOBAL.checkPermission("uk$q+$PB")? //Show Company Achievement
                                <div>
                                    <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                                    <div style={this.props.currentPage=="companyachievement"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/companyachievement')}>CompanyAchievement</div>
                                </div>
                                :
                                null
                            }
                            {
                            GLOBAL.checkPermission("%Cgdb7Ab")?
                                <div>
                                    <div style={{color:'grey',border:"0.5px solid",paddingLeft:15,paddingRight:15,borderBottomWidth:0,borderLeftWidth:0,borderRightStyle:0}}/>
                                    <div style={this.props.currentPage=="dashboard"?styles.selectedMobilePageStyle:styles.MobilePageStyle}  onClick={()=>history.push('/dashboard')}>Dashboard</div>
                                </div>
                                :null
                            }
                        </div>
                    </div>

                </Modal>
                <div style={{maxWidth:"100%"}}>
                    {
                    window.innerWidth >=600?
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",backgroundColor:"lightgrey"}}>
                            <h3 style={{padding:10}}>EnllianceSales</h3>

                            <div  style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",position:"relative"}}>
                                {
                                GLOBAL.checkPermission("-!95U_]G")?
                                    <div style={this.props.currentPage=="saleslog"?styles.selectedPageStyle:styles.pageLinkStyle} onClick={()=>history.push('/saleslog')}>SalesLog</div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("cy)sI>SA")?
                                    <div style={this.props.currentPage=="lead"?styles.selectedPageStyle:styles.pageLinkStyle} onClick={()=>history.push('/lead')}>Lead</div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("Rnw%-%bQ")?
                                    <div style={this.props.currentPage=="ticket"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/ticket')}>Ticket</div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("%:9rsY_/")? //Show Classes
                                    <div ref={this.classDropdownDiv} onClick={()=>this.setState({classDropdown:!this.state.classDropdown})}>
                                        <div style={this.props.currentPage=="offlineevent"||this.props.currentPage=="conference"||this.props.currentPage=="offlinecourse"?
                                            styles.selectedPageStyle:styles.pageLinkStyle
                                        }>Classes</div>
                                        <div style={{display:this.state.classDropdown==true?"flex":"none",flexDirection:"column",position:"absolute",top:35,border:"solid 1px grey",backgroundColor:"lightgrey",
                                            zIndex:10,padding:10,gap:10}}>
                                            {
                                            reactLocalStorage.get("companyOfflineEvent")!=null&&JSON.parse(reactLocalStorage.get("companyOfflineEvent")).map((offlineEvent)=>{
                                                return(
                                                    <div style={this.props.currentPage=="offlineevent"&&this.props.currentType==offlineEvent.sku?styles.selectedPageStyle:styles.pageLinkStyle}
                                                        onClick={()=>window.open('/offlineevent?type='+offlineEvent.sku+"&name="+offlineEvent.name,"_self")}>
                                                        {offlineEvent.name}
                                                    </div>
                                                )
                                            })
                                            }
                                            {
                                            GLOBAL.checkPermission("rr`~Jhf[")? //Show Conference
                                                <div style={this.props.currentPage=="conference"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/conference')}>Conference</div>
                                                :
                                                null
                                            }
                                            {
                                            GLOBAL.checkPermission("5=go4bgp")? //Show OfflineCourse
                                                <div style={this.props.currentPage=="offlinecourse"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/offlinecourse')}>OfflineCourse</div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("*[4T$P.*")?
                                    <div style={this.props.currentPage=="agency"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/agency')}>Agency</div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("$S4/vrR%")?
                                    <div style={this.props.currentPage=="execution"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/execution')}>Execution</div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("5w]>hsY>")?
                                    <div style={this.props.currentPage=="achievement"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/achievement')}>Achievement</div>
                                    :null
                                }

                                {
                                GLOBAL.checkPermission(")lI3_[ZO")?
                                    <div style={this.props.currentPage=="agents"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/agents')}>Agents</div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("uk$q+$PB")?
                                    <div style={this.props.currentPage=="companyachievement"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/companyachievement')}>CompanyAchievement</div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("%Cgdb7Ab")?
                                    <div style={this.props.currentPage=="dashboard"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/dashboard')}>Dashboard</div>
                                    :null
                                }

                                {
                                GLOBAL.checkPermission("@W?]g7UC")?
                                    <div style={this.props.currentPage=="permissions"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/permissions')}>Permissions</div>
                                    :null
                                }

                                {
                                GLOBAL.checkPermission("4<HS%NC3")?
                                    <div style={this.props.currentPage=="notifications"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/notifications')}>Notifications</div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission(":!IK^f:w")?
                                    <div style={this.props.currentPage=="other"?styles.selectedPageStyle:styles.pageLinkStyle}  onClick={()=>history.push('/other')}>Other</div>
                                    :
                                    null
                                }
                            </div>

                            <div style={{display:"flex",flexDirection:"row",padding:10,gap:10,alignItems:"center"}}>
                                <img style={{width:40,height:40,borderRadius:"50%",backgroundColor:"grey"}} src={reactLocalStorage.get("profileImage")} />
                                <div>{reactLocalStorage.get("name")}</div>
                                <Button color="secondary" onClick={()=>{this.logout()}}>Log Out</Button>
                            </div>
                        </div>
                        :
                        <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",backgroundColor:"lightgrey"}}>
                            <h3 style={{padding:10,fontSize:15,marginTop:5}}>EnllianceSales</h3>
                            <div style={{display:"flex",flexDirection:"row",padding:10,gap:10,alignItems:"center"}}>
                                <div>{reactLocalStorage.get("name")}</div>
                                <Button color="secondary" onClick={()=>{this.logout()}}>Log Out</Button>
                                <GiHamburgerMenu onClick={() => this.setState({mobileMenuModal: true})} style={{alignSelf:"center",width:"30px",height:"20px"}}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

let styles = {
    selectedPageStyle:{
        marginRight:30,
        cursor:"pointer",
        fontWeight:"bold",
        textDecoration:"underline",
        whiteSpace:"nowrap",
    },
    pageLinkStyle:{
        marginRight:30,
        cursor:"pointer",
        whiteSpace:"nowrap",
    },
    closeButton:{
        width:35,
        height:35,
        color:'darkgrey',
        position:"absolute",
        top:10,
        right:10,
        zIndex:10,
        cursor:"pointer"
    },
    selectedMobilePageStyle:{
        fontSize:20,
        textAlign:'center',
        color:"",
        fontFamily:"Hiragino Sans GB",
        fontWeight:"bold",
        padding:10,
        cursor:'pointer',
        width:"100%",
        textDecoration:"underline"
    },
    MobilePageStyle:{
        fontSize:20,
        textAlign:'center',
        color:"",
        fontFamily:"Hiragino Sans GB",
        padding:10,
        cursor:'pointer',
        width:"100%",
    },
    modalMobile:{
        maxWidth:350,
        width:'100%',
        height:'auto',
        display:'block',
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:10,
    }
}
