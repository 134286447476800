import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal,Form} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'

export default class Restricted extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
        }
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{

    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <NavigationBar currentPage="restricted" />
                <div style={{fontSize:20,color:"black",textAlign:"center",margin:20,width:"100%"}}>
                    Your account does not have access to any function
                </div>
            </div>
        )
    }
}

Restricted = Radium(Restricted);

let styles={

}
