import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import * as GLOBAL from '../pages/global.js'

import {FaCheckCircle,FaTimesCircle,FaCopy,FaInfoCircle} from 'react-icons/fa'

export default class Enlight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enlightExerciseList:[],
            selectedExercise:null,
            participantList:[],

            createNewExerciseModal:false,
            newExerciseFromDate:null,
            newExerciseToDate:null,

            editExerciseModal:false,
            editExerciseFromDate:null,
            editExerciseToDate:null,

            addParticipantModal:false,
            addParticipantUsername:null,

            removeParticipantModal:false,
            removeParticipantUsername:null,

            openReferMemberModal:false,
            enrollList:[],
        }
        this.rankArray=["normal","bronze","silver","gold"]
    }

    componentWillMount=()=>{
        //prevent contract CA from accessing this page
        // if(reactLocalStorage.get("job")=="Course Advisor"){
        //     history.push("/saleslog")
        //     return
        // }

        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        this.getEnlightExerciseList()
    }

    getEnlightExerciseList=()=>{
        this.props.setLoading(true)
        const payload = {

        }
        axios
            .post(`sales/getenlightexerciselist`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                for(let exercise of response.data){
                    let rawSplit=exercise.createDate.toString().split("T")[0]
                    let dateSplit=rawSplit.split("-")
                    exercise.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]
                }

                this.props.setLoading(false)
                this.setState({
                    enlightExerciseList:response.data,
                })
            })
            .catch(error =>{
                alert("Get Enlight Exercise list error\n"+error)
                this.props.setLoading(false)
            })
    }

    createNewEnlightExercise=()=>{
        this.props.setLoading(true)
        const payload = {
            fromDate:this.state.newExerciseFromDate,
            toDate:this.state.newExerciseToDate,
        }
        axios
            .post(`sales/createnewenlightexercise`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                alert("New Enlightenment Exercise created")
                this.getEnlightExerciseList()
            })
            .catch(error =>{
                alert("Create new Enlight Exercise error\n"+error)
                this.props.setLoading(false)
            })
    }

    editEnlightExercise=()=>{
        this.props.setLoading(true)
        const payload = {
            enlightID:this.state.selectedExercise.id,
            fromDate:this.state.editExerciseFromDate,
            toDate:this.state.editExerciseToDate,
        }
        axios
            .post(`sales/editenlightexercise`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                alert("Enlightenment Exercise Edited")
                this.getEnlightExerciseList()
            })
            .catch(error =>{
                alert("Edit Enlight Exercise error\n"+error)
                this.props.setLoading(false)
            })
    }

    getReferListSales=(getUser)=>{
        this.props.setLoading(true)
        const payload = {
            enlightSequence:this.state.selectedExercise.sequence,
            username:getUser
        }
        axios
            .post('sales/getreferlistsales',qs.stringify(payload),{timeout:60000})
            .then(async response => {
                this.props.setLoading(false)
                this.setState({
                    openReferMemberModal:true,
                    enrollList:response.data.enrollList,
                })
            })
            .catch(error =>{
                alert("Get refer lsit error\n"+error)
                this.props.setLoading(false)
            })
    }

    getEnlightParticipantList=()=>{
        this.props.setLoading(true)
        const payload = {
            enlightSequence:this.state.selectedExercise.sequence,
        }
        axios
            .post(`sales/getenlightparticipantlist`, qs.stringify(payload),{timeout:60000}) //with extra timeout
            .then(async response => {
                //parse date
                for(var member of response.data){
                    var rawDateSplit=member.createDate.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    var timeSplit=rawDateSplit[1].split(":")
                    member.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //sort
                response.data.sort((a,b)=>{
                    return b.createScore-a.createScore||this.rankArray.indexOf(b.member.referRank)-this.rankArray.indexOf(a.member.referRank)||b.referredCount-a.referredCount
                })

                this.props.setLoading(false)
                this.setState({
                    participantList:response.data,
                });

            })
            .catch(error =>{
                alert("Get Enlightenment participant lsit error\n"+error)
                this.props.setLoading(false)
            })
    }

    addEnlightParticipant=()=>{
        this.props.setLoading(true)
        const payload = {
            enlightID:this.state.selectedExercise.id,
            addParticipantUsername:this.state.addParticipantUsername
        }
        axios
            .post(`sales/addenlightparticipant`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                alert("Enlightenment Exercise participant added")
                this.setState({addParticipantModal:false})
                this.getEnlightParticipantList()
            })
            .catch(error =>{
                alert("Add Enlightenment Exercise participant error\n"+error)
                this.props.setLoading(false)
            })
    }

    removeEnlightParticipant=()=>{
        this.props.setLoading(true)
        const payload = {
            enlightID:this.state.selectedExercise.id,
            removeParticipantUsername:this.state.removeParticipantUsername
        }
        axios
            .post(`sales/removeenlightparticipant`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                alert("Enlightenment Exercise participant removed")
                this.setState({removeParticipantModal:false})
                this.getEnlightParticipantList()
            })
            .catch(error =>{
                alert("Remove Enlightenment Exercise participant error\n"+error)
                this.props.setLoading(false)
            })
    }

    copyList=()=>{
        var copyString="Phone\tName\tAgent Company\tAgent tier\tSignup Date\t心关系\n"
        for(var cs of this.state.participantList){
            copyString+=cs.member.username+"\t"
            copyString+=cs.member.name+"\t"
            copyString+=cs.agentCompany+"\t"
            copyString+=cs.agentTier+"\t"
            copyString+=cs.createDate+"\t"
            copyString+=cs.createScore
            copyString+="\n"
        }
        GLOBAL.copyTextToClipboard(copyString)
    }

    getTotalCreateScore=()=>{
        let totalCreateScore=0
        for(let participant of this.state.participantList){
            totalCreateScore+=participant.createScore
        }
        return totalCreateScore
    }
    getTotalReferScore=()=>{
        let totalReferScore=0
        for(let participant of this.state.participantList){
            totalReferScore+=participant.referredCount
        }
        return totalReferScore
    }

    render(){
        return(
            <div>
                <Modal style={{}} isOpen={this.state.createNewExerciseModal} toggle={()=>this.setState({createNewExerciseModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Create New Enlightenment Exercise</div>
                        <div style={{width:"100%",marginTop:15}}>
                            <div>From Date</div>
                            <Input type="date" style={{width:"100%"}} value={this.state.newExerciseFromDate} onChange={(e)=>{
                                this.setState({newExerciseFromDate:e.target.value})
                            }} />
                            <div>To Date</div>
                            <Input type="date" style={{width:"100%"}} value={this.state.newExerciseToDate} onChange={(e)=>{
                                this.setState({newExerciseToDate:e.target.value})
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:5,marginTop:20,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.createNewEnlightExercise()
                            }}>
                                Create
                            </Button>
                            <Button color="light" style={{flex:1,color:"white",backgroundColor:"salmon"}} onClick={()=>{
                                this.setState({createNewExerciseModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{}} isOpen={this.state.editExerciseModal} toggle={()=>this.setState({editExerciseModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Edit Enlightenment Exercise</div>
                        <div style={{width:"100%",marginTop:15}}>
                            <div>From Date</div>
                            <Input type="date" style={{width:"100%"}} value={this.state.editExerciseFromDate} onChange={(e)=>{
                                this.setState({editExerciseFromDate:e.target.value})
                            }} />
                            <div>To Date</div>
                            <Input type="date" style={{width:"100%"}} value={this.state.editExerciseToDate} onChange={(e)=>{
                                this.setState({editExerciseToDate:e.target.value})
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:5,marginTop:20,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.setState({editExerciseModal:false},()=>{this.editEnlightExercise()})
                            }}>
                                Edit
                            </Button>
                            <Button color="light" style={{flex:1,color:"white",backgroundColor:"salmon"}} onClick={()=>{
                                this.setState({editExerciseModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.openReferMemberModal} toggle={()=>this.setState({openReferMemberModal:false})} style={{}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5,width:"100%"}}>
                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5,justifyContent:"center"}}>
                            <button style={{border: "none", background: "transparent",textAlign:"right"}} onClick={()=>this.setState({openReferMemberModal:false})}>
                                x
                            </button>
                            <table style={{fontSize:12}}>
                                <tr>
                                    <td style={{flex:0.1,border:"1px solid lightgrey",padding:10,textAlign:"center"}}>Date</td>
                                    <td style={{flex:0.2,border:"1px solid lightgrey",padding:10,textAlign:"center"}}>User</td>
                                    <td style={{flex:0.1,border:"1px solid lightgrey",padding:10,textAlign:"center"}}>Agenda</td>
                                </tr>
                                {
                                this.state.enrollList.map((enroll, index) =>{
                                    return(
                                        <tr>
                                            <td style={{border: "1px solid lightgrey", padding: 10}}>{new Date(enroll.signupDate).toLocaleDateString("en-US", { year: "numeric",month: "2-digit",day: "2-digit",}).replace(/\//g, ".")}</td>
                                            <td style={{border:"1px solid lightgrey",padding:10,textAlign:"left"}}>
                                                {
                                                enroll.user.username==enroll.user.name?
                                                    enroll.user.name
                                                    :
                                                    enroll.user.username+" "+enroll.user.name
                                                }
                                            </td>
                                            <td style={{border:"1px solid lightgrey",padding:10,textAlign:"left"}}>{enroll.courseSessionString}</td>
                                        </tr>
                                    )
                                })
                                }
                            </table>
                        </div>
                    </div>
                </Modal>

                <Modal style={{}} isOpen={this.state.addParticipantModal} toggle={()=>this.setState({addParticipantModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Add Enlightenment Participant</div>
                        <div style={{width:"100%",marginTop:15}}>
                            <div>Username</div>
                            <Input type="text" style={{width:"100%"}} value={this.state.addParticipantUsername} onChange={(e)=>{
                                this.setState({addParticipantUsername:e.target.value.replace(/\D/g,"")})
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:5,marginTop:20,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.addEnlightParticipant()
                            }}>
                                Add Participant
                            </Button>
                            <Button color="light" style={{flex:1,color:"white",backgroundColor:"salmon"}} onClick={()=>{
                                this.setState({addParticipantModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{}} isOpen={this.state.removeParticipantModal} toggle={()=>this.setState({removeParticipantModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Remove Enlightenment Participant</div>
                        <div style={{width:"100%",marginTop:15}}>
                            <div>Username</div>
                            <Input type="text" style={{width:"100%"}} value={this.state.removeParticipantUsername} onChange={(e)=>{
                                this.setState({removeParticipantUsername:e.target.value.replace(/\D/g,"")})
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:5,marginTop:20,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.removeEnlightParticipant()
                            }}>
                                Remove Participant
                            </Button>
                            <Button color="light" style={{flex:1,color:"white",backgroundColor:"salmon"}} onClick={()=>{
                                this.setState({removeParticipantModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                {
                window.innerWidth >= 600 ?
                    <div>
                        <div style={{display:"flex",flexDirection:"row",padding:10}}>
                            <Button color="primary" style={{marginRight:10}} onClick={()=>{
                                this.setState({createNewExerciseModal:true})
                            }}>
                                Create New Exercise
                            </Button>
                            {GLOBAL.checkPermission("x/9HzS_F")?
                                this.state.selectedExercise!=null?
                                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                        this.setState({
                                            editExerciseModal:true,
                                            editExerciseFromDate:this.state.selectedExercise.fromDate,
                                            editExerciseToDate:this.state.selectedExercise.toDate,
                                        })
                                    }}>
                                        Edit Exercise
                                    </Button>
                                    :
                                    null
                                :
                                null
                            }
                            <div style={{flex:1}} />
                        </div>
                        <div style={{padding:10,gap:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {
                            this.state.enlightExerciseList.map((item)=>{
                                return(
                                    <Button color={this.state.selectedExercise!=null&&this.state.selectedExercise.id==item.id?"primary":"secondary"} style={{}} onClick={()=>{
                                        this.setState({selectedExercise:item},()=>{this.getEnlightParticipantList()})
                                    }}>
                                        <div>{item.title}</div>
                                        <div>{item.fromDate}~{item.toDate}</div>
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <div style={{padding:"0px 20px 20px 20px"}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:10}}>
                                <div>Total: {this.state.participantList.length}/{this.state.totalSeat}</div>
                                <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                    borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                        this.copyList()
                                    }}>
                                    <FaCopy style={{width:20,height:20,padding:4}}/>
                                    <div style={{fontSize:12}}>Copy list</div>
                                </Button>
                                <div>{this.state.participantList[0]?.fromDate} - {this.state.participantList[0]?.toDate}</div>
                                <div style={{flex:1}} />
                                {
                                this.state.selectedExercise!=null?
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                        <Button color="primary" onClick={() => {
                                            const url = "https://enlliance.com/enlightenment?batch="+this.state.selectedExercise.sequence
                                            navigator.clipboard.writeText(url)
                                            alert("Link copied to clipboard!")
                                        }}>
                                            Link
                                        </Button>
                                        <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                            this.setState({addParticipantModal:true})
                                        }}>
                                            Add Participant
                                        </Button>
                                        <Button color="secondary" style={{marginLeft:10}} onClick={()=>{
                                            this.setState({removeParticipantModal:true})
                                        }}>
                                            Remove Participant
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div style={{marginTop:10,padding:10,borderRadius:10,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey"}}>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div style={{flex:0.15,textDecoration:"underline"}}>Phone</div>
                                    <div style={{flex:0.1,textDecoration:"underline"}}>Name</div>
                                    <div style={{flex:0.2,textDecoration:"underline",textAlign:"center"}}>Agent Company</div>
                                    <div style={{flex:0.1,textDecoration:"underline",textAlign:"center"}}>Agent Tier</div>
                                    <div style={{flex:0.1,textDecoration:"underline"}}>Signup Date</div>
                                    <div style={{flex:0.1,textDecoration:"underline",textAlign:"center"}}>心关系</div>
                                </div>
                                {
                                this.state.participantList.map((item)=>{
                                    return(
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:2}}>
                                            <div style={{flex:0.15}}>{item.member.username}</div>
                                            <div style={{flex:0.1,fontSize:14,display:"flex",flexDirection:"row",flexWrap:"nowrap",alignItems:"center"}}>
                                                {
                                                item.member.membership_type=="SP"?
                                                    <div style={{marginRight:5,backgroundColor:"#fffcf2",color:"red",border:"1px solid red",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                        SP
                                                    </div>
                                                    :
                                                    <div style={{marginRight:5,backgroundColor:"lightgrey",border:"1px solid grey",
                                                        borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1,whiteSpace:"nowrap"}}>
                                                        {item.member.membership_type}
                                                    </div>
                                                }
                                                <div style={{whiteSpace:"nowrap"}}>{item.member.name}</div>
                                            </div>
                                            <div style={{flex:0.2,fontSize:12,textAlign:"center"}}>{item.agentCompany}</div>
                                            <div style={{flex:0.1,fontSize:12,textAlign:"center"}}>{item.agentTier}</div>
                                            <div style={{flex:0.1,fontSize:12}}>{item.createDate}</div>
                                            <div style={{flex:0.1,fontSize:12,textAlign:"center"}}>
                                            {item.createScore > 0  ? (
                                                <Button color="primary" style={{fontSize:13,padding:"2px 5px"}}
                                                    onClick={() => {
                                                        this.getReferListSales(item.member.username);
                                                    }}
                                                >
                                                {item.createScore}
                                                </Button>
                                            ) : (
                                            item.createScore
                                            )}
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"flex-end",gap:20}}>
                                <div>Total 心关系: {this.getTotalCreateScore()}</div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div style={{display:"flex",flexDirection:"column",gap:5,padding:10,}}>
                            <Button color="primary" style={{marginRight:10}} onClick={()=>{
                                this.setState({createNewExerciseModal:true})
                            }}>
                                Create New Exercise
                            </Button>
                            {GLOBAL.checkPermission("x/9HzS_F")?
                                this.state.selectedExercise!=null?
                                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                        this.setState({
                                            editExerciseModal:true,
                                            editExerciseFromDate:this.state.selectedExercise.fromDate,
                                            editExerciseToDate:this.state.selectedExercise.toDate,
                                        })
                                    }}>
                                        Edit Exercise
                                    </Button>
                                    :
                                    null
                                :
                                null
                            }
                            <div style={{flex:1}} />
                        </div>
                        <div style={{padding:10,gap:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {
                            this.state.enlightExerciseList.map((item)=>{
                                return(
                                    <Button color={this.state.selectedCourseSession!=null&&this.state.selectedCourseSession.courseTitle==item.courseTitle?"primary":"secondary"} style={{}} onClick={()=>{
                                        this.setState({selectedExercise:item},()=>{this.getEnlightParticipantList()})
                                    }}>
                                        <div>{item.title}</div>
                                        <div>{item.createDate}</div>
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <div style={{padding:"0px 20px 20px 20px"}}>
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:10}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>

                                <div>Total: {this.state.participantList.length}/{this.state.totalSeat}</div>
                                <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                    borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                        this.copyList()
                                    }}>
                                    <FaCopy style={{width:20,height:20,padding:4}}/>
                                    <div style={{fontSize:12}}>Copy list</div>
                                </Button>
                                </div>
                                {
                                this.state.selectedCourseSession!=null?
                                    <div style={{gap:15,display:"flex",flexDirection:"column",alignItems:"center"}}>
                                        <div style={{marginTop:8}}>Attendance:</div>
                                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:10}}>
                                            {this.calculateAndGenerateAttendance()}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <div style={{flex:1}} />
                                {
                                this.state.selectedExercise!=null?
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                        <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                            this.setState({addCourseSessionParticipantModal:true})
                                        }}>
                                            Add Participant
                                        </Button>
                                        <Button color="secondary" style={{marginLeft:10}} onClick={()=>{
                                            this.setState({removeParticipantModal:true})
                                        }}>
                                            Remove Participant
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div style={{marginTop:10,padding:10,borderRadius:10,borderWidth:1,borderColor:"lightgrey"}}>
                                {
                                this.state.participantList.length>0?
                                    this.state.participantList.map((item)=>{
                                        return(
                                            <div style={{display:"flex",flexDirection:"column",borderRadius:"15px",border:"1px solid",borderColor:"lightgrey",marginBottom:10}}>
                                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                    <div style={{display:"flex",flexDirection:"column",padding:10,width:"100%"}}>
                                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                {item.member.username}
                                                                <div style={{flex:0.2,fontSize:18,display:"flex",flexDirection:"row",flexWrap:"wrap",alignItems:"center"}}>
                                                                    {
                                                                    item.member.membership_type=="SP"?
                                                                        <div style={{marginRight:5,backgroundColor:"#fffcf2",color:"red",border:"1px solid red",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                                            SP
                                                                        </div>
                                                                        :
                                                                        <div style={{marginRight:5,backgroundColor:"lightgrey",border:"1px solid grey",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                                            {item.member.membership_type}
                                                                        </div>
                                                                    }
                                                                    {item.member.name}
                                                                </div>
                                                                <div style={{flex:0.1,fontSize:12}}>
                                                                    Signup Date:{item.createDate}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:5,marginBottom:5,width:"100%",color:"lightgrey"}}/>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div> ------- </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

        )
    }
}

Enlight = Radium(Enlight)
