import {reactLocalStorage} from 'reactjs-localstorage'

export function getCrayonColor(crayonColor){
    if(crayonColor=="cantaloupe"){return "#FFCE6E"}
    else if(crayonColor=="honeydew"){return "#CEFA6E"}
    else if(crayonColor=="spindrift"){return "#68FBD0"}
    else if(crayonColor=="sky"){return "#6ACFFF"}
    else if(crayonColor=="lavender"){return "#D278FF"}
    else if(crayonColor=="carnation"){return "#FF7FD3"}
    else if(crayonColor=="licorice"){return "#000000"}
    else if(crayonColor=="snow"){return "#FFFFFF"}
    else if(crayonColor=="salmon"){return "#FF726E"}
    else if(crayonColor=="banana"){return "#FFFB6D"}
    else if(crayonColor=="flora"){return "#68F96"}
    else if(crayonColor=="ice"){return "#68FDFF"}
    else if(crayonColor=="orchid"){return "#6E76FF"}
    else if(crayonColor=="bubblegum"){return "#FF7AFF"}
    else if(crayonColor=="lead"){return "#1E1E1EF"}
    else if(crayonColor=="mercury"){return "#E8E8E8"}
    else if(crayonColor=="tangerine"){return "#FF8802"}
    else if(crayonColor=="lime"){return "#83F902"}
    else if(crayonColor=="seamfoam"){return "#03F987"}
    else if(crayonColor=="aqua"){return "#008cff"}
    else if(crayonColor=="grape"){return "#8931FF"}
    else if(crayonColor=="strawberry"){return "#FF2987"}
    else if(crayonColor=="tungsten"){return "#3A3A3A"}
    else if(crayonColor=="silver"){return "#D0D0D0"}
    else if(crayonColor=="maraschino"){return "#FF2101"}
    else if(crayonColor=="lemon"){return "#FFFA03"}
    else if(crayonColor=="spring"){return "#05F802"}
    else if(crayonColor=="turquoise"){return "#00FDFF"}
    else if(crayonColor=="blueberry"){return "#002EFF"}
    else if(crayonColor=="magenta"){return "#FF39FF"}
    else if(crayonColor=="iron"){return "#545453"}
    else if(crayonColor=="magnesium"){return "#B8B8B8"}
    else if(crayonColor=="mocha"){return "#894800"}
    else if(crayonColor=="fern"){return "#458401"}
    else if(crayonColor=="moss"){return "#018448"}
    else if(crayonColor=="ocean"){return "#004A88"}
    else if(crayonColor=="eggplant"){return "#491A88"}
    else if(crayonColor=="maroon"){return "#891648"}
    else if(crayonColor=="steel"){return "#6E6E6E"}
    else if(crayonColor=="aluminum"){return "#A09FA0"}
    else if(crayonColor=="cayenne"){return "#891100"}
    else if(crayonColor=="aspargus"){return "#888501"}
    else if(crayonColor=="clover"){return "#028401"}
    else if(crayonColor=="teal"){return "#008688"}
    else if(crayonColor=="midnight"){return "#001888"}
    else if(crayonColor=="plum"){return "#891E88"}
    else if(crayonColor=="tin"){return "#878687"}
    else if(crayonColor=="nickel"){return "#888787"}
}

export const channelList=[
    "Facebook",
    "WhatsApp",
    "小红书",
    "线下课",
    "Internal",
    "Contact Form"
]
export function getChannelColor(channel){
    if(channel=="Facebook"){
        return "steelblue"
    }else if(channel=="WhatsApp"){
        return "green"
    }else if(channel=="小红书"){
        return "maroon"
    }else if(channel=="线下课"){
        return "olive"
    }else if(channel=="Internal"){
        return "grey"
    }else if(channel=="Contact Form"){
        return "purple"
    }else{
        return "white"
    }
}

export function getStatusColor(status){
    if(status=="还没联系"||status=="有兴趣"||status=="购买课程"){
        return "red"
    }else if(status=="等待回复"||status=="安排预约"||status=="已经回复"){
        return "orange"
    }else if(status=="不精准客户"||status=="无法联系"||status=="没兴趣"||status=="Conversion Fail"||status=="Task Terminated"){
        return "grey"
    }else if(status=="安排汇款"||status=="预付定金"||status=="分期付款"||status=="全额付款"||status=="跟进购买"){
        return "yellow"   
    }else if(status=="完成报名"||status=="Conversion Success"){
        return "green"
    }else{
        return "black"
    }
}
export const memberShipList=[
    "SP",
    "CP",
    "GP",
    "CM",
    "Free",
]

export const referRankList=[
    "normal",
    "bronze",
    "silver",
    "gold",
]

export function getReferRankColor(referRank){
    if(referRank=="normal"){
        return "white"
    }else if(referRank=="bronze"){
        return "#CD7F32"
    }else if(referRank=="silver"){
        return "silver"
    }else if(referRank=="gold"){
        return "gold"
    }
}

export function fallbackCopyTextToClipboard(text){
    var textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        const editable = textArea.contentEditable
        textArea.contentEditable = true
        const range = document.createRange()
        range.selectNodeContents(textArea)
        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
        textArea.setSelectionRange(0, 999999)
        textArea.contentEditable = editable
    }else {
        textArea.select()
    }

    try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
        alert('Copying text ' + msg)
    } catch (err) {
        alert('Oops, unable to copy', err)
    }
    document.body.removeChild(textArea)
}

export function copyTable() {
    let copyString = '';

    const tableRows = document.querySelectorAll('table tr');
    tableRows.forEach(row => {
        const cells = row.querySelectorAll('td');

        cells.forEach((cell, index) => {
            let columnContent = '';

            if (cell.querySelector('input[type="text"]')) {
                columnContent = cell.querySelector('input[type="text"]').value.trim();
            } else {
                columnContent = cell.innerText.trim().replace(/\n/g, ' ');
            }

            copyString += columnContent + (index === cells.length - 1 ? '\n' : '\t');
        });
    });

    copyTextToClipboard(copyString.trim());
}

export function copyTextToClipboard(text){
    if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
    }
    navigator.clipboard.writeText(text).then(function() {
        alert('Copy to clipboard successful')
    }, function(err) {
        alert('Could not copy text: ', err)
    });
}

export function copyDiv(divRef){
    const parentDiv = divRef.current
    let horizontalContent = ''
    let isNestedDiv = false
    let isParentDiv = true
    let isRow = false
    let headingCount = 0

    const checkDiv = (div) => {              
        const hasNestedDivs = div.querySelector('div') !== null
        if (!hasNestedDivs) {
            isNestedDiv = false
            let columnContent = div.innerText.trim()
            columnContent = columnContent.replace(/\n/g, '\t')
            horizontalContent += columnContent + '\t'
        } else {
            isNestedDiv = true
            processNestedDivs(div)
            horizontalContent += '\n'
        }

        if(isRow){
            horizontalContent = horizontalContent.replace(/\n\n/g, '\n ')
        }
    };

    //due to the some .jsx is use <Row> to display data 
    const checkRow = (row) => {
        const hasRowElement = row.querySelector('Row') !== null

        if (!hasRowElement) {
            isRow = true
            let columnContent = row.innerText.trim()
            columnContent = columnContent.replace(/\n/g, '\t')
            horizontalContent += columnContent + '\t'
        }
    }


    // Function to handle nested divs recursively
    const processNestedDivs = (div) => {
        for (let k = 0; k < div.children.length; k++) {
            const nestedDiv = div.children[k]            
            checkDiv(nestedDiv)
        }
    }

    const processDivs = (rowDiv) => {
        let totalDivInRow =0
        for (let j = 0; j < rowDiv.children.length; j++) {
            const divContent = rowDiv.children[j]         
            
            if(j==0){//first div
                totalDivInRow = divContent.querySelectorAll('div').length
            }        

            if(totalDivInRow != headingCount){
                let columnContent = divContent.innerText.trim()
                columnContent = columnContent.replace(/\n/g, '\t')
                horizontalContent += columnContent + '\t'
            }else{
                checkDiv(divContent)
            }                        
        }            
    }

    for (let i = 0; i < parentDiv.children.length; i++) {
        const rowDiv = parentDiv.children[i]

        if(isParentDiv){
            checkRow(rowDiv)
        }  
        
        if(!isRow || !isParentDiv){
            processDivs(rowDiv)
        }        
        // Add a newline character after the first row (heading)
        if (isParentDiv) {
            horizontalContent += '\n'
            isParentDiv = false// Update the flag
            headingCount=rowDiv.querySelectorAll('div').length
        }
        
        console.log("headingCount : "+ headingCount)
        if(isRow){
            horizontalContent += '\n'
        }

    }
    copyTextToClipboard(horizontalContent.trim())
}

export function checkPermission(searchString) {   
    const permissionString =reactLocalStorage.get("permissions")
    if (!permissionString) {      
        return false
    }else{
        let splitPermissionString = permissionString.split(",")
        let found = false

        for(let permission of splitPermissionString){
            if(permission == searchString){
                found = true
                break
            }
        }     
        return found
    }
}
