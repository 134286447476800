import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerLogo from '../assets/marker.jsx';
import CurrentLocation from '../assets/get_location.png';
import {Button, Spinner} from 'reactstrap';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';

import { Input } from 'reactstrap';

const Marker = () => <MarkerLogo style={{ position: "absolute", bottom:0, left: -10}}/>;

class GoogleMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            lat: this.props.position.lat? parseFloat(this.props.position.lat):"",
            lng: this.props.position.lng? parseFloat(this.props.position.lng):"",
            address: this.props.address,
            mapStyle: this.props.mapStyle,
            mapRadius: (this.props.mapRadius)?this.props.mapRadius:0,
            isMapLoaded: false,
            showMapMarker: (this.props.address && this.props.lat && this.props.lng) ? true : false,
            currentLat: "",
            currentLng: "",
            loadCurrentLocation: false,
        };

        this.setLngLat = this.props.setLngLat;
        this.setAddress = this.props.setAddress;
        this.circle = null;

    }

    componentDidMount = () => {
        this.setState({
            mapRadius:this.props.mapRadius
        })
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.setState({
                    currentLat:position.coords.latitude,
                    currentLng:position.coords.longitude,
                })
            },
            (error) => {
                console.log("Failed to get your location from permission")
            },
            {
            enableHighAccuracy: true,
            timeout : 5000,
            }
        );
    };
    componentDidUpdate(prevProps) {
        if (this.props.mapRadius !== prevProps.mapRadius) {
            this.setState({ mapRadius:this.props.mapRadius })
            this.circle.setRadius(this.props.mapRadius*1000)
            this.circle.setCenter({lat:this.state.lat,lng:this.state.lng})
        }
    }

    runInit = () => {
        if (this.props.address != undefined){
            this.setState({address:this.props.address, lat:this.props.position.lat, lng:this.props.position.lng})
        }
        if (this.props.position.lat != undefined && this.props.position.lng != undefined){
            const request = { address: this.props.address };

            this.geocoder.geocode(request, (results, status) => {
                if (status == 'OK' && results.length > 0) {
                    const result = results[0];

                    this.map.fitBounds(result.geometry.viewport);
                }
            })
            this.setAddress(this.props.address);

            this.setLngLat(this.props.position.lat, this.props.position.lng);

            this.setState({
                lat: this.props.position.lat,
                lng: this.props.position.lng,
                address: this.props.address,
                showMapMarker: true,
            });
        }
        else if (this.state.address)
            this.handleSelect(this.state.address);
    }

    handleChange = address => {
        this.setState({ address });
        this.setAddress(address);
    };

    handleSelect = address => {
        const request = { address: address };

        this.geocoder.geocode(request, (results, status) => {
            if (status == 'OK' && results.length > 0) {

                const result = results[0];

                this.map.fitBounds(result.geometry.viewport);

                this.setAddress(address);

                this.setLngLat(result.geometry.location.lat(), result.geometry.location.lng());

                this.setState({
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng(),
                    address: address,
                    showMapMarker: true,
                });

                if (this.props.mapRadius>0) {
                    this.circle.setCenter({lat:result.geometry.location.lat(),lng:result.geometry.location.lng()})
                }

            }
        })

    };

    getReverseGeocodingData =()=> {
        if (!this.state.currentLat && !this.state.currentLng){
            alert("Please allow the location permission and refresh the page to get current location.")
            return
        }
        var latlng = new window.google.maps.LatLng(this.state.currentLat,this.state.currentLng);
        // This is making the Geocode request
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'latLng': latlng },  (results, status) => {
            // This is checking to see if the Geoeode Status is OK before proceeding
            if (status == window.google.maps.GeocoderStatus.OK) {
                var theBiggest = results[0]
                for (var result of results){
                    if (result.address_components.length > theBiggest.address_components.length) {
                        theBiggest = result
                    }
                }

                this.handleSelect(theBiggest.formatted_address);

            }else {
                // alert(status)
                alert("Fail to get current location.")
            }
        });

    }

    render() {
        return (
            <>
            {
                this.props.address !== undefined &&
            <div>
                {
                    this.state.isMapLoaded &&
                    <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                    >
                        {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                        }) => (
                            <div>
                                <div style={{display:"flex",flexDirection:"row", marginBottom: 10 }}>

                                    <Input type="text" value={this.state.address}
                                        {...getInputProps({
                                            placeholder: 'Search Places...',
                                            className: 'location-search-input'
                                        })}
                                    />
                                    <Button outline  title="Show your current location" className="secondary" style={{marginLeft:5,textAlign:"center",paddingTop:4,paddingLeft:5,paddingRight:5}} onClick={()=>{this.getReverseGeocodingData() }}><img style={{width:25,height:25}} src={CurrentLocation}/></Button>
                                    <Button style={{marginLeft:5}} onClick={()=>{
                                        var clear = ""
                                        this.setState({lat:"",lng:"", address:"" });
                                        this.setAddress(clear);
                                        this.setLngLat(clear, clear);
                                        if(this.props.mapRadius>0){
                                            this.circle.setCenter({lat: "", lng: ""})
                                            this.circle.setRadius(0)
                                        }
                                    }}>Clear</Button>
                                </div>
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style
                                                })}
                                                key={suggestion.placeId}
                                            >
                                                <strong>
                                                    {suggestion.formattedSuggestion.mainText}
                                                </strong>{' '}
                                                <small>
                                                    {suggestion.formattedSuggestion.secondaryText}
                                                </small>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                }
                <div style={this.state.mapStyle?{width:this.state.mapStyle.width, height:this.state.mapStyle.height}:{ height: '400px', maxWidth: '100%', maxHeight: '100%', width: '100vw' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: 'AIzaSyCwuKYKjwg15li8R2jTvWVVKqPWiCrKxVE',
                            libraries: ['places', 'geometry'],
                        }}
                        defaultCenter={
                            {
                                "lat": (this.state.lat && this.state.lng) ? this.state.lat : 2.963799804463665,
                                "lng": (this.state.lat && this.state.lng) ? this.state.lng : 101.62298467497963
                            }
                        }
                        center={
                            this.state.lat && this.state.lng ? 
                                { lat: this.state.lat, lng: this.state.lng } 
                            : 
                                { lat: 2.963799804463665, lng: 101.62298467497963 } 
                        }
                        defaultZoom={(this.state.lat && this.state.lng) ? 17 : 10}
                        onClick={({ x, y, lat, lng, event }) => {
                            this.setLngLat(lat, lng);
                            this.setState({
                                lat: lat,
                                lng: lng,
                            });
                            if (this.props.mapRadius>0){
                                this.circle.setRadius(this.props.mapRadius*1000)
                                this.circle.setCenter({lat: lat, lng: lng})
                            }
                        }}
                        onGoogleApiLoaded={({ map, maps }) => {
                            this.circle = new window.google.maps.Circle({
                                strokeColor: "#FF0000",
                                strokeOpacity: 0.5,
                                strokeWeight: 2,
                                fillColor: "#FF0000",
                                fillOpacity: 0.2,
                                map,
                                center: {lat: "", lng: ""},
                                radius: this.state.mapRadius*1000
                            })

                            this.map = map;
                            this.geocoder = new maps.Geocoder();

                            this.runInit();

                            this.setState({ isMapLoaded: true });
                        }}
                        yesIWantToUseGoogleMapApiInternals
                    >
                        {
                            <Marker
                                lat={this.state.lat}
                                lng={this.state.lng}
                                text="My Marker"
                            />
                        }
                    </GoogleMapReact>
                </div>
            </div>
            }
            </>
        );
    }
}

export default GoogleMap;
