import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal,Tooltip} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from 'react-icons/ai';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'
import ReactPaginate from 'react-paginate';
import WalletLog from '../components/walletLog.jsx';
import {FaCheckCircle,FaCircle} from 'react-icons/fa';
import {BsFillInfoCircleFill} from 'react-icons/bs'

export default class Agency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            mode:null,
            agentData:[],

            selectedCompany:null,

            walletLogList:[],
            walletLogModal:false,
            walletLogPageCount:0,
            walletLogPage:1,

            profitSharingLogList:[],
            profitSharingModal:false,
            profitSharingPageCount:0,
            profitSharingPage:1,

            modalFunction:"",
            infoMessage:"",
            inputField:[],
            inputValue:[],
            executionModal:false,

            infoModal:false,
            message:null,
            loadingFromChild:null,
            
            searchField:"",
            serviceAgencyPage:0,
            itemPerPage:6,
            totalServiceAgencyPage:0,
            totalCompanyCount:0,
            companyData:[],
            totalReferralAgencyPage:0,
            referralAgencyPage:0,
            agentList:[]
        }
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        if(GLOBAL.checkPermission("S>.~z2my")){   //staff
            this.getServiceAgentStats()
        }else if(GLOBAL.checkPermission("7aO.[=5g")){ //advisor (can only access advised agencies)
            this.getAdvisorAgency()
        }else{
            alert("You do not have permission to access this page")
        }
    }

    getAdvisorAgency=()=>{
        this.setState({loading:true})
        const payload={
            
        }
        axios
            .post(`sales/getalladvisoragency`,qs.stringify(payload),{timeout:60000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentData:JSON.parse(response.data),
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Get all advisor agency error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    getServiceAgentStats=()=>{
        this.setState({loading:true})
        const payload={
            page:this.state.serviceAgencyPage,
            itemPerPage:this.state.itemPerPage,
        }
        axios
            .post(`sales/getallagentstats`,qs.stringify(payload),{timeout:60000})
            .then(async response => {
                this.setState({
                    loading:false,
                    mode:"service",
                    agentData:response.data.companyData,
                    totalServiceAgencyPage:response.data.totalServiceAgencyPage,
                    totalCompanyCount:response.data.totalCompanyCount,
                    serviceAgencyPage:response.data.serviceAgencyPage -1,
                    searchField:"",
                    agentList:[],
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Get all agent stats error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    getReferralAgentStats=()=>{
        this.setState({loading:true})
        const payload={
            page:this.state.referralAgencyPage,
            itemPerPage:this.state.itemPerPage,
        }
        axios
            .post(`sales/getreferralagentstats`,qs.stringify(payload),{timeout:60000})
            .then(async response => {
                this.setState({
                    loading:false,
                    mode:"referral",
                    agentData:response.data.companyData,
                    totalReferralAgencyPage:response.data.totalReferralAgencyPage,
                    totalCompanyCount:response.data.totalCompanyCount,
                    referralAgencyPage:response.data.referralAgencyPage -1,
                    searchField:"",
                    agentList:[]
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Get all agent stats error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    handleSearch= () => {
        if (this.state.searchField.length >= 2) {
            this.getAllAgentDetails()
        } else {
            this.setState({
                agentList: [],
                loading:false
            })
            alert("Search field must contain at least 2 characters.")
        }
    }

    clearSearch= () =>{
        this.state.mode=="service"?this.getServiceAgentStats():this.getReferralAgentStats()
    }


    getAllAgentDetails = () => {
        this.setState({ loading: true })
        const payload = {
            searchField: this.state.searchField
        }
        axios
            .post(`sales/getallagentdetail`, qs.stringify(payload), { timeout: 60000 })
            .then(async response => {
                this.setState({
                    loading: false,
                    agentData:response.data.companyList,
                    agentList:response.data.employeeList,
                    mode:""
                })
            })
            .catch(error => {
                alert("Get all agent details error\n" + error)
                this.setState({ 
                    loading: false
                })
            })
    }
    
    getSharingRecord=(page=1)=>{
        this.setState({loading:true})
        const payload={
            companyID:this.state.selectedCompany.companyDetails.id,
            page:page,
            itemPerPage:10,
        }
        axios
            .post(`sales/getprofitsharingrecord`,qs.stringify(payload))
            .then(async response => {
                //parse date
                for(let profitSharingLog of response.data.profitSharingLogList){
                    let rawDateSplit=profitSharingLog.createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    profitSharingLog.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    profitSharingLogList:response.data.profitSharingLogList,
                    profitSharingModal:true,
                    profitSharingPageCount:response.data.pageCount,
                    profitSharingPage:page,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Get profit sharing log error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }
    handleProfitSharingLogPageClick=(data)=>{
        let selected = data.selected;
        this.getSharingRecord(selected+1)
    }

    handleChildLoading = (loading) => {
        // Update the parent state with data from the child
        this.setState({ loadingFromChild: loading });      
      };
    render(){
        return(
            <div>
                {
                this.state.loading || this.state.loadingFromChild ?
                    <LoadingScreen />
                    : null
                }

                <Modal isOpen={this.state.infoModal} toggle={()=>this.setState({infoModal:false})}>
                    <div style={{padding:20}}>
                        {this.state.message}
                    </div>
                </Modal>

                <Modal isOpen={this.state.profitSharingModal} toggle={()=>this.setState({profitSharingModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>
                            Profit Sharing Log
                        </div>
                        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                            {
                            this.state.profitSharingLogList.map((profitSharingLog)=>{
                                if(profitSharingLog.operator==null){
                                    return(
                                        <div style={{border:"solid 1px green",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                                <div>{profitSharingLog.description}</div>
                                                <div>{profitSharingLog.createDate}</div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                                <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",width:"100%",fontSize:12}}>
                                                    <div>Total Value: RM{profitSharingLog.totalValue}</div>
                                                    <div>Total Cost: RM{profitSharingLog.totalCost}</div>
                                                    <div>Total Commission: RM{profitSharingLog.totalCommission}</div>
                                                    <div>Total Remaining Profit: RM{profitSharingLog.totalRemainingProfit}</div>
                                                    <div>Profit Sharing Percentage: {profitSharingLog.profitSharingPercentage}%</div>

                                                </div>
                                                <div style={{display:"flex",flexDirection:"column",textAlign:"right",fontSize:13,whiteSpace:"nowrap"}}>
                                                    <div>Amount: RM{profitSharingLog.profitSharingAmount}</div>
                                                    <div style={{marginLeft:10}}>Balance: RM{profitSharingLog.balance}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return(
                                        <div style={{border:"solid 1px lightgrey",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                                <div>Operator: {profitSharingLog.operator}</div>
                                                <div>{profitSharingLog.createDate}</div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                                <div style={{flex:1,display:"flex",flexDirection:"column"}}>
                                                    <div style={{fontSize:12}}>Payment Number: {profitSharingLog.paymentNumber}</div>
                                                    <div style={{fontSize:12,color:"grey"}}>
                                                        {profitSharingLog.description}
                                                    </div>
                                                </div>
                                                <div style={{marginLeft:5,textAlign:"right",fontSize:13}}>
                                                    <div>Amount: RM{profitSharingLog.profitSharingAmount}</div>
                                                    <div>Balance: RM{profitSharingLog.balance}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                        <div>
                            {this.state.profitSharingPageCount > 0 && (
                                <div style={this.state.width > 600 ? {display: "flex", justifyContent: "flex-end", marginTop: 10} : {display: "flex", justifyContent: "flex-end", marginTop: 10, fontSize: "0.7rem"}}>
                                    <ReactPaginate
                                        disableInitialCallback={true}   //stop auto reload upon mount
                                        initialPage={this.state.profitSharingPage-1}
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        pageCount={this.state.profitSharingPageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handleProfitSharingLogPageClick}
                                        subContainerClassName={'pages pagination'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        forcePage={this.state.profitSharingPage-1}
                                    />
                                </div>
                            )}
                        </div>
                        <div style={{marginTop:10,width:"100%"}}>
                            <Button style={{}} onClick={()=>{
                                this.setState({profitSharingModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                { 
                this.state.walletLogModal==true?
                    <WalletLog
                        sendLoadingToParent={this.handleChildLoading}
                        company={this.state.selectedCompany.id}
                        isOpen={this.state.walletLogModal}
                        toggle={() => this.setState({ walletLogModal: false, loading: false })}
                    />
                    :
                    null
                }        

                <NavigationBar currentPage="agency" />
                <div style={{padding:15}}>
                    <div style={{display:"flex",flexDirection:"row",gap:10,marginBottom:10}}>
                        {
                        GLOBAL.checkPermission("S>.~z2my")? //get all service agent stats
                            <Button color={this.state.mode=="service"?"primary":"secondary"} style={{}} onClick={()=>{
                                if(GLOBAL.checkPermission("S>.~z2my")){   //get all service agents stats
                                    this.getServiceAgentStats()
                                }else{
                                    alert("You do not have permission to access this page")
                                }
                            }}>
                                Service Agency
                            </Button>
                            :
                            null
                        }
                        {
                        GLOBAL.checkPermission("540XnFmK")? //get all referral agency
                            <Button color={this.state.mode=="referral"?"primary":"secondary"} style={{}} onClick={()=>{
                                if(GLOBAL.checkPermission("540XnFmK")){   //get all referral agents stats
                                    this.getReferralAgentStats()
                                }else{
                                    alert("You do not have permission to access this page")
                                }
                            }}>
                                Referral Agency
                            </Button>
                            :
                            null
                        }
                        <Input placeholder="Search company and agent"
                            value={this.state.searchField} style={{width:"50%"}}
                            onKeyDown={(e)=>{
                                if(e.keyCode==13){
                                    e.preventDefault()
                                    this.handleSearch()
                                }
                            }}
                            onChange={(e)=>{
                                this.setState({searchField:e.target.value})
                            }} />
                            <Button type= "reset" color="danger" style={{whiteSpace:"nowrap"}} onClick={()=>{
                                this.clearSearch()    
                            }}>
                                Clear
                            </Button>
                            
                            <Button color="primary" style={{paddingLeft:30,paddingRight:30}} onClick={()=>{
                                this.handleSearch()
                            }}>
                                Search
                            </Button>
                    </div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginBottom:5}}>
                                    {this.state.agentList.map((agent) => (
                                        <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",margin: 5,border: agent.active ? "solid 1px skyblue" : "solid 1px lightgrey",
                                                borderRadius: 5,padding: 6,backgroundColor:agent.active?"#ebf4ff":"#f7f7f7"
                                                }}>
                                                {agent.active?
                                                    <FaCheckCircle style={{color:"green",marginRight:5,marginTop:4}}/>
                                                    :
                                                    <FaCircle style={{color:"orange", marginRight:5,marginTop:4}}/>
                                                    }
                                                    {agent.phone===agent.name?agent.phone:agent.phone+" "+agent.name}
                                                    {agent.company?" ["+agent.company.name+"]":null}
                                                        <Button color="primary" style={{marginLeft:10,fontSize:13,paddingTop:2,paddingBottom:2,paddingLeft:10,paddingRight:10}} onClick={()=>{
                                                            history.push("agents?companyid="+agent.company.id)
                                                            }}>
                                                            View
                                                        </Button>
                                        </div>
                                    ))}
                        </div>
                        {
                        this.state.agentData.map((company)=>{
                        return(
                            <div style={{position:"relative",padding:15,border:"solid 1px grey",borderRadius:15,marginBottom:5,backgroundColor:company.type=="service"?"#e6e6ff":"#f8eded"}}>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div>
                                        <div>Agency Name: {company.name}</div>
                                        <div>SSM Number: {company.ssmNumber}</div>
                                        <div>Director: {company.directorUsername} {company.directorName}</div>
                                        <div>Advisor: {company.advisorString}</div>
                                    </div>
                                    <div style={{marginLeft:15}}>
                                        <div>Bank Account Name: {company.bankAccountName}</div>
                                        <div>Bank Account Number: {company.bankAccountNumber}</div>
                                        <div>Bank Name: {company.bankName}</div>
                                    </div>
                                    <div style={{flex:1}} />
                                    <div style={{marginLeft:20}}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",}}>
                                            <div style={{marginLeft:10}}>Company Wallet: RM{company.wallet!=null?company.wallet.toLocaleString():"-"}</div>
                                            <div style={{display:"flex",flexDirection:"column",marginLeft:15}}>
                                                <Button style={{}} onClick={()=>{
                                                    this.setState({selectedCompany:company,walletLogModal:true})
                                                }}>
                                                    Wallet Records
                                                </Button>
                                            </div>
                                            <BsFillInfoCircleFill style={{marginLeft:5,marginRight:10,color:"skyblue",cursor:"pointer"}} onClick={()=>{
                                                this.setState({infoModal:true,message:"A virtual user account of the agent company to store awarded referral credit when agent company use company link to generate leads."})
                                            }}/>
                                            <div style={{marginLeft:5}}>Pay To Company : </div>
                                            {
                                                company.payToCompany?
                                                <AiOutlineCheckSquare style={{marginLeft: 7,marginRight: 15,color: "green",fontSize: "1.5rem",}}/>
                                                :
                                                <AiOutlineCloseSquare style={{marginLeft: 7,marginRight: 15,color: "red",fontSize: "1.5rem",}}/>
                                            }
                                            {/*
                                            <div style={{marginLeft:20}}>Profit Sharing Wallet: RM{company.companyDetails.profitSharingWallet.toLocaleString()}</div>
                                            <div style={{display:"flex",flexDirection:"column",marginLeft:15}}>
                                                <Button style={{}} onClick={()=>{
                                                    this.setState({selectedCompany:company},()=>{
                                                        this.getSharingRecord()
                                                    })
                                                }}>
                                                    Profit Sharing Records
                                                </Button>
                                            </div>
                                            */}
                                        </div>
                                        <div style={{marginTop:10,display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}>
                                            <Button color="primary" style={{marginRight:10}} onClick={()=>{
                                                history.push("agents?companyid="+company.id)
                                            }}>
                                                Agents
                                            </Button>
                                            <Button color="primary" style={{marginRight:10}} onClick={()=>{
                                                history.push("companyachievement?companyid="+company.id)
                                            }}>
                                                Company Achievement
                                            </Button>
                                            <div style={{position:"absolute",top:65,right:320,padding:5,fontSize:18,border:company.active?null:"2px solid red",
                                                        color:company.active?null:"salmon"}}>
                                                        {company.active?null:"INACTIVE"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    Linked GD Shop: {company.gdShopName?company.gdShopName:"-"}
                                </div>
                            </div>
                        )}
                    )}  
                    {/*Service Agency*/}
                    {this.state.mode === "service" && (    
                        <ReactPaginate
                            pageCount={this.state.totalServiceAgencyPage}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                            forcePage={this.state.serviceAgencyPage}
                            onPageChange={async ({selected}) => {
                                console.log("Page selected:", {selected})
                                    this.setState({serviceAgencyPage:selected},()=>{
                                        this.getServiceAgentStats()
                                })
                        }}/>
                    )}
                    {/*Referral Agency*/}
                    {this.state.mode ==="referral" && (
                        <ReactPaginate
                            pageCount={this.state.totalReferralAgencyPage}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                            forcePage={this.state.referralAgencyPage}
                            onPageChange={async ({selected}) => {
                                console.log("Page selected:", {selected})
                                    this.setState({referralAgencyPage:selected},()=>{
                                        this.getReferralAgentStats()
                                })
                        }}/>
                    )}
                </div>
            </div>
        )
    }
}

Agency = Radium(Agency);

let styles={

}
