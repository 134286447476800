import React from 'react';
import { Router,Switch, Route, Link } from 'react-router-dom';
import history from './history';
import axios from 'axios';
import {reactLocalStorage} from 'reactjs-localstorage';

import LoginPage from './pages/loginpage'
import SignUpPage from './pages/signuppage'
import Permissions from './pages/permissions'
import Template from './pages/template';

import SalesLog from './pages/saleslog'
import OtherPage from './pages/other'
import Execution from './pages/execution'
import OfflineCourse from './pages/offlinecourse'
import Achievement from './pages/achievement'
import CompanyAchievement from './pages/companyachievement'
import LeadPage from './pages/lead'
import Conference from './pages/conference'
import Agents from './pages/agents'
import JoinAgent from './pages/joinagent'
import Agency from './pages/agency'
import Affiliate from './pages/affiliate'
import Dashboard from './pages/dashboard'
import OfflineEvent from './pages/offlineevent'
import Ticket from './pages/ticket'
import ClientBackground from './pages/clientbackground'
import Notifications from './pages/notifications'
import LeadPool from './pages/leadpool'
import Restricted from './pages/restricted'

export default class App extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            token:reactLocalStorage.get("token"),
        }
        this.launchFunc()
    }

    launchFunc=()=>{
        if(window.location.host.startsWith("localhost")||window.location.host.startsWith("127.0.0.1")){
            axios.defaults.baseURL = "http://127.0.0.1:8000/"
        }else{
            axios.defaults.baseURL="https://api.enlliance.com/"
        }
        axios.defaults.timeout = 15000
    }

    render=()=>{
        return (
            <Router history={history}>
                <div style={{width:"100%"}}>
                    <Switch>
                        <Route path="/" exact component={LoginPage}/>
                        <Route path="/signin" exact component={LoginPage}/>
                        <Route path="/signup" exact component={SignUpPage}/>
                        <Route path="/saleslog" exact component={SalesLog}/>
                        <Route path="/other" exact component={OtherPage}/>
                        <Route path="/offlineevent" exact component={OfflineEvent} />
                        <Route path="/conference" exact component={Conference}/>
                        <Route path="/execution" exact component={Execution}/>
                        <Route path="/offlinecourse" exact component={OfflineCourse}/>
                        <Route path="/achievement" exact component={Achievement}/>
                        <Route path="/companyachievement" exact component={CompanyAchievement}/>
                        <Route path="/lead" exact component={LeadPage}/>
                        <Route path="/ticket" exact component={Ticket}/>
                        <Route path="/agents" exact component={Agents}/>
                        <Route path="/joinagent" exact component={JoinAgent}/>
                        <Route path="/agency" exact component={Agency}/>
                        <Route path="/affiliate" exact component={Affiliate}/>
                        <Route path="/dashboard" exact component={Dashboard}/>
                        <Route path="/permissions" exact component={Permissions}/>
                        <Route path="/notifications" exact component={Notifications} />
                        <Route path="/clientbackground" exact component={ClientBackground} />
                        <Route path="/template" exact component={Template}/>
                        <Route path="/leadpool" exact component={LeadPool} />
                        <Route path="/restricted" exact component={Restricted} />
                    </Switch>
                </div>
            </Router>
        )
    }
}
