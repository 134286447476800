import React from 'react';
import {Spinner} from 'reactstrap';

export default class LoadingScreen extends React.Component{

    render(){
        return(
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",
                position:"fixed",width:"100%",height:"100%",backgroundColor:"black",opacity:0.5,color:"white",zIndex:1100}}>
                <Spinner color="light" style={{width:80,height:80}}/>
                <div style={{fontSize:18,marginTop:10}}>运行中。。。</div>
            </div>
        )
    }
}

var styles = {

}
