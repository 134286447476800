import React from 'react';
import {Input,Button,Row,Col,Table,Modal} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage'
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen'
import * as GLOBAL from './global.js'
import ReactPaginate from 'react-paginate'

import {TiDelete} from 'react-icons/ti'
import {MdRefresh} from 'react-icons/md'
import {FaCopy} from 'react-icons/fa'
import {AiFillCaretDown,AiFillCaretUp} from 'react-icons/ai'
import {BsQuestionCircleFill}  from 'react-icons/bs'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw,ContentState } from 'draft-js';

export default class LeadPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            modalLoading:false,
            findField:"",
            memberList:[],
            viewMode:"search",  //search || detail
            currentMember:{},

            editProfileMode:false,
            toChangeCurrentMember:{},

            newSalesLog:"",
            tagModal:false,
            selectedTag:[],
            allTags:[],
            tagsToShow:[],
            tagSearch:"",
            contactLogHistory:[],

            promoModalVoucher:false,
            currentPromoVoucher:null,

            addTagModal:false,
            tagList:[],
            clientTagList:[],
            clientTagSearch:"",
            relatedClientTag:[],

            sortName:"",
            sortPhone:"",
            sortEmail:"",
            sortJoinDate:"",

            addContactModal:false,
            contactName:"",
            contactPhone:"",
            contactEmail:"",
            contactPIC:"",
            contactReferrer:"",
            contactPhoneError:false,
            contactPICError:false,
            contactReferrerError:false,
            contactEmailError:false,

            tutorialModal:false,

            page:0,
            resultCount:0,
            entryPerPage:20,

            changePICModal:false,
            changePICPhone:null,

            changeReferrerModal:false,
            changeReferrerPhone:null,

            alreadyExistModal:false,
            alreadyExistUser:null,  //either already exist user or contact
            alreadyExistContact:null,   //either already exist user or contact
            alreadyExistSalesLog:null,
            alreadyExistLeadLog:null,
            alreadyExistCanView:false,

            leadTicketList:[],

            selectedChannel:null,
            newInterest:"",
            selectChannelError:false,
            newInterestError:false,

            createTicketMode:"lead",    //lead,member

            createTicketModal:false,
            createTicketReferrer:null,
            createTicketPIC:null,
            createTicketChannel:null,
            createTicketInterest:null,

            createTicketReferrerError:false,
            createTicketPICError:false,
            createTicketChannelError:false,
            createTicketInterestError:false,

            agentListModal:false,
            agentCompanyList:[],
            agentList:[],
            agentListMode:"agentCompany",
            staffListModal:false,
            staffList:[],
            
            editorState:EditorState.createEmpty(),
          
            selectAlreadyExistModal:false,
            selectAlreadyExistArray:[],
        }
        this.divRef = React.createRef();
        this.extensionList=[];
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        // //load all tags
        // this.setState({loading:true,})
        // //get all tags from server
        // axios
        //     .get(`sales/getalltags`)
        //     .then(async response => {
        //         //group the tags [[tags],[tags]]
        //         var tagGroup=[]
        //         for(const tag of response.data){
        //             var foundGroup=false
        //             for(var tagArray of tagGroup){
        //                 if(tag.group==tagArray[0].group){
        //                     tagArray.push(tag)
        //                     foundGroup=true
        //                     break
        //                 }
        //             }
        //             if(!foundGroup){
        //                 tagGroup.push([tag])
        //             }
        //         }
        //
        //         this.setState({
        //             allTags:tagGroup,
        //             tagsToShow:this.hideSubgroupTag(response.data),
        //             loading:false,
        //         })
        //     })
        //     .catch(error =>{
        //         alert("Get all tags error\n"+error)
        //         this.setState({loading:false})
        //     })

        this.parseSearchPath()
    }

    parseSearchPath=()=>{
        let runPhoneSearch=null
        let runContactSearch=null
        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        for(var input of inputSplit){
            let param=input.split("=")[0]
            let value=input.split("=")[1]
            if(param=="phone"){
                runPhoneSearch=value
            }else if(param=="search"){
                runContactSearch=value
            }
        }

        if(runContactSearch!=null){
            if(runPhoneSearch!=null){
                this.setState({findField:runPhoneSearch},()=>{
                    this.searchMember(0,false,runPhoneSearch)
                })
            }else{
                this.setState({findField:runContactSearch},()=>this.searchMember())
            }
        }else if(runContactSearch==null&&runPhoneSearch!=null){
            this.setState({findField:runPhoneSearch},()=>{
                this.searchMember(0,false,runPhoneSearch)
            })
        }
    }

    searchMember=(page=0,silent=false,runPhoneSearch=null)=>{
        if(silent==false){
            this.setState({loading:true})
        }
        let searchTerm=this.state.findField
        const payload = {
            searchTerm:searchTerm,
            page:page,
            entryPerPage:this.state.entryPerPage,
        }
        axios
            .post(`sales/searchcontact`, qs.stringify(payload),{timeout:40000})
            .then(async response => {
                //parse the date
                for(let item of response.data.memberList){
                    let parsedDate=item.createDate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.createDate=parsedDate
                }

                if(silent==false&&runPhoneSearch==null){
                    this.setState({viewMode:"search",loading:false})
                }

                this.setState({
                    memberList:response.data.memberList,
                    resultCount:response.data.resultCount,
                    page:page,
                },()=>{
                    if(runPhoneSearch!=null){
                        let foundTheOne=false
                        for(let member of response.data.memberList){
                            if(member.phone==runPhoneSearch){
                                this.viewMember(member).then(()=>{
                                    this.loadContactLog(member.id)
                                })
                                foundTheOne=true
                                break
                            }
                        }
                        if(foundTheOne==false){
                            this.setState({viewMode:"search",loading:false})
                        }
                    }
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==445){
                        alert("Error with search criteria")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Search error\n"+error)
                this.setState({loading:false})
            })
    }

    viewMember=async (theMember)=>{
        this.setState({
            viewMode:"detail",
            currentMember:theMember,
        })
    }

    openTagModal=()=>{
        this.setState({tagModal:true})
    }

    hideSubgroupTag=(inputTags)=>{
        var allTags=null
        if(inputTags){
            allTags=inputTags
        }else{
            //dissemble the tag group of alltags
            var dissembledTags=[]
            for(const group of this.state.allTags){
                for(const tag of group){
                    dissembledTags.push(tag)
                }
            }
            allTags=dissembledTags
        }

        //collect all the subgroup names
        var subgroupNames=[]
        for(const tag of allTags){
            if(tag.subgroup){
                subgroupNames.push(tag.subgroup)
            }
        }
        //delete duplicates
        subgroupNames= subgroupNames.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })

        //go through the subgroup names and remove them from the tag list
        var filteredTags=[]
        for(const tag of allTags){
            var isSubgroupMember=false
            for(const name of subgroupNames){
                if(tag.group==name){
                    isSubgroupMember=true
                    break
                }
            }
            if(!isSubgroupMember){
                filteredTags.push(tag)
            }
        }

        //regroup them by group name
        //group the tags [[tags],[tags]]
        var tagGroup=[]
        for(const tag of filteredTags){
            var foundGroup=false
            for(var tagArray of tagGroup){
                if(tag.group==tagArray[0].group){
                    tagArray.push(tag)
                    foundGroup=true
                    break
                }
            }
            if(!foundGroup){
                tagGroup.push([tag])
            }
        }

        return tagGroup
    }

    addTag=(theTag)=>{
        var currentTag=this.state.selectedTag
        //check if the tag was already added
        for(var j=0;j<currentTag.length;j++){
            if(currentTag[j].name==theTag.name){
                this.setState({tagModal:false})
                return
            }
        }
        currentTag.push(theTag)

        //if the tag has a subgroup, one must tag the subgroup too next
        if(theTag.subgroup){
            //filter out the subgroup
            var subgroupList=[]
            for(var tGroup of this.state.allTags){
                for(var t of tGroup){
                    if(t.group==theTag.subgroup){
                        subgroupList.push(t)
                    }
                }
            }

            //regroup them by group name
            //group the tags [[tags],[tags]]
            var tagGroup=[]
            for(const tag of subgroupList){
                var foundGroup=false
                for(var tagArray of tagGroup){
                    if(tag.group==tagArray[0].group){
                        tagArray.push(tag)
                        foundGroup=true
                        break
                    }
                }
                if(!foundGroup){
                    tagGroup.push([tag])
                }
            }

            this.setState({
                tagsToShow:tagGroup,
                selectedTag:currentTag,
            })
        }else{
            this.setState({
                selectedTag:currentTag,
                tagModal:false,
                tagsToShow:this.hideSubgroupTag(),
            })
        }
    }

    removeTag=(theTag)=>{
        var currentTag=this.state.selectedTag
        for(var r=0;r<currentTag.length;r++){
            if(currentTag[r].name==theTag.name){
                currentTag.splice(r,1)
                break
            }
        }
        this.setState({selectedTag:currentTag})
    }

    postContactLog=()=>{
        //prepare the tag as list of tag names
        var tagNameList=[]
        for(var t=0;t<this.state.selectedTag.length;t++){
            tagNameList.push(this.state.selectedTag[t].name)
        }

        //post sales log to server
        this.setState({loading:true})
        const payload = {
            message:this.state.newSalesLog,
            tag:JSON.stringify(tagNameList),
            contactID:this.state.currentMember.id,
        }
        axios
            .post(`sales/postcontactlog`, qs.stringify(payload))
            .then(async response => {
                this.loadContactLog(this.state.currentMember.id)
                this.setState({
                    newSalesLog:"",
                    selectedTag:[],
                    loading:false,
                })
            })
            .catch(error =>{
                alert("Post lead log error\n"+error)
                this.setState({loading:false})
            })
    }

    loadContactLog=(currentMemberID)=>{
        this.setState({loading:true})
        if(!currentMemberID){
            let currentMemberID=this.state.currentMember.id
        }
        const payload = {
            contactID:currentMemberID,
        }
        axios
            .post(`sales/loadcontactlog`, qs.stringify(payload))
            .then(async response => {
                //parse time
                for(let f=0;f<response.data.contactLogHistory.length;f++){
                    let rawDateSplit=response.data.contactLogHistory[f].dateTime.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.contactLogHistory[f].dateTime=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.leadTicketList.length;f++){
                    let rawDateSplit=response.data.leadTicketList[f].lastUpdate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.leadTicketList[f].lastUpdate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let f=0;f<response.data.leadTicketList.length;f++){
                    let rawDateSplit=response.data.leadTicketList[f].createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.leadTicketList[f].createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }
                for(let ticket of response.data.leadTicketList){
                    if(ticket.expiryDate!=null){
                        let rawDateSplit=ticket.expiryDate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        ticket.expiryDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                    }
                }
                for(let i=0;i<response.data.contactLogHistory.length;i++){
                    if(response.data.contactLogHistory[i].message.startsWith("{")){
                        let content= convertFromRaw(JSON.parse(response.data.contactLogHistory[i].message))
                        response.data.contactLogHistory[i].message=EditorState.createWithContent(content)
                    }
                }

                this.setState({
                    loading:false,
                    contactLogHistory:response.data.contactLogHistory,
                    clientTagList:response.data.clientTagList,
                    leadTicketList:response.data.leadTicketList,
                })
            })
            .catch(error =>{
                alert("Load lead log error\n"+error)
                this.setState({loading:false})
            })
    }

    deleteContactLog=(theLog)=>{
        this.setState({loading:true})
        const payload = {
            logID:theLog.id
        }
        axios
            .post(`sales/deletecontactlog`, qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                })
                this.loadContactLog(this.state.currentMember.id)
            })
            .catch(error =>{
                alert("Delete lead log history error\n"+error)
                this.setState({loading:false})
            })
    }

    saveProfile=()=>{
        //validate email
        let theEmail=this.state.toChangeCurrentMember.email
        if(theEmail!=null&&theEmail.trim().length==0){
            theEmail=null
        }else{
            if(!this.validateEmail(this.state.toChangeCurrentMember.email)){
                alert("Invalid email format")
                return
            }
        }

        //submit the new profile
        this.setState({loading:true})
        const payload = {
            id:this.state.toChangeCurrentMember.id,
            phone:this.state.toChangeCurrentMember.phone,
            name:this.state.toChangeCurrentMember.name,
            email:theEmail,
        }
        axios
            .post(`sales/updatecontact`, qs.stringify(payload))
            .then(async response => {
                //change the data at the search result list
                var newMemberList=this.state.memberList
                for(var member of newMemberList){
                    if(member.id==this.state.toChangeCurrentMember.id){
                        member.phone=this.state.toChangeCurrentMember.phone
                        member.name=this.state.toChangeCurrentMember.name
                        member.email=this.state.toChangeCurrentMember.email
                        break
                    }
                }
                this.setState({
                    loading:false,
                    editProfileMode:false,
                    currentMember:this.state.toChangeCurrentMember,
                    memberList:newMemberList,
                })

                alert("New profile detail saved")
            })
            .catch(error =>{
                alert("Edit profile data error\n"+error)
                this.setState({loading:false,editProfileMode:false})
            })
    }

    loadAllTagList=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/getallclienttaglist`,{timeout:10000})
            .then(async response => {
                this.setState({
                    addTagModal:true,
                    loading:false,
                    tagList:response.data,
                })
            })
            .catch(error =>{
                alert("Get all client tag list error\n"+error)
                this.setState({loading:false})
            })
    }

    createNewClientTag=()=>{
        if(this.state.clientTagSearch==""){
            return
        }

        this.setState({modalLoading:true})
        const payload = {
            newClientTag:this.state.clientTagSearch,
        }
        axios
            .post(`sales/createnewclienttag`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                alert("Create new client tag successful")
                this.setState({modalLoading:false})
                this.loadAllTagList()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){ //same client tag exists
                        alert("Same client tag exists")
                        this.setState({modalLoading:false})
                        return
                    }
                }
                alert("Create new client tag error\n"+error)
                this.setState({modalLoading:false})
            })
    }

    addTagToClient=(theTag)=>{
        this.setState({loading:true,addTagModal:false})
        const payload = {
            tagTitle:theTag.title,
            contactID:this.state.currentMember.id,
        }
        axios
            .post(`sales/addtagtocontact`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                this.setState({loading:false})
                this.loadContactLog(this.state.currentMember.id)
            })
            .catch(error =>{
                alert("Add tag to contact error\n"+error)
                this.setState({loading:false})
            })
    }

    deleteContactTag=(tag)=>{
        var deleteConfirm=window.confirm("Delete the tag for this contact?")
        if(deleteConfirm){
            this.setState({loading:true})
            const payload = {
                tagTitle:tag.title,
                contactID:this.state.currentMember.id
            }
            axios
                .post(`sales/deletecontacttag`, qs.stringify(payload),{timeout:10000})
                .then(async response => {
                    this.setState({
                        loading:false,
                    })
                    this.loadContactLog(this.state.currentMember.id)
                })
                .catch(error =>{
                    alert("Delete contact tag error\n"+error)
                    this.setState({loading:false})
                })
        }
    }
    sortColumn=(column)=>{
        if(column=="Phone"){
            if(this.state.sortPhone=="Down"){
                this.setState({sortPhone:""})
            }else{  //""
                //rearrange array by phone
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    return a.phone - b.phone
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"Down",
                    sortEmail:"",
                    sortJoinDate:"",
                })
            }
        }else if(column=="Name"){
            if(this.state.sortName=="Down"){
                this.setState({sortName:""})
            }else{  //""
                //rearrange array by name
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    if(a.name<b.name)return -1
                    else return 1
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"Down",
                    sortPhone:"",
                    sortEmail:"",
                    sortJoinDate:"",
                })
            }
        }else if(column=="Email"){
            if(this.state.sortEmail=="Down"){
                this.setState({sortEmail:""})
            }else{  //""
                //rearrange array by phone
                var sortedMemberList=this.state.memberList
                sortedMemberList.sort(function(a, b){
                    if(a.email<b.email)return -1
                    else return 1
                })
                this.setState({
                    memberList:sortedMemberList,
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"Down",
                    sortJoinDate:"",
                })
            }
        }else if(column=="Create Date"){
            if(this.state.sortJoinDate=="Down"){
                this.setState({sortJoinDate:""})
            }else{  //""
                this.setState({
                    sortName:"",
                    sortPhone:"",
                    sortEmail:"",
                    sortJoinDate:"Down",
                })
            }
        }
    }

    validateEmail=(email)=>{
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    clearContact=()=>{
        this.setState({
            contactName:"",
            contactPhone:"",
            contactEmail:"",
            contactReferrer:"",
            contactPIC:"",
            selectedChannel:null,
            newInterest:"",
        })
    }
    semiClearContact=()=>{
        this.setState({
            contactName:"",
            contactPhone:"",
            contactEmail:"",
        })
    }

    createTicket=()=>{
        //validate
        let errorMessage=[]
        if(this.state.createTicketPIC==null||this.state.createTicketPIC.length==0){
            errorMessage.push("PIC cannot be left empty")
            this.setState({createTicketPICError:true})
        }else{
            this.setState({createTicketPICError:false})
        }

        if(this.state.createTicketChannel==null){
            errorMessage.push("Please select a channel")
            this.setState({createTicketChannelError:true})
        }else{
            this.setState({createTicketChannelError:false})
        }

        if(this.state.createTicketInterest==null||this.state.createTicketInterest.trim().length==0){
            errorMessage.push("Please enter interest of the client")
            this.setState({createTicketInterestError:true})
        }else{
            this.setState({createTicketInterestError:false})
        }

        if(errorMessage.length>0){
            alert(errorMessage.join("\n"))
            return
        }

        if(this.state.createTicketMode=="lead"){
            this.setState({loading:true})
            const payload = {
                loading:true,
                contactID:this.state.currentMember.id,
                referrer:this.state.createTicketReferrer,
                pic:this.state.createTicketPIC,
                channel:this.state.createTicketChannel,
                interest:this.state.createTicketInterest,
            }
            axios
                .post(`sales/createleadticket`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Lead ticket successfully created")
                    this.setState({
                        loading:false,
                        createTicketModal:false,
    
                        createTicketReferrer:null,
                        createTicketPIC:null,
                        createTicketChannel:null,
                        createTicketInterest:null,
    
                        createTicketReferrerError:false,
                        createTicketPICError:false,
                        createTicketChannleError:false,
                        createTicketInterestError:false,
                    })
                    this.loadContactLog(this.state.currentMember.id)
                })
                .catch(error =>{
                    if(error.response){
                        if(error.response.status==444){
                            alert("Cannot find the PIC")
                            this.setState({
                                loading:false,
                                createTicketPICError:true,
                            })
                            return
                        }if(error.response.status==449){
                            alert("PIC is not Agent or Staff")
                            this.setState({
                                loading:false,
                                createTicketPICError:true,
                            })
                            return
                        }else if(error.response.status==448){
                            alert("Cannot find the Referrer")
                            this.setState({
                                loading:false,
                                createTicketReferrerError:true,
                            })
                            return
                        }else if(error.response.status==450){
                            alert("Ticket with same PIC already exists")
                            this.setState({
                                loading:false,
                                createTicketPICError:true,
                            })
                            return
                        }
                    }
                    alert("Create new lead ticket error\n"+error)
                    this.setState({
                        loading:false,
                    })
                })
        }else if(this.state.createTicketMode=="member"){
            this.setState({loading:true})
            const payload = {
                loading:true,
                userID:this.state.alreadyExistUser.id,
                referrer:this.state.createTicketReferrer,
                pic:this.state.createTicketPIC,
                channel:this.state.createTicketChannel,
                interest:this.state.createTicketInterest,
            }
            axios
                .post(`sales/creatememberticket`, qs.stringify(payload),{timeout:20000})
                .then(async response => {
                    alert("Member ticket successfully created")
                    this.setState({
                        loading:false,
                        createTicketModal:false,
    
                        createTicketReferrer:null,
                        createTicketPIC:null,
                        createTicketChannel:null,
                        createTicketInterest:null,
    
                        createTicketReferrerError:false,
                        createTicketPICError:false,
                        createTicketChannleError:false,
                        createTicketInterestError:false,
                    })
                })
                .catch(error =>{
                    if(error.response){
                        if(error.response.status==444){
                            alert("Cannot find the PIC")
                            this.setState({
                                loading:false,
                                createTicketPICError:true,
                            })
                            return
                        }if(error.response.status==449){
                            alert("PIC is not Agent or Staff")
                            this.setState({
                                loading:false,
                                createTicketPICError:true,
                            })
                            return
                        }else if(error.response.status==448){
                            alert("Cannot find the Referrer")
                            this.setState({
                                loading:false,
                                createTicketReferrerError:true,
                            })
                            return
                        }else if(error.response.status==450){
                            alert("Ticket with same PIC already exists")
                            this.setState({
                                loading:false,
                                createTicketPICError:true,
                            })
                            return
                        }
                    }
                    alert("Create new member ticket error\n"+error)
                    this.setState({
                        loading:false,
                    })
                })
        }
    }

    createContact=()=>{
        //validate
        let errorMessage=[]
        if(this.state.contactPhone==null||this.state.contactPhone.trim().length==0){
            errorMessage.push("Please fill in the contact phone")
            this.setState({contactPhoneError:true})
        }else{
            this.setState({contactPhoneError:false})
        }

        if(this.state.contactEmail!=null&&this.state.contactEmail.trim().length>0&&!this.validateEmail(this.state.contactEmail)){
            errorMessage.push("Invalid email format")
            this.setState({contactEmailError:true})
        }else{
            this.setState({contactEmailError:false})
        }

        if(this.state.contactPIC==null||this.state.contactPIC.length==0){
            errorMessage.push("PIC cannot be left empty")
            this.setState({contactPICError:true})
        }else{
            this.setState({contactPICError:false})
        }

        if(this.state.selectedChannel==null){
            errorMessage.push("Please select a channel")
            this.setState({selectChannelError:true})
        }else{
            this.setState({selectChannelError:false})
        }

        if(this.state.newInterest==null||this.state.newInterest.trim().length==0){
            errorMessage.push("Please enter interest of the client")
            this.setState({newInterestError:true})
        }else{
            this.setState({newInterestError:false})
        }

        if(errorMessage.length>0){
            alert(errorMessage.join("\n"))
            return
        }

        this.setState({loading:true})
        const payload = {
            loading:true,
            name:this.state.contactName,
            phone:this.state.contactPhone,
            email:this.state.contactEmail,
            pic:this.state.contactPIC,
            referrer:this.state.contactReferrer,
            channel:this.state.selectedChannel,
            interest:this.state.newInterest,
        }
        axios
            .post(`sales/createnewcontact`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                alert("Lead successfully created")
                this.setState({
                    loading:false,
                    contactPhoneError:false,
                    contactPICError:false,
                    contactReferrerError:false,
                    contactEmailError:false,
                    contactPICError:false,
                    selectChannelError:false,
                    newInterestError:false,
                })
                this.semiClearContact()
                
                window.open(response.data.ticketUrl, "_blank")
            })
            .catch(error =>{
                this.setState({
                    contactPhoneError:false,
                    contactPICError:false,
                    contactReferrerError:false,
                    contactEmailError:false,
                    contactPICError:false,
                    selectChannelError:false,
                    newInterestError:false,
                })
                if(error.response){
                    if(error.response.status==444){
                        alert("Cannot find the PIC")
                        this.setState({
                            loading:false,
                            contactPICError:true,
                        })
                        return
                    }if(error.response.status==449){
                        alert("PIC is not Agent or Staff")
                        this.setState({
                            loading:false,
                            contactPICError:true,
                        })
                        return
                    }else if(error.response.status==447){
                        alert("User with the same phone number already exists")
                        this.setState({
                            loading:false,
                            contactPhoneError:true,
                        })
                        return
                    }else if(error.response.status==448){
                        alert("Cannot find the Referrer")
                        this.setState({
                            loading:false,
                            contactReferrerError:true,
                        })
                        return
                    }else if(error.response.status==450){
                        alert("Lead with same phone number already exists")
                        this.setState({
                            loading:false,
                            contactPhoneError:true,
                        })
                        return
                    }
                }
                alert("Create new contact error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    deleteContact=()=>{
        var confirm=window.confirm("Are you sure want to delete this contact?")
        if(!confirm){
            return
        }

        this.setState({loading:true})
        const payload = {
            id:this.state.currentMember.id
        }
        axios
            .post(`sales/deletecontact`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                alert("Delete contact successful")
                this.setState({
                    loading:false,
                })
                this.searchMember()
            })
            .catch(error =>{
                alert("Delete contact error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    changePIC=()=>{
        this.setState({loading:true})
        const payload = {
            id:this.state.currentMember.id,
            picPhone:this.state.changePICPhone,
        }
        axios
            .post(`sales/changecontactpic`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                alert("Lead PIC change successful")
                this.setState({
                    loading:false,
                    changePICModal:false,
                })
                this.searchMember(this.state.page,true,this.state.currentMember.phone)
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Cannot find the PIC user account")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("The user is not an agent")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Lead PIC change error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    changeReferrer=()=>{
        this.setState({loading:true})
        const payload = {
            id:this.state.currentMember.id,
            referrerPhone:this.state.changeReferrerPhone,
        }
        axios
            .post(`sales/changecontactreferrer`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                alert("Lead Referrer change successful")
                this.setState({
                    loading:false,
                    changeReferrerModal:false,
                })
                this.searchMember(this.state.page,true,this.state.currentMember.phone)
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Cannot find the Referrer user account")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Lead Referrer change error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    checkExistNewContact=()=>{
        //validate
        if(this.state.contactPhone==null||this.state.contactPhone.trim().length==0){
            this.setState({contactPhoneError:true})
            alert("Please enter contact phone to check exist")
            return
        }

        this.setState({loading:true})
        const payload = {
            contactPhone:this.state.contactPhone,
        }
        axios
            .post(`sales/checkexistnewcontact`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                alert("This phone number is new")
                this.setState({
                    loading:false,
                    contactPhoneError:false,
                })
            })
            .catch(error =>{
                this.setState({contactPhoneError:true})
                if(error.response){
                    if(error.response.status==444){ //repeat lead within agent company (operated by agent leader)
                        this.setState({
                            loading:false,
                            alreadyExistModal:true,
                            alreadyExistUser:null,
                            alreadyExistContact:error.response.data,
                        })
                        return
                    }else if(error.response.status==446){   //already has existing lead (staff operating)
                        if(error.response.data.length==1){  //if there is only one result
                            this.setState({
                                loading:false,
                                alreadyExistModal:true,
                                alreadyExistUser:null,
                                alreadyExistContact:error.response.data[0],
                            })
                        }else{  //if there are multiple result, let the staff choose
                            this.setState({
                                loading:false,
                                selectAlreadyExistModal:true,
                                selectAlreadyExistArray:error.response.data,
                            })
                            
                        }
                        return
                    }else if(error.response.status==445){   //already exist as user
                        //parse time
                        let rawDateSplit=error.response.data.userData.date_joined.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        error.response.data.userData.date_joined=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]

                        this.setState({
                            loading:false,
                            alreadyExistModal:true,
                            alreadyExistUser:error.response.data.userData,
                            alreadyExistContact:null,
                            alreadyExistCanView:error.response.data.canView,
                        })
                        return
                    }
                }
                alert("Check exist new contact phone error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    createAlreadyExistSalesLog=()=>{
        //validate
        if(this.state.alreadyExistSalesLog==null||this.state.alreadyExistSalesLog.trim().length==0){
            alert("Please enter sales log content")
            return
        }

        this.setState({loading:true})
        const payload = {
            userID:this.state.alreadyExistUser.id,
            saleslog:this.state.alreadyExistSalesLog,
        }
        axios
            .post(`sales/createalreadyexistsaleslog`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                alert("Sales Log created")
                this.setState({
                    loading:false,
                    alreadyExistModal:false,
                    alreadyExistUser:null,
                    alreadyExistSalesLog:null,
                    alreadyExistCanView:false,
                })
            })
            .catch(error =>{
                this.setState({contactPhoneError:true})
                if(error.response){

                }
                alert("Create already exist sales log error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    createAlreadyExistLeadLog=()=>{
        //validate
        if(this.state.alreadyExistLeadLog==null||this.state.alreadyExistLeadLog.trim().length==0){
            alert("Please enter sales log content")
            return
        }

        this.setState({loading:true})
        const payload = {
            leadID:this.state.alreadyExistContact.id,
            leadLog:this.state.alreadyExistLeadLog,
        }
        axios
            .post(`sales/createalreadyexistleadlog`, qs.stringify(payload),{timeout:10000})
            .then(async response => {
                alert("Lead Log created")
                this.setState({
                    loading:false,
                    alreadyExistModal:false,
                    alreadyExistContact:null,
                    alreadyExistLeadLog:null,
                    alreadyExistCanView:null,
                })
            })
            .catch(error =>{
                this.setState({contactPhoneError:true})
                if(error.response){

                }
                alert("Create already exist lead log error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    loadStaffList=()=>{
        this.setState({loading:true})
        const payload = {
            
        }
        axios
            .post(`sales/loadstafflist`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    staffList:response.data,
                    staffListModal:true,
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Load staff list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    loadAgentCompanyList=()=>{
        this.setState({loading:true})
        const payload = {
            
        }
        axios
            .post(`sales/loadagentcompanylist`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentCompanyList:response.data,
                    agentList:[],   //clear
                    agentListModal:true,
                    agentListMode:"agentCompany",
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Load agent company list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    loadAgentList=(agentCompanyID)=>{
        this.setState({loading:true})
        const payload = {
            companyID:agentCompanyID,
        }
        axios
            .post(`sales/loadagentlist`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentList:response.data,
                    agentListMode:"agent",
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Load agent list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    loadSimpleAgentList=()=>{
        this.setState({loading:true})
        const payload = {
            
        }
        axios
            .post(`sales/getsimpleagentlist`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                this.setState({
                    loading:false,
                    agentListModal:true,
                    agentList:response.data,
                    agentListMode:"agent",
                })
            })
            .catch(error =>{
                if(error.response){

                }
                alert("Get simple agent list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    imageUploadCallback=(file)=>{
        const imageObject = {
          file: file,
          localSrc: URL.createObjectURL(file),
        }
        let re = /(?:\.([^.]+))?$/
        let extension=re.exec(file.name)[1]
        this.extensionList.push([imageObject.localSrc,extension])
        return new Promise(
          (resolve, reject) => {
            resolve({ data: { link: imageObject.localSrc } })
          }
        )
    }

    onEditorStateChange=(editorState) => {
        this.setState({
            editorState,
        })
    }

    post=async ()=>{
        let theJSON=convertToRaw(this.state.editorState.getCurrentContent())
        var theImages=theJSON.entityMap
        let theKeys=Object.keys(theImages)
        var imagesToUpload=[]
        for(const key of theKeys){
            let srcLink=theImages[key].data.src
            if(srcLink.includes("blob:")){
                //add to the upload queue
                imagesToUpload.push(srcLink)
            }
        }
        this.setState({loading:true})
        const formData = new FormData();
        for(var v=0;v<imagesToUpload.length;v++){
            let blob = await fetch(imagesToUpload[v]).then(r => r.blob());  //get dat blob
            var theType="image/"
            var ext=""
            //find out the extension
            for(const extList of this.extensionList){
                if(extList[0]==imagesToUpload[v]){
                    ext=extList[1]
                    break
                }
            }
            const imageFile = new File([blob],`${Date.now()}.${ext}`,{type:theType,});
            formData.append('image'+v,imageFile)
            formData.append('image'+v+'_originalName',imagesToUpload[v])
        }
        axios
            .post(`sales/uploadcontactlogimage`, formData, {headers: {"Content-type": "multipart/form-data"}},{timeout:40000})
            .then(async response => {
                //replace the blobs with returned server image links {serverLink:,imageName:}
                let imageLinks=response.data
                for(const image of imageLinks){
                    for(const key of theKeys){
                        let srcLink=theImages[key].data.src
                        if(srcLink==image.originalName){
                            theJSON.entityMap[key].data.src=image.serverLink
                        }
                    }
                }})

                var tagNameList=[]
                for(var t=0;t<this.state.selectedTag.length;t++){
                tagNameList.push(this.state.selectedTag[t].name)
                }
                const payload = {
                    message:JSON.stringify(theJSON),
                    tag:JSON.stringify(tagNameList),
                    contactID:this.state.currentMember.id,
                }
                axios
                    .post(`sales/postcontactlog`, qs.stringify(payload),{timeout:40000})
                    .then(async response => {
                        this.loadContactLog(this.state.currentMember.id)
                        this.setState({
                            newSalesLog:"",
                            selectedTag:[],
                            loading:false,
                            editorState:EditorState.createEmpty(),
                        })
                    })
                    .catch(error =>{
                        alert("Post lead log error\n"+error)
                        this.setState({loading:false})
                    })
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal isOpen={this.state.selectAlreadyExistModal} toggle={()=>this.setState({selectAlreadyExistModal:false,selectAlreadyExistArray:[]})} style={{}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Select Already Exist Contact to Update</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5}}>
                            {
                            this.state.selectAlreadyExistArray.map((ae)=>{
                                return(
                                    <div style={{padding:10,border:"1px solid lightgrey",borderRadius:5,cursor:"pointer"}} onClick={()=>{
                                        this.setState({
                                            alreadyExistModal:true,
                                            alreadyExistUser:null,
                                            alreadyExistContact:ae,
                                        })
                                    }}>
                                        <div>Phone: {ae.phone}&nbsp;{ae.name}</div>
                                        <div>Email: {ae.email}</div>
                                        <div>Referrer: {ae.referrer!=null?ae.referrer:"-"}</div>
                                        <div>PIC: {ae.personInCharge!=null?ae.personInCharge:"-"}</div>
                                        <div>
                                            Agent Company: 
                                            {
                                            ae.agentCompany!=null?
                                                ae.agentCompany.name
                                                :
                                                "Enlliance"
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({selectAlreadyExistModal:false,selectAlreadyExistArray:[]})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.staffListModal} toggle={()=>this.setState({staffListModal:false})} style={{}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Staff List</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5}}>
                            {
                            this.state.staffList.map((staff)=>{
                                return(
                                    <div style={{padding:5,fontSize:14,border:"1px solid lightgrey",cursor:"pointer"}} onClick={()=>{
                                        if(this.state.addContactModal==true){
                                            this.setState({contactPIC:staff.username})
                                        }else if(this.state.createTicketModal==true){
                                            this.setState({createTicketPIC:staff.username})
                                        }
                                        this.setState({                                            
                                            staffListModal:false,
                                        })
                                    }}>
                                        {
                                        staff.username==staff.name?
                                            staff.name
                                            :
                                            staff.username+" "+staff.name
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({staffListModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.agentListModal} toggle={()=>this.setState({agentListModal:false})} style={{}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Agent List</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5}}>
                            {
                            this.state.agentListMode=="agent"&&this.state.agentCompanyList.length>0?
                                <Button color="light" style={{border:"1px solid lightgrey",marginBottom:10}} onClick={()=>{
                                    this.loadAgentCompanyList()
                                }}>
                                    Back
                                </Button>
                                :
                                null
                            }
                            
                            {/*
                            <Input value={this.state.changeReferrerPhone} onChange={(e)=>{
                                this.setState({changeReferrerPhone:e.target.value.replace(/\D/g,"")})
                            }} />
                            */}
                        </div>
                        {
                        this.state.agentListMode=="agentCompany"?
                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                {
                                this.state.agentCompanyList.map((agentCompany)=>{
                                    return(
                                        <div style={{padding:10,border:"1px solid lightgrey",cursor:"pointer"}} onClick={()=>{
                                            this.loadAgentList(agentCompany.id)
                                        }}>
                                            {agentCompany.name}
                                        </div>
                                    )
                                })
                                }
                            </div>
                            :
                        this.state.agentListMode=="agent"?
                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5}}>
                                {
                                this.state.agentList.map((agent)=>{
                                    return(
                                        <div style={{padding:5,fontSize:14,border:"1px solid lightgrey",cursor:"pointer"}} onClick={()=>{
                                            if(this.state.addContactModal==true){
                                                this.setState({contactPIC:agent.username,})
                                            }else if(this.state.createTicketModal==true){
                                                this.setState({createTicketPIC:agent.username})
                                            }
                                            this.setState({
                                                agentListModal:false
                                            })
                                        }}>
                                            {
                                            agent.username==agent.name?
                                                agent.name+" ("+agent.tierName+")"
                                                :
                                                agent.username+" "+agent.name+" ("+agent.tierName+")"
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>
                            :
                            null
                        }
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({agentListModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.alreadyExistModal} toggle={()=>this.setState({alreadyExistModal:false,alreadyExistCanView:false})} style={{width:600,maxWidth:2000}}>
                    {
                    this.state.alreadyExistUser!=null?
                        <div style={{padding:25,borderRadius:10}}>
                            <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>{this.state.alreadyExistUser.name} already exist in User</div>
                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                                    {
                                    this.state.alreadyExistUser.username?
                                        this.state.alreadyExistUser.username==this.state.alreadyExistUser.name?
                                            this.state.alreadyExistUser.name
                                            :
                                            this.state.alreadyExistUser.username+" "+this.state.alreadyExistUser.name
                                        :
                                        null
                                    }
                                    {
                                    this.state.alreadyExistUserCanView==true?
                                        <Button color="primary" style={{fontSize:13,padding:"2px 5px"}} onClick={()=>{
                                            window.open("/saleslog?username="+this.state.alreadyExistUser.username,"_blank")
                                        }}>
                                            View User
                                        </Button>
                                        :
                                        null
                                    }
                                </div>
                                <div>Join date: {this.state.alreadyExistUser.date_joined}</div>
                                <div>Referrer:
                                    {
                                    this.state.alreadyExistUser.referrer?
                                        this.state.alreadyExistUser.referrer.username==this.state.alreadyExistUser.referrer.name?
                                            this.state.alreadyExistUser.referrer.name
                                            :
                                            this.state.alreadyExistUser.referrer.username+" "+this.state.alreadyExistUser.referrer.name
                                        :
                                        null
                                    }
                                </div>
                                <div>PIC: {
                                    this.state.alreadyExistUser.personInCharge.username==this.state.alreadyExistUser.personInCharge.name?
                                        this.state.alreadyExistUser.personInCharge.name
                                        :
                                        this.state.alreadyExistUser.personInCharge.username+" "+this.state.alreadyExistUser.personInCharge.name
                                    }
                                </div>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",width:"100%",flexWrap:"wrap",whiteSpace:"nowrap",gap:10}}>
                                    <div>Membership type:</div>
                                    {
                                    this.state.alreadyExistUser.membership_type=="SP"?
                                        <div style={{color:"red",border:"1px solid red",borderRadius:10,padding:"2px 5px",fontSize:13}}>战略伙伴</div>
                                        :
                                    this.state.alreadyExistUser.membership_type=="Free"?
                                        <div style={{color:"steelblue",border:"1px solid steelblue",borderRadius:10,padding:"2px 5px",fontSize:13}}>新会员</div>
                                        :
                                        <div style={{color:"brown",border:"1px solid brown",borderRadius:10,padding:"2px 5px",fontSize:13}}>普通会员</div>
                                    }
                                </div>
                                <div>Badges:</div>
                                <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",width:"100%",gap:5}}>
                                    {
                                    this.state.alreadyExistUser.badge.map((badge)=>{
                                        return(
                                            <div style={{padding:"5px 10px",borderRadius:5,backgroundColor:"#fafafa",border:"1px solid grey"}}>{badge.title}</div>
                                        )
                                    })
                                    }
                                </div>
                                
                                {
                                GLOBAL.checkPermission("=oj$ppRa")?
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:10}}>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            this.setState({createTicketModal:true,createTicketMode:"member",createTicketPIC:this.state.alreadyExistUser.personInCharge.username})
                                        }}>
                                            Create ticket
                                        </Button>
                                    </div>
                                    :
                                    null
                                }

                                {
                                GLOBAL.checkPermission("?bOo4U:y")?
                                    <div style={{width:"100%"}}>
                                        <div style={{}}>New Saleslog: </div>
                                        <Input type="textarea" style={{width:"100%"}} value={this.state.alreadyExistSaleslog} rows={5} onChange={(e)=>{
                                            this.setState({alreadyExistSalesLog:e.target.value})
                                        }}/>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                                {
                                GLOBAL.checkPermission("?bOo4U:y")?
                                    <Button color="primary" style={{flex:1}} onClick={()=>{
                                        this.createAlreadyExistSalesLog()
                                    }}>
                                        Create Saleslog
                                    </Button>
                                    :
                                    null
                                }
                                <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                    this.setState({alreadyExistModal:false,alreadyExistCanView:false})
                                }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                        :
                    this.state.alreadyExistContact!=null?
                        <div style={{padding:25,borderRadius:10}}>
                            <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>{this.state.alreadyExistContact.name} already exist in Lead</div>
                            <div style={{display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                                <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                                    <div>Name: {this.state.alreadyExistContact.name}</div>
                                    <Button color="primary" style={{fontSize:13,padding:"2px 5px"}} onClick={()=>{
                                        window.open("/lead?phone="+this.state.alreadyExistContact.phone,"_blank")
                                    }}>
                                        View Lead
                                    </Button>
                                </div>
                                <div>Phone: {this.state.alreadyExistContact.phone}</div>
                                <div>Email: {this.state.alreadyExistContact.email!=null?this.state.alreadyExistContact.email:"-"}</div>
                                <div>Creator: {this.state.alreadyExistContact.creator}</div>
                                <div>Referrer: {this.state.alreadyExistContact.referrer!=null?this.state.alreadyExistContact.referrer:"-"}</div>
                                <div>PIC: {this.state.alreadyExistContact.personInCharge!=null?this.state.alreadyExistContact.personInCharge:"-"}</div>
                                <div>Agent Company: 
                                    {
                                    this.state.alreadyExistContact.agentCompany!=null?
                                        this.state.alreadyExistContact.agentCompany.name
                                        :
                                        "Enlliance"
                                    }
                                </div>
                                <div style={{marginTop:10}}>New Lead Log: </div>
                                <textarea value={this.state.alreadyExistSaleslog} rows={5} onChange={(e)=>{
                                    this.setState({alreadyExistLeadLog:e.target.value})
                                }}/>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                                <Button color="primary" style={{flex:1}} onClick={()=>{
                                    this.createAlreadyExistLeadLog()
                                }}>
                                    Create Lead Log
                                </Button>
                                <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                    this.setState({alreadyExistModal:false,alreadyExistCanView:false})
                                }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                        :
                        null
                    }
                </Modal>

                <Modal isOpen={this.state.changeReferrerModal} toggle={()=>this.setState({changeReferrerModal:false})} style={{width:600,maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Change Referrer</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5}}>
                            <Input value={this.state.changeReferrerPhone} onChange={(e)=>{
                                this.setState({changeReferrerPhone:e.target.value.replace(/\D/g,"")})
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.changeReferrer()
                            }}>
                                Change Referrer
                            </Button>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({changeReferrerModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.changePICModal} toggle={()=>this.setState({changePICModal:false})} style={{width:600,maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Change PIC</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5}}>
                            <Input value={this.state.changePICPhone} onChange={(e)=>{
                                this.setState({changePICPhone:e.target.value.replace(/\D/g,"")})
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.changePIC()
                            }}>
                                Change PIC
                            </Button>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({changePICModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.createTicketModal} toggle={()=>this.setState({createTicketModal:false})} style={{maxWidth:700}}>
                    <div style={{padding:25,borderRadius:10,width:"100%"}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Create Ticket</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5,width:"100%"}}>
                            <table style={{width:"100%"}}>
                                <tr>
                                    <td style={{padding:5}}>Phone</td>
                                    <td style={{padding:5}}>
                                        {
                                        this.state.createTicketMode=="lead"?
                                            this.state.currentMember.phone
                                            :
                                        this.state.createTicketMode=="member"?
                                            this.state.alreadyExistUser.username
                                            :
                                            null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{padding:5}}>Name</td>
                                    <td style={{padding:5}}>
                                        {
                                        this.state.createTicketMode=="lead"?
                                            this.state.currentMember.name
                                            :
                                        this.state.createTicketMode=="member"?
                                            this.state.alreadyExistUser.name
                                            :
                                            null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{padding:5}}>Email</td>
                                    <td style={{padding:5}}>
                                        {
                                        this.state.createTicketMode=="lead"?
                                            this.state.currentMember.email
                                            :
                                        this.state.createTicketMode=="member"?
                                            this.state.alreadyExistUser.email
                                            :
                                            null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{height:20}}></td>
                                </tr>
                                {
                                this.state.createTicketMode=="lead"?
                                    <tr style={{}}>
                                        <td style={{padding:5,whiteSpace:"nowrap"}}>Referrer Phone</td>
                                        <td style={{padding:5}}>
                                            <Input style={{borderColor:this.state.createTicketReferrerError?"salmon":"lightgrey"}} 
                                                value={this.state.createTicketReferrer} 
                                                onChange={(e)=>{
                                                    this.setState({createTicketReferrer:e.target.value.replace(/\D/g,"")})
                                            }} />
                                        </td>
                                    </tr>
                                    :
                                    null
                                }
                                <tr>
                                    <td style={{padding:5}}>
                                        <div style={{}}>*PIC Phone:</div>
                                    </td>
                                    <td style={{padding:5,display:"flex",flexDirection:"row",alignItems:"center",gap:10}}>
                                        <Input 
                                                style={{borderColor:this.state.createTicketPICError?"salmon":"lightgrey"}}
                                                value={this.state.createTicketPIC} 
                                                onChange={(e)=>{
                                                    this.setState({createTicketPIC:e.target.value.replace(/\D/g,"")})    
                                            }} />
                                        <div>
                                        <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                                            {
                                            GLOBAL.checkPermission("@S!I2MbK")?
                                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                    this.loadAgentCompanyList()
                                                }}>
                                                    Agency
                                                </Button>
                                                :
                                            GLOBAL.checkPermission("}APx]vkJ")?
                                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                    this.loadSimpleAgentList()
                                                }}>
                                                    Agent
                                                </Button>
                                                :
                                                null
                                            }
                                            {
                                            GLOBAL.checkPermission("cdA9kH38")?
                                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                    this.loadStaffList()
                                                }}>
                                                    Staff
                                                </Button>
                                                :
                                                null
                                            }
                                            <Button color="light" style={{border:"1px solid lightgrey",whiteSpace:"nowrap"}} onClick={()=>{
                                                this.setState({
                                                    createTicketPIC:"Lead Pool"
                                                })
                                            }}>
                                                Lead Pool
                                            </Button>
                                        </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{padding:5}}>*Channel</td>
                                    <td style={{padding:5}}>
                                        <div style={{display:"flex",flexDirection:"row",gap:10,flexWrap:"wrap",
                                            border:this.state.createTicketChannelError?"1px solid salmon":"none"}}>
                                            {
                                            GLOBAL.channelList.map((channel)=>{
                                                return(
                                                    <div style={{padding:"5px 10px",backgroundColor:GLOBAL.getChannelColor(channel),borderRadius:5,color:"white",whiteSpace:"nowrap",
                                                        cursor:"pointer",boxShadow:this.state.createTicketChannel==channel?"0px 0px 0px 5px orange inset":"none"}} onClick={()=>{
                                                            this.setState({createTicketChannel:channel})
                                                    }}>
                                                        {channel}
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{padding:5}}>*Interest</td>
                                    <td style={{padding:5}}>
                                        <textarea rows={3} style={{width:"100%",borderColor:this.state.createTicketInterestError?"salmon":"lightgrey"}} 
                                            value={this.state.createTicketInterest} 
                                            onChange={(e)=>{
                                                this.setState({createTicketInterest:e.target.value})
                                        }} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.createTicket()
                            }}>
                                Create
                            </Button>
                            <Button color="secondary" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({createTicketModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.tutorialModal} toggle={()=>this.setState({tutorialModal:false})} style={{width:600,maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:10,fontWeight:"bold",textAlign:"center",fontSize:20}}>Usage hints</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5}}>
                            Creator:011XXXXXXXX
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.addContactModal} toggle={()=>this.setState({addContactModal:false})} style={{width:650,maxWidth:2000}}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{marginBottom:20,fontWeight:"bold",textAlign:"center",fontSize:20}}>Add Lead</div>
                        <table style={{width:"100%"}}>
                            <tr>
                                <td style={{padding:5}}>
                                    <div style={{}}>*Phone:</div>
                                </td>
                                <td style={{padding:5}}>
                                    <Input placeHolder="011XXXXXXXX" type="tel" value={this.state.contactPhone} onChange={(e)=>{
                                        this.setState({contactPhone:e.target.value.replace(/\D/g,"")})
                                    }} style={this.state.contactPhoneError?{border:"2px solid salmon"}:{}} />
                                </td>
                                <td style={{padding:5}}>
                                    <Button color="light" style={{border:"1px solid lightgrey",whiteSpace:"nowrap"}} onClick={()=>{
                                        this.checkExistNewContact()
                                    }}>
                                        Check exist
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding:5}}>
                                    <div style={{}}>Name:</div>
                                </td>
                                <td colspan="2" style={{padding:5}}>
                                    <Input type="text" value={this.state.contactName} onChange={(e)=>{
                                        this.setState({contactName:e.target.value})
                                    }} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding:5}}>
                                    <div style={{}}>Email:</div>
                                </td>
                                <td colspan="2" style={{padding:5}}>
                                    <Input type="email" placeHolder="XXX@XXX.xxx" value={this.state.contactEmail} onChange={(e)=>{
                                        this.setState({contactEmail:e.target.value})
                                    }} style={this.state.contactEmailError?{border:"2px solid salmon"}:{}}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding:5}}>
                                    <div style={{}}>Referrer Phone:</div>
                                </td>
                                <td colspan="2" style={{padding:5}}>
                                    <Input type="text" placeHolder="011XXXXXXXX" value={this.state.contactReferrer} onChange={(e)=>{
                                        this.setState({contactReferrer:e.target.value})
                                    }} style={this.state.contactReferrerError?{border:"2px solid salmon"}:{}} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding:5}}>
                                    <div style={{}}>*PIC Phone:</div>
                                </td>
                                <td style={{padding:5}}>
                                    <Input type="text" placeHolder="011XXXXXXXX" value={this.state.contactPIC} onChange={(e)=>{
                                        this.setState({contactPIC:e.target.value})
                                    }} style={this.state.contactPICError?{border:"2px solid salmon"}:{}} />
                                </td>
                                <td style={{padding:5}}>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                                        {
                                        GLOBAL.checkPermission("@S!I2MbK")?
                                            <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                this.loadAgentCompanyList()
                                            }}>
                                                Agency
                                            </Button>
                                            :
                                        GLOBAL.checkPermission("}APx]vkJ")?
                                            <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                this.loadSimpleAgentList()
                                            }}>
                                                Agent
                                            </Button>
                                            :
                                            null
                                        }
                                        {
                                        GLOBAL.checkPermission("cdA9kH38")?
                                            <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                this.loadStaffList()
                                            }}>
                                                Staff
                                            </Button>
                                            :
                                            null
                                        }
                                        <Button color="light" style={{border:"1px solid lightgrey",whiteSpace:"nowrap"}} onClick={()=>{
                                            this.setState({
                                                contactPIC:"Lead Pool"
                                            })
                                        }}>
                                            Lead Pool
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding:5}}>
                                    <div style={{}}>*Channel</div>
                                </td>
                                <td colspan="2" style={{padding:5,border:this.state.selectChannelError?"solid 1px red":"none"}}>
                                    <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:10}}>
                                        {
                                        GLOBAL.channelList
                                        .filter(channel=>channel!=="Contact Form")
                                        .map((channel)=>{
                                            return(
                                                <div style={{padding:"5px 10px",backgroundColor:GLOBAL.getChannelColor(channel),borderRadius:5,color:"white",
                                                    cursor:"pointer",boxShadow:this.state.selectedChannel==channel?"0px 0px 0px 5px orange inset":"none"}} onClick={()=>{
                                                        this.setState({selectedChannel:channel})
                                                }}>
                                                    {channel}
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding:5}}>
                                    <div style={{}}>*Interest</div>
                                </td>
                                <td colspan="2" style={{padding:5}}>
                                    <textarea rows={3} style={{width:"100%",padding:5,border:this.state.newInterestError?"solid 1px red":"solid 1px lightgrey",borderRadius:2}} 
                                        value={this.state.newInterest} onChange={(e)=>{
                                            this.setState({newInterest:e.target.value})
                                        }}/>
                                </td>
                            </tr>
                        </table>
                        {
                        GLOBAL.checkPermission(".RJ%tVVu")? (
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <Button style={{marginTop:20,width:"75%"}} onClick={()=>{
                                    this.createContact()
                                }}>
                                    Add Lead
                                </Button>
                                <Button style={{marginTop:20, marginLeft:10, width:"25%", backgroundColor:"red"}} onClick={()=>{
                                    this.clearContact()
                                }}>
                                    Clear
                                </Button>
                            </div>

                        ) :null
                        }
                    </div>
                </Modal>

                <Modal isOpen={this.state.addTagModal} toggle={()=>this.setState({addTagModal:false})} style={{width:"90%",maxWidth:2000}}>
                    {
                    this.state.modalLoading?
                        <LoadingScreen />
                        :null
                    }
                    <div style={{padding:25,borderRadius:10}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input type="search" style={{width:"auto",flex:1}} value={this.state.clientTagSearch} onChange={(e)=>{
                                //search for existing related tag
                                var relatedClientTag=[]
                                for(var tag of this.state.tagList){
                                    if(tag.title.toLowerCase().includes(e.target.value.toLowerCase())){
                                        relatedClientTag.push(tag)
                                    }
                                }
                                this.setState({clientTagSearch:e.target.value,relatedClientTag:relatedClientTag})
                            }} />
                            {
                            reactLocalStorage.get("job")=="Course Advisor"?
                                null
                                :
                                <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                    this.createNewClientTag()
                                }}>
                                    Create new tag
                                </Button>
                            }
                        </div>
                        <div style={{marginTop:10}}>
                            {
                            this.state.relatedClientTag.map((item)=>{
                                return(
                                    <Button color="primary" style={{margin:2}} onClick={()=>{
                                        this.addTagToClient(item)
                                    }}>
                                        <div>{item.title}</div>
                                        <div style={{fontSize:10}}>Times used: {item.timesUsed}</div>
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <div style={{marginTop:10}}>
                            {
                            this.state.tagList.map((item)=>{
                                return(
                                    <Button color="secondary" style={{margin:2}} onClick={()=>{
                                        this.addTagToClient(item)
                                    }}>
                                        <div>{item.title}</div>
                                        <div style={{fontSize:10}}>Times used: {item.timesUsed}</div>
                                    </Button>
                                )
                            })
                            }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.tagModal} toggle={()=>this.setState({tagModal:false})}>
                    {
                    this.state.loading?
                        <div style={{backgroundColor:"rgba(0,0,0,0.5)",width:"100%",height:"100%",position:"absolute"}} />
                        :null
                    }
                    <div style={{padding:25,borderRadius:10}}>
                        <Input type="text" placeholder="Tag name"
                            style={{marginBottom:10}}
                            value={this.state.tagSearch}
                            onChange={(event) => {
                                    //show only relevent
                                    var newTagsToShow=[]
                                    var tagGroup=[]
                                    //nothing typed = show all
                                    if(event.target.value.length==0){
                                        tagGroup=this.state.allTags
                                    }else{
                                        for(var y=0;y<this.state.allTags.length;y++){
                                            for(var theTag of this.state.allTags[y]){
                                                if(theTag.name.includes(event.target.value)){   //case insensitive
                                                    newTagsToShow.push(theTag)
                                                }
                                            }
                                        }
                                    }
                                    //group the tags [[tags],[tags]]
                                    for(const tag of newTagsToShow){
                                        var foundGroup=false
                                        for(var tagArray of tagGroup){
                                            if(tag.group==tagArray[0].group){
                                                tagArray.push(tag)
                                                foundGroup=true
                                                break
                                            }
                                        }
                                        if(!foundGroup){
                                            tagGroup.push([tag])
                                        }
                                    }

                                    this.setState({
                                        tagSearch: event.target.value,
                                        tagsToShow:tagGroup,
                                    })
                                }}
                            />
                        {
                            this.state.tagsToShow.map((group)=>{
                                return(
                                    <div>
                                        <div style={{textAlign:"left",fontWeight:"bold"}}>{group[0].group}</div>
                                        {
                                        group.map((item)=>{
                                            return(
                                                <Button style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 5px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,margin:5}}
                                                    onClick={()=>{this.addTag(item)}}>
                                                    {item.name}
                                                </Button>
                                            )
                                        })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal>

                <NavigationBar currentPage="lead" />
                <div style={{padding:20}}>
                    <div style={{marginBottom:10,display:"flex",flexDirection:"row"}}>
                        {
                        GLOBAL.checkPermission(".RJ%tVVu")?
                            <Button onClick={()=>{
                                this.setState({addContactModal:true})
                            }}>
                                Add Lead
                            </Button>
                            :
                            null
                        }

                        {/*<Button style={{marginLeft:10}}>
                            Bulk Import
                        </Button>*/}
                    </div>

                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginBottom:5}}>
                        <Input type="text" name="username" placeholder="Lead's name / email / phone number"
                            style={{marginRight:5}}
                            value={this.state.findField}
                            onChange={event => this.setState({findField: event.target.value})}
                            onKeyDown={(e)=>{
                                if(e.keyCode==13){
                                    e.preventDefault()
                                    //update url
                                    let here = new URL(window.location.href)
                                    here.searchParams.delete('phone')
                                    here.searchParams.set('search',this.state.findField)
                                    window.history.pushState({},"",here)
                                    //search
                                    this.searchMember()
                                }
                        }}/>
                        <BsQuestionCircleFill style={{color:"skyblue",marginRight:5,width:28,height:28,cursor:"pointer"}} onClick={()=>{
                            this.setState({tutorialModal:true})
                        }} />
                        <Button color="secondary" style={{paddingLeft:20,paddingRight:20}} onClick={()=>{
                            //update url
                            let here = new URL(window.location.href)
                            here.searchParams.delete('phone')
                            here.searchParams.set('search',this.state.findField)
                            window.history.pushState({},"",here)
                            //search
                            this.searchMember()
                        }}>Search</Button>
                    </div>
                    <div style={{paddingTop:5,marginBottom:20,color:"grey",paddingLeft:10,display:"flex",flexDirection:"row"}}>
                        {this.state.resultCount} results found.
                        <div style={{marginLeft:10,color:"blue",cursor:"pointer"}} onClick={()=>{
                            let here = new URL(window.location.href)
                            here.searchParams.delete('phone')
                            window.history.pushState({},"",here)
                            
                            this.setState({viewMode:"search",currentMember:{}})
                        }}>
                            Search result page
                        </div>
                        <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                            borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                GLOBAL.copyDiv(this.divRef)
                            }}>
                            <FaCopy style={{width:20,height:20,padding:4}}/>
                            <div style={{fontSize:12}}>Copy list</div>
                        </Button>
                    </div>

                    {
                    this.state.viewMode=="search"?
                        <div ref={this.divRef} style={{paddingLeft:10,paddingRight:10}}>
                            <Row style={{paddingBottom:20,borderBottomWidth:1,borderLeftWidth:0,borderTopWidth:0,borderRightWidth:0,
                                borderStyle:"solid",borderColor:"lightgrey"}}>
                                {
                                GLOBAL.checkPermission("yZX}WGbi")?
                                    <Col style={styles.columnTitleStyle} xs={1}>Company</Col>
                                    :
                                    null
                                }
                                <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                    this.sortColumn("Phone")
                                }}>
                                    <div>Phone</div>
                                    {
                                    this.state.sortPhone=="Up"?
                                        <AiFillCaretUp />
                                        :
                                    this.state.sortPhone=="Down"?
                                        <AiFillCaretDown />
                                        :null
                                    }
                                </Col>
                                <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                    this.sortColumn("Name")
                                }}>
                                    <div>Name</div>
                                    {
                                    this.state.sortName=="Up"?
                                        <AiFillCaretUp />
                                        :
                                    this.state.sortName=="Down"?
                                        <AiFillCaretDown />
                                        :null
                                    }
                                </Col>
                                <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                    this.sortColumn("Email")
                                }}>
                                    <div>Email</div>
                                    {
                                    this.state.sortEmail=="Up"?
                                        <AiFillCaretUp />
                                        :
                                    this.state.sortEmail=="Down"?
                                        <AiFillCaretDown />
                                        :null
                                    }
                                </Col>
                                <Col style={styles.columnTitleStyle} xs={2} onClick={()=>{
                                    this.sortColumn("Creator")
                                }}>
                                    <div>Creator</div>
                                    {
                                    this.state.sortEmail=="Up"?
                                        <AiFillCaretUp />
                                        :
                                    this.state.sortEmail=="Down"?
                                        <AiFillCaretDown />
                                        :null
                                    }
                                </Col>
                                <Col style={styles.columnTitleStyle} xs={2} onClick={()=>{
                                    this.sortColumn("Referrer")
                                }}>
                                    <div>Referrer</div>
                                    {
                                    this.state.sortEmail=="Up"?
                                        <AiFillCaretUp />
                                        :
                                    this.state.sortEmail=="Down"?
                                        <AiFillCaretDown />
                                        :null
                                    }
                                </Col>
                                <Col style={styles.columnTitleStyle} xs={2} onClick={()=>{
                                    this.sortColumn("PIC")
                                }}>
                                    <div>PIC</div>
                                    {
                                    this.state.sortEmail=="Up"?
                                        <AiFillCaretUp />
                                        :
                                    this.state.sortEmail=="Down"?
                                        <AiFillCaretDown />
                                        :null
                                    }
                                </Col>
                                <Col style={styles.columnTitleStyle} xs={1} onClick={()=>{
                                    this.sortColumn("Create Date")
                                }}>
                                    <div>Create Date</div>
                                    {
                                    this.state.sortJoinDate=="Up"?
                                        <AiFillCaretUp />
                                        :
                                    this.state.sortJoinDate=="Down"?
                                        <AiFillCaretDown />
                                        :null
                                    }
                                </Col>
                                <Col xs={1}></Col>
                            </Row>
                            {
                            this.state.memberList.length>0?
                                this.state.memberList.map((item)=>{
                                    return(
                                        <Row style={styles.searchResultRow} key={item.phone}>
                                            {
                                            GLOBAL.checkPermission("yZX}WGbi")?
                                                <Col xs={1}>
                                                    {
                                                    item.agentCompany!=null?
                                                        item.agentCompany.name
                                                        :
                                                        "Enlliance"
                                                    }
                                                </Col>
                                                :
                                                null
                                            }
                                            <Col xs={1}>{item.phone?item.phone:"-"}</Col>
                                            <Col xs={1}>{item.name?item.name:"-"}</Col>
                                            <Col xs={1} style={{fontSize:13,wordBreak:"break-all"}}>{item.email?item.email:"-"}</Col>
                                            <Col xs={2} style={{fontSize:13}}>{item.creator?item.creator:"-"}</Col>
                                            <Col xs={2} style={{fontSize:13}}>{item.referrer?item.referrer:"-"}</Col>
                                            <Col xs={2} style={{fontSize:13}}>{item.personInCharge?item.personInCharge:"-"}</Col>
                                            <Col xs={1}>{item.createDate}</Col>
                                            <Col xs={1}>
                                                <Button color="primary" onClick={()=>{
                                                    let here = new URL(window.location.href)
                                                    here.searchParams.set('phone',item.phone)
                                                    window.history.pushState({},"",here)

                                                    this.viewMember(item).then(()=>{
                                                        this.loadContactLog(item.id)
                                                    })
                                                }}>
                                                    View
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                                :
                                <div> ------- </div>
                            }

                            <div style={{marginTop:10}}/>
                            <ReactPaginate
                                pageCount={Math.ceil(this.state.resultCount/this.state.entryPerPage)}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLabel={"..."}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                disabledClassName={"disabled"}
                                forcePage={this.state.page}
                                onPageChange={async ({selected}) => {
                                    this.searchMember(selected);
                                }}
                            />
                        </div>
                        :
                        this.state.currentMember.id?
                        <Row style={{display:"flex",flexDirection:"row",justifyContent:"center",paddingLeft:10,paddingRight:10,width:"100%"}}>
                            <Col xs={12} sm={6} md={4} style={{width:"100%"}}>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",borderStyle:"solid",borderWidth:1,padding:10,borderRadius:10,width:"100%"}}>
                                {/* here */}
                                <table style={{maxWidth:600,width:"100%"}}>
                                    <tbody>
                                    <tr>
                                        <td colSpan={2} style={{textAlign:"center"}}>
                                            {
                                            GLOBAL.checkPermission("K2|g?@Vb")?
                                                this.state.editProfileMode?
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                                                        <Button color="primary" style={{paddingTop:3,paddingBottom:3,fontSize:10}} onClick={()=>{this.setState({editProfileMode:false})}}>
                                                            Cancel
                                                        </Button>
                                                        <Button color="danger" style={{paddingTop:3,paddingBottom:3,fontSize:10,marginLeft:10}} onClick={()=>{
                                                            this.saveProfile()
                                                        }}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                    :
                                                    <Button color="primary" style={{paddingTop:3,paddingBottom:3,fontSize:10}} onClick={()=>{
                                                        this.setState({
                                                            editProfileMode:true,
                                                            toChangeCurrentMember:JSON.parse(JSON.stringify(this.state.currentMember)), //shallow quick clone of object
                                                        })
                                                    }}>
                                                        Edit Profile
                                                    </Button>
                                                :
                                                null
                                            }
                                            {
                                            GLOBAL.checkPermission("g?=m>x`%")?
                                                <Button color="danger" style={{marginLeft:10,paddingTop:3,paddingBottom:3,fontSize:10}} onClick={()=>{
                                                    this.deleteContact()
                                                }}>
                                                    Delete Contact
                                                </Button>
                                                :
                                                null
                                            }
                                        </td>
                                    </tr>

                                    {
                                    this.state.editProfileMode?
                                        <div>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Phone: </td>
                                                <td style={styles.memberDetailCell}>
                                                    <Input value={this.state.toChangeCurrentMember.phone} onChange={(e)=>{
                                                        var newDetail=this.state.toChangeCurrentMember
                                                        newDetail.phone=e.target.value.replace(/\D/g,"")
                                                        this.setState({toChangeCurrentMember:newDetail})
                                                    }}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Name: </td>
                                                <td style={styles.memberDetailCell}>
                                                    <Input value={this.state.toChangeCurrentMember.name} onChange={(e)=>{
                                                        var newDetail=this.state.toChangeCurrentMember
                                                        newDetail.name=e.target.value
                                                        this.setState({toChangeCurrentMember:newDetail})
                                                    }}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Email: </td>
                                                <td style={styles.memberDetailCell}>
                                                    <Input value={this.state.toChangeCurrentMember.email} onChange={(e)=>{
                                                        var newDetail=this.state.toChangeCurrentMember
                                                        newDetail.email=e.target.value
                                                        this.setState({toChangeCurrentMember:newDetail})
                                                    }}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Creator: </td>
                                                <td style={styles.memberDetailCell}>
                                                    {this.state.toChangeCurrentMember.creator}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Company: </td>
                                                <td style={styles.memberDetailCell}>
                                                    {
                                                    this.state.toChangeCurrentMember.agentCompany!=null?
                                                        this.state.toChangeCurrentMember.agentCompany.name
                                                        :
                                                        "Enlliance"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Create Date: </td>
                                                <td style={styles.memberDetailCell}>{this.state.currentMember.createDate?this.state.currentMember.createDate:"-"}</td>
                                            </tr>
                                        </div>
                                        :
                                        <div>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Phone: </td>
                                                <td style={styles.memberDetailCell}>{this.state.currentMember.phone}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Name: </td>
                                                <td style={styles.memberDetailCell}>{this.state.currentMember.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Email: </td>
                                                <td style={styles.memberDetailCell}>{this.state.currentMember.email}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Creator: </td>
                                                <td style={styles.memberDetailCell}>{this.state.currentMember.creator}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Company: </td>
                                                <td style={styles.memberDetailCell}>
                                                    {
                                                    this.state.currentMember.agentCompany!=null?
                                                        this.state.currentMember.agentCompany.name
                                                        :
                                                        "Enlliance"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={styles.memberDetailCell}>Create Date: </td>
                                                <td style={styles.memberDetailCell}>{this.state.currentMember.createDate?this.state.currentMember.createDate:"-"}</td>
                                            </tr>
                                        </div>
                                    }
                                    </tbody>
                                </table>
                                </div>
                            </Col>

                            <Col xs={12} sm={6} md={8} style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-start"}}>
                                {/*
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                    <div>Tags</div>
                                    <Button color="light" style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",padding:2,paddingLeft:8,paddingRight:10,fontSize:12,marginLeft:10}} onClick={()=>{
                                        this.loadAllTagList()
                                    }}>
                                        <div>Add</div>
                                    </Button>
                                </div>
                                <div style={{maxHeight:280,overflowY:"scroll",border:"1px solid lightgrey",display:"flex",flexDirection:"row",flexWrap:"wrap",padding:5,marginTop:5}}>
                                    {
                                    this.state.clientTagList.length>0?
                                        this.state.clientTagList.map((item)=>{
                                            return(
                                                <div style={{padding:5,borderRadius:5,border:"1px solid lightgrey",marginRight:5}}>
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                        <div>{item.title}</div>
                                                        <Button color="light" style={{padding:5,lineHeight:1,fontSize:12,height:"auto",borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                            this.deleteContactTag(item)
                                                        }}>x</Button>
                                                    </div>
                                                    <div style={{fontSize:10}}>
                                                        Times used: {item.timesUsed}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :<div style={{color:"lightgrey"}}>Empty</div>
                                    }
                                </div>
                                */}

                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10}}>
                                    <div>Tickets</div>
                                    <Button color="primary" style={{padding:"2px 10px",fontSize:13}} onClick={()=>{
                                        this.setState({createTicketModal:true,createTicketMode:"lead"})
                                    }}>
                                        Create Ticket
                                    </Button>
                                </div>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap:"wrap",marginTop:10}}>
                                    {
                                    this.state.leadTicketList.length==0?
                                        <div style={{textAlign:"center",padding:"20px 20px",color:"lightgrey",border:"1px solid lightgrey"}}>
                                            No Ticket
                                        </div>
                                        :
                                        <div style={{display:"flex",flexDirection:"row",gap:10}}>
                                            {
                                            this.state.leadTicketList.map((ticket)=>{
                                                return(
                                                    <Button color="light" style={{padding:0,border:"1px solid lightgrey",display:"flex",flexDirection:"column",alignItems:"flex-start",fontSize:13}} 
                                                        onClick={()=>{
                                                            window.open("/ticket?id="+ticket.id,"_blank")
                                                    }}>
                                                        <div style={{fontWeight:"bold",width:"100%",fontSize:15,backgroundColor:"#6A6A68",
                                                            padding:"5px 20px",color:"#B8DBDA"}}>
                                                            Ticket {ticket.id}
                                                        </div>
                                                        <div style={{padding:"5px 20px 10px 20px",textAlign:"left"}}>
                                                            <div>Referrer: {ticket.referrerString}</div>
                                                            <div>PIC: {ticket.picString}</div>
                                                            <div>Channel: {ticket.channel}</div>
                                                            <div>Interest: {ticket.interest}</div>
                                                            <div>Expiry: {ticket.expiryDate}</div>
                                                            <div>Last Update: {ticket.lastUpdate}</div>
                                                            <div>Create Date: {ticket.createDate}</div>
                                                        </div>
                                                    </Button>
                                                )
                                            })
                                            }
                                        </div>
                                    }
                                </div>

                                <div style={{marginTop:10}}>
                                    Lead Log History
                                    <MdRefresh style={{fontSize:23}} onClick={()=>{this.loadContactLog(this.state.currentMember.id)}}/>
                                </div>
                                {
                                this.state.contactLogHistory.length>0?
                                    this.state.contactLogHistory.map((item,index)=>{
                                        return(
                                            <div style={{borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",marginBottom:5,padding:5,borderRadius:5}} key={item.dateTime}>
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                                    <div style={{display:"flex",flexDirection:"row"}}>
                                                        <div style={{fontWeight:"bold"}}>{item.loggerName?item.loggerName:"System"}</div>
                                                        <div style={{color:"grey",fontSize:14,marginLeft:20}}>{item.dateTime}</div>
                                                    </div>
                                                    {
                                                    reactLocalStorage.get("job")=="Sales Supervisor"||item.loggerUsername==reactLocalStorage.get("username")?
                                                        <div style={{color:"red",textDecoration:"underline",fontSize:12,cursor:"pointer"}} onClick={()=>{
                                                            if(window.confirm("Delete the lead log\nAre you sure?")){
                                                                this.deleteContactLog(item)
                                                            }
                                                        }}>Delete</div>
                                                        :null
                                                    }
                                                </div>
                                                {
                                                typeof item.message != 'string' ?
                                                    <Editor
                                                        editorState={item.message}
                                                        readOnly={true}
                                                        toolbarHidden={true}
                                                        />
                                                :
                                                    <div style={{width:"100%"}}>
                                                        {item.message}
                                                    </div>
                                                }
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                                                    {
                                                    item.tag.map((item)=>{
                                                        return(
                                                            <div style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 4px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,marginRight:5,paddingLeft:5,paddingRight:5,borderRadius:5}}>
                                                                {item.name}
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{color:"lightgrey"}}>Empty</div>
                                }
                                <div style={{marginTop:10}}>New Lead Log</div>
                                <div style={{width:"100%",marginTop:10,padding:10,borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}}>
                                            {
                                                window.innerWidth>600?
                                                <Editor style={{}}
                                                editorState={this.state.editorState}
                                                onEditorStateChange={this.onEditorStateChange}
                                                toolbar={{
                                                    image:{
                                                        uploadEnabled:true,
                                                        uploadCallback:this.imageUploadCallback,
                                                        previewImage: true,
                                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                        defaultSize: {
                                                            height: 300,
                                                            width: 300,
                                                        },
                                                    }
                                                }}
                                                />
                                                :
                                                <Editor style={{}}
                                                editorState={this.state.editorState}
                                                onEditorStateChange={this.onEditorStateChange}
                                                toolbar={{
                                                    options:['inline','image','fontSize','emoji','history'],
                                                    inline:{
                                                        options: ['bold', 'italic', 'underline'],
                                                    },
                                                    image:{
                                                        uploadEnabled:true,
                                                        uploadCallback:this.imageUploadCallback,
                                                        previewImage: true,
                                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                        defaultSize: {
                                                            height: 300,
                                                            width: 300,
                                                        },
                                                    }
                                                }}
                                                />
                                            }
                                            
                                </div>
                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                                        {this.state.selectedTag.map((item)=>{
                                            return(
                                                <Button style={{backgroundColor:"white",color:"black",WebkitBoxShadow:"inset 0px 0px 0px 5px "+GLOBAL.getCrayonColor(item.tagColor),borderWidth:0,marginRight:5}}
                                                    onClick={()=>{this.removeTag(item)}}>
                                                    {item.name}<TiDelete style={{marginLeft:5,fontSize:25}}/>
                                                </Button>
                                            )
                                        })}
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",marginTop:5}}>
                                        {/*
                                        <Button color="info" style={{marginRight:10,width:100,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} onClick={()=>{this.openTagModal()}}>
                                            <div>Tag</div>
                                        </Button>
                                        */}
                                        <Button color="primary" style={{width:100}} onClick={()=>{this.post()}}>Post</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        :
                        <div>No data</div>
                    }
                </div>
            </div>
        )
    }
}

let styles={
    columnTitleStyle:{
        fontWeight:"bold",
        cursor:"pointer",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
    },
    searchResultRow:{
        paddingTop:10,
        paddingBottom:10,
        borderBottomWidth:1,
        borderLeftWidth:0,
        borderTopWidth:0,
        borderRightWidth:0,
        borderStyle:"solid",
        borderColor:"#e6e6e6",
        ':hover':{
            backgroundColor:"grey",
            color:"white",
        },
    },
    memberDetailCell:{
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:5,
        paddingRight:5,
    },
    purchaseText:{
        color:"grey",
        flex:0.2,
        textAlign:"right",
    },
    profitText:{
        color:"green",
        flex:0.2,
        textAlign:"right",
    },
    lossText:{
        color:"salmon",
        flex:0.2,
        textAlign:"right",
    },
}
