import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal,Tooltip} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'
import ReactPaginate from 'react-paginate';

import {BsFillInfoCircleFill} from 'react-icons/bs'

export default class Affiliate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            agentData:[],

            selectedCompany:null,

            walletLogList:[],
            walletLogModal:false,
            walletLogPageCount:0,
            walletLogPage:1,

            profitSharingLogList:[],
            profitSharingModal:false,
            profitSharingPageCount:0,
            profitSharingPage:1,

            infoModal:false,
            message:null,
        }
    }

    componentWillMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        this.getAgentStats()
    }

    getAgentStats=()=>{
        this.setState({loading:true})
        const payload={
            companyType:"Affiliate",
        }
        axios
            .post(`sales/getallagentstats`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    mode:"Agent",
                    agentData:JSON.parse(response.data),
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Get all agent stats error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    getWalletLog=(page=1)=>{
        this.setState({loading:true})
        const payload={
            companyID:this.state.selectedCompany.companyDetails.id,
            page:page,
            itemPerPage:10,
        }
        axios
            .post(`sales/getagentcompanywalletlog`,qs.stringify(payload))
            .then(async response => {
                //parse date
                for(var walletLog of response.data.walletLogList){
                    var rawDateSplit=walletLog.createDate.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    var timeSplit=rawDateSplit[1].split(":")
                    walletLog.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    walletLogList:response.data.walletLogList,
                    walletLogModal:true,
                    walletLogPageCount:response.data.pageCount,
                    walletLogPage:page,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Get release log error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }
    handleWalletLogPageClick=(data)=>{
        let selected = data.selected;
        this.getWalletLog(selected+1)
    }

    getSharingRecord=(page=1)=>{
        this.setState({loading:true})
        const payload={
            companyID:this.state.selectedCompany.companyDetails.id,
            page:page,
            itemPerPage:10,
        }
        axios
            .post(`sales/getprofitsharingrecord`,qs.stringify(payload))
            .then(async response => {
                //parse date
                for(var profitSharingLog of response.data.profitSharingLogList){
                    var rawDateSplit=profitSharingLog.createDate.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    var timeSplit=rawDateSplit[1].split(":")
                    profitSharingLog.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    profitSharingLogList:response.data.profitSharingLogList,
                    profitSharingModal:true,
                    profitSharingPageCount:response.data.pageCount,
                    profitSharingPage:page,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Get profit sharing log error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }
    handleProfitSharingLogPageClick=(data)=>{
        let selected = data.selected;
        this.getSharingRecord(selected+1)
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal isOpen={this.state.infoModal} toggle={()=>this.setState({infoModal:false})}>
                    <div style={{padding:20}}>
                        {this.state.message}
                    </div>
                </Modal>

                <Modal isOpen={this.state.profitSharingModal} toggle={()=>this.setState({profitSharingModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>
                            Profit Sharing Log
                        </div>
                        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                            {
                            this.state.profitSharingLogList.map((profitSharingLog)=>{
                                if(profitSharingLog.operator==null){
                                    return(
                                        <div style={{border:"solid 1px green",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                                <div>{profitSharingLog.description}</div>
                                                <div>{profitSharingLog.createDate}</div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                                <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",width:"100%",fontSize:12}}>
                                                    <div>Total Value: RM{profitSharingLog.totalValue}</div>
                                                    <div>Total Cost: RM{profitSharingLog.totalCost}</div>
                                                    <div>Total Commission: RM{profitSharingLog.totalCommission}</div>
                                                    <div>Total Remaining Profit: RM{profitSharingLog.totalRemainingProfit}</div>
                                                    <div>Profit Sharing Percentage: {profitSharingLog.profitSharingPercentage}%</div>

                                                </div>
                                                <div style={{display:"flex",flexDirection:"column",textAlign:"right",fontSize:13,whiteSpace:"nowrap"}}>
                                                    <div>Amount: RM{profitSharingLog.profitSharingAmount}</div>
                                                    <div style={{marginLeft:10}}>Balance: RM{profitSharingLog.balance}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return(
                                        <div style={{border:"solid 1px lightgrey",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                                <div>Operator: {profitSharingLog.operator}</div>
                                                <div>{profitSharingLog.createDate}</div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                                <div style={{flex:1,display:"flex",flexDirection:"column"}}>
                                                    <div style={{fontSize:12}}>Payment Number: {profitSharingLog.paymentNumber}</div>
                                                    <div style={{fontSize:12,color:"grey"}}>
                                                        {profitSharingLog.description}
                                                    </div>
                                                </div>
                                                <div style={{marginLeft:5,textAlign:"right",fontSize:13}}>
                                                    <div>Amount: RM{profitSharingLog.profitSharingAmount}</div>
                                                    <div>Balance: RM{profitSharingLog.balance}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                        <div>
                            {this.state.profitSharingPageCount > 0 && (
                                <div style={this.state.width > 600 ? {display: "flex", justifyContent: "flex-end", marginTop: 10} : {display: "flex", justifyContent: "flex-end", marginTop: 10, fontSize: "0.7rem"}}>
                                    <ReactPaginate
                                        disableInitialCallback={true}   //stop auto reload upon mount
                                        initialPage={this.state.profitSharingPage-1}
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        pageCount={this.state.profitSharingPageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handleProfitSharingLogPageClick}
                                        subContainerClassName={'pages pagination'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        forcePage={this.state.profitSharingPage-1}
                                    />
                                </div>
                            )}
                        </div>
                        <div style={{marginTop:10,width:"100%"}}>
                            <Button style={{}} onClick={()=>{
                                this.setState({profitSharingModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.walletLogModal} toggle={()=>this.setState({walletLogModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>
                            Wallet Log
                        </div>
                        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                            {
                            this.state.walletLogList.map((walletLog)=>{
                                if(walletLog.operator==null){
                                    return(
                                        <div style={{border:"solid 1px green",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                                <div>{walletLog.description}</div>
                                                <div>{walletLog.createDate}</div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",width:"100%"}}>
                                                <div>Amount: RM{walletLog.amount}</div>
                                                <div style={{marginLeft:10}}>Balance: RM{walletLog.balance}</div>
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return(
                                        <div style={{border:"solid 1px lightgrey",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                                <div>Operator: {walletLog.operator}</div>
                                                <div>{walletLog.createDate}</div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                                <div style={{flex:1,display:"flex",flexDirection:"column"}}>
                                                    <div style={{fontSize:12}}>Payment Number: {walletLog.paymentNumber}</div>
                                                    <div style={{fontSize:12,color:"grey"}}>
                                                        {walletLog.description}
                                                    </div>
                                                </div>
                                                <div style={{marginLeft:5,textAlign:"right"}}>
                                                    <div>Amount: RM{walletLog.amount}</div>
                                                    <div>Balance: RM{walletLog.balance}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                        <div>
                            {this.state.walletLogPageCount > 0 && (
                                <div style={this.state.width > 600 ? {display: "flex", justifyContent: "flex-end", marginTop: 10} : {display: "flex", justifyContent: "flex-end", marginTop: 10, fontSize: "0.7rem"}}>
                                    <ReactPaginate
                                        disableInitialCallback={true}   //stop auto reload upon mount
                                        initialPage={this.state.walletLogPage-1}
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        pageCount={this.state.walletLogPageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handleWalletLogPageClick}
                                        subContainerClassName={'pages pagination'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        forcePage={this.state.walletLogPage-1}
                                    />
                                </div>
                            )}
                        </div>
                        <div style={{marginTop:10,width:"100%"}}>
                            <Button style={{}} onClick={()=>{
                                this.setState({walletLogModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <NavigationBar currentPage="affiliate" />
                <div style={{padding:15}}>
                    {
                    this.state.agentData.map((company)=>{
                        return(
                            <div style={{padding:10,border:"solid 1px grey",borderRadius:10,marginBottom:5}}>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div>
                                        <div>Affiliate Company Name: {company.companyDetails.name}</div>
                                        <div>SSM Number: {company.companyDetails.ssmNumber}</div>
                                        <div>Director: {company.companyDetails.directorUsername} {company.companyDetails.directorName}</div>
                                    </div>
                                    <div style={{marginLeft:15}}>
                                        <div>Bank Account Name: {company.companyDetails.bankAccountName}</div>
                                        <div>Bank Account Number: {company.companyDetails.bankAccountNumber}</div>
                                        <div>Bank Name: {company.companyDetails.bankName}</div>
                                    </div>
                                    <div style={{flex:1}} />
                                    <div style={{marginLeft:20}}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",}}>
                                            <div style={{marginLeft:10}}>Company Wallet: RM{company.companyDetails.wallet.toLocaleString()}</div>
                                            <div style={{display:"flex",flexDirection:"column",marginLeft:15}}>
                                                <Button style={{}} onClick={()=>{
                                                    this.setState({selectedCompany:company},()=>{
                                                        this.getWalletLog()
                                                    })
                                                }}>
                                                    Wallet Records
                                                </Button>
                                            </div>
                                            <div style={{marginLeft:20}}>Profit Sharing Wallet: RM{company.companyDetails.profitSharingWallet.toLocaleString()}</div>
                                            <div style={{display:"flex",flexDirection:"column",marginLeft:15}}>
                                                <Button style={{}} onClick={()=>{
                                                    this.setState({selectedCompany:company},()=>{
                                                        this.getSharingRecord()
                                                    })
                                                }}>
                                                    Profit Sharing Records
                                                </Button>
                                            </div>
                                        </div>
                                        <div style={{marginTop:10,display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}>
                                            <div style={{marginRight:15,display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                <div>Company Referral Credit: RM{company.companyDetails.referralCredit.toLocaleString()}</div>
                                                <BsFillInfoCircleFill style={{marginLeft:5,color:"skyblue",cursor:"pointer"}} onClick={()=>{
                                                    this.setState({infoModal:true,message:"A virtual user account of the agent company to store awarded referral credit when agent company use company link to generate leads."})
                                                }}/>
                                            </div>
                                            <Button color="primary" style={{marginRight:10}} onClick={()=>{
                                                history.push("agents?companyid="+company.companyDetails.id)
                                            }}>
                                                Agents
                                            </Button>
                                            <Button color="primary" style={{}} onClick={()=>{
                                                history.push("companyachievement?companyid="+company.companyDetails.id)
                                            }}>
                                                Company Achievement
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    Linked GD Shop: {company.companyDetails.gdShopName?company.companyDetails.gdShopName:"-"}
                                </div>
                                <div>Agent: {company.agentList.length}pp</div>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                {
                                company.agentList.map((agent)=>{
                                    return(
                                        <div style={{margin:2,border:agent.active?"solid 1px skyblue":"solid 1px lightgrey",borderRadius:5,padding:"2px 5px",
                                            backgroundColor:agent.active?"#ebf4ff":"#f7f7f7"}}>
                                            {agent.phone} {agent.name}
                                        </div>
                                    )
                                })
                                }
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        )
    }
}

Affiliate = Radium(Affiliate);

let styles={

}
