import React from 'react';
import axios from 'axios';
import qs from 'qs';
import ReactPaginate from 'react-paginate';
import {Input,Button,Alert,Row,Col,Table,Modal,Tooltip} from 'reactstrap';

export default class WalletLog extends React.Component{

    constructor(props) {        
        super(props);
        this.state = {
            agentData:[],
            selectedCompany:null,
            walletLogList:[],
            walletLogModal:false, 
            itemPerPage:0,
            nonRecord:false,                    
        }
    }
    
    componentDidMount() {
        // Code to run after the component is mounted
        this.getWalletLog();
      }
    
    getWalletLog = async (page = 0) => {
    try{
        this.sendLoadingToParent(true);    
        const payload = {
            target: this.props.currentMember,
            company:this.props.company,
            page: page,
            itemPerPage: window.innerWidth <= 600 ? 4 : 7,
        }
    
        const response = await axios.post(`sales/getwalletlog`, qs.stringify(payload), { timeout: 60000 })
        // Parse date and update state
        for (let log of response.data.walletList) {
            let rawDateSplit = log.createDate.split("T")
            let dateSplit = rawDateSplit[0].split("-")
            let timeSplit = rawDateSplit[1].split(":")
            log.createDate = dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0] + "  " + timeSplit[0] + ":" + timeSplit[1]
        }          

        this.setState({
            walletLogModal: true,
            walletLogList: response.data.walletList,
            walletPages: response.data.theWalletPages,
            page: response.data.page,
            totalWalletCount: response.data.totalWalletCount,
        });

        if (this.state.walletLogList.length === 0) {
            this.setState({ nonRecord: true }); //show finding when loading
        }
        
        // Notify the parent that loading has completed
        this.sendLoadingToParent(false);
    }catch (error) {        
        console.error("Error fetching wallet log:", error);            
        this.setState({ loading: false });
        this.sendLoadingToParent(false);
    }
    }   

    getWalletName=(wallet)=>{
        if(wallet=="rWallet"){
            return "R Wallet"
        }else if(wallet=="pWallet"){
            return "P Wallet"
        }else if(wallet=="wWallet"){
            return "C Wallet"
        }else if(wallet=="System"){
            return "System"
        }else if(wallet=="wClaim"){
            return "Claimed"
        }else if(wallet=="Other"){
            return "Other"
        }
    }
    getWalletLogColor=(log)=>{
        if(log.type=== null){
            if(log.fromWallet=="wWallet"&&log.toWallet=="wClaim"){
                return "#fff0f0" //red
            }else{
                return "#f5f5fa" //lightgrey
            }
        }else{
            if(log.type === "referral"){
                return "#dedeff"   // blue
            }else{
                return "#f0faf0"  //green
            }
        }
    }

    getWalletLogBorderColor=(log)=>{
        if(log.type=== null){
            if(log.fromWallet=="wWallet"&&log.toWallet=="wClaim"){
                return "red" //red
            }else{
                return "lightgrey" //lightgrey
            }
        }else{
            if(log.type === "referral"){
                return "blue"   // blue
            }else if(log.type === "commission"){
                return "green"  //green
            }
        }
    }
    sendLoadingToParent = (isLoading) => {
        // Call the function passed from the parent and pass data to it
        this.props.sendLoadingToParent(isLoading);      
      };

    render(){
        
        return(                      
            <div>                           
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} 
                        style={{ maxWidth: window.innerWidth <= 600 ? "100%": 1000 }}> 
                        {/* less then 600 is mobile view else desktop */} 

                    {
                         window.innerWidth <= 600 ?   // wallet mobile  
                            <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                            <div style={{fontSize:20,fontWeight:"bold"}}>Wallet Log</div> 

                               { this.state.walletLogList.length >0?               
                                <div style={{display:"flex",flexDirection:"column",marginBottom:10,gap:5}}>    
                                    {    
                                    this.state.walletLogList.map((log)=>
                                    {    
                                        return(    
                                                <div style={{padding:10,border:"1px solid",borderRadius:10,borderColor:this.getWalletLogBorderColor(log)}}>    
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>    
                                                        <div>Operator: {log.operator}</div>    
                                                        <div>{log.createDate}</div>    
                                                    </div>
    
                                                    <div style={{display:"flex",flexDirection:"row"}}>    
                                                        <div style={{border:"1px solid",borderColor:this.getWalletLogBorderColor(log),padding:2,borderRadius:5,backgroundColor : ` ${this.getWalletLogColor(log)} `}}>From: {this.getWalletName(log.fromWallet)}</div>    
                                                        <div style={{border:"1px solid",borderColor:this.getWalletLogBorderColor(log),padding:2,borderRadius:5,backgroundColor : ` ${this.getWalletLogColor(log)} `,marginLeft:10}}>To: {this.getWalletName(log.toWallet)}</div>    
                                                    </div>
                                                    <div>RM{log.amount.toLocaleFixed(2)}</div>    
                                                    <div>{log.description}</div>    
                                                </div>    
                                                )    
                                        })
                                    }
                                    </div>
                                    :
                                    this.state.nonRecord?
                                        <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f2f2f2', border: '1px solid #ddd', borderRadius: '5px', color: '#888' }}>
                                        No records found....
                                        </div> 
                                        :
                                        <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f2f2f2', border: '1px solid #ddd', borderRadius: '5px', color: '#888' }}>
                                        finding record....
                                        </div> 
                                }                                    
                                </div>
                                :
                                //wallet desktop
                            
                                <div style={{ padding: 15, paddingTop: 10 }}>
                                <div style={{ fontSize: 20, fontWeight: "bold" ,padding:5}}>Wallet Log</div>                  
                                <div style={{padding:10}}>    

                                 { this.state.walletLogList.length >0?  
                                    <table style={{textAlign:`center`, borderRadius: 5, borderSpacing: "0px 2px", width: "100%",borderCollapse: "separate"}}>
                                    <thead>
                                        <tr style={{ fontSize: 15, fontWeight: "bold" ,padding:10}}>
                                            <th style={{ width: '15%' }}>Operator</th>
                                            <th style={{ width: '15%' }}>Create Date</th>
                                            <th style={{ width: '12%' }}>From Wallet</th>
                                            <th style={{ width: '12%' }}>To Wallet</th>
                                            <th style={{ width: '15%' }}>Amount</th>
                                            <th style={{ width: '40%' }}>Description</th>
                                        </tr>
                                    </thead>

                                    <tbody style={{ borderCollapse: "separate"}}>                                   
                                        {this.state.walletLogList.map((log) => (
                                            <tr style={{ ...styles.searchsultRow,fontSize:`14px`,backgroundColor :`${this.getWalletLogColor(log)}`,padding:0}}>                                             
                                                <td style={{textAlign:`left` ,...styles.WalletRowPadding,borderBottom: `1px solid ${this.getWalletLogBorderColor(log)}`,borderTop: `1px solid ${this.getWalletLogBorderColor(log)}`}}>                                                  
                                                        {log.operator}                                                    
                                                </td>
                                                <td style={{...styles.WalletRowPadding, borderBottom: `1px solid ${this.getWalletLogBorderColor(log)}`,borderTop: `1px solid ${this.getWalletLogBorderColor(log)}`}}>                                                    
                                                            {log.createDate}                                                                                                          
                                                </td>
                                                <td style={{...styles.WalletRowPadding, textAlign: "center",borderBottom: `1px solid ${this.getWalletLogBorderColor(log)}`,borderTop: `1px solid ${this.getWalletLogBorderColor(log)}`}}>
                                                    {this.getWalletName(log.fromWallet)}
                                                </td>
                                                <td style={{...styles.WalletRowPadding, marginLeft: 10, textAlign: "center" ,borderBottom: `1px solid ${this.getWalletLogBorderColor(log)}`,borderTop: `1px solid ${this.getWalletLogBorderColor(log)}`}}>
                                                    {this.getWalletName(log.toWallet)}
                                                </td>
                                                <td style={{...styles.WalletRowPadding,borderBottom: `1px solid ${this.getWalletLogBorderColor(log)}`,borderTop: `1px solid ${this.getWalletLogBorderColor(log)}`}}>
                                                    RM{log.amount.toFixed(2)}
                                                </td>
                                                <td style={{textAlign:`left`,...styles.WalletRowPadding,borderBottom: `1px solid ${this.getWalletLogBorderColor(log)}`,borderTop: `1px solid ${this.getWalletLogBorderColor(log)}`}}>
                                                    {log.description}
                                                </td>                                          
                                            </tr>
                                        ))}
                                    
                                    </tbody>
                                    </table>   
                                    : 
                                    this.state.nonRecord?
                                        <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f2f2f2', border: '1px solid #ddd', borderRadius: '5px', color: '#888' }}>
                                        No records found....
                                        </div> 
                                        :
                                        <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f2f2f2', border: '1px solid #ddd', borderRadius: '5px', color: '#888' }}>
                                        finding record....
                                        </div> 
                                }                                                                                                                 
                                </div> 
                                </div>                                                        
                    }                      
                                
                    <div style={{padding:10 , display :`flex` , justifyContent : `center`}}>
                        <ReactPaginate
                                    pageCount={this.state.walletPages}
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLabel={"..."}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    disabledClassName={"disabled"}
                                    forcePage={this.state.page}
                                    onPageChange={async ({selected}) => {                                                                                
                                    this.getWalletLog(selected);                                            
                                    }}                                        
                        />
                        <div style={{ paddingLeft:10}}>
                                <Button color="danger" style={{ flex: 1, backgroundColor: "salmon", border: "none"}} 
                                onClick={this.props.toggle}>
                                    Close
                                </Button>
                        </div>
                    </div>             
                </Modal>
            </div>
            
        )
    }
}

var styles = {

}
