import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Modal,Tooltip,ModalHeader} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'
import ReactPaginate from 'react-paginate';

import {FaCheckCircle,FaTimesCircle,FaCopy,FaDatabase, FaCircle, FaCrown,} from 'react-icons/fa';
import {AiOutlineCopy, AiFillPauseCircle, AiOutlineUser} from 'react-icons/ai'
import {ImCross,ImCheckmark} from 'react-icons/im'
import {BsFillInfoCircleFill} from 'react-icons/bs'

export default class Agents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,

            companyDetail:null,
            inviteAgentModal:false,
            agentPhone:null,
            inviteAgentTier:null,
            inviteAgentMaster:null,
            copyModal:false,
            copyLink:"",

            inviteAmbassadorModal:false,
            ambassadorPhone:null,
            inviteAffiliateModal:false,
            affiliatePhone:null,

            invitationList:[],
            agentList:[],
            ambassadorList:[],
            ambassadorInvitationList:[],
            affiliateList:[],
            affiliateInvitationList:[],

            selectedAgent:null,

            agentTierModal:false,
            agentTierMode:null,
            agentTierList:[],

            selectMasterModal:false,

            setRosterSequenceModal:false,
            setRosterSequence:-1,

            rosterHistoryModal:false,
            rosterHistoryList:[],
            rosterHistoryPage:0,
            rosterHistoryPageCount:0,

            selectInviteMasterModal:false,

            changePICModal:false,
            changePICClientUsername:null,
            changePICAgentID:null,

            openBankModal:false,
            affiliateBankAccountName:null,
            affiliateBankAccountNumber:null,
            affiliateBankName:null,

            censoredColumn:[],
        }
        this.companyID=null
    }

    componentWillMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        let searchPath = this.props.location.search;
        if(searchPath){
            searchPath=searchPath.replace("?","")
            let searchPathSplit=searchPath.split("&")
            for(let path of searchPathSplit){
                let param=path.split("=")[0]
                let value=path.split("=")[1]
                if(param=="companyid"){
                    this.companyID=value
                }
            }
        }

        this.getAgentList()
    }

    getAgentList=()=>{
        this.setState({loading:true})
        const payload = {
            companyID:this.companyID,
        }
        axios
            .post(`sales/getagentlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                // parse date
                response.data.company.createDate = response.data.company.createDate.split("T")[0].split("-")
                response.data.company.createDate = response.data.company.createDate[2] + "." + response.data.company.createDate[1] + "." + response.data.company.createDate[0]

                for(let agent of response.data.agentList){
                    agent.joinDate = agent.joinDate.split("T")[0].split("-")
                    agent.joinDate = agent.joinDate[2] + "." + agent.joinDate[1] + "." + agent.joinDate[0]
                }
                //fill in censored data with dummy data
                for(let agent of response.data.agentList){
                    if(agent.tier==null){
                        agent.tier={
                            name:"-"
                        }
                    }
                }

                for(let ambassador of response.data.ambassadorList){
                    ambassador.joinDate = ambassador.joinDate.split("T")[0].split("-")
                    ambassador.joinDate = ambassador.joinDate[2] + "." + ambassador.joinDate[1] + "." + ambassador.joinDate[0]
                }
                for(let affiliate of response.data.affiliateList){
                    affiliate.joinDate = affiliate.joinDate.split("T")[0].split("-")
                    affiliate.joinDate = affiliate.joinDate[2] + "." + affiliate.joinDate[1] + "." + affiliate.joinDate[0]
                }

                for(let invitation of response.data.invitationList){
                    let timeSplit=invitation.createDate.split("T")[1].split("+")[0].split(".")[0]
                    invitation.createDate = invitation.createDate.split("T")[0].split("-")
                    invitation.createDate = invitation.createDate[2] + "." + invitation.createDate[1] + "." + invitation.createDate[0]+" "+timeSplit
                }
                for(let invitation of response.data.ambassadorInvitationList){
                    let timeSplit=invitation.createDate.split("T")[1].split("+")[0].split(".")[0]
                    invitation.createDate = invitation.createDate.split("T")[0].split("-")
                    invitation.createDate = invitation.createDate[2] + "." + invitation.createDate[1] + "." + invitation.createDate[0]+" "+timeSplit
                }
                for(let invitation of response.data.affiliateInvitationList){
                    let timeSplit=invitation.createDate.split("T")[1].split("+")[0].split(".")[0]
                    invitation.createDate = invitation.createDate.split("T")[0].split("-")
                    invitation.createDate = invitation.createDate[2] + "." + invitation.createDate[1] + "." + invitation.createDate[0]+" "+timeSplit
                }

                this.setState({
                    loading:false,
                    companyDetail:response.data.company,
                    agentList:response.data.agentList,
                    censoredColumn:response.data.censoredColumn,
                    ambassadorList:response.data.ambassadorList,
                    affiliateList:response.data.affiliateList,
                    invitationList:response.data.invitationList,
                    ambassadorInvitationList:response.data.ambassadorInvitationList,
                    affiliateInvitationList:response.data.affiliateInvitationList,
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Session token is invalid. Please log in again.")
                        history.push("/")
                        return
                    }else if(error.response.status==401){
                        alert("You do not have permission to access agent list")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Get agent list error\n"+error)
                this.setState({loading:false})
            });
    }

    inviteAffiliate=()=>{
        //validate
        if(this.state.affiliatePhone==null||this.state.affiliatePhone.trim().length<8){
            alert("Please enter affiliate phone number correctly")
            return
        }

        this.setState({loading:true})
        const payload = {
            affiliatePhone:this.state.affiliatePhone,
            companyID:this.state.companyDetail.id,
        }
        axios
            .post(`sales/inviteaffiliate`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    inviteAffiliateModal:false,
                    copyModal:true,
                    copyLink:response.data.invitationLink
                })
                this.getAgentList()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==444){
                        this.setState({notAgent:true,loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("Only leader is allowed to invite affiliate")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==446){
                        alert("Cannot invite yourself")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==448){
                        alert("Error. The person is already an agent or affiliate")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==449){
                        alert("There is an existing invitation to this phone number")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==450){
                        alert("Cannot invite agent from another company")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Invite affiliate error\n"+error)
                this.setState({loading:false})
            });
    }

    inviteAmbassador=()=>{
        //validate
        if(this.state.ambassadorPhone==null||this.state.ambassadorPhone.trim().length<8){
            alert("Please enter ambassador phone number correctly")
            return
        }

        this.setState({loading:true})
        const payload = {
            ambassadorPhone:this.state.ambassadorPhone,
        }
        axios
            .post(`sales/inviteambassador`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    inviteAmbassadorModal:false,
                    copyModal:true,
                    copyLink:response.data.invitationLink
                })
                this.getAgentList()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==444){
                        this.setState({notAgent:true,loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("Only leader is allowed to invite ambassador")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==446){
                        alert("Cannot invite yourself")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==448){
                        alert("Error. The person is already an agent or ambassador")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==449){
                        alert("There is an existing invitation to this phone number")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Get agent data error\n"+error)
                this.setState({loading:false})
            });
    }

    inviteAgent=()=>{
        //validate
        if(this.state.agentPhone==null||this.state.agentPhone.trim().length<8){
            alert("Please enter agent phone number correctly")
            return
        }

        let agentTierID=12 //default 合作顾问
        if(this.state.companyDetail.defaultAgentTier!=null){
            agentTierID=this.state.companyDetail.defaultAgentTier.id
        }
        //agent rule
        if(this.state.inviteAgentTier!=null&&this.state.inviteAgentTier.tier==0&&
            (this.state.inviteAgentMaster==null||this.state.inviteAgentMaster.tier.tier<1)){
            alert("L0 agent must have a master and the master must be a >=L1 agent.")
            return
        }

        let masterID=-1
        if(this.state.inviteAgentMaster!=null){
            masterID=this.state.inviteAgentMaster.id
        }

        this.setState({loading:true})
        const payload = {
            agentPhone:this.state.agentPhone,
            agentTier:agentTierID,
            agentMaster:masterID,
        }
        axios
            .post(`sales/inviteagent`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    inviteAgentModal:false,
                    copyModal:true,
                    copyLink:response.data.invitationLink
                })
                // }
                this.getAgentList()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==444){
                        this.setState({notAgent:true,loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("Only leader is allowed to invite agent")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==446){
                        alert("Cannot invite yourself")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==448){
                        alert("Error. The person is already an agent or ambassador")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==449){
                        alert("There is an existing invitation to this phone number")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Get agent data error\n"+error)
                this.setState({loading:false})
            });
    }

    fallbackCopyTextToClipboard=(text)=>{
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
    copyTextToClipboard=(text)=>{
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            alert('分享信息成功copy到clipboard');
        }, function(err) {
            alert('Could not copy text: ', err);
        });
    }

    getShareString=()=>{
        var theCompany="our"
        if(this.state.agentData){
            theCompany=this.state.agentData.company
        }
        return "Join "+theCompany+" team on Enlliance now to explore a new opportunity in promoting entrepreneurial training and improvement of professional abilities.\n"+this.getShareLink()
    }
    getShareStringInURL=()=>{
        return encodeURIComponent(this.getShareString())
    }
    getShareLink=()=>{
        return this.state.copyLink
    }

    deleteInvitation=(invitation)=>{
        if(!window.confirm("Are you sure you want to delete the invitation?")){
            return
        }

        this.setState({loading:true})
        const payload = {
            invitationID:invitation.id
        }
        axios
            .post(`sales/deleteagentinvitation`,qs.stringify(payload))
            .then(async response => {
                alert("Invitation deleted")
                this.setState({
                    loading:false,
                })
                this.getAgentList()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==444){
                        this.setState({notAgent:true,loading:false})
                        return
                    }else if(error.response.status==445){
                        alert("Only leader is allowed to delete invitation")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==446){
                        alert("Cannot find the invitation")
                        this.setState({loading:false})
                        this.getAgentData()
                        return
                    }else if(error.response.status==447){
                        alert("Cannot delete invitation that does not belong to your company")
                        this.setState({loading:false})
                        this.getAgentData()
                        return
                    }
                }
                alert("Delete invitation error\n"+error)
                this.setState({loading:false})
            });
    }

    deactivateAgent=(agent)=>{
        if(!window.confirm("Are you sure you want to deactivate this agent?")){
            return
        }

        this.setState({loading:true})
        const payload = {
            agentID:agent.id,
        }
        axios
            .post(`sales/deactivateagent`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                })
                this.getAgentList()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Session token is invalid. Please log in again.")
                        history.push("/")
                        return
                    }else if(error.response.status==401){
                        alert("You do not have permission to access agent list")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Deactivate agent error\n"+error)
                this.setState({loading:false})
            });
    }

    activateAgent=(agent)=>{
        if(!window.confirm("Are you sure you want to activate this agent?")){
            return
        }

        this.setState({loading:true})
        const payload = {
            agentID:agent.id,
        }
        axios
            .post(`sales/activateagent`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                })
                this.getAgentList()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Session token is invalid. Please log in again.")
                        history.push("/")
                        return
                    }else if(error.response.status==401){
                        alert("You do not have permission to access agent list")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Activate agent error\n"+error)
                this.setState({loading:false})
            });
    }

    loadAgentTierList=(agentTierMode)=>{
        this.setState({loading:true})
        const payload={
            companyID:this.state.companyDetail.id
        }
        axios
            .post(`sales/loadagenttierlist`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    agentTierModal:true,
                    agentTierMode:agentTierMode,
                    agentTierList:response.data,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Load agent tier list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    setAgentTier=(tier)=>{
        this.setState({loading:true})
        const payload={
            agentID:this.state.selectedAgent.id,
            tierID:tier.id,
        }
        axios
            .post(`sales/setagenttier`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    agentTierModal:false,
                })
                this.getAgentList()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Set agent tier error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    selectMasterForAgent=(master)=>{
        //validate
        if(master!=null&&master.tier.tier==0){
            alert("L0 agent cannot be a master")
            return
        }
        if(master!=null&&this.state.selectedAgent.tier.tier==0&&master.tier.tier<1){
            alert("Only >=L1 agent can be master of a L0 agent")
            return
        }

        this.setState({loading:true})
        let theMasterID=null
        if(master!=null){
            theMasterID=master.id
        }
        const payload={
            agentID:this.state.selectedAgent.id,
            masterID:theMasterID
        }
        axios
            .post(`sales/selectmasterforagent`,qs.stringify(payload))
            .then(async response => {
                let masterName="NONE"
                if(master!=null){
                    masterName=master.name
                }
                alert("Master for "+this.state.selectedAgent.name+" changed to "+masterName)
                this.setState({
                    selectMasterModal:false,
                    selectedAgent:null
                })
                this.getAgentList()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }else if(error.response.status==444){
                        alert("Invalid. You cannot create a loop relationship.")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Set agent master error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    setAgentRosterSequence=()=>{
        this.setState({loading:true})
        const payload={
            companyID:this.state.companyDetail.id,
            agentID:this.state.selectedAgent.id,
            rosterSequence:this.state.setRosterSequence
        }
        axios
            .post(`sales/setagentrostersequence`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    setRosterSequenceModal:false,
                    selectedAgent:null
                })
                this.getAgentList()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }else if(error.response.status==444){
                        alert("Cannot have same roster sequence as another agent")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Set agent roster sequence error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    loadRosterHistory=(page=1)=>{
        this.setState({loading:true})
        const payload={
            companyID:this.state.companyDetail.id,
            page:page,
        }
        axios
            .post(`sales/loadleadrosterrecord`,qs.stringify(payload))
            .then(async response => {
                for(var rosterHistory of response.data.rosterHistoryList){
                    let rawSplit = rosterHistory.createDate.split("T")[0].split("-")
                    rosterHistory.createDate = rawSplit[2] + "." + rawSplit[1] + "." + rawSplit[0]
                }

                this.setState({
                    loading:false,
                    rosterHistoryModal:true,
                    rosterHistoryList:response.data.rosterHistoryList,
                    rosterHistoryPage:page,
                    rosterHistoryPageCount:response.data.pageCount,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Load roster history error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }
    handleRosterHistoryPageClick=(data)=>{
        let selected = data.selected;
        this.loadRosterHistory(selected+1)
    }

    handleBankDetailsClick=(affiliate)=>{
        this.setState({
            openBankModal:true,
            affiliateBankAccountName:affiliate.bankAccountName,
            affiliateBankAccountNumber:affiliate.bankAccountNumber,
            affiliateBankName:affiliate.bankName,
        });
    }

    changePICFunction=()=>{
        this.setState({loading:true})
        const payload={
            companyID:this.state.companyDetail.id,
            changePICClientUsername:this.state.changePICClientUsername,
            changePICAgentID:this.state.changePICAgentID,
        }
        axios
            .post(`sales/changeagentclientpic`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    changePICModal:false,
                })
                this.getAgentList()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }else if(error.response.status==448){
                        alert("Cannot change PIC of an agent")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==446){
                        alert("Cannot change PIC of a client that is not in charge by your company")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Change PIC error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    approveInvitation=(invitation)=>{
        this.setState({loading:true})
        const payload={
            invitationID:invitation.id
        }
        axios
            .post(`sales/approveagentinvitation`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                })
                this.getAgentList()
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Approve agent invitation error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    kickAffiliate=(affiliate)=>{
        if(!window.confirm("Are you sure you want to kick this affiliate out of the company?")){
            return
        }

        this.setState({loading:true})
        const payload = {
            affiliateID:affiliate.id,
        }
        axios
            .post(`sales/kickaffiliate`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                alert("Affiliate successfully kicked")
                this.setState({
                    loading:false,
                })
                this.getAgentList()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Session token is invalid. Please log in again.")
                        history.push("/")
                        return
                    }else if(error.response.status==401){
                        alert("You do not have permission to access agent list")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Kick affiliate error\n"+error)
                this.setState({loading:false})
            })
    }

    kickAmbassador=(ambassador)=>{
        if(!window.confirm("Are you sure you want to kick this ambassador out of the company?")){
            return
        }

        this.setState({loading:true})
        const payload = {
            ambassadorID:ambassador.id,
        }
        axios
            .post(`sales/kickambassador`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                alert("Ambassador successfully kicked")
                this.setState({
                    loading:false,
                })
                this.getAgentList()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Session token is invalid. Please log in again.")
                        history.push("/")
                        return
                    }else if(error.response.status==401){
                        alert("You do not have permission to access agent list")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Kick ambassador error\n"+error)
                this.setState({loading:false})
            })
    }

    checkCensored=(columnName)=>{
        for(let col of this.state.censoredColumn){
            if(col==columnName){
                return true
            }
        }
        return false
    }

    checkAdvisor=()=>{
        if(this.state.companyDetail!=null&&reactLocalStorage.get("advisorCompany")!=null&& 
            reactLocalStorage.get("advisorCompany").includes(this.state.companyDetail.name)){
            return true
        }
        return false
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal isOpen={this.state.changePICModal} toggle={()=>this.setState({changePICModal:false})} style={{maxWidth:500}}>
                    {
                    this.state.agentList!=null?
                        <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                            <div style={{fontSize:20,fontWeight:"bold"}}>Change PIC</div>
                            <div style={{display:"flex",flexDirection:"column",flexWrap:"wrap",width:"100%",marginTop:10}}>
                                <div>Client username</div>
                                <Input placeHolder="Phone number" style={{}} value={this.state.changePICClientUsername} onChange={(e)=>{
                                    this.setState({changePICClientUsername:e.target.value.replace(/\D/g,"")})
                                }} />
                                <div>Agent</div>
                                <select onChange={(e)=>{
                                    this.setState({changePICAgentID:e.target.value})
                                }}>
                                    {
                                    this.state.agentList.map((option,ind)=>{
                                        return(
                                            <option value={option.id}>{option.phone+" "+option.name}</option>
                                        )
                                    })
                                    }
                                </select>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:20}}>
                                <Button color="primary" style={{flex:1}} onClick={()=>{
                                    this.changePICFunction()
                                }}>
                                    Confirm
                                </Button>
                                <Button style={{marginLeft:10,border:"none",backgroundColor:"salmon",flex:1}} onClick={()=>{
                                    this.setState({changePICModal:false})
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                        :
                        null
                    }
                </Modal>

                <Modal isOpen={this.state.rosterHistoryModal} toggle={()=>this.setState({rosterHistoryModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>
                            Roster History
                        </div>
                        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                            {
                            this.state.rosterHistoryList.map((rosterHistory)=>{
                                return(
                                    <div style={{border:"solid 1px grey",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                            <div>{rosterHistory.createDate}</div>
                                        </div>
                                        <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-end",width:"100%",fontSize:13}}>
                                            <div>Client: {
                                                rosterHistory.client.username==rosterHistory.client.name?
                                                    rosterHistory.client.name
                                                    :
                                                    rosterHistory.client.username+" "+rosterHistory.client.name
                                                }
                                            </div>
                                            <div>PIC Employee: {rosterHistory.employeeString}</div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div>
                            {this.state.rosterHistoryPageCount > 0 && (
                                <div style={this.state.width > 600 ? {display: "flex", justifyContent: "flex-end", marginTop: 10} : {display: "flex", justifyContent: "flex-end", marginTop: 10, fontSize: "0.7rem"}}>
                                    <ReactPaginate
                                        disableInitialCallback={true}   //stop auto reload upon mount
                                        initialPage={this.state.rosterHistoryPage-1}
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        pageCount={this.state.rosterHistoryPageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handleRosterHistoryPageClick}
                                        subContainerClassName={'pages pagination'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        forcePage={this.state.rosterHistoryPage-1}
                                    />
                                </div>
                            )}
                        </div>
                        <div style={{marginTop:10,width:"100%"}}>
                            <Button style={{}} onClick={()=>{
                                this.setState({rosterHistoryModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.setRosterSequenceModal} toggle={()=>this.setState({setRosterSequenceModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                        <div>Set Roster Sequence</div>
                        <div style={{display:"flex",flexDirection:"column",flexWrap:"wrap",width:"100%",marginTop:10}}>
                            <Input value={this.state.setRosterSequence} onChange={(e)=>{
                                this.setState({
                                    setRosterSequence:e.target.value,
                                })
                            }} />
                        </div>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:20}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.setAgentRosterSequence()
                            }}>
                                Confirm
                            </Button>
                            <Button style={{marginLeft:10,border:"none",backgroundColor:"salmon",flex:1}} onClick={()=>{
                                this.setState({setRosterSequenceModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.selectInviteMasterModal} toggle={()=>this.setState({selectInviteMasterModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                        <div>Select Master of this Agent</div>
                        <div style={{display:"flex",flexDirection:"column",flexWrap:"wrap",width:"100%",gap:5,marginTop:10}}>
                            {
                            this.state.agentList.map((agent)=>{
                                return(
                                    <div style={{border:"1px solid lightgrey",borderRadius:5,padding:10,wdith:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                        <div>{agent.phone} {agent.name} ({agent.tier.name})</div>
                                        {
                                        agent.tier.tier==0?
                                            <Button color="secondary" onClick={()=>{
                                                alert("合作顾问不能带任何下线")
                                            }}>
                                                Select
                                            </Button>
                                            :
                                            <Button color="primary" onClick={()=>{
                                                this.setState({inviteAgentMaster:agent,selectInviteMasterModal:false})
                                            }}>
                                                Select
                                            </Button>
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.selectMasterModal} toggle={()=>this.setState({selectMasterModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                        <div>Select Master of {this.state.selectedAgent!=null?this.state.selectedAgent.phone+" "+this.state.selectedAgent.name:null}</div>
                        <div style={{display:"flex",flexDirection:"column",flexWrap:"wrap",width:"100%",gap:5,marginTop:10}}>
                            <div style={{border:"1px solid lightgrey",borderRadius:5,padding:10,wdith:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                <div style={{color:"grey"}}>Set to NONE</div>
                                <Button color="primary" onClick={()=>{
                                    this.selectMasterForAgent(null)
                                }}>
                                    Select
                                </Button>
                            </div>
                            {
                            this.state.selectedAgent!=null&&this.state.agentList.map((agent)=>{
                                if(agent.phone!=this.state.selectedAgent.phone){
                                    return(
                                        <div style={{border:"1px solid lightgrey",borderRadius:5,padding:10,wdith:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                            <div>{agent.phone} {agent.name} ({agent.tier.name})</div>
                                            {
                                            agent.tier.tier==0?
                                                <Button color="secondary" onClick={()=>{
                                                    alert("合作顾问不能带任何下线")
                                                }}>
                                                    Select
                                                </Button>
                                                :
                                                <Button color="primary" onClick={()=>{
                                                    this.selectMasterForAgent(agent)
                                                }}>
                                                    Select
                                                </Button>
                                            }
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.agentTierModal} toggle={()=>this.setState({agentTierModal:false})} style={{maxWidth:900}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                        <div>Set Agent Tier</div>
                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5,justifyContent:"center"}}>
                        {
                        this.state.agentTierList.map((tier)=>{return(
                            <div style={{display:"flex",flexDirection:"row",border:"1px solid lightgrey",width:"100%",marginBottom:5,padding:10,borderRadius:10}}>
                                <div style={{flex:1,display:"flex",flexDirection:"column"}}>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",alignItems:"center"}}>
                                        <div style={{fontWeight:"bold"}}>{tier.name}</div>
                                        <Button color="primary" style={{margin:0,padding:"2px 10px",marginLeft:10}} onClick={()=>{
                                            if(this.state.agentTierMode=="edit"){
                                                this.setAgentTier(tier)
                                            }
                                            // else if(this.state.agentTierMode=="invite"){
                                            //     this.setState({
                                            //         inviteAgentTier:tier,
                                            //         agentTierModal:false,
                                            //     })
                                            // }
                                        }}>
                                            Select
                                        </Button>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",gap:5,fontSize:13,marginTop:10,flexWrap:"wrap",paddingBottom:5,alignItems:"center",justifyContent:"center"}}>
                                        <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                            <div style={{whiteSpace:"nowrap"}}>前端课commission: {tier.commission}%</div>
                                            {
                                            this.state.companyDetail!=null?
                                                <div style={{whiteSpace:"nowrap"}}>模式三力commission: {tier.spCommission}%</div>
                                                :
                                                null
                                            }
                                            <div style={{whiteSpace:"nowrap"}}>原始流量commission: 0~15%</div>
                                        </div>
                                        {
                                        (tier.frontendPersonalCommission==true||tier.backendPersonalCommission==true)&&(tier.frontendGroupCommission==true||tier.backendGroupCommission==true)?
                                            <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                                <div style={{whiteSpace:"nowrap"}}>个人绩效目标: RM{tier.personalGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div style={{whiteSpace:"nowrap"}}>个人绩效工资: RM{tier.personalGoalReward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div>OR</div>
                                                <div style={{whiteSpace:"nowrap"}}>团队绩效目标: RM{tier.groupGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div style={{whiteSpace:"nowrap"}}>团队绩效工资: RM{tier.groupGoalReward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            </div>
                                            :
                                        tier.frontendPersonalCommission==true||tier.backendPersonalCommission==true?
                                            <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                                <div style={{whiteSpace:"nowrap"}}>个人绩效目标: RM{tier.personalGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div style={{whiteSpace:"nowrap"}}>个人绩效工资: RM{tier.personalGoalReward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            </div>
                                            :
                                        tier.frontendGroupCommission==true||tier.backendGroupCommission==true?
                                            <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                                <div style={{whiteSpace:"nowrap"}}>团队绩效目标: RM{tier.groupGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div style={{whiteSpace:"nowrap"}}>团队绩效工资: RM{tier.groupGoalReward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            </div>
                                            :
                                            null
                                        }
                                        {/*
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",border:"1px solid lightgrey",flexWrap:"nowrap",
                                            padding:5,borderRadius:5}}>
                                            <div style={{marginRight:5}}>Commission Overriding</div>
                                            {
                                            tier.commissionOverriding==true?
                                                <ImCheckmark style={{color:"green"}}/>
                                                :
                                                <ImCross style={{color:"red"}} />
                                            }
                                        </div>
                                        */}
                                        {
                                        tier.validForLeadershipBonus?
                                            <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                                <div style={{whiteSpace:"nowrap"}}>前端课Leadership Bonus: {tier.frontendLeadershipBonus}%</div>
                                                <div style={{whiteSpace:"nowrap"}}>模式三力Leadership Bonus: {tier.backendLeadershipBonus}%</div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div style={{fontSize:13,textAlign:"left",flex:1}}>
                                    {
                                    tier.requirement!=null?
                                        tier.requirement.split("\n").map((line)=>{
                                            return(
                                                <div>{line}</div>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        )})
                        }
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.copyModal} toggle={()=>this.setState({copyModal:false})} style={{marginTop:"5vh",marginBottom:"5vh",maxHeight:"90vh",maxWidth:350}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                        <textarea value={this.getShareString()}
                            style={{fontSize:12,width:"100%",height:100,marginBottom:5,borderStyle:"solid",borderWidth:1,borderColor:"lightgrey",borderRadius:5}} />
                        <Button color="secondary" style={{marginBottom:20,width:"100%",padding:5,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}
                            onClick={()=>{
                                GLOBAL.copyTextToClipboard(this.getShareString())
                            }}>
                            <FaCopy style={{marginRight:5}} />
                            <div>Copy</div>
                        </Button>

                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",marginBottom:15}}>
                            <a href={"whatsapp://send?text="+this.getShareStringInURL()+"&amp;url="+this.getShareLink()} target="_blank" rel="noopener"
                                style={{display:"flex",flexDirection:"column",alignItems:"center",marginRight:10}}>
                                <div style={{width:50,height:50,display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",backgroundColor:"#25D366"}}>
                                    <div style={{height:30,width:30}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{fill:"white"}}>
                                            <path d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div style={{fontSize:10}}>Whatsapp</div>
                            </a>

                            <a href={"https://telegram.me/share/url?url=👏👏👏&text="+this.getShareStringInURL()} target="_blank" rel="noopener"
                                style={{display:"flex",flexDirection:"column",alignItems:"center",marginRight:10}}>
                                <div style={{width:50,height:50,display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%",backgroundColor:"#54A9EB"}}>
                                    <div style={{height:25,width:25}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{fill:"white"}}>
                                            <path d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div style={{fontSize:10}}>Telegram</div>
                            </a>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.inviteAgentModal} toggle={()=>this.setState({inviteAgentModal:false})} style={{marginTop:"5vh",marginBottom:"5vh",maxHeight:"90vh"}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"flex-start",borderRadius:5}}>
                        <div style={{}}>Phone number:</div>
                        <Input type="text" placeholder="0112345678" onChange={(e)=>{
                            this.setState({agentPhone:e.target.value.replace(/\D/g,"")})
                        }}/>
                        {
                        this.state.companyDetail!=null?
                            <div>
                                <div>Tier</div>
                                <div>
                                    {
                                    this.state.companyDetail.defaultAgentTier!=null?
                                        this.state.companyDetail.defaultAgentTier.name
                                        :
                                        "合作顾问"
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                        this.state.companyDetail!=null&&this.checkCensored("Master")==false?
                            <div>
                                <div>Master</div>
                                <div>
                                    {
                                    this.state.inviteAgentMaster!=null?
                                        this.state.inviteAgentMaster.phone+" "+this.state.inviteAgentMaster.name+" - "+this.state.inviteAgentMaster.tier.name
                                        :
                                        "-"
                                    }
                                    <Button style={{marginLeft:5,}} onClick={()=>{
                                        this.setState({selectInviteMasterModal:true})
                                    }}>
                                        Edit
                                    </Button>
                                </div>
                            </div>
                            :
                            null
                        }
                        <Button color="success" style={{width:"100%",marginTop:20}} onClick={()=>{
                            this.inviteAgent()
                        }}>
                            Invite
                        </Button>
                    </div>
                </Modal>

                <Modal isOpen={this.state.inviteAmbassadorModal} toggle={()=>this.setState({inviteAmbassadorModal:false})} style={{marginTop:"5vh",marginBottom:"5vh",maxHeight:"90vh"}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"flex-start",borderRadius:5}}>
                        <div style={{}}>Phone number:</div>
                        <Input type="text" placeholder="0112345678" onChange={(e)=>{
                            this.setState({ambassadorPhone:e.target.value.replace(/\D/g,"")})
                        }}/>
                        <Button color="success" style={{width:"100%",marginTop:20}} onClick={()=>{
                            this.inviteAmbassador()
                        }}>
                            Invite
                        </Button>
                    </div>
                </Modal>

                <Modal isOpen={this.state.inviteAffiliateModal} toggle={()=>this.setState({inviteAffiliateModal:false})} style={{marginTop:"5vh",marginBottom:"5vh",maxHeight:"90vh"}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"flex-start",borderRadius:5}}>
                        <div style={{}}>Phone number:</div>
                        <Input type="text" placeholder="0112345678" onChange={(e)=>{
                            this.setState({affiliatePhone:e.target.value.replace(/\D/g,"")})
                        }}/>
                        <Button color="success" style={{width:"100%",marginTop:20}} onClick={()=>{
                            this.inviteAffiliate()
                        }}>
                            Invite
                        </Button>
                    </div>
                </Modal>

                <Modal isOpen={this.state.openBankModal} toggle={()=>this.setState({openBankModal:false})} style={{marginTop:"5vh",marginBottom:"5vh",maxHeight:"90vh"}}>
                <ModalHeader toggle={()=>this.setState({openBankModal:false})}>Bank Details</ModalHeader>
                <div style={{padding:15, paddingTop:10, borderRadius:5}}>
                <div style={{display:"flex", justifyContent:"space-between", marginBottom: 10}}>
                    <p>Bank Account Name:   {this.state.affiliateBankAccountName}</p>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", marginBottom: 10}}>
                    <p>Bank Account Number:   {this.state.affiliateBankAccountNumber}</p>
                </div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <p>Bank Name:   {this.state.affiliateBankName}</p>
                </div>
                </div>
                </Modal>


                <NavigationBar currentPage="agents" />
                <div style={{padding:"10px 10px 40px 10px",display:"flex",flexDirection:"column",width:"100%",gap:10}}>
                    {
                    this.state.companyDetail?
                        <div style={{display:"flex",flexDirection:window.innerWidth>600?"row":"column",flexWrap:"wrap",gap:20,
                            width:"100%",border:"solid 1px lightgrey",padding:10,borderRadius:10}}>
                            <div style={{}}>
                                <div>
                                    Company Name: {this.state.companyDetail.name}
                                </div>
                                <div>Type: {this.state.companyDetail.type=="service"?"Service Agency":"Referral Agency"}</div>
                                <div>SSM Number: {this.state.companyDetail.ssmNumber}</div>
                                <div style={{whiteSpace:"nowrap"}}>Director: {this.state.companyDetail.directorUsername+" "+this.state.companyDetail.directorName}</div>
                                <div style={{}}>Advisor: {this.state.companyDetail.advisorString}</div>
                                <div>Join Date: {this.state.companyDetail.createDate}</div>
                                {/*<div>Commission: {this.state.companyDetail.commission}% / {this.state.companyDetail.spCommission}%</div>*/}
                            </div>
                            <div style={{}}>
                                <div>Tax Number: {this.state.companyDetail.taxNumber}</div>
                                <div>Bank Account Name: {this.state.companyDetail.bankAccountName}</div>
                                <div>Bank Account Number: {this.state.companyDetail.bankAccountNumber}</div>
                                <div>Bank Name: {this.state.companyDetail.bankName}</div>
                            </div>
                            {
                            this.state.companyDetail!=null?
                                <div style={{width:"100%"}}>
                                    <div>Company share links:</div>
                                    <div style={{gap:5,display:"flex",flexDirection:"row",flexWrap:"wrap",width:"100%"}}>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            if(this.state.companyDetail.systemClassLink!=null){ //specific landing page for sepcial agent company
                                                GLOBAL.copyTextToClipboard("https://enlliance.com/signup?ac="+this.state.companyDetail.companyCode+"&redirect="+this.state.companyDetail.systemClassLink)
                                            }else{
                                                GLOBAL.copyTextToClipboard("https://enlliance.com/signup?ac="+this.state.companyDetail.companyCode+"&redirect=system")
                                            }
                                        }}>
                                            Sign Up then 系统管理
                                        </Button>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            if(this.state.companyDetail.systemClassLink!=null){ //specific landing page for sepcial agent company
                                                GLOBAL.copyTextToClipboard("https://enlliance.com/"+this.state.companyDetail.systemClassLink+"?ac="+this.state.companyDetail.companyCode)
                                            }else{
                                                GLOBAL.copyTextToClipboard("https://enlliance.com/system?ac="+this.state.companyDetail.companyCode)
                                            }
                                        }}>
                                            系统管理
                                        </Button>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            if(this.state.companyDetail.franchiseClassLink!=null){  //specific landing page for sepcial agent company
                                                GLOBAL.copyTextToClipboard("https://enlliance.com/"+this.state.companyDetail.franchiseClassLink+"?ac="+this.state.companyDetail.companyCode)
                                            }else{
                                                GLOBAL.copyTextToClipboard("https://enlliance.com/franchise?ac="+this.state.companyDetail.companyCode)
                                            }
                                        }}>
                                            连锁系统
                                        </Button>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            GLOBAL.copyTextToClipboard("https://enlliance.com/strategic?ac="+this.state.companyDetail.companyCode)
                                        }}>
                                            战略三力
                                        </Button>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            GLOBAL.copyTextToClipboard("https://enlliance.com/marketing?ac="+this.state.companyDetail.companyCode)
                                        }}>
                                            营销三力
                                        </Button>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            GLOBAL.copyTextToClipboard("https://enlliance.com/organization?ac="+this.state.companyDetail.companyCode)
                                        }}>
                                            组织三力
                                        </Button>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            GLOBAL.copyTextToClipboard("https://enlliance.com/deal?ac="+this.state.companyDetail.companyCode)
                                        }}>
                                            顺势开单
                                        </Button>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            GLOBAL.copyTextToClipboard("https://enlliance.com/khgl?ac="+this.state.companyDetail.companyCode)
                                        }}>
                                            客户管理
                                        </Button>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            GLOBAL.copyTextToClipboard("https://enlliance.com/gxyy?ac="+this.state.companyDetail.companyCode)
                                        }}>
                                            高效运营
                                        </Button>
                                    </div>
                                    {
                                    GLOBAL.checkPermission("4j:-6CBP")?   //change PIC
                                        <div style={{width:"100%"}}>
                                            <div>Operations:</div>
                                            <div>
                                                <Button onClick={()=>{
                                                    this.setState({changePICModal:true,changePICAgentID:this.state.agentList[0].id})
                                                }}>
                                                    Change PIC
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                        :null
                    }

                    {
                    this.state.companyDetail!=null&&this.state.companyDetail.type=="service"?
                        <div style={{width:"100%"}}>
                            <div>课程顾问 Agents</div>
                            {
                            window.innerWidth > 600 ?
                                <table style={{width:"100%",fontSize:13}}>
                                    <tr>
                                        <td style={{border:"solid 1px grey",padding:"0px 10px"}}>Phone</td>
                                        <td style={{border:"solid 1px grey",padding:"0px 10px"}}>Name</td>
                                        {
                                        this.checkCensored("Tier")?
                                            null
                                            :
                                            <td style={{border:"solid 1px grey",padding:"0px 10px"}}>Tier</td>
                                        }
                                        <td style={{border:"solid 1px grey",textAlign:"center"}}>Personal</td>
                                        {
                                        this.checkCensored("Group")?
                                            null
                                            :
                                            <td style={{border:"solid 1px grey",textAlign:"center"}}>Group</td>
                                        }
                                        <td style={{border:"solid 1px grey",textAlign:"center"}}>Join Date</td>
                                        {
                                        this.checkCensored("Master")?
                                            null
                                            :
                                            <td style={{border:"solid 1px grey",textAlign:"center"}}>Master</td>
                                        }
                                        <td style={{border:"solid 1px grey",textAlign:"center"}}>
                                            Roster<BsFillInfoCircleFill id="rosterTooltip" style={{color:"skyblue",cursor:"pointer",marginLeft:5}} onClick={()=>{
                                                this.setState({rosterTooltip:true})
                                            }}/>
                                            <FaDatabase style={{color:"grey",marginLeft:5,cursor:"pointer"}} onClick={()=>{
                                                this.loadRosterHistory()
                                            }} />
                                            <Tooltip placement="right" isOpen={this.state.rosterTooltip} target="rosterTooltip" toggle={()=>this.setState({rosterTooltip:false})}>
                                                Set to -1 to disable roster. Roster is queue of agents to receive company generated leads, using referral links from buttons on top of this page.
                                            </Tooltip>
                                        </td>
                                        <td style={{border:"solid 1px grey",textAlign:"center"}}>Members in Charge</td>
                                        <td></td>
                                    </tr>
                                    {
                                    this.state.agentList.map((agent)=>{
                                        return(
                                            <tr style={{backgroundColor: agent.active ? "#FFFFFF" : "#F5F5F5"}}>
                                                <td style={{border:"solid 1px lightgrey",padding:"0px 10px"}}>
                                                    {
                                                    agent.memberID==reactLocalStorage.get("id")?
                                                        <AiOutlineUser style={{color:"grey", marginRight:5, verticalAlign:"-2px"}}/>
                                                        :
                                                    agent.active?
                                                        <FaCheckCircle  style={{color:"green", marginRight:5, cursor:"pointer", verticalAlign:"-2px"}} onClick={()=>{
                                                            this.deactivateAgent(agent)
                                                        }}/>
                                                        :
                                                        <FaCircle style={{color:"orange", marginRight:5, cursor:"pointer", verticalAlign:"-2px"}} onClick={()=>{
                                                            this.activateAgent(agent)
                                                        }}/>
                                                    }
                                                    {agent.phone}
                                                </td>
                                                <td style={{border:"solid 1px lightgrey",padding:"0px 10px",position: "relative"}}>
                                                    {agent.name}
                                                    {
                                                    agent.jobName=="Agent Leader"?
                                                        <span color="light" style={{border:"solid 1px salmon",textAlign:"center",fontSize:"10px", padding:"1px",color:"salmon",position:"absolute",top:0,right:0}}>
                                                            Leader
                                                        </span>
                                                        :null
                                                    }
                                                </td>
                                                {
                                                this.checkCensored("Tier")?
                                                    null
                                                    :
                                                    <td style={{ border: "solid 1px lightgrey", padding: "0px 10px"}}>
                                                        {
                                                        agent.tier!=null?
                                                            <>
                                                                {agent.tier.name}, {agent.tier.commission}% / {agent.tier.spCommission}%
                                                            </>
                                                            : 
                                                            "-"
                                                        }
                                                        {
                                                        reactLocalStorage.get("job") === "Sales Supervisor" ||reactLocalStorage.get("job") === "Sales" ?
                                                            <Button style={{ padding: "5px 10px", lineHeight: 1, marginLeft: 5, fontSize: 13 }}
                                                                onClick={() => {
                                                                this.setState({selectedAgent: agent,},() => {
                                                                        this.loadAgentTierList("edit");
                                                                    });
                                                                }}
                                                            >
                                                            Edit
                                                            </Button>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                }
                                                <td style={{border:"solid 1px lightgrey",textAlign:"center"}}>
                                                    {
                                                    agent.tier!=null&&agent.tier.personalGoal>0?
                                                        <div style={{color:agent.monthlyPersonalSales>=agent.tier.personalGoal?"green":"black"}}>
                                                            {agent.monthlyPersonalSales.toLocaleString()}/{agent.tier.personalGoal.toLocaleString()}
                                                        </div>
                                                        :
                                                        "-"
                                                    }
                                                </td>
                                                {
                                                this.checkCensored("Group")?
                                                    null
                                                    :
                                                    <td style={{border:"solid 1px lightgrey",textAlign:"center"}}>
                                                        {
                                                        agent.tier!=null&&agent.tier.groupGoal>0?
                                                            <div style={{color:agent.monthlyGroupSales>=agent.tier.groupGoal?"green":"black"}}>
                                                                {agent.monthlyGroupSales.toLocaleString()}/{agent.tier.groupGoal.toLocaleString()}
                                                            </div>
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                }
                                                <td style={{border:"solid 1px lightgrey",textAlign:"center"}}>{agent.joinDate}</td>
                                                {
                                                this.checkCensored("Master")?
                                                    null
                                                    :
                                                    <td style={{border:"solid 1px lightgrey",textAlign:"right",padding:"0px 20px",textAlign:"center"}}>
                                                        {
                                                        agent.masterUsername!=null?
                                                            agent.masterUsername+" "+agent.masterName+" ("+agent.masterTier+")"
                                                            :
                                                            "-"
                                                        }
                                                        <Button style={{padding:"5px 10px",lineHeight:1,marginLeft:5,fontSize:13}} onClick={()=>{
                                                            this.setState({
                                                                selectMasterModal:true,
                                                                selectedAgent:agent,
                                                            })
                                                        }}>
                                                            Edit
                                                        </Button>
                                                    </td>
                                                }
                                                <td style={{border:"solid 1px lightgrey",textAlign:"right",padding:"0px 20px"}}>
                                                    {agent.rosterSequence}
                                                    {
                                                    agent.id!=this.state.companyDetail.picAccount?
                                                        <Button style={{padding:"5px 10px",lineHeight:1,marginLeft:5,fontSize:13}} onClick={()=>{
                                                            this.setState({
                                                                setRosterSequenceModal:true,
                                                                setRosterSequence:agent.rosterSequence,
                                                                selectedAgent:agent,
                                                            })
                                                        }}>
                                                            Edit
                                                        </Button>
                                                        :
                                                        null
                                                    }
                                                </td>
                                                <td style={{border:"solid 1px lightgrey",textAlign:"right",padding:"0px 20px"}}>
                                                    <div style={{cursor:"pointer",color:"steelblue",fontWeight:"bold"}} onClick={()=>{
                                                        history.push("/saleslog?search=PIC:"+agent.phone)
                                                    }}>
                                                        {agent.referCount}
                                                    </div>
                                                </td>
                                                <td stlye={{display:"flex",flexDirection:"row"}}>
                                                    <Button color="primary" style={{marginLeft:5}} onClick={()=>{
                                                        history.push("/achievement?agent="+agent.id)
                                                    }}>
                                                        View Achievement
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </table>
                                :
                                this.state.agentList.map((agent)=>{
                                    return(
                                        <div style={{border: "1px solid lightgrey",padding: 5,position: "relative"}}>
                                            <div>{agent.phone} {agent.name}</div>
                                            <div>Tier:
                                                {
                                                agent.tier!=null?
                                                    agent.tier.name+", "+agent.tier.commission+"% / "+agent.tier.spCommission+"%"
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div>Join Date: {agent.joinDate}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        null
                    }

                    <div style={{marginTop:10}}>联盟伙伴 Affiliate</div>
                    {
                    window.innerWidth > 600 ?
                        <table style={{width:"100%",fontSize:13}}>
                            <tr>
                                <td style={{border:"solid 1px grey",padding:"0px 10px"}}>Phone</td>
                                <td style={{border:"solid 1px grey",padding:"0px 10px"}}>Name</td>
                                <td style={{border:"solid 1px grey",textAlign:"center"}}>Join Date</td>
                                <td style={{border:"solid 1px grey",textAlign:"center"}}>PIC</td>
                                <td style={{border:"solid 1px grey",textAlign:"center"}}>Referred Member</td>
                                <td></td>
                            </tr>
                            {
                            this.state.affiliateList.map((agent)=>{
                                return(
                                    <tr>
                                        <td style={{border:"solid 1px lightgrey",padding:"0px 10px"}}>{agent.phone}</td>
                                        <td style={{border:"solid 1px lightgrey",padding:"0px 10px",position: "relative"}}>
                                            {agent.name}
                                            {
                                            agent.jobName=="Agent Leader"?
                                                <span color="light" style={{border:"solid 1px salmon",textAlign:"center",fontSize:"10px", padding:"1px",color:"salmon",position:"absolute",top:0,right:0}}>
                                                    Leader
                                                </span>
                                                :null
                                            }
                                        </td>
                                        <td style={{border:"solid 1px lightgrey",textAlign:"center"}}>{agent.joinDate}</td>
                                        <td style={{border:"solid 1px lightgrey",textAlign:"center"}}>{agent.personInCharge}</td>
                                        <td style={{border:"solid 1px lightgrey",textAlign:"right",padding:"0px 20px"}}>
                                            <div style={{cursor:"pointer",color:"steelblue",fontWeight:"bold"}} onClick={()=>{
                                                history.push("/saleslog?search=referrer:"+agent.phone)
                                            }}>
                                                {agent.referCount}
                                            </div>
                                        </td>
                                        <td stlye={{display:"flex",flexDirection:"row"}}>
                                            <Button color="primary" style={{marginLeft:5}} onClick={()=>{
                                                history.push("/saleslog?username="+agent.phone)
                                            }}>
                                                View Profile
                                            </Button>
                                            <Button color="primary" style={{marginLeft:10}} onClick={()=>{
                                             this.handleBankDetailsClick(agent)
                                            }}>
                                                Bank Details
                                            </Button>
                                            {
                                            GLOBAL.checkPermission("/Wg}}tfE")&&!this.checkAdvisor()?
                                                <Button color="warning" style={{marginLeft:10}} onClick={()=>{
                                                    this.kickAffiliate(agent)
                                                }}>
                                                    Kick Affiliate
                                                </Button>
                                                :
                                                null
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </table>
                        :
                        this.state.affiliateList.map((agent)=>{
                            return(
                                <div style={{border: "1px solid lightgrey",padding: 5,position: "relative"}}>
                                    <div>{agent.phone} {agent.name}</div>
                                    <div>Tier:
                                        {
                                        agent.tier!=null?
                                            agent.tier.name+", "+agent.tier.commission+"% / "+agent.tier.spCommission+"%"
                                            :
                                            "-"
                                        }
                                    </div>
                                    <div>Join Date: {agent.joinDate}</div>
                                </div>
                            )
                        })
                    }

                    {
                    this.state.companyDetail!=null?
                        this.state.companyDetail.hideAmbassador==true?
                            null
                            :
                            <div style={{width:"100%"}}>
                                <div style={{marginTop:10}}>宣传大使 Ambassador</div>
                                {
                                window.innerWidth > 600 ?
                                    <table style={{width:"100%",fontSize:13}}>
                                        <tr>
                                            <td style={{border:"solid 1px grey",padding:"0px 10px"}}>Phone</td>
                                            <td style={{border:"solid 1px grey",padding:"0px 10px"}}>Name</td>
                                            <td style={{border:"solid 1px grey",textAlign:"center"}}>Join Date</td>
                                            <td style={{border:"solid 1px grey",textAlign:"center"}}>PIC</td>
                                            <td style={{border:"solid 1px grey",textAlign:"center"}}>Referred Member</td>
                                            <td></td>
                                        </tr>
                                        {
                                        this.state.ambassadorList.map((agent)=>{
                                            return(
                                                <tr>
                                                    <td style={{border:"solid 1px lightgrey",padding:"0px 10px"}}>{agent.phone}</td>
                                                    <td style={{border:"solid 1px lightgrey",padding:"0px 10px"}}>{agent.name}</td>
                                                    <td style={{border:"solid 1px lightgrey",textAlign:"center"}}>{agent.joinDate}</td>
                                                    <td style={{border:"solid 1px lightgrey",textAlign:"center"}}>{agent.personInCharge}</td>
                                                    <td style={{border:"solid 1px lightgrey",textAlign:"right",padding:"0px 20px"}}>
                                                        <div style={{cursor:"pointer",color:"steelblue",fontWeight:"bold"}} onClick={()=>{
                                                            history.push("/saleslog?search=referrer:"+agent.phone)
                                                        }}>
                                                            {agent.referCount}
                                                        </div>
                                                    </td>
                                                    <td stlye={{display:"flex",flexDirection:"row"}}>
                                                        <Button color="primary" style={{marginLeft:5}} onClick={()=>{
                                                            history.push("/saleslog?username="+agent.phone)
                                                        }}>
                                                            View Profile
                                                        </Button>
                                                        {
                                                        GLOBAL.checkPermission("^cQnRq_.")&&!this.checkAdvisor()?
                                                            <Button color="warning" style={{marginLeft:10}} onClick={()=>{
                                                                this.kickAmbassador(agent)
                                                            }}>
                                                                Kick Ambassador
                                                            </Button>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </table>
                                    :
                                    this.state.ambassadorList.map((agent)=>{
                                        return(
                                            <div style={{border: "1px solid lightgrey",padding: 5,position: "relative"}}>
                                                <div>{agent.phone} {agent.name}</div>
                                                <div>Tier:
                                                    {
                                                    agent.tier!=null?
                                                        agent.tier.name+", "+agent.tier.commission+"% / "+agent.tier.spCommission+"%"
                                                        :
                                                        "-"
                                                    }
                                                </div>
                                                <div>Join Date: {agent.joinDate}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        :
                        null
                    }

                    <div style={{marginTop:15}}>Invitations</div>

                    {
                    this.state.companyDetail!=null&&this.state.companyDetail.type=="service"?
                        <div style={{width:"100%"}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:10,marginBottom:5}}>
                                <div style={{}}>课程顾问 Agent</div>
                                {
                                (this.companyID==null&&GLOBAL.checkPermission("%h?42M9&"))||this.checkAdvisor()?    //invite agent
                                    <Button color="primary" style={{marginLeft:10,padding:"5px 10px",lineHeight:1,fontSize:13}} onClick={()=>{
                                        this.setState({inviteAgentModal:true,inviteAgentMaster:null})
                                    }}>
                                        Invite Agent
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                            {
                            window.innerWidth > 600 ?
                                <table style={{width:"100%"}}>
                                    <tr>
                                        <td style={{border:"solid 1px grey"}}>Phone</td>
                                        {
                                        this.state.companyDetail!=null&&this.checkCensored("Tier")==false?
                                            <td style={{border:"solid 1px grey"}}>Tier</td>
                                            :
                                            null
                                        }
                                        {
                                        this.state.companyDetail!=null&&this.checkCensored("Master")==false?
                                            <td style={{border:"solid 1px grey"}}>Master</td>
                                            :
                                            null
                                        }
                                        <td style={{border:"solid 1px grey"}}>Link</td>
                                        <td style={{border:"solid 1px grey"}}>Create Date</td>
                                        <td></td>
                                    </tr>
                                    {
                                    this.state.invitationList.map((invitation)=>{
                                        return(
                                            <tr>
                                                <td style={{border:"solid 1px lightgrey"}}>{invitation.agentPhone}</td>
                                                {
                                                this.state.companyDetail!=null&&this.checkCensored("Tier")==false?
                                                    <td style={{border:"solid 1px lightgrey"}}>
                                                        {
                                                        invitation.tier!=null?
                                                            invitation.tier.name+", "+invitation.tier.commission+"% / "+invitation.tier.spCommission+"%"
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                    :
                                                    null
                                                }
                                                {
                                                this.state.companyDetail!=null&&this.checkCensored("Master")==false?
                                                    <td style={{border:"solid 1px lightgrey"}}>
                                                        {
                                                        invitation.master!=null?
                                                            invitation.master.username==invitation.master.name?
                                                                invitation.master.name+" - "+invitation.master.tierName
                                                                :
                                                                invitation.master.username+" "+invitation.master.name+" - "+invitation.master.tierName
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                    :
                                                    null
                                                }
                                                <td style={{ border: "solid 1px lightgrey" }} onClick={() => { GLOBAL.copyTextToClipboard(invitation.invitationLink) }}>
                                                    {invitation.invitationLink}
                                                    <AiOutlineCopy style={{ marginLeft: 5 }} />
                                                </td>
                                                <td style={{border:"solid 1px lightgrey"}}>{invitation.createDate}</td>
                                                <td>
                                                    <ImCross style={{marginLeft:5,cursor:"pointer"}} onClick={()=>{
                                                        this.deleteInvitation(invitation)
                                                    }} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </table>
                                :
                                this.state.invitationList.map((invitation)=>{
                                    return(
                                        <div style={{border: "1px solid lightgrey",padding: 5,position: "relative"}}>
                                            <div>Phone: {invitation.agentPhone}</div>
                                            {
                                            this.checkCensored("Tier")==false?
                                                <div>Tier:
                                                    {
                                                    invitation.tier!=null?
                                                        invitation.tier.name
                                                        :
                                                        "-"
                                                    }
                                                </div>
                                                :
                                                null
                                            }
                                            <div>Commission:
                                                {
                                                invitation.tier!=null?
                                                    invitation.tier.commission+"% / "+invitation.tier.spCommission+"%"
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div style={{display: "flex",}}>
                                                <div>Link: </div>
                                                <div style={{marginLeft: 5}} onClick={() => {GLOBAL.copyTextToClipboard(invitation.invitationLink)}}>
                                                    {invitation.invitationLink}
                                                    <AiOutlineCopy style={{marginLeft: 5}}/>
                                                </div>
                                            </div>
                                            <div>Create Date: {invitation.createDate}</div>
                                            <ImCross style={{position: "absolute",top: 0, right: 0, cursor:"pointer",margin: 5}} onClick={()=>{this.deleteInvitation(invitation)}} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        null
                    }

                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:10,marginBottom:5,}}>
                        <div style={{}}>联盟伙伴 Affiliate</div>
                        {
                        (this.companyID==null&&GLOBAL.checkPermission("79zYpomp"))||this.checkAdvisor()?    //Invite Affiliate
                            <Button color="primary" style={{marginLeft:10,padding:"5px 10px",lineHeight:1,fontSize:13}} onClick={()=>{
                                this.setState({inviteAffiliateModal:true})
                            }}>
                                Invite Affiliate
                            </Button>
                            :
                            null
                        }
                    </div>
                    {
                    window.innerWidth > 600 ?
                        <table style={{width:"100%"}}>
                            <tr>
                                <td style={{border:"solid 1px grey"}}>Phone</td>
                                {
                                this.state.companyDetail!=null&&this.checkCensored("Tier")==false?
                                    <td style={{border:"solid 1px grey"}}>Tier</td>
                                    :
                                    null
                                }
                                <td style={{border:"solid 1px grey"}}>Link</td>
                                <td style={{border:"solid 1px grey"}}>Create Date</td>
                                <td></td>
                            </tr>
                            {
                            this.state.affiliateInvitationList.map((invitation)=>{
                                return(
                                    <tr>
                                        <td style={{border:"solid 1px lightgrey"}}>{invitation.agentPhone}</td>
                                        {
                                        this.state.companyDetail!=null&&this.checkCensored("Tier")==false?
                                            <td style={{border:"solid 1px lightgrey"}}>
                                                {
                                                invitation.tier!=null?
                                                    invitation.tier.name
                                                    :
                                                    "-"
                                                }
                                            </td>
                                            :
                                            null
                                        }
                                        <td style={{border:"solid 1px lightgrey"}}
                                            onClick={() => {GLOBAL.copyTextToClipboard(invitation.invitationLink)}}>
                                            {invitation.invitationLink}
                                            <AiOutlineCopy style={{marginLeft: 5}}/>
                                        </td>
                                        <td style={{border:"solid 1px lightgrey"}}>{invitation.createDate}</td>
                                        <td>
                                            <ImCross style={{marginLeft:5,cursor:"pointer"}} onClick={()=>{
                                                this.deleteInvitation(invitation)
                                            }} />
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </table>
                        :
                        this.state.affiliateInvitationList.map((invitation)=>{
                            return(
                                <div style={{border: "1px solid lightgrey",padding: 5,position: "relative"}}>
                                    <div>Phone: {invitation.agentPhone}</div>
                                    <div style={{display: "flex",}}>
                                        <div>Link: </div>
                                        <div style={{marginLeft: 5}} onClick={() => {GLOBAL.copyTextToClipboard(invitation.invitationLink)}}>
                                            {invitation.invitationLink}
                                            <AiOutlineCopy style={{marginLeft: 5}}/>
                                        </div>
                                    </div>
                                    <div>Create Date: {invitation.createDate}</div>
                                    <ImCross style={{position: "absolute",top: 0, right: 0, cursor:"pointer",margin: 5}} onClick={()=>{this.deleteInvitation(invitation)}} />
                                </div>
                            )
                        })
                    }

                    {
                    this.state.companyDetail!=null?
                        this.state.companyDetail.hideAmbassador==true?
                            null
                            :
                            <div style={{width:"100%",}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:10,marginBottom:5,}}>
                                    <div style={{}}>宣传大使 Ambassador</div>
                                    {
                                    (this.companyID==null&&reactLocalStorage.get("job")=="Agent Leader")||this.checkAdvisor()?
                                        <Button color="primary" style={{marginLeft:10,padding:"5px 10px",lineHeight:1,fontSize:13}} onClick={()=>{
                                            this.setState({inviteAmbassadorModal:true})
                                        }}>
                                            Invite Ambassador
                                        </Button>
                                        :
                                        null
                                    }
                                </div>
                                {
                                window.innerWidth > 600 ?
                                    <table style={{width:"100%"}}>
                                        <tr>
                                            <td style={{border:"solid 1px grey"}}>Phone</td>
                                            {
                                            this.state.companyDetail!=null?
                                                <td style={{border:"solid 1px grey"}}>Tier</td>
                                                :
                                                null
                                            }
                                            <td style={{border:"solid 1px grey"}}>Link</td>
                                            <td style={{border:"solid 1px grey"}}>Create Date</td>
                                            <td></td>
                                        </tr>
                                        {
                                        this.state.ambassadorInvitationList.map((invitation)=>{
                                            return(
                                                <tr>
                                                    <td style={{border:"solid 1px lightgrey"}}>{invitation.agentPhone}</td>
                                                    {
                                                    this.state.companyDetail!=null?
                                                        <td style={{border:"solid 1px lightgrey"}}>
                                                            {
                                                            invitation.tier!=null?
                                                                invitation.tier.name
                                                                :
                                                                "-"
                                                            }
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                    <td style={{border:"solid 1px lightgrey"}}
                                                        onClick={() => {GLOBAL.copyTextToClipboard(invitation.invitationLink)}}>
                                                        {invitation.invitationLink}
                                                        <AiOutlineCopy style={{marginLeft: 5}}/>
                                                    </td>
                                                    <td style={{border:"solid 1px lightgrey"}}>{invitation.createDate}</td>
                                                    <td>
                                                        <ImCross style={{marginLeft:5,cursor:"pointer"}} onClick={()=>{
                                                            this.deleteInvitation(invitation)
                                                        }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </table>
                                    :
                                    this.state.ambassadorInvitationList.map((invitation)=>{
                                        return(
                                            <div style={{border: "1px solid lightgrey",padding: 5,position: "relative"}}>
                                                <div>Phone: {invitation.agentPhone}</div>
                                                {
                                                this.state.companyDetail!=null?
                                                    <div>Commission:
                                                        {
                                                        invitation.tier!=null?
                                                            invitation.tier.name
                                                            :
                                                            "-"
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div style={{display: "flex",}}>
                                                    <div>Link: </div>
                                                    <div style={{marginLeft: 5}} onClick={() => {GLOBAL.copyTextToClipboard(invitation.invitationLink)}}>
                                                        {invitation.invitationLink}
                                                        <AiOutlineCopy style={{marginLeft: 5}}/>
                                                    </div>
                                                </div>
                                                <div>Create Date: {invitation.createDate}</div>
                                                <ImCross style={{position: "absolute",top: 0, right: 0, cursor:"pointer",margin: 5}} onClick={()=>{this.deleteInvitation(invitation)}} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

Agents = Radium(Agents);

let styles={

}
