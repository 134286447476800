import React from 'react';
import {Input,Button,Alert,Row,Col,Table,Modal,Spinner} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'

import {BsFillInfoCircleFill} from 'react-icons/bs'
import {ImCross,ImCheckmark} from 'react-icons/im'
import {MdNavigateNext,MdNavigateBefore} from 'react-icons/md'

export default class Achievement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            requestDate:new Date().getFullYear()+"-"+('0' + (new Date().getMonth()+1)).slice(-2),
            achievementData:[],
            totalSales:0,

            monthAgentCommission:0,
            monthPersonalSales:0,
            monthGroupSales:0,
            monthPersonalCommission:0,
            monthGroupCommission:0,
            monthOverriding:0,
            monthLeadership:0,

            monthTotalCommission:0,
            monthReferral:0,

            agentTierModal:false,
            agentTierList:[],

            remarkModal:false,
            remarkText:"",

            editBankDetailModal:false,
            editBankName:null,
            editAccountName:null,
            editAccountNumber:null,

            employeeDetail:null,
            censoredColumn:[],
        }
        this.agentID=null
        this.month=["January","February","March","April","May","June","July","August","September","October","November","December"]
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        for(var input of inputSplit){
            let param=input.split("=")[0]
            let value=input.split("=")[1]
            if(param=="agent"){  //seek user by id
                this.agentID=value
            }
        }

        if(this.agentID){
            this.loadEmployeeAchievement(this.agentID)
        }else{
            this.loadEmployeeAchievement()
        }
    }

    loadEmployeeAchievement=(agentID="")=>{
        this.setState({loading:true})
        const payload = {
            agentID:agentID,
            requestDate:this.state.requestDate,
        }
        axios
            .post(`sales/loademployeeachievement`,qs.stringify(payload),{timeout:90000}) //with extra timeout
            .then(async response => {
                //parse date
                for(let historyDetail of response.data.logList){
                    let rawDateSplit=historyDetail.history.createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    historyDetail.history.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //calculate sales and commission of the month
                let monthAgentCommission=0
                let monthPersonalSales=0
                let monthGroupSales=0
                let monthPersonalCommission=0
                let monthGroupCommission=0
                let monthOverriding=0
                let monthLeadership=0
                let monthTotalCommission=0
                let monthReferral=0
                for(let historyDetail of response.data.logList){
                    if(historyDetail.type=="agentCommission"){
                        monthAgentCommission+=historyDetail.commission
                    }else if(historyDetail.type=="个人绩效Overflow"){
                        monthAgentCommission+=historyDetail.commission
                    }else if(historyDetail.type=="personal"){
                        monthPersonalSales+=historyDetail.history.value
                        monthPersonalCommission+=historyDetail.commission
                    }else if(historyDetail.type=="group"){
                        monthGroupSales+=historyDetail.history.value
                        monthGroupCommission+=historyDetail.commission
                    }else if(historyDetail.type=="overriding"){
                        monthOverriding+=historyDetail.commission
                    }else if(historyDetail.type=="leadership"){
                        monthLeadership+=historyDetail.commission
                    }else if(historyDetail.type=="referral"){
                        monthReferral+=historyDetail.referral
                    }
                }
                //calculate this month total commission
                monthTotalCommission=monthAgentCommission+monthOverriding+monthLeadership
                if(response.data.employeeDetail.tier.personalGoalReward>0&&monthPersonalCommission>=monthGroupCommission){
                    if(monthPersonalCommission<response.data.employeeDetail.tier.personalGoalReward){
                        monthTotalCommission+=monthPersonalCommission
                    }else{
                        monthTotalCommission+=response.data.employeeDetail.tier.personalGoalReward
                    }
                }else if(response.data.employeeDetail.tier.groupGoalReward>0&&monthGroupCommission>monthPersonalCommission){
                    if(monthGroupCommission<response.data.employeeDetail.tier.groupGoalReward){
                        monthTotalCommission+=monthGroupCommission
                    }else{
                        monthTotalCommission+=response.data.employeeDetail.tier.groupGoalReward
                    }
                }

                monthAgentCommission=monthAgentCommission.toLocaleString()
                monthPersonalSales=monthPersonalSales.toLocaleString()
                monthGroupSales=monthGroupSales.toLocaleString()
                monthOverriding=monthOverriding.toLocaleString()
                monthLeadership=monthLeadership.toLocaleString()
                monthTotalCommission=monthTotalCommission.toLocaleString()
                monthReferral=monthReferral.toLocaleString()

                //convert english to chinese
                for(let historyDetail of response.data.logList){
                    if(historyDetail.type=="agentCommission"){
                        if(history.courseType=="backend"){
                            historyDetail.type="成交奖金"
                        }else{
                            historyDetail.type="成交奖金"
                        }
                    }else if(historyDetail.type=="personal"){
                        historyDetail.type="绩效奖金"
                    }else if(historyDetail.type=="group"){
                        historyDetail.type="组织业绩"
                    }else if(historyDetail.type=="overriding"){
                        historyDetail.type="差额奖金"
                    }else if(historyDetail.type=="leadership"){
                        historyDetail.type="领导奖金"
                    }else if(historyDetail.type=="referral"){
                        historyDetail.type="流量奖金"
                    }else if(historyDetail.type=="个人绩效Overflow"){
                        historyDetail.type="绩效Overflow"
                    }
                }

                this.setState({
                    loading:false,
                    achievementData:response.data.logList,
                    employeeDetail:response.data.employeeDetail,
                    censoredColumn:response.data.censoredColumn,
                    monthAgentCommission:monthAgentCommission,
                    monthPersonalSales:monthPersonalSales,
                    monthGroupSales:monthGroupSales,
                    monthPersonalCommission:monthPersonalCommission,
                    monthGroupCommission:monthGroupCommission,
                    monthOverriding:monthOverriding,
                    monthLeadership:monthLeadership,
                    monthTotalCommission:monthTotalCommission,
                    monthReferral:monthReferral,
                })
            })
            .catch(error =>{
                alert("Load achievement error\n"+error)
                this.setState({loading:false})
            })
    }

    fallbackCopyTextToClipboard=(text)=>{
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
    copyTextToClipboard=(text)=>{
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            alert('Copy to clipboard successful');
        }, function(err) {
            alert('Could not copy text: ', err);
        });
    }
    copyList=()=>{
        let theText=this.state.requestData+"\n"
        theText+="Agent Commission: RM"+this.state.monthAgentCommission+"\t"
        theText+="Personal Sales: RM"+this.state.monthPersonalSales+"\t"
        theText+="Group Sales: RM"+this.state.monthGroupSales+"\t"
        theText+="Personal Commission: RM"+this.state.monthPersonalCommission.toLocaleString()+"\t"
        theText+="Group Commission: RM"+this.state.monthGroupCommission.toLocaleString()+"\t"
        theText+="Overriding: RM"+this.state.monthOverriding+"\t"
        theText+="Leadership: RM"+this.state.monthLeadership+"\t"
        theText+="Date\tAgenda\tValue(RM)\tCommission(RM)\n"
        for(let item of this.state.achievementData){
            theText+=item.createDate+"\t"
            theText+=item.agenda+"\t"
            theText+=(parseFloat(item.value).toLocaleFixed(2))+"\t"
            theText+=(parseFloat(item.commission).toLocaleFixed(2))+"\n"
        }
        GLOBAL.copyTextToClipboard(theText)
    }

    loadAgentTierList=()=>{
        this.setState({loading:true})
        const payload={

        }
        axios
            .post(`sales/loadagenttierlist`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    agentTierModal:true,
                    agentTierList:response.data,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Load agent tier list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    editBankDetailFunction=()=>{
        this.setState({loading:true})
        const payload={
            employeeID:this.state.employeeDetail.id,
            bankName:this.state.editBankName,
            accountName:this.state.editAccountName,
            accountNumber:this.state.editAccountNumber,
        }
        axios
            .post(`sales/editagentbankdetail`,qs.stringify(payload))
            .then(async response => {
                alert("Bank detail successfully edited")
                this.setState({editBankDetailModal:false})
                if(this.agentID){
                    this.loadEmployeeAchievement(this.agentID)
                }else{
                    this.loadEmployeeAchievement()
                }
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Edit bank detail error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    checkCensored=(columnName)=>{
        for(let col of this.state.censoredColumn){
            if(col==columnName){
                return true
            }
        }
        return false
    }

    render(){
        return(
            <div style={{}}>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal isOpen={this.state.editBankDetailModal} toggle={()=>this.setState({editBankDetailModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",borderRadius:5}}>
                        <div style={{fontSize:20,fontWeight:"bold",marginBottom:15}}>Edit Bank Details</div>
                        <div>Bank Name</div>
                        <Input value={this.state.editBankName} onChange={(e)=>{this.setState({editBankName:e.target.value})}} />
                        <div>Bank Account Name</div>
                        <Input value={this.state.editAccountName} onChange={(e)=>{this.setState({editAccountName:e.target.value})}} />
                        <div>Bank Account Number</div>
                        <Input value={this.state.editAccountNumber} onChange={(e)=>{this.setState({editAccountNumber:e.target.value.replace(/\D/g,'')})}} />
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,gap:10,width:"100%"}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.editBankDetailFunction()
                            }}>
                                Confirm
                            </Button>
                            <Button color="danger" style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({editBankDetailModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.remarkModal} toggle={()=>this.setState({remarkModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                        {this.state.remarkText!=null?this.state.remarkText.split("\n").map((line)=>{return(<div>{line}</div>)}):null}
                    </div>
                </Modal>

                <Modal isOpen={this.state.agentTierModal} toggle={()=>this.setState({agentTierModal:false})} style={{maxWidth:900}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:5,justifyContent:"center"}}>
                        {
                        this.state.agentTierList.map((tier)=>{return(
                            <div style={{display:"flex",flexDirection:"row",border:"1px solid lightgrey",width:"100%",marginBottom:5,padding:10,borderRadius:10}}>
                                <div style={{flex:1,display:"flex",flexDirection:"column"}}>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",alignItems:"center"}}>
                                        <div style={{fontWeight:"bold"}}>{tier.name}</div>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",width:"100%",gap:5,fontSize:13,flexWrap:"wrap",paddingBottom:5,alignItems:"center",justifyContent:"center"}}>
                                        <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                            <div style={{whiteSpace:"nowrap"}}>前端课commission: {tier.commission}%</div>
                                            <div style={{whiteSpace:"nowrap"}}>战略伙伴commission: {tier.spCommission}%</div>
                                            <div style={{whiteSpace:"nowrap"}}>原始流量commission: 10%</div>
                                        </div>
                                        {
                                        (tier.frontendPersonalCommission==true||tier.backendPersonalCommission==true)&&(tier.frontendGroupCommission==true||tier.backendGroupCommission==true)?
                                            <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                                <div style={{whiteSpace:"nowrap"}}>绩效目标: RM{tier.personalGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div style={{whiteSpace:"nowrap"}}>绩效奖金: RM{tier.personalGoalReward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div>OR</div>
                                                <div style={{whiteSpace:"nowrap"}}>团队绩效目标: RM{tier.groupGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div style={{whiteSpace:"nowrap"}}>团队绩效奖金: RM{tier.groupGoalReward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            </div>
                                            :
                                        tier.frontendPersonalCommission==true||tier.backendPersonalCommission==true?
                                            <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                                <div style={{whiteSpace:"nowrap"}}>绩效目标: RM{tier.personalGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div style={{whiteSpace:"nowrap"}}>绩效奖金: RM{tier.personalGoalReward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            </div>
                                            :
                                        tier.frontendGroupCommission==true||tier.backendGroupCommission==true?
                                            <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                                <div style={{whiteSpace:"nowrap"}}>团队绩效目标: RM{tier.groupGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                <div style={{whiteSpace:"nowrap"}}>团队绩效奖金: RM{tier.groupGoalReward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            </div>
                                            :
                                            null
                                        }
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",border:"1px solid lightgrey",flexWrap:"nowrap",
                                            padding:5,borderRadius:5}}>
                                            <div style={{marginRight:5}}>Commission Overriding</div>
                                            {
                                            tier.commissionOverriding==true?
                                                <ImCheckmark style={{color:"green"}}/>
                                                :
                                                <ImCross style={{color:"red"}} />
                                            }
                                        </div>
                                        {
                                        tier.validForLeadershipBonus?
                                            <div style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",padding:5,borderRadius:5}}>
                                                <div style={{whiteSpace:"nowrap"}}>前端课Leadership Bonus: {tier.frontendLeadershipBonus}%</div>
                                                <div style={{whiteSpace:"nowrap"}}>战略伙伴Leadership Bonus: {tier.backendLeadershipBonus}%</div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div style={{fontSize:13,textAlign:"left",flex:1}}>
                                    {
                                    tier.requirement!=null?
                                        tier.requirement.split("\n").map((line)=>{
                                            return(
                                                <div>{line}</div>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        )})
                        }
                        </div>
                    </div>
                </Modal>

                <NavigationBar currentPage="achievement" />
                {
                this.state.employeeDetail!=null?
                    <div style={{padding:10,display:"flex",flexDirection:"column"}}>
                        <div style={{fontWeight:"bold",fontSize:20}}>Achievement</div>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",alignItems:"center"}}>
                            <div style={{}}>
                                Agent: {this.state.employeeDetail.member.username} {this.state.employeeDetail.member.name}
                            </div>

                            <div style={{marginLeft:20}}>Company: {this.state.employeeDetail.company}</div>

                            {
                            this.checkCensored("Tier")==false?
                                <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                    <div style={{marginLeft:20}}>Tier: {this.state.employeeDetail.tier.name}</div>
                                    <BsFillInfoCircleFill style={{color:"skyblue",marginLeft:5,cursor:"pointer"}} onClick={()=>{
                                        this.loadAgentTierList()
                                    }}/>
                                </div>
                                :
                                null
                            }
                            
                            {
                            this.checkCensored("Master")==false?
                                <div style={{marginLeft:20}}>上线: {
                                    this.state.employeeDetail.master!=null?
                                        this.state.employeeDetail.master.username==this.state.employeeDetail.master.name?
                                            this.state.employeeDetail.master.name+" - "+this.state.employeeDetail.master.tierName
                                            :
                                            this.state.employeeDetail.master.username+" "+this.state.employeeDetail.master.name+" - "+this.state.employeeDetail.master.tierName
                                        :
                                        "-"
                                    }
                                </div>
                                :
                                null
                            }
                            <div style={{flex:1}} />
                            <div style={{marginLeft:10,fontSize:12,display:"flex",flexDirection:"row"}}>
                                <div style={{}}>
                                    <div>Bank Name: {this.state.employeeDetail.bankName}</div>
                                    <div>Bank Account Name: {this.state.employeeDetail.bankAccountName}</div>
                                    <div>Bank Account Number: {this.state.employeeDetail.bankAccountNumber}</div>
                                </div>
                                {
                                this.agentID==null?
                                    <div>
                                        <Button color="light" style={{marginLeft:10,fontSize:12,padding:"5px 5px",border:"1px solid grey"}} onClick={()=>{
                                            this.setState({
                                                editBankDetailModal:true,
                                                editBankName:this.state.employeeDetail.bankName,
                                                editAccountName:this.state.employeeDetail.bankAccountName,
                                                editAccountNumber:this.state.employeeDetail.bankAccountNumber,
                                            })
                                        }}>
                                            Edit
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner />
                }

                <div style={{paddingLeft:10,paddingRight:10,paddingBottom:60}}>
                    {/*<div>Total Sales: RM{this.state.totalSales}</div>*/}
                    <div style={{fontSize:20,fontWeight:"bold",marginLeft:10,marginRight:10,marginBottom:10,display:"flex",flexDirection:"row",alignItems:"center"}}>
                        <Input type="month" name="Month" value={this.state.requestDate} onChange={(e)=>{
                            this.setState({requestDate:e.target.value},()=>{
                                if(this.agentID){
                                    this.loadEmployeeAchievement(this.agentID)
                                }else{
                                    this.loadEmployeeAchievement()
                                }
                            })
                        }} />
                        <Button color="primary" style={{marginLeft:10,color:"white",display:"flex",flexDirection:"center",alignItems:"center"}} onClick={()=>{
                            let date=new Date(this.state.requestDate)
                            let d = date.getDate()
                            date.setMonth(date.getMonth() -1)
                            let monthString=""+(date.getMonth()+1)
                            if(monthString.length==1){
                                monthString="0"+monthString
                            }
                            let dateString=date.getFullYear()+"-"+monthString
                            this.setState({requestDate:dateString},()=>{
                                if(this.agentID){
                                    this.loadEmployeeAchievement(this.agentID)
                                }else{
                                    this.loadEmployeeAchievement()
                                }
                            })
                        }}>
                            <MdNavigateBefore style={{color:"white",fontSize:22}} />
                        </Button>
                        <Button color="primary" style={{marginLeft:5,color:"white",display:"flex",flexDirection:"center",alignItems:"center"}} onClick={()=>{
                            let date=new Date(this.state.requestDate)
                            let d = date.getDate()
                            date.setMonth(date.getMonth()+1)
                            let monthString=""+(date.getMonth()+1)
                            if(monthString.length==1){
                                monthString="0"+monthString
                            }
                            let dateString=date.getFullYear()+"-"+monthString
                            this.setState({requestDate:dateString},()=>{
                                if(this.agentID){
                                    this.loadEmployeeAchievement(this.agentID)
                                }else{
                                    this.loadEmployeeAchievement()
                                }
                            })
                        }}>
                            <MdNavigateNext style={{color:"white",fontSize:22}} />
                        </Button>
                        {/*this.month[(parseInt(monthArray[0].createDate.split(".")[1])-1)]+" "+monthArray[0].createDate.split(" ")[0].split(".")[2]*/}
                    </div>
                    <div style={{display:"flex",flexDirection:"row",padding:5,width:"100%",
                        borderStyle:"solid",borderColor:"lightgrey",borderWidth:0,borderTopWidth:1,borderBottomWidth:1}}>
                        <div style={{flex:0.1}}>Date</div>
                        <div style={{flex:0.1}}>Invoice No.</div>
                        <div style={{flex:0.25}}>Agenda</div>
                        <div style={{flex:0.1}}>Agent</div>
                        <div style={{flex:0.1}}>Referrer</div>
                        <div style={{flex:0.1,textAlign:"center"}}>Type</div>
                        <div style={{flex:0.15,textAlign:"right",paddingRight:10}}>Value (RM)</div>
                        <div style={{flex:0.05,textAlign:"right"}}>Commission (RM)</div>
                        <div style={{flex:0.05,textAlign:"right"}}>Referral (RM)</div>
                        <div style={{flex:0.05,textAlign:"center"}}>Percentage (%)</div>
                        <div style={{flex:0.05,textAlign:"center"}}>Remark</div>
                    </div>
                    <div>
                        {
                        this.state.employeeDetail!=null?
                            <div style={{display:"flex",flexDirection:"row",marginBottom:5,alignItems:"center"}}>
                                <div style={Object.assign({marginLeft:10,display:"flex",flexDirection:"column",border:"1px solid",padding:10,borderRadius:5},
                                    this.state.employeeDetail.tier.personalGoalReward>0&&this.state.monthPersonalCommission>=this.state.monthGroupCommission?
                                        {borderColor:"blue",color:"black"}
                                        :
                                        {borderColor:"lightgrey",color:"lightgrey"}
                                    )}>
                                    <div>绩效业绩: RM {this.state.monthPersonalSales} / {this.state.employeeDetail.tier.personalGoal>0?this.state.employeeDetail.tier.personalGoal.toLocaleString():"-"}</div>
                                    <div>绩效奖金: RM {
                                        this.state.monthPersonalCommission<this.state.employeeDetail.tier.personalGoalReward?
                                            this.state.monthPersonalCommission.toLocaleString()
                                            :
                                            this.state.employeeDetail.tier.personalGoalReward.toLocaleString()
                                    } / {this.state.employeeDetail.tier.personalGoalReward>0?this.state.employeeDetail.tier.personalGoalReward.toLocaleString():"-"}</div>
                                </div>
                                <div style={Object.assign({marginLeft:10,display:"flex",flexDirection:"column",border:"1px solid",padding:10,borderRadius:5},
                                    this.state.employeeDetail.tier.groupGoalReward>0&&this.state.monthGroupCommission>=this.state.monthPersonalCommission?
                                        {borderColor:"blue",color:"black"}
                                        :
                                        {borderColor:"lightgrey",color:"lightgrey"}
                                    )}>
                                    <div>组织业绩: RM {this.state.monthGroupSales} / {this.state.employeeDetail.tier.groupGoal>0?this.state.employeeDetail.tier.groupGoal.toLocaleString():"-"}</div>
                                    <div>组织绩效工资: RM {this.state.monthGroupCommission.toLocaleString()} / {this.state.employeeDetail.tier.groupGoalReward>0?this.state.employeeDetail.tier.groupGoalReward.toLocaleString():"-"}</div>
                                </div>

                                <div style={{marginLeft:10,display:"flex",flexDirection:"column"}}>
                                    <div>课程奖金: RM{this.state.monthAgentCommission}</div>
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <div style={{}}>Overriding: RM{this.state.monthOverriding}</div>
                                        <div style={{marginLeft:20}}>领导奖金: RM{this.state.monthLeadership}</div>
                                    </div>
                                </div>
                                <div style={{marginLeft:25,fontWeight:"bold",fontSize:16}}>
                                    Total Commission: RM{this.state.monthTotalCommission.toLocaleString()}
                                </div>
                                <div style={{marginLeft:25,fontWeight:"bold",fontSize:16}}>
                                    Total Referral: RM{this.state.monthReferral.toLocaleString()}
                                </div>
                                <div style={{flex:1}}/>
                                <Button color="light" style={{marginLeft:10,padding:0,paddingLeft:5,paddingRight:5,fontSize:12}} onClick={()=>{
                                    this.copyList()
                                }}>
                                    Copy
                                </Button>
                            </div>
                            :
                            null
                        }
                        {
                        this.state.achievementData.map((item)=>{
                            return(
                                <div style={{display:"flex",flexDirection:"row",padding:5,borderStyle:"solid",width:"100%",
                                    borderColor:"lightgrey",borderWidth:1,borderRadius:5,
                                    color:
                                        item.type=="绩效奖金"?
                                            this.state.employeeDetail.tier.personalGoalReward<=0?
                                                "lightgrey"
                                                :
                                            this.state.monthPersonalCommission>this.state.monthGroupCommission?
                                                "black"
                                                :
                                                "lightgrey"
                                            :
                                        item.type=="组织业绩"?
                                            this.state.employeeDetail.tier.groupGoalReward<=0?
                                                "lightgrey"
                                                :
                                            this.state.monthGroupCommission>=this.state.monthPersonalCommission?
                                                "black"
                                                :
                                                "lightgrey"
                                            :
                                            "black",
                                }}>
                                    <div style={{flex:0.1}}>{item.history.createDate}</div>
                                    <div style={{flex:0.1}}>{item.history.invoiceNumber}</div>
                                    <div style={{flex:0.25}}>{item.history.agenda}</div>
                                    <div style={{flex:0.1}}>
                                        {
                                        item.history.employee!=null?
                                            item.history.employee.username==item.history.employee.name?
                                                item.history.employee.name+" "+item.history.employee.tierName
                                                :
                                                item.history.employee.username+" "+item.history.employee.name+" "+item.history.employee.tierName
                                            :
                                            "-"
                                        }
                                    </div>
                                    <div style={{flex:0.1}}>
                                        {
                                        item.referrer!=null?
                                            item.referrer.username==item.referrer.name?
                                                item.referrer.name
                                                :
                                                item.referrer.username+" "+item.referrer.name
                                            :
                                            "-"
                                        }
                                        {
                                        item.referrer!=null&&item.referrer.tier!=null?
                                            " ("+item.referrer.tier.name+")"
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{flex:0.1,textAlign:"center"}}>{item.type}</div>
                                    <div style={{flex:0.15,textAlign:"right",paddingRight:10}}>{item.history.value.toLocaleString()}</div>
                                    <div style={{flex:0.05,textAlign:"right"}}>
                                        {item.commission!=null&&item.commission!=0?item.commission.toLocaleString():"-"}
                                    </div>
                                    <div style={{flex:0.05,textAlign:"right"}}>
                                        {item.referral!=null&&item.referral!=0?item.referral.toLocaleString():"-"}
                                    </div>
                                    <div style={{flex:0.05,textAlign:"center"}}>{item.percentage>=0?item.percentage:"-"}</div>
                                    <div style={{flex:0.05,textAlign:"center"}}>
                                        <BsFillInfoCircleFill style={{cursor:"pointer",color:"skyblue"}} onClick={()=>{
                                            this.setState({
                                                remarkModal:true,
                                                remarkText:item.remark,
                                            })
                                        }}/>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

let styles={

}
