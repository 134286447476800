import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal,Form} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'

export default class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            activated:true,
            verificationToken:null,
            notificationSettingList:[]
        }
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        this.checkEmployeeActivateTelegram()
    }

    checkEmployeeActivateTelegram=()=>{
        this.setState({loading:true})
        const payload = {
            
        }
        axios
            .post(`sales/checkemployeeactivatetelegram`, qs.stringify(payload),{timeout:20000})
            .then(async response => {
                if(response.data.activated==true){
                    this.setState({
                        activated:true,
                        notificationSettingList:response.data.notificationSettingList,
                    })
                }else{
                    this.setState({
                        activated:false,
                        verificationToken:response.data.verificationToken,
                    })
                }
                this.setState({loading:false})
            })
            .catch(error =>{
                if(error.response){
                    
                }
                alert("Check employee telegram activated error\n"+error)
                this.setState({loading:false})
            })
    }

    validateTelegramActivation=()=>{
        this.setState({loading:true})
        const payload = {
            
        }
        axios
            .post(`sales/validatetelegramactivation`, qs.stringify(payload),{timeout:60000})
            .then(async response => {
                alert("Validation success")
                this.setState({loading:false,activated:true})
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==444){
                        alert("Validation failed, please try to send the verification code again.")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Validate Telegram activation error\n"+error)
                this.setState({loading:false})
            })
    }

    changeTopicSubscription=(topic)=>{
        this.setState({loading:true})
        const payload = {
            topicID:topic.id
        }
        axios
            .post(`sales/changetopicsubscription`, qs.stringify(payload),{timeout:60000})
            .then(async response => {
                this.setState({
                    loading:false,
                    notificationSettingList:response.data,
                })
            })
            .catch(error =>{
                if(error.response){
                    
                }
                alert("Change Telegram topic subscription error\n"+error)
                this.setState({loading:false})
            })
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <NavigationBar currentPage="notifications" />
                    <div style={{padding:20,display:"flex",flexDirection:"column",alignItems:"center",width:"100%"}}>
                        {
                        this.state.activated==false?
                            <div style={{padding:30,display:"flex",flexDirection:"column",alignItems:"flex-start",
                                gap:15,border:"1px solid lightgrey",borderRadius:20,maxWidth:600}}>
                                <div style={{width:"100%",textAlign:"center",fontWeight:"bold"}}>Activate Telegram Notification</div>
                                <div>1. Install Telegram</div>
                                <div style={{}}>
                                    2. Click this button below to open a chat with the bot
                                </div>
                                <Button color="primary" style={{width:"100%"}} onClick={()=>{
                                    window.open("https://t.me/EnllianceNotificationBot","_blank")
                                }}>
                                    Activate Enlliancesales Telegram Notification Bot
                                </Button>
                                <div style={{}}>
                                    3. Click "Start" button in chat with the Telegram bot
                                </div>
                                <div>
                                    4. As the bot instructed, give the bot this verification token below to verify your subscription
                                </div>
                                <div style={{padding:20,backgroundColor:"grey",color:"white",width:"100%",fontWeight:"bold",fontSize:24,textAlign:"center"}}>
                                    VC_{this.state.verificationToken}
                                </div>
                                <div>
                                    5. Validate with button below after completing above steps
                                </div>
                                <Button color="warning" style={{width:"100%"}} onClick={()=>{
                                    this.validateTelegramActivation()
                                }}>
                                    Validate
                                </Button>
                            </div>
                            :
                            <div style={{display:"flex",flexDirection:"column",width:"100%",maxWidth:600,gap:15}}>
                                <div style={{fontWeight:"bold",fontSize:20,textAlign:"center",width:"100%"}}>Notification Settings</div>
                                {
                                this.state.notificationSettingList.map((category)=>{
                                    return(
                                        <div style={{display:"flex",flexDirection:"column",gap:10}}>
                                            <div style={{color:"steelblue",fontSize:18,fontWeight:"bold"}}>{category.title}</div>
                                            {
                                            category.topicList.map((topic)=>{
                                                return(
                                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"0px 0px 0px 30px"}}>
                                                        <Input type="checkbox" checked={topic.enabled} style={{marginBottom:5,width:22,height:22,cursor:"pointer"}} onClick={()=>{
                                                            this.changeTopicSubscription(topic)
                                                        }}/>
                                                        <div style={{marginLeft:10}}>{topic.title}</div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>
                        }
                    </div>
            </div>
        )
    }
}

Notifications = Radium(Notifications);

let styles={

}
